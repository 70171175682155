var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$crc_32.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var uY,hha,iha,CY,jha,kha,mha,yY,nha,lha;uY=function(a){return a+1};$CLJS.vY=function(a){if("string"===typeof a){var b=$CLJS.mh(/[+-]?\d+/,a);return $CLJS.n(b)?(a=parseInt(a),a<=Number.MAX_SAFE_INTEGER&&a>=Number.MIN_SAFE_INTEGER?a:null):b}throw Error($CLJS.bm(a));};
hha=function(a){var b=new $CLJS.Fa,c=$CLJS.hw;$CLJS.hw=new $CLJS.qc(b);try{var d=$CLJS.hw,e=$CLJS.Sa($CLJS.xx(d)),f=$CLJS.hw;$CLJS.hw=e?$CLJS.ox(d):d;try{d=$CLJS.zx;$CLJS.zx=!0;try{$CLJS.Bx(a)}finally{$CLJS.zx=d}$CLJS.F.g(0,$CLJS.pw($CLJS.hw,$CLJS.rw))||$CLJS.Zb($CLJS.hw,"\n");$CLJS.ow()}finally{$CLJS.hw=f}$CLJS.th($CLJS.p.h(b))}finally{$CLJS.hw=c}};$CLJS.wY=function(a,b){return $CLJS.O.j($CLJS.ha,a,b)};
$CLJS.xY=function(a){var b=new $CLJS.Fa,c=$CLJS.Oa,d=$CLJS.Pa;$CLJS.Oa=!0;$CLJS.Pa=function(e){return b.append(e)};try{hha(a)}finally{$CLJS.Pa=d,$CLJS.Oa=c}return $CLJS.p.h(b)};
iha=function(a){a=$CLJS.Ee(a);var b=$CLJS.I.j(a,$CLJS.HX,$CLJS.Sd),c=$CLJS.I.j(a,$CLJS.EX,function(f,k){return[$CLJS.p.h(f),"_",$CLJS.p.h(k)].join("")}),d=$CLJS.Re($CLJS.N),e=$CLJS.Re($CLJS.N);return function(){function f(m,t){for(;;){var u=b.h?b.h(t):b.call(null,t),v=function(){var A=new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),D=$CLJS.q(d);return D.h?D.h(A):D.call(null,A)}();if($CLJS.n(v))return v;var x=$CLJS.I.g($CLJS.Nh.A(e,$CLJS.Lk,u,$CLJS.EG(uY,0)),u);if($CLJS.F.g(x,1))return $CLJS.Nh.A(d,$CLJS.R,
new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null),t),t;t=function(){var A=t,D=$CLJS.p.h(x);return c.g?c.g(A,D):c.call(null,A,D)}()}}function k(m){var t=l,u=t.g;null==yY&&(yY=$CLJS.Re(0));var v=$CLJS.Eh.h([$CLJS.p.h("G__"),$CLJS.p.h($CLJS.Nh.g(yY,uY))].join(""));return u.call(t,v,m)}var l=null;l=function(m,t){switch(arguments.length){case 1:return k.call(this,m);case 2:return f.call(this,m,t)}throw Error("Invalid arity: "+arguments.length);};l.h=k;l.g=f;return l}()};
$CLJS.zY=function(a){return $CLJS.zd(a)&&1<$CLJS.E(a)&&$CLJS.B(a)instanceof $CLJS.M&&$CLJS.xd($CLJS.id(a))&&$CLJS.Hd($CLJS.id(a),$CLJS.fH)};$CLJS.AY=function(a){return $CLJS.zY(a)?$CLJS.ED(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,$CLJS.FH],null)):null};$CLJS.BY=function(a,b){return $CLJS.RN($CLJS.zY(a)?a:new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,2,[$CLJS.fH,$CLJS.p.h($CLJS.oH()),$CLJS.Fi,$CLJS.sH(a)],null),a],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,$CLJS.FH],null),b)};
CY=function(a,b,c){var d=$CLJS.Hd(a,b)?$CLJS.Lk.j(a,b,function(e){return $CLJS.zd(e)&&$CLJS.F.g($CLJS.B(e),$CLJS.vt)?$CLJS.Bf($CLJS.We(1,e)):new $CLJS.P(null,1,5,$CLJS.Q,[e],null)}):a;return $CLJS.Hd(a,b)?$CLJS.CX(d,$CLJS.De([b,c])):d};jha=function(a){var b=$CLJS.Yl(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eR,$CLJS.JS],null));b=DY.h?DY.h(b):DY.call(null,b);return $CLJS.pY($CLJS.R.l(CY($CLJS.Ak.l(a,$CLJS.eR,$CLJS.G([$CLJS.JS])),$CLJS.TT,$CLJS.yN),$CLJS.Bj,$CLJS.qN,$CLJS.G([$CLJS.vN,b])))};
kha=function(a){return $CLJS.Kk.g(jha,a)};mha=function(a){return $CLJS.n(a)?$CLJS.R.j($CLJS.Lk.j($CLJS.Fl(a)?new $CLJS.h(null,1,[$CLJS.EY,a],null):a,$CLJS.EY,function(b){return $CLJS.Kk.g(function(c){return $CLJS.R.j($CLJS.FD(c,$CLJS.SD),$CLJS.Bj,$CLJS.rY)},b)}),$CLJS.Bj,lha):null};
$CLJS.FY=function(a){if($CLJS.F.g($CLJS.Bj.h(a),$CLJS.gY))return a;var b=$CLJS.jj.h(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "native":return $CLJS.$k.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.gY,$CLJS.vN,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$k.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.Bj,$CLJS.aY],null),$CLJS.CX($CLJS.jS.h(a),new $CLJS.h(null,1,[$CLJS.FO,$CLJS.jS],null))]))],null)],null),$CLJS.Ak.l(a,$CLJS.jj,$CLJS.G([$CLJS.jS]))]));case "query":return $CLJS.$k.l($CLJS.G([new $CLJS.h(null,
2,[$CLJS.Bj,$CLJS.gY,$CLJS.vN,DY($CLJS.FO.h(a))],null),$CLJS.Ak.l(a,$CLJS.jj,$CLJS.G([$CLJS.FO]))]));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};$CLJS.GY=function(a,b){a=$CLJS.Ee(a);a=$CLJS.I.g(a,$CLJS.vN);var c=0>b?$CLJS.E(a)+b:b;if(c>=$CLJS.E(a)||0>c)throw $CLJS.hi($CLJS.SG("Stage {0} does not exist",$CLJS.G([b])),new $CLJS.h(null,1,[nha,$CLJS.E(a)],null));return c};
$CLJS.HY=function(a,b){a=$CLJS.FY(a);a=$CLJS.Ee(a);var c=$CLJS.I.g(a,$CLJS.vN);return $CLJS.I.g($CLJS.Bf(c),$CLJS.GY(a,b))};$CLJS.IY=function(a){return"string"===typeof a&&(a=$CLJS.nh(/^card__(\d+)$/,a),$CLJS.n(a))?($CLJS.H(a,0,null),a=$CLJS.H(a,1,null),$CLJS.vY(a)):null};$CLJS.KY=function(){return $CLJS.Ik.g(JY,iha($CLJS.G([$CLJS.HX,$CLJS.PD,$CLJS.EX,function(a,b){return JY.h([$CLJS.p.h(a),"_",$CLJS.p.h(b)].join(""))}])))};yY=null;$CLJS.LY=new $CLJS.M("lib","source","lib/source",-434086550);
$CLJS.MY=new $CLJS.M("metadata","segment","metadata/segment",-1286738015);$CLJS.NY=new $CLJS.M("metadata","table","metadata/table",-882499405);$CLJS.OY=new $CLJS.M(null,"stage-number","stage-number",-1752729638);$CLJS.PY=new $CLJS.M(null,"dimensions","dimensions",-254818097);nha=new $CLJS.M(null,"num-stages","num-stages",1426797535);$CLJS.QY=new $CLJS.M(null,"table-id","table-id",-766649466);$CLJS.EY=new $CLJS.M(null,"columns","columns",1998437288);
$CLJS.RY=new $CLJS.M("source","breakouts","source/breakouts",-1706018158);$CLJS.oha=new $CLJS.M("metadata","database","metadata/database",-924828824);$CLJS.SY=new $CLJS.M(null,"table","table",-564943036);$CLJS.TY=new $CLJS.M("metadata","card","metadata/card",-1039333889);lha=new $CLJS.M("metadata","results","metadata/results",330329298);$CLJS.UY=new $CLJS.M("source","aggregations","source/aggregations",-1828710137);var DY,JY;
DY=function DY(a){a=$CLJS.Ee(a);var c=$CLJS.I.g(a,$CLJS.JS),d=$CLJS.I.g(a,$CLJS.RQ);c=$CLJS.n(c)?DY.h?DY.h(c):DY.call(null,c):$CLJS.zf;d=mha(d);var e=$CLJS.E(c)-1;c=$CLJS.n($CLJS.n(d)?!(0>e):d)?$CLJS.RN(c,new $CLJS.P(null,2,5,$CLJS.Q,[e,$CLJS.qX],null),d):c;d=$CLJS.n($CLJS.jS.h(a))?$CLJS.aY:$CLJS.VX;a=$CLJS.$k.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.Bj,d],null),$CLJS.Ak.l(a,$CLJS.JS,$CLJS.G([$CLJS.RQ]))]));a=$CLJS.z($CLJS.CP.h(a))?$CLJS.Lk.j(a,$CLJS.CP,kha):a;a=CY(a,$CLJS.gP,$CLJS.hY);return $CLJS.$d.g(c,a)};
$CLJS.VY=function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.y(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e,f){c=$CLJS.FY(c);c=$CLJS.Ee(c);var k=$CLJS.I.g(c,$CLJS.vN);d=$CLJS.GY(c,d);e=$CLJS.O.N($CLJS.Lk,$CLJS.Bf(k),d,e,f);return $CLJS.R.j(c,$CLJS.vN,e)}a.v=3;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.C(c);var f=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,f,c)};a.l=
b;return a}();
JY=function(){function a(d,e){if((new TextEncoder).encode(d).length<=e)return d;var f;a:for(f=($CLJS.mY.str(d,0)>>>0).toString(16);;)if(8>$CLJS.E(f))f=["0",$CLJS.p.h(f)].join("");else break a;e=new Uint8Array(e-9);e=(new TextEncoder).encodeInto(d,e);return[d.substring(0,e.read),"_",$CLJS.p.h(f)].join("")}function b(d){return c.g?c.g(d,60):c.call(null,d,60)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);
};c.h=b;c.g=a;return c}();$CLJS.WY=RegExp(" id$","i");