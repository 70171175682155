var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var J_=function(a,b){var c=$CLJS.z(b);for(a=$CLJS.z($CLJS.We(a,b));;)if(a)c=$CLJS.C(c),a=$CLJS.C(a);else return c},gia=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj.j?$CLJS.jj.j(k,l,m):$CLJS.jj.call(null,k,l,m),$CLJS.fl.j?$CLJS.fl.j(k,l,m):$CLJS.fl.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj.g?$CLJS.jj.g(k,l):$CLJS.jj.call(null,k,l),$CLJS.fl.g?$CLJS.fl.g(k,l):$CLJS.fl.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.jj.h?$CLJS.jj.h(k):$CLJS.jj.call(null,k),$CLJS.fl.h?$CLJS.fl.h(k):$CLJS.fl.call(null,k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj.o?$CLJS.jj.o():$CLJS.jj.call(null),$CLJS.fl.o?$CLJS.fl.o():$CLJS.fl.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var A=Array(arguments.length-3);x<A.length;)A[x]=arguments[x+3],++x;x=new $CLJS.y(A,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.O.N($CLJS.jj,m,t,u,v),$CLJS.O.N($CLJS.fl,m,t,u,v)],null)}k.v=3;k.B=function(m){var t=$CLJS.B(m);m=$CLJS.C(m);var u=$CLJS.B(m);m=$CLJS.C(m);var v=$CLJS.B(m);m=$CLJS.Kc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+
3],++u;u=new $CLJS.y(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.v=3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()},K_=function(a){return $CLJS.LN($CLJS.kY)(a,$CLJS.zf,$CLJS.zf)},hia=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.zI,b),$CLJS.mu(2,2,c))},iia=function(a){return $CLJS.bf.g($CLJS.OX(a),$CLJS.PX(a))},L_=function(a,b,c){var d=new $CLJS.$g(null,
new $CLJS.h(null,2,[$CLJS.Rt,null,$CLJS.ll,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.fw(2,c):$CLJS.fw(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?J_(2,c):J_(1,c);var f=$CLJS.H(b,0,null);return $CLJS.z(e)?$CLJS.AZ.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.dg.j($CLJS.zf,$CLJS.Jk.h($CLJS.sl),l):$CLJS.xd(k)?$CLJS.KD($CLJS.Ta,l):l}):$CLJS.Ak.g(a,f)},kia=function(a){return $CLJS.ab(function(b,c){var d=$CLJS.H(c,0,null);$CLJS.H(c,1,null);return L_(b,jia,d)},a,
iia(a))},lia=function(a){for(var b=0;;){var c=$CLJS.bd($CLJS.vN.h(a),b);a:{var d=c;for(var e=$CLJS.zf;;){var f=$CLJS.B($CLJS.cf.g(gia(),$CLJS.ff($CLJS.Ik.j(M_,$CLJS.B,$CLJS.fl),$CLJS.Vt.h(K_(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);var l=L_(d,f,k),m=$CLJS.ND($CLJS.gC);if($CLJS.n($CLJS.MD("metabase.lib.convert",m))){var t=$CLJS.pE.l($CLJS.G([$CLJS.bD,$CLJS.Lh.l($CLJS.G([k]))])),u=$CLJS.pE,v=u.l,x=$CLJS.Lh,A=x.l;var D=f;D=$CLJS.n(D)?D:$CLJS.QN(K_(d));$CLJS.LD("metabase.lib.convert",
m,$CLJS.wY("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.G([t,v.call(u,$CLJS.G([$CLJS.bD,A.call(x,$CLJS.G([D]))])),$CLJS.pE.l($CLJS.G([$CLJS.RC,$CLJS.xY($CLJS.B($CLJS.Lv(d,l)))]))])),null)}if($CLJS.F.g(l,d))break a;d=l;e=$CLJS.$d.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=kia(d);if($CLJS.F.g(c,d)){if($CLJS.F.g(b,$CLJS.E($CLJS.vN.h(a))-1))return a;b+=1}else a=$CLJS.Lk.N(a,$CLJS.vN,$CLJS.R,b,d)}},mia=function(a){var b=$CLJS.KY();return $CLJS.Kk.g(function(c){return $CLJS.F.g($CLJS.BH.h(c),
"__join")?$CLJS.Lk.j(c,$CLJS.BH,b):c},a)},nia=function(a){return"string"===typeof $CLJS.eR.h(a)?$CLJS.Ak.g($CLJS.R.j(a,$CLJS.QX,$CLJS.IY($CLJS.eR.h(a))),$CLJS.eR):a},N_=function(a){return $CLJS.dg.j($CLJS.N,$CLJS.$l(function(b,c){$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);c=$CLJS.Ee(c);c=$CLJS.I.g(c,$CLJS.fH);return new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null)}),a)},P_=function(a,b){var c=$CLJS.I.j(a,b,O_);if($CLJS.F.g(c,O_))throw $CLJS.hi(["Unable to find ",$CLJS.Lh.l($CLJS.G([b]))," in map."].join(""),new $CLJS.h(null,
2,[oia,a,$CLJS.Vy,b],null));return c},Q_=function(){return $CLJS.Jk.h(function(a){var b=$CLJS.H(a,0,null);$CLJS.H(a,1,null);(a=$CLJS.ke(b))?(b=$CLJS.ge(b),b=$CLJS.n(b)?$CLJS.F.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})},R_=function(a){return $CLJS.dg.j($CLJS.N,Q_(),a)},S_=function(a){return $CLJS.Fe($CLJS.dg.j($CLJS.N,$CLJS.Ik.g(Q_(),$CLJS.Jk.h(function(b){var c=$CLJS.H(b,0,null);$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.Fi)})),a))},U_=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);
var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d=$CLJS.dg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.h($CLJS.T_),c);a=$CLJS.F.g(a,$CLJS.uI)&&$CLJS.C(c)?$CLJS.$d.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(S_(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rO,a,b],null):a},pia=function(a){return $CLJS.dg.j($CLJS.zf,$CLJS.Ik.g($CLJS.cf.h(function(b){return $CLJS.FD(b,$CLJS.tE)}),$CLJS.cf.h($CLJS.T_)),$CLJS.EY.h(a))},V_=function(a){a=$CLJS.Ee(a);a=$CLJS.I.g(a,
$CLJS.vN);a=$CLJS.B($CLJS.ab(function(b,c){var d=$CLJS.H(b,0,null),e=$CLJS.H(b,1,null);b=$CLJS.Q;var f=$CLJS.T_.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.JS,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.RQ,pia(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.qX.h(c)],null)},null,a));return $CLJS.n($CLJS.jS.h(a))?$CLJS.CX(a,new $CLJS.h(null,1,[$CLJS.jS,$CLJS.FO],null)):a},W_=function(a,b,c){var d=$CLJS.F.g($CLJS.E($CLJS.I.g(a,b)),1)?$CLJS.kZ.j(a,b,$CLJS.Ik.g($CLJS.T_,$CLJS.B)):a;a=1<$CLJS.E($CLJS.I.g(a,b))?$CLJS.kZ.j(d,
b,function(e){return $CLJS.dg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vt],null),$CLJS.cf.h($CLJS.T_),e)}):d;return $CLJS.CX(a,$CLJS.De([b,c]))},qia=function(a){var b=$CLJS.QX.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Ak.g(a,$CLJS.QX),$CLJS.eR,["card__",$CLJS.p.h(b)].join("")):a},ria=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336),sia=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700),O_=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",
967996550),jia=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475),X_=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672),Y_=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782),oia=new $CLJS.M(null,"m","m",1632677161),Z_=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var $_,a0,M_,tia,uia,via,wia,xia,yia,zia,Aia,Bia,Cia;$_=$CLJS.N;a0=$CLJS.N;M_=new $CLJS.$g(null,new $CLJS.h(null,7,[$CLJS.hY,null,$CLJS.xH,null,$CLJS.CP,null,$CLJS.$R,null,$CLJS.GQ,null,$CLJS.RT,null,$CLJS.zI,null],null),null);tia=$CLJS.Re($CLJS.N);uia=$CLJS.Re($CLJS.N);via=$CLJS.Re($CLJS.N);wia=$CLJS.Re($CLJS.N);xia=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));
$CLJS.b0=new $CLJS.di($CLJS.Eh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.SE,xia,tia,uia,via,wia);$CLJS.b0.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.B(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.z(a);a=$CLJS.B(d);d=$CLJS.C(d);b=$CLJS.pY($CLJS.dg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.cf.h($CLJS.b0),d))}else b=a;return b});$CLJS.b0.m(null,$CLJS.gY,function(a){return a});
$CLJS.b0.m(null,$CLJS.VX,function(a){var b=$CLJS.b0.h($CLJS.zI.h(a)),c=$CLJS.Fe($CLJS.Kk.g(function(k){var l=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);return $CLJS.BY($CLJS.b0.h(k),l)},$CLJS.$R.h(a))),d=a0;a0=N_(b);try{var e=hia(nia(a),b,$CLJS.G([$CLJS.$R,c])),f=$CLJS.ab(function(k,l){return $CLJS.Sa($CLJS.I.g(k,l))?k:$CLJS.Lk.j(k,l,$CLJS.b0)},e,$CLJS.Bk.l(M_,$CLJS.zI,$CLJS.G([$CLJS.$R])));return $CLJS.n($CLJS.CP.h(f))?$CLJS.Lk.j(f,$CLJS.CP,mia):f}finally{a0=d}});
$CLJS.b0.m(null,$CLJS.aY,function(a){return $CLJS.kZ.A(a,$CLJS.GS,$CLJS.Ou,function(b){return $CLJS.kZ.j(b,$CLJS.fO,$CLJS.b0)})});$CLJS.b0.m(null,$CLJS.qN,function(a){a=$CLJS.Lk.j($CLJS.Lk.j(a,$CLJS.yN,$CLJS.b0),$CLJS.vN,$CLJS.b0);var b=$CLJS.n($CLJS.xH.h(a))?$CLJS.Lk.j(a,$CLJS.xH,function(c){return $CLJS.Fl(c)?$CLJS.Kk.g($CLJS.b0,c):$CLJS.yh.h(c)}):a;return $CLJS.Sa($CLJS.BH.h(a))?$CLJS.R.j(b,$CLJS.BH,"__join"):b});$CLJS.b0.m(null,$CLJS.aE,function(a){return $CLJS.Kk.g($CLJS.b0,a)});
$CLJS.b0.m(null,$CLJS.cE,function(a){return $CLJS.n($CLJS.jj.h(a))?lia($CLJS.Lk.j($CLJS.FY(a),$CLJS.vN,function(b){return $CLJS.Kk.g($CLJS.b0,b)})):$CLJS.Ou(a,$CLJS.b0)});$CLJS.b0.m(null,$CLJS.KI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.pY(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,a,b],null))});
$CLJS.b0.m(null,$CLJS.Aj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.CX(a,new $CLJS.h(null,3,[$CLJS.TF,$CLJS.gE,$CLJS.CQ,$CLJS.Sj,$CLJS.ZT,$CLJS.bH],null));var c=$CLJS.R.j;var d=$CLJS.Fi.h(a);$CLJS.n(d)||(d=$CLJS.gE.h(a),d=$CLJS.n(d)?d:$CLJS.sH(b));a=c.call($CLJS.R,a,$CLJS.Fi,d);return $CLJS.pY(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,a,b],null))});
$CLJS.b0.m(null,$CLJS.uI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uI,$CLJS.Ak.g(c,$CLJS.ci),$CLJS.Kk.g($CLJS.b0,b)],null);b=$CLJS.pY(b);return null!=a?$CLJS.$d.g(b,$CLJS.b0.h(a)):b});$CLJS.b0.m(null,$CLJS.dE,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.pY(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.b0.m(null,$CLJS.zI,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.pY(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,P_(a0,c)],null))});$CLJS.b0.m(null,$CLJS.rO,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=$CLJS.b0.h(b);var c=$CLJS.z(b);b=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);d=$CLJS.C(d);return $CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.$k.l($CLJS.G([c,a]))],null),d)});yia=$CLJS.Re($CLJS.N);zia=$CLJS.Re($CLJS.N);Aia=$CLJS.Re($CLJS.N);
Bia=$CLJS.Re($CLJS.N);Cia=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));$CLJS.T_=new $CLJS.di($CLJS.Eh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.SE,Cia,yia,zia,Aia,Bia);
$CLJS.T_.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.B(a)instanceof $CLJS.M){a=$CLJS.z(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);if($CLJS.xd(a)){c=$CLJS.dg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.bf.g;d=$CLJS.cf.g($CLJS.T_,d);a=S_(a);a=c.call($CLJS.dg,b,e.call($CLJS.bf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.dg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.cf.g($CLJS.T_,$CLJS.ce(a,d)))}else a=$CLJS.xd(a)?$CLJS.Ou(R_(a),$CLJS.T_):
a;return a});for(var c0=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[Z_,Y_],null)),d0=null,e0=0,f0=0;;)if(f0<e0){var Dia=d0.X(null,f0);$CLJS.WH(Dia,X_);f0+=1}else{var g0=$CLJS.z(c0);if(g0){var h0=g0;if($CLJS.Ad(h0)){var i0=$CLJS.jc(h0),Eia=$CLJS.kc(h0),Fia=i0,Gia=$CLJS.E(i0);c0=Eia;d0=Fia;e0=Gia}else{var Hia=$CLJS.B(h0);$CLJS.WH(Hia,X_);c0=$CLJS.C(h0);d0=null;e0=0}f0=0}else break}
for(var j0=$CLJS.z(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.TA,$CLJS.EI,$CLJS.TI,$CLJS.qI,$CLJS.fk,$CLJS.YI,$CLJS.Rn,$CLJS.wI,$CLJS.RI,$CLJS.$I,$CLJS.XI,$CLJS.dJ],null)),k0=null,l0=0,m0=0;;)if(m0<l0){var Iia=k0.X(null,m0);$CLJS.WH(Iia,Z_);m0+=1}else{var n0=$CLJS.z(j0);if(n0){var o0=n0;if($CLJS.Ad(o0)){var p0=$CLJS.jc(o0),Jia=$CLJS.kc(o0),Kia=p0,Lia=$CLJS.E(p0);j0=Jia;k0=Kia;l0=Lia}else{var Mia=$CLJS.B(o0);$CLJS.WH(Mia,Z_);j0=$CLJS.C(o0);k0=null;l0=0}m0=0}else break}
for(var q0=$CLJS.z($CLJS.vf([$CLJS.Rs,$CLJS.tu,$CLJS.Ss,$CLJS.eJ,$CLJS.uI,$CLJS.NI,$CLJS.mI,$CLJS.gJ,$CLJS.fI,$CLJS.yI,$CLJS.iI,$CLJS.II,$CLJS.PI,$CLJS.jI,$CLJS.rL,$CLJS.tL,$CLJS.hC,$CLJS.nL,$CLJS.vL,$CLJS.hL,$CLJS.zL,$CLJS.mL,$CLJS.fL,$CLJS.yL,$CLJS.IL,$CLJS.CL,$CLJS.lL,$CLJS.LL,$CLJS.BL,$CLJS.JL,$CLJS.lI,$CLJS.gI,$CLJS.hJ,$CLJS.zK,$CLJS.MI,$CLJS.KA,$CLJS.aJ,$CLJS.cJ,$CLJS.iJ,$CLJS.Tu,$CLJS.hv],!0)),r0=null,s0=0,t0=0;;)if(t0<s0){var Nia=r0.X(null,t0);$CLJS.WH(Nia,Y_);t0+=1}else{var u0=$CLJS.z(q0);
if(u0){var v0=u0;if($CLJS.Ad(v0)){var w0=$CLJS.jc(v0),Oia=$CLJS.kc(v0),Pia=w0,Qia=$CLJS.E(w0);q0=Oia;r0=Pia;s0=Qia}else{var Ria=$CLJS.B(v0);$CLJS.WH(Ria,Y_);q0=$CLJS.C(v0);r0=null;s0=0}t0=0}else break}$CLJS.T_.m(null,X_,function(a){return U_(a)});$CLJS.T_.m(null,$CLJS.cE,function(a){return $CLJS.dg.j($CLJS.N,$CLJS.Ik.g(Q_(),$CLJS.cf.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.T_.h(b)],null)})),a)});
$CLJS.T_.m(null,$CLJS.zI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);if($CLJS.xd(b))try{var d=S_(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zI,P_($_,c)],null);return $CLJS.n(d)?$CLJS.$d.g(e,d):e}catch(f){throw b=f,c=$CLJS.vX(b),c=$CLJS.ha.g?$CLJS.ha.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.ha.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.V,a],null),b);}else return a});
$CLJS.T_.m(null,$CLJS.aE,function(a){return $CLJS.Kk.g($CLJS.T_,a)});$CLJS.T_.m(null,$CLJS.KI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,$CLJS.T_.h(a),S_(b)],null)});
$CLJS.T_.m(null,$CLJS.Aj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=S_($CLJS.CX(b,new $CLJS.h(null,3,[$CLJS.gE,$CLJS.TF,$CLJS.Sj,$CLJS.CQ,$CLJS.bH,$CLJS.ZT],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,a,b],null)});
$CLJS.T_.m(null,$CLJS.qN,function(a){var b=R_(a);a=0==$CLJS.BH.h(a).lastIndexOf("__join",0)?$CLJS.Ak.g(b,$CLJS.BH):b;return $CLJS.$k.l($CLJS.G([$CLJS.Ou($CLJS.Ak.l(a,$CLJS.vN,$CLJS.G([$CLJS.yN])),$CLJS.T_),W_($CLJS.Yl(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.yN],null)),$CLJS.yN,$CLJS.TT),V_(a)]))});
$CLJS.T_.m(null,$CLJS.VX,function(a){var b=$_;$_=$CLJS.dg.j($CLJS.N,$CLJS.$l(function(c,d){$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);d=$CLJS.Ee(d);d=$CLJS.I.g(d,$CLJS.fH);return new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}),$CLJS.zI.h(a));try{return $CLJS.ab(function(c,d){return $CLJS.kZ.j(c,d,$CLJS.T_)},W_($CLJS.kZ.j($CLJS.kZ.j(qia(R_(a)),$CLJS.zI,function(c){return $CLJS.Kk.g(U_,c)}),$CLJS.$R,function(c){return $CLJS.dg.g($CLJS.N,function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=
$CLJS.z(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.ld(l,u),x=$CLJS.T_.h(v);v=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AY(v),$CLJS.F.g($CLJS.Aj,$CLJS.B(x))?$CLJS.id(x):x],null);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.kc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);l=$CLJS.T_.h(t);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AY(t),$CLJS.F.g($CLJS.Aj,$CLJS.B(l))?$CLJS.id(l):l],null),f($CLJS.Kc(k)))}return null}},
null,null)}(c)}())}),$CLJS.hY,$CLJS.gP),$CLJS.Bk.l(M_,$CLJS.zI,$CLJS.G([$CLJS.hY,$CLJS.$R])))}finally{$_=b}});$CLJS.T_.m(null,$CLJS.aY,function(a){return $CLJS.Ou(R_(a),$CLJS.T_)});
$CLJS.T_.m(null,$CLJS.gY,function(a){try{var b=R_(a),c=$CLJS.tP.h(b),d=V_(b),e=$CLJS.F.g($CLJS.Bj.h($CLJS.jd($CLJS.vN.h(a))),$CLJS.aY)?$CLJS.jS:$CLJS.FO;return $CLJS.$k.l($CLJS.G([$CLJS.Ou($CLJS.Ak.l(b,$CLJS.vN,$CLJS.G([$CLJS.tP])),$CLJS.T_),function(){var k=$CLJS.De([$CLJS.jj,e,e,d]);return $CLJS.z(c)?$CLJS.R.j(k,$CLJS.tP,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.vX(f);return $CLJS.ha.g?$CLJS.ha.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.ha.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.FO,a],null),f);}});
$CLJS.x0=function(){function a(d,e,f){f=$CLJS.I_(null,$CLJS.oD(f,$CLJS.G([$CLJS.yi,!0])));var k=$CLJS.HY(d,e);k=$CLJS.Ee(k);var l=$CLJS.I.g(k,$CLJS.zI);k=a0;a0=N_(l);try{try{return $CLJS.b0.h(f)}catch(u){l=u;var m=$CLJS.vX(l);var t=$CLJS.ha.g?$CLJS.ha.g("Error converting legacy ref to pMBQL: %s",m):$CLJS.ha.call(null,"Error converting legacy ref to pMBQL: %s",m);throw $CLJS.gi(t,new $CLJS.h(null,4,[$CLJS.FO,d,$CLJS.OY,e,sia,f,ria,a0],null),l);}}finally{a0=k}}function b(d,e){return c.j?c.j(d,-1,e):
c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();