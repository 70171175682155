var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.expression.arithmetic.js");require("./metabase.lib.schema.expression.conditional.js");require("./metabase.lib.schema.expression.string.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.schema.template_tag.js");require("./metabase.lib.schema.util.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.registry.js");
'use strict';var LX,MX,NX,Uga,RX,SX,Wga,TX,UX,WX,XX,YX,ZX,$X,bY,cY,dY,eY,fY,Xga,iY,jY,Vga;
LX=function(a,b){a=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zf,a],null)],null);for(var c=$CLJS.zf;;){var d=$CLJS.rd(a);if($CLJS.n(d)){var e=d,f=$CLJS.H(e,0,null),k=$CLJS.H(e,1,null),l=e,m=$CLJS.sd(a);a=function(t,u,v,x,A,D,J,K){return function(X){return $CLJS.dg.j(v,$CLJS.cf.h(function(Z,fa,ra,xa,tb){return function(Rb){var Vb=$CLJS.H(Rb,0,null);Rb=$CLJS.H(Rb,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$d.g(tb,Vb),Rb],null)}}(t,u,v,x,A,D,J,K)),X)}}(a,c,m,e,f,k,l,d);
$CLJS.n(b.h?b.h(k):b.call(null,k))?(a=m,c=$CLJS.$d.g(c,l)):a=$CLJS.xd(k)?a(k):$CLJS.wd(k)?a($CLJS.Xl($CLJS.Jt,k)):m}else return c}};
MX=function(a){var b=2>$CLJS.E(a);return b?b:$CLJS.O.g($CLJS.lF,function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.ld(k,t);u=$CLJS.nH(u,$CLJS.Ak,$CLJS.G([$CLJS.fH]));m.add(u);t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.kc(f))):$CLJS.re($CLJS.te(m),null)}m=$CLJS.B(f);return $CLJS.ce($CLJS.nH(m,$CLJS.Ak,$CLJS.G([$CLJS.fH])),e($CLJS.Kc(f)))}return null}},
null,null)}(a)}())};NX=function(a,b,c){return $CLJS.zd(c)&&$CLJS.F.g(a,$CLJS.B(c))&&!$CLJS.Hd(b,$CLJS.I.g(c,2))};$CLJS.OX=function(a){var b=$CLJS.dg.j($CLJS.ah,$CLJS.cf.h($CLJS.Ik.g($CLJS.FH,$CLJS.id)),$CLJS.$R.h(a));return LX($CLJS.Ak.l(a,$CLJS.CP,$CLJS.G([$CLJS.qX])),function(c){return NX($CLJS.dE,b,c)})};$CLJS.PX=function(a){var b=$CLJS.dg.j($CLJS.ah,$CLJS.cf.h($CLJS.Ik.g($CLJS.fH,$CLJS.id)),$CLJS.zI.h(a));return LX($CLJS.Ak.l(a,$CLJS.CP,$CLJS.G([$CLJS.qX])),function(c){return NX($CLJS.zI,b,c)})};
Uga=function(a){function b(d){return $CLJS.df(c,$CLJS.G([$CLJS.CP.h(d)]))}function c(d){return $CLJS.ce($CLJS.BH.h(d),$CLJS.df(b,$CLJS.G([$CLJS.vN.h(d)])))}return $CLJS.n($CLJS.QX.h(a))?$CLJS.Oe(!0):$CLJS.eh(b(a))};
RX=function(a){if($CLJS.wd(a))for(var b=$CLJS.Oe(!1),c=$CLJS.z(a),d=$CLJS.B(c),e=$CLJS.C(c),f=b,k=0,l=a;;){var m=f,t=k,u=l,v=$CLJS.z(u),x=$CLJS.B(v),A=$CLJS.C(v),D=x,J=A,K=$CLJS.DD(m,Uga(D));f=$CLJS.B($CLJS.z($CLJS.ff($CLJS.Ta,function(X,Z,fa,ra,xa,tb){return function fc(Vb,Gc){try{if($CLJS.zd(Gc)&&3===$CLJS.E(Gc))try{var Hc=$CLJS.bd(Gc,0);if($CLJS.fe(Hc,$CLJS.KI))try{var zb=$CLJS.bd(Gc,1);if($CLJS.n($CLJS.tR.h(zb)))try{var Mb=$CLJS.bd(Gc,1);if(null!=Mb?Mb.C&256||$CLJS.r===Mb.We||(Mb.C?0:$CLJS.Wa($CLJS.qb,
Mb)):$CLJS.Wa($CLJS.qb,Mb))try{var gd=$CLJS.I.j(Mb,$CLJS.tR,$CLJS.GX);if($CLJS.n($CLJS.Ne(ra)(gd))){var Ci=$CLJS.I.g(Mb,$CLJS.tR);$CLJS.bd(Gc,2);return new $CLJS.P(null,1,5,$CLJS.Q,[["Invalid :field reference in stage ",$CLJS.p.h(tb),": no join named ",$CLJS.Lh.l($CLJS.G([Ci]))].join("")],null)}throw $CLJS.Y;}catch(af){if(af instanceof Error){var Af=af;if(Af===$CLJS.Y)throw $CLJS.Y;throw Af;}throw af;}else throw $CLJS.Y;}catch(af){if(af instanceof Error){Af=af;if(Af===$CLJS.Y)throw $CLJS.Y;throw Af;
}throw af;}else throw $CLJS.Y;}catch(af){if(af instanceof Error){Af=af;if(Af===$CLJS.Y)throw $CLJS.Y;throw Af;}throw af;}else throw $CLJS.Y;}catch(af){if(af instanceof Error){Af=af;if(Af===$CLJS.Y)throw $CLJS.Y;throw Af;}throw af;}else throw $CLJS.Y;}catch(af){if(af instanceof Error){Af=af;if(Af===$CLJS.Y)return $CLJS.DX(fc,Vb,Gc);throw Af;}throw af;}}}(f,k,l,K,m,t,u,v,x,A,D,J,b,0,a,a,c,d,e,d,e)($CLJS.zf,$CLJS.Ak.l(D,$CLJS.CP,$CLJS.G([Vga]))))));if($CLJS.n(f))return f;if($CLJS.z(J))k=t+1,f=K,l=J;
else return null}else return null};SX=new $CLJS.M("metabase.lib.schema","breakouts","metabase.lib.schema/breakouts",1354104361);Wga=new $CLJS.M("metabase.lib.schema","stage","metabase.lib.schema/stage",1626908550);TX=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-table","metabase.lib.schema/stage.mbql.with-source-table",-2119049176);UX=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source-card","metabase.lib.schema/stage.mbql.with-source-card",-1292215799);
$CLJS.VX=new $CLJS.M("mbql.stage","mbql","mbql.stage/mbql",1578747798);WX=new $CLJS.M("metabase.lib.schema","stage.additional","metabase.lib.schema/stage.additional",1347605157);XX=new $CLJS.M("metabase.lib.schema","stage.mbql.with-source","metabase.lib.schema/stage.mbql.with-source",1947556064);YX=new $CLJS.M("metabase.lib.schema","stage.native","metabase.lib.schema/stage.native",-1206212320);
ZX=new $CLJS.M("metabase.lib.schema","stage.mbql.without-source","metabase.lib.schema/stage.mbql.without-source",1507072886);$X=new $CLJS.M("metabase.lib.schema","stage.type","metabase.lib.schema/stage.type",1362996639);$CLJS.aY=new $CLJS.M("mbql.stage","native","mbql.stage/native",359422194);bY=new $CLJS.M("metabase.lib.schema","filters","metabase.lib.schema/filters",889389881);cY=new $CLJS.M("metabase.lib.schema","breakout","metabase.lib.schema/breakout",-921298600);
dY=new $CLJS.M("metabase.lib.schema","fields","metabase.lib.schema/fields",-2010307316);eY=new $CLJS.M("metabase.lib.schema","filterable","metabase.lib.schema/filterable",-1640076631);fY=new $CLJS.M("metabase.lib.schema","stage.valid-refs","metabase.lib.schema/stage.valid-refs",-383625241);$CLJS.gY=new $CLJS.M("mbql","query","mbql/query",-1285688662);$CLJS.QX=new $CLJS.M(null,"source-card","source-card",-1580820390);Xga=new $CLJS.M("metabase.lib.schema","query","metabase.lib.schema/query",-1207387140);
$CLJS.hY=new $CLJS.M(null,"filters","filters",974726919);iY=new $CLJS.M("metabase.lib.schema","stages.valid-refs","metabase.lib.schema/stages.valid-refs",-193458245);jY=new $CLJS.M("metabase.lib.schema","stage.initial","metabase.lib.schema/stage.initial",-1483255395);$CLJS.kY=new $CLJS.M("metabase.lib.schema","stage.mbql","metabase.lib.schema/stage.mbql",1051351388);Vga=new $CLJS.M("stage","metadata","stage/metadata",1707239131);$CLJS.W(YX,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.aY],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jS,$CLJS.Ta],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$t,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,$CLJS.vl],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kQ,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.$G],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GS,new $CLJS.h(null,1,[$CLJS.Mt,
!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hX],null)],null)],null));$CLJS.W(cY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OI],null));$CLJS.W(SX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),cY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"Breakouts must be distinct"],null),MX],null)],null));
$CLJS.W(dY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OI],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,":fields must be distinct"],null),MX],null)],null));
$CLJS.W(eY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.CH],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.kI],null),$CLJS.ek,$CLJS.Wj],null)],null));$CLJS.W(bY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),eY],null));
var lY=$CLJS.DD(function(a){a=$CLJS.B($CLJS.OX(a));if($CLJS.n(a)){var b=$CLJS.ED(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :expression reference: no expression named ",$CLJS.Lh.l($CLJS.G([b]))].join(""):["Invalid :expression reference: ",$CLJS.p.h($CLJS.I.g(a,1))].join("")}return null},function(a){a=$CLJS.B($CLJS.PX(a));if($CLJS.n(a)){var b=$CLJS.ED(a,new $CLJS.P(null,2,5,$CLJS.Q,[1,2],null));return $CLJS.n(b)?["Invalid :aggregation reference: no aggregation with uuid ",
$CLJS.p.h(b)].join(""):["Invalid :aggregation reference: ",$CLJS.p.h($CLJS.I.g(a,1))].join("")}return null});$CLJS.W(fY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.Du,"Valid references for a single query stage",$CLJS.Fu,function(a){a=$CLJS.Ee(a);a=$CLJS.I.g(a,$CLJS.Aj);return lY(a)}],null),$CLJS.Ne(lY)],null));
$CLJS.W($CLJS.kY,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.VX],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CP,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.tN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$R,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.zH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.GQ,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),SX],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zI,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.lJ],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xH,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),dY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hY,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),bY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RT,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.BN],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eR,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QX,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kN],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,":source-query is not allowed in pMBQL queries."],null),function(a){return!$CLJS.Hd(a,$CLJS.JS)}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,
new $CLJS.h(null,1,[$CLJS.Du,"A query cannot have both a :source-table and a :source-card."],null),$CLJS.Ne($CLJS.KN($CLJS.eR,$CLJS.QX))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fY],null)],null));$CLJS.W(TX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kY],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iN],null)],null)],null)],null));
$CLJS.W(UX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kY],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QX,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kN],null)],null)],null)],null));$CLJS.W(XX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,TX],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,UX],null)],null));
$CLJS.W(ZX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kY],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"Only the initial stage of a query can have a :source-table or :source-card."],null),$CLJS.Ne($CLJS.DD($CLJS.eR,$CLJS.QX))],null)],null));$CLJS.W($X,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,$CLJS.aY,$CLJS.VX],null));
$CLJS.W(Wga,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,$X],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.Bj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,YX],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VX,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kY],null)],null)],null)],null));
$CLJS.W(jY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,$X],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.Bj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aY,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,YX],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VX,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,XX],null)],null)],null)],null));$CLJS.W(WX,ZX);
$CLJS.W(iY,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.Du,"Valid references for all query stages",$CLJS.Fu,function(a){a=$CLJS.Ee(a);a=$CLJS.I.g(a,$CLJS.Aj);return RX.h?RX.h(a):RX.call(null,a)}],null),$CLJS.Ne(RX)],null));
$CLJS.W($CLJS.rN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jY],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,WX],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iY],null)],null));
$CLJS.W(Xga,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.gY],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UR,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.lN,$CLJS.mN],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vN,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.rN],null)],null)],null),$CLJS.Nga],null));