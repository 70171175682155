var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.literal.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");require("./shadow.js.shim.module$moment.js");require("./shadow.js.shim.module$moment_timezone.js");
'use strict';var gL,iL,kL,wL,AL,KL;$CLJS.fL=new $CLJS.M(null,"get-month","get-month",-369374731);gL=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.options","metabase.lib.schema.expression.temporal/absolute-datetime.options",-925669633);$CLJS.hL=new $CLJS.M(null,"convert-timezone","convert-timezone",-124803001);iL=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.unit","metabase.lib.schema.expression.temporal/temporal-extract.unit",339336486);
$CLJS.jL=new $CLJS.M(null,"iso","iso",-1366207543);kL=new $CLJS.M("metabase.lib.schema.expression.temporal","absolute-datetime.base-type","metabase.lib.schema.expression.temporal/absolute-datetime.base-type",-904703365);$CLJS.lL=new $CLJS.M(null,"get-second","get-second",-2065946318);$CLJS.mL=new $CLJS.M(null,"get-year","get-year",-936011274);$CLJS.nL=new $CLJS.M(null,"absolute-datetime","absolute-datetime",-560340465);
$CLJS.oL=new $CLJS.M("lib.type-of","type-is-temporal-type-of-first-arg","lib.type-of/type-is-temporal-type-of-first-arg",76272405);$CLJS.pL=new $CLJS.M(null,"week-of-year-iso","week-of-year-iso",-1827744293);$CLJS.qL=new $CLJS.M(null,"datetime-diff","datetime-diff",1133112155);$CLJS.rL=new $CLJS.M(null,"interval","interval",1708495417);$CLJS.sL=new $CLJS.M(null,"us","us",746429226);$CLJS.tL=new $CLJS.M(null,"relative-datetime","relative-datetime",1285169278);
$CLJS.uL=new $CLJS.M(null,"datetime","datetime",494675702);$CLJS.vL=new $CLJS.M(null,"now","now",-1650525531);wL=new $CLJS.M("metabase.lib.schema.expression.temporal","temporal-extract.week-mode","metabase.lib.schema.expression.temporal/temporal-extract.week-mode",430590916);$CLJS.xL=new $CLJS.M(null,"instance","instance",-2121349050);$CLJS.yL=new $CLJS.M(null,"get-day","get-day",127568857);$CLJS.zL=new $CLJS.M(null,"get-week","get-week",-888059349);
AL=new $CLJS.M("metabase.lib.schema.expression.temporal","relative-datetime.amount","metabase.lib.schema.expression.temporal/relative-datetime.amount",7216442);$CLJS.BL=new $CLJS.M(null,"datetime-add","datetime-add",209603411);$CLJS.CL=new $CLJS.M(null,"get-minute","get-minute",1468086343);$CLJS.DL=new $CLJS.M(null,"get-day-of-week","get-day-of-week",2010140752);$CLJS.GL=new $CLJS.M("metabase.lib.schema.expression.temporal","timezone-id","metabase.lib.schema.expression.temporal/timezone-id",-1909635091);
$CLJS.HL=new $CLJS.M(null,"temporal-extract","temporal-extract",1781686405);$CLJS.IL=new $CLJS.M(null,"get-hour","get-hour",622714059);$CLJS.JL=new $CLJS.M(null,"datetime-subtract","datetime-subtract",275307697);KL=new $CLJS.M("metabase.lib.schema.expression.temporal","get-week-mode","metabase.lib.schema.expression.temporal/get-week-mode",1489967652);$CLJS.LL=new $CLJS.M(null,"get-quarter","get-quarter",1327778593);$CLJS.ML=new $CLJS.M(null,"week-of-year-us","week-of-year-us",849423511);
$CLJS.NL=new $CLJS.M(null,"week-of-year-instance","week-of-year-instance",2047974624);$CLJS.OL=new $CLJS.M(null,"target","target",253001721);$CLJS.cI($CLJS.rL,$CLJS.G([$CLJS.tu,$CLJS.RF,$CLJS.Bt,$CLJS.EJ]));$CLJS.rH.m(null,$CLJS.oL,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=$CLJS.sH(a);return $CLJS.vd(a)?(a=$CLJS.Qv.g(a,new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.GF,null,$CLJS.dG,null],null),null)),$CLJS.F.g($CLJS.E(a),1)?$CLJS.B(a):a):a});$CLJS.WH($CLJS.oL,$CLJS.JH);
for(var PL=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BL,$CLJS.JL],null)),QL=null,RL=0,SL=0;;)if(SL<RL){var TL=QL.X(null,SL);$CLJS.cI(TL,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null),$CLJS.Bt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.EJ],null)]));$CLJS.WH(TL,$CLJS.oL);SL+=1}else{var UL=$CLJS.z(PL);if(UL){var VL=UL;if($CLJS.Ad(VL)){var WL=$CLJS.jc(VL),Xca=$CLJS.kc(VL),Yca=WL,Zca=$CLJS.E(WL);PL=Xca;QL=Yca;RL=Zca}else{var XL=$CLJS.B(VL);$CLJS.cI(XL,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.V,$CLJS.AH],null),$CLJS.Bt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.EJ],null)]));$CLJS.WH(XL,$CLJS.oL);PL=$CLJS.C(VL);QL=null;RL=0}SL=0}else break}
for(var YL=$CLJS.z(new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.mL,$CLJS.fL,$CLJS.yL,$CLJS.IL,$CLJS.CL,$CLJS.lL,$CLJS.LL],null)),ZL=null,$L=0,aM=0;;)if(aM<$L){var $ca=ZL.X(null,aM);$CLJS.cI($ca,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null)],null)]));aM+=1}else{var bM=$CLJS.z(YL);if(bM){var cM=bM;if($CLJS.Ad(cM)){var dM=$CLJS.jc(cM),ada=$CLJS.kc(cM),bda=dM,cda=$CLJS.E(dM);YL=ada;ZL=bda;$L=cda}else{var dda=$CLJS.B(cM);$CLJS.cI(dda,$CLJS.G([$CLJS.tu,
$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null)],null)]));YL=$CLJS.C(cM);ZL=null;$L=0}aM=0}else break}$CLJS.cI($CLJS.qL,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.BJ],null)]));
for(var eM=$CLJS.z(new $CLJS.$g(null,new $CLJS.h(null,8,[$CLJS.LL,null,$CLJS.CL,null,$CLJS.IL,null,$CLJS.DL,null,$CLJS.lL,null,$CLJS.fL,null,$CLJS.mL,null,$CLJS.yL,null],null),null)),fM=null,gM=0,hM=0;;)if(hM<gM){var eda=fM.X(null,hM);$CLJS.cI(eda,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null)],null)]));hM+=1}else{var iM=$CLJS.z(eM);if(iM){var jM=iM;if($CLJS.Ad(jM)){var kM=$CLJS.jc(jM),fda=$CLJS.kc(jM),gda=kM,hda=$CLJS.E(kM);
eM=fda;fM=gda;gM=hda}else{var ida=$CLJS.B(jM);$CLJS.cI(ida,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null)],null)]));eM=$CLJS.C(jM);fM=null;gM=0}hM=0}else break}$CLJS.W(KL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kt,$CLJS.jL,$CLJS.sL,$CLJS.xL],null));
$CLJS.eI($CLJS.zL,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KL],null)],null)],null)],null)]));
$CLJS.W($CLJS.GL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,$CLJS.$G,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,2,[$CLJS.Du,"valid timezone ID",$CLJS.Fu,function(a){a=$CLJS.Ee(a);a=$CLJS.I.g(a,$CLJS.Aj);return["invalid timezone ID: ",$CLJS.Lh.l($CLJS.G([a]))].join("")}],null)],null),$CLJS.vJ($CLJS.Id,$CLJS.eL.tz.names()))],null));
$CLJS.eI($CLJS.hL,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.OL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.GL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.GL],null)],null)],null)],
null)]));$CLJS.WH($CLJS.hL,$CLJS.oL);$CLJS.cI($CLJS.vL,$CLJS.G([$CLJS.tu,$CLJS.JF]));$CLJS.W(kL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,":absolute-datetime base-type must derive from :type/Date or :type/DateTime"],null),function(a){return $CLJS.Me(function(b){return $CLJS.fF(a,b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GF,$CLJS.dG],null))}],null)],null));
$CLJS.W(gL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.eH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gE,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kL],null)],null)],null)],null));
$CLJS.jJ.g($CLJS.nL,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.Du,"valid :absolute-datetime clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.nL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ct,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.Du,":absolute-datetime literal and unit for :type/Date"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.VK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.QK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DJ],null)],null)],null)],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.Du,":absolute-datetime literal and unit for :type/DateTime"],
null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.Lx],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZK],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.ci],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.yJ],null)],null)],null)],null)],null)],null));
$CLJS.rH.m(null,$CLJS.nL,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);a=$CLJS.F.g(b,$CLJS.Lx)?$CLJS.F.g(a,$CLJS.ci)?$CLJS.dG:$CLJS.n($CLJS.VH($CLJS.DJ,a))?$CLJS.GF:$CLJS.dG:null;if($CLJS.n(a))return a;a="string"===typeof b?$CLJS.n($CLJS.mh($CLJS.cL,b))?$CLJS.GF:$CLJS.n($CLJS.mh($CLJS.dL,b))?$CLJS.GF:null:null;if($CLJS.n(a))return a;b=$CLJS.sH(b);b=$CLJS.vd(b)?$CLJS.dg.j($CLJS.ah,$CLJS.ef(function(c){return $CLJS.fF(c,$CLJS.Tj)}),b):b;return $CLJS.vd(b)&&
$CLJS.F.g($CLJS.E(b),1)?$CLJS.B(b):b});$CLJS.W(AL,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.Lx],null),$CLJS.Bt],null));
$CLJS.eI($CLJS.tL,$CLJS.G([$CLJS.tu,$CLJS.dG,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bz,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.EJ],null)],null)],null)],null)]));
$CLJS.cI($CLJS.hC,$CLJS.G([$CLJS.tu,$CLJS.CG,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.MH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.CJ],null)]));$CLJS.W(iL,new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.kt,$CLJS.AJ,$CLJS.zi,$CLJS.hk,$CLJS.pL,$CLJS.ML,$CLJS.NL,$CLJS.Dj,$CLJS.Gi,$CLJS.vi,$CLJS.fj,$CLJS.xJ],null));$CLJS.W(wL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kt,$CLJS.jL,$CLJS.sL,$CLJS.xL],null));
$CLJS.eI($CLJS.HL,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iL],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xi,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,wL],null)],null)],
null)],null)]));