var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./shadow.js.shim.module$moment.js");require("./clojure.string.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");
'use strict';var V$=function(a){return new $CLJS.le(null,function(){return $CLJS.ce(a.o?a.o():a.call(null),V$(a))},null,null)},W$=function(a,b){return new $CLJS.le(null,function(){var c=$CLJS.z(b);if(c){var d=$CLJS.B(c),e=a.h?a.h(d):a.call(null,d),f=$CLJS.ce(d,$CLJS.ew(function(k){return $CLJS.F.g(e,a.h?a.h(k):a.call(null,k))},$CLJS.C(c)));return $CLJS.ce(f,W$(a,new $CLJS.le(null,function(){return $CLJS.We($CLJS.E(f),c)},null,null)))}return null},null,null)},Xra=function(a,b){switch(a){case "minutes":return $CLJS.BX("Minute",
"Minutes",b);case "hours":return $CLJS.BX("Hour","Hours",b);case "days":return $CLJS.BX("Day","Days",b);case "weeks":return $CLJS.BX("Week","Weeks",b);case "months":return $CLJS.BX("Month","Months",b);case "quarters":return $CLJS.BX("Quarter","Quarters",b);case "years":return $CLJS.BX("Year","Years",b);default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},Yra=function(a,b,c){b=parseInt(b);c=Xra(c,b);a=new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.F.g(b,1)],null);if($CLJS.F.g(new $CLJS.P(null,
2,5,$CLJS.Q,["past",!0],null),a))return $CLJS.SG("Previous {0}",$CLJS.G([c]));if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,["past",!1],null),a))return $CLJS.SG("Previous {0} {1}",$CLJS.G([b,c]));if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!0],null),a))return $CLJS.SG("Next {0}",$CLJS.G([c]));if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,["next",!1],null),a))return $CLJS.SG("Next {0} {1}",$CLJS.G([b,c]));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));},$ra=function(a){return $CLJS.HD(a,
Zra,function(b){return["\\",$CLJS.p.h(b)].join("")})},X$=function(a,b,c,d,e){this.tag=a;this.source=b;this.S=c;this.G=d;this.J=e;this.C=2230716170;this.I=139264},bsa=function(a){var b=$CLJS.tv(a,asa),c=$CLJS.cf.g($CLJS.B,$CLJS.YY(Y$,a)),d=function(){var e=$CLJS.E(b),f=$CLJS.E(c);return e>f?e:f}();a=$CLJS.bf.g(b,$CLJS.Ve(d-$CLJS.E(b),V$($CLJS.Oe(""))));d=$CLJS.bf.g(c,$CLJS.Ve(d-$CLJS.E(c),V$($CLJS.Oe(""))));a=$CLJS.Ze.g(a,d);return $CLJS.cf.g(function(e){var f=$CLJS.mh(Y$,e);return $CLJS.n(f)?($CLJS.H(f,
0,null),f=$CLJS.H(f,1,null),new X$(f,e,null,null,null)):e},a)},csa=function(a){return $CLJS.df(function(b){return"string"===typeof $CLJS.B(b)?new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.zX(b)],null):b},$CLJS.G([W$(function(b){return"string"===typeof b||$CLJS.Sa($CLJS.Aj.h(b))},a)]))},dsa=function(a,b,c,d){return $CLJS.cf.g(function(e){if(e instanceof X$){var f=$CLJS.R.j;var k=$CLJS.Ej.h(e),l=$CLJS.I.g(a,k);k=$CLJS.Aj.h(l);l=$CLJS.jj.h(l);if($CLJS.n(k))try{var m=Z$.j(l,k,b);var t=$CLJS.n(c)?$ra(m):m}catch(u){if(u instanceof
Error)t=Z$.j($CLJS.ci,k,b);else throw u;}else t=null;e=f.call($CLJS.R,e,$CLJS.Aj,t)}return e},d)},gsa=function(a){a=$CLJS.zX($CLJS.cf.g(function(b){return b instanceof X$?b:$CLJS.HD(b,esa,"")},a));return $CLJS.HD(a,fsa,$CLJS.id)},hsa=function(a){return $CLJS.FD($CLJS.B($CLJS.I_(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.tP],null),new $CLJS.P(null,1,5,$CLJS.Q,[a],null))),$CLJS.yh)};var Z$=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.shared.parameters.parameters","formatted-value"),function(f){return $CLJS.yh.h(f)},e,a,b,c,d)}();Z$.m(null,$CLJS.XQ,function(a,b,c){return(0,$CLJS.eL)(b).locale(c).format("MMMM D, YYYY")});
Z$.m(null,$CLJS.yP,function(a,b,c){a=(0,$CLJS.eL)(b,"YYYY-MM").locale(c);return $CLJS.n(a.isValid())?a.format("MMMM, YYYY"):""});Z$.m(null,$CLJS.sQ,function(a,b,c){a=(0,$CLJS.eL)(b,"[Q]Q-YYYY").locale(c);return $CLJS.n(a.isValid())?a.format("[Q]Q, YYYY"):""});Z$.m(null,$CLJS.aP,function(a,b,c){b=$CLJS.tv(b,/~/);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.n($CLJS.n(a)?b:a)?[$CLJS.p.h(Z$.j($CLJS.XQ,a,c))," - ",$CLJS.p.h(Z$.j($CLJS.XQ,b,c))].join(""):""});
Z$.m(null,$CLJS.tQ,function(a,b){function c(d,e){return $CLJS.C($CLJS.nh(d,e))}if(c(/^today$/,b)||c(/^thisday$/,b))return $CLJS.pH("Today");if(c(/^thisweek$/,b))return $CLJS.pH("This Week");if(c(/^thismonth$/,b))return $CLJS.pH("This Month");if(c(/^thisquarter$/,b))return $CLJS.pH("This Quarter");if(c(/^thisyear$/,b))return $CLJS.pH("This Year");if(c(/^past1days$/,b))return $CLJS.pH("Yesterday");if(c(/^next1days$/,b))return $CLJS.pH("Tomorrow");if(a=c(/^(past|next)([0-9]+)([a-z]+)~?$/,b))return $CLJS.O.g(Yra,
a);throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));});Z$.m(null,$CLJS.dU,function(a,b,c){function d(e,f){return $CLJS.id($CLJS.nh(e,f))}a=d(/^(this[a-z]+)$/,b);if($CLJS.n(a))return Z$.j($CLJS.tQ,a,c);a=d(/^~?([0-9-T:]+)~?$/,b);if($CLJS.n(a))return Z$.j($CLJS.XQ,a,c);a=d(/^([0-9-T:]+~[0-9-T:]+)$/,b);return $CLJS.n(a)?Z$.j($CLJS.aP,a,c):Z$.j($CLJS.tQ,b,c)});
Z$.m(null,$CLJS.ci,function(a,b){$CLJS.wd(b)?(a=$CLJS.E(b),b=$CLJS.n($CLJS.F.g?$CLJS.F.g(1,a):$CLJS.F.call(null,1,a))?$CLJS.p.h($CLJS.B(b)):$CLJS.n($CLJS.F.g?$CLJS.F.g(2,a):$CLJS.F.call(null,2,a))?$CLJS.SG("{0} and {1}",$CLJS.G([$CLJS.B(b),$CLJS.id(b)])):$CLJS.SG("{0}, {1}, and {2}",$CLJS.G([$CLJS.Pu(", ",$CLJS.fw(2,b)),$CLJS.bd(b,$CLJS.E(b)-2),$CLJS.jd(b)]))):b=$CLJS.p.h(b);return b});var Zra=/[\\\/*_`'\[\](){}<>#+-.!$@%^&=|\?~]/,Y$=/\{\{\s*([A-Za-z0-9_\.]+?)\s*\}\}/,asa=/\{\{\s*[A-Za-z0-9_\.]+?\s*\}\}/;
$CLJS.g=X$.prototype;$CLJS.g.toString=function(){var a=$CLJS.Aj.h(this);return $CLJS.n(a)?a:this.source};$CLJS.g.pa=function(a,b){return this.ca(null,b,null)};$CLJS.g.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "tag":return this.tag;case "source":return this.source;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Cb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.rh(b,function(d){return $CLJS.rh(b,$CLJS.xh,""," ","",c,d)},"#metabase.shared.parameters.parameters.TextParam{",", ","}",c,$CLJS.bf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,this.tag],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wu,this.source],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Nu(this,2,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,$CLJS.Wu],null),$CLJS.n(this.G)?$CLJS.pc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};
$CLJS.g.ha=function(){return 2+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-884441273^$CLJS.Pc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.tag,b.tag)&&$CLJS.F.g(this.source,b.source)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Mb=function(a,b){return $CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.Wu,null,$CLJS.Ej,null],null),null),b)?$CLJS.Ak.g($CLJS.Jb($CLJS.dg.g($CLJS.N,this),this.S),b):new X$(this.tag,this.source,this.S,$CLJS.Fe($CLJS.Ak.g(this.G,b)),null)};$CLJS.g.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "tag":case "source":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.na=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.Ej,b):$CLJS.fe.call(null,$CLJS.Ej,b))?new X$(c,this.source,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.Wu,b):$CLJS.fe.call(null,$CLJS.Wu,b))?new X$(this.tag,c,this.S,this.G,null):new X$(this.tag,this.source,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.bf.g(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.Xf($CLJS.Ej,this.tag),new $CLJS.Xf($CLJS.Wu,this.source)],null),this.G))};
$CLJS.g.P=function(a,b){return new X$(this.tag,this.source,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.na(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.ab($CLJS.jb,this,b)};var esa=/\[\[.+\]\]/,fsa=/\[\[(.+?)\]\]/,$$=function $$(a){switch(arguments.length){case 2:return $$.g(arguments[0],arguments[1]);case 4:return $$.A(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.Aa("metabase.shared.parameters.parameters.substitute_tags",$$);$$.g=function(a,b){return $$.A(a,b,"en",!0)};$$.A=function(a,b,c,d){return $CLJS.n(a)?(b=$CLJS.pD(b),b=$CLJS.Ou(b,hsa),gsa(csa(dsa(b,c,d,bsa(a))))):null};$$.v=4;module.exports={tag_names:function(a){a=$CLJS.eh($CLJS.cf.g($CLJS.id,$CLJS.YY(Y$,$CLJS.n(a)?a:"")));return $CLJS.cl(a)},substitute_tags:$$};