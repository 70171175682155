var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var y0,Sia,Uia,Via,Xia,Yia,Zia,bja,Q0,R0,T0,cja,dja,eja,V0,fja;y0=function(){};$CLJS.z0=function(a){return null!=a?$CLJS.r===a.Qh?!0:a.Pc?!1:$CLJS.Wa(y0,a):$CLJS.Wa(y0,a)};Sia=new $CLJS.w(null,"metabase.lib.metadata.protocols","metabase.lib.metadata.protocols",128660233,null);$CLJS.A0=new $CLJS.M("lib","source-column-alias","lib/source-column-alias",1494821386);$CLJS.B0=new $CLJS.M("source","previous-stage","source/previous-stage",1962610878);
$CLJS.Tia=new $CLJS.M(null,"database-id","database-id",1883826326);$CLJS.C0=new $CLJS.M(null,"metadata-fn","metadata-fn",915781389);$CLJS.D0=new $CLJS.M("source","table-defaults","source/table-defaults",909975194);Uia=new $CLJS.M(null,"human-readable-values","human-readable-values",-624842907);Via=new $CLJS.M(null,"field-name","field-name",1300687948);$CLJS.Wia=new $CLJS.M(null,"dataset","dataset",1159262238);$CLJS.E0=new $CLJS.M(null,"operators","operators",-2064102509);
Xia=new $CLJS.M(null,"field-definitions","field-definitions",1650155116);$CLJS.F0=new $CLJS.M("source","expressions","source/expressions",-458367840);$CLJS.G0=new $CLJS.M(null,"definition","definition",-1198729982);Yia=new $CLJS.M(null,"state","state",-1988618099);$CLJS.H0=new $CLJS.M("source","implicitly-joinable","source/implicitly-joinable",-729101197);$CLJS.I0=new $CLJS.M("source","joins","source/joins",1225821486);$CLJS.J0=new $CLJS.M("lib","desired-column-alias","lib/desired-column-alias",-1998967402);
$CLJS.K0=new $CLJS.M(null,"fk-target-field-id","fk-target-field-id",2019750287);Zia=new $CLJS.M("lib","external-remap","lib/external-remap",543358316);$CLJS.L0=new $CLJS.M(null,"selected?","selected?",-742502788);$CLJS.M0=new $CLJS.M(null,"table-name","table-name",-2117866341);$CLJS.N0=new $CLJS.M("source","card","source/card",-139977973);$CLJS.O0=new $CLJS.M(null,"active","active",1895962068);$CLJS.$ia=new $CLJS.M(null,"dataset-query","dataset-query",1851066427);
$CLJS.aja=new $CLJS.M(null,"result-metadata","result-metadata",2017934672);$CLJS.P0=new $CLJS.M("source","fields","source/fields",-649667981);bja=new $CLJS.M("metabase.lib.metadata","persisted-info","metabase.lib.metadata/persisted-info",1906179826);Q0=new $CLJS.M("metabase.lib.metadata","column.remapping.external","metabase.lib.metadata/column.remapping.external",-228294930);R0=new $CLJS.M("metabase.lib.metadata","column-source","metabase.lib.metadata/column-source",-458963769);
$CLJS.S0=new $CLJS.M("source","native","source/native",-1444604147);T0=new $CLJS.M("metabase.lib.metadata","column.remapping.internal","metabase.lib.metadata/column.remapping.internal",-716967804);$CLJS.U0=new $CLJS.M("lib","card-id","lib/card-id",-1770167062);cja=new $CLJS.M("metadata.column.remapping","internal","metadata.column.remapping/internal",-1077920983);dja=new $CLJS.w(null,"metadata-provider?","metadata-provider?",1669871524,null);
eja=new $CLJS.M("lib","internal-remap","lib/internal-remap",-220033801);V0=new $CLJS.M("metabase.lib.metadata","persisted-info.definition","metabase.lib.metadata/persisted-info.definition",1100108182);fja=new $CLJS.M("metadata.column.remapping","external","metadata.column.remapping/external",-1813287755);$CLJS.W0=new $CLJS.M(null,"source-alias","source-alias",1652088724);$CLJS.W(R0,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.kt,$CLJS.N0,$CLJS.S0,$CLJS.B0,$CLJS.D0,$CLJS.P0,$CLJS.UY,$CLJS.RY,$CLJS.I0,$CLJS.F0,$CLJS.H0],null));$CLJS.W(Q0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,fja],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,$CLJS.jN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.$G],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FX,$CLJS.oN],null)],null));
$CLJS.W(T0,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,cja],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,$CLJS.jN],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.$G],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.hu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,$CLJS.Gt],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[Uia,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,$CLJS.Gt],null)],null)],null));
$CLJS.X0=new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Du,"Valid column metadata"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.rY],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.Wj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gE,$CLJS.hH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.oN],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WG,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Wj],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.hH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HS,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.oN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.K0,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.oN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.W0,
new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.$G],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LY,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,R0],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U0,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.kN],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.A0,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.$G],
null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.J0,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wj,new $CLJS.h(null,2,[$CLJS.Rn,1,$CLJS.fk,60],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.L0,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null),new $CLJS.P(null,3,5,$CLJS.Q,[Zia,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,Q0],null)],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[eja,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,T0],null)],null)],null)],null);
$CLJS.W(V0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.M0,$CLJS.$G],null),new $CLJS.P(null,2,5,$CLJS.Q,[Xia,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[Via,$CLJS.$G],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gE,$CLJS.hH],null)],null)],null)],null)],null)],null));
$CLJS.W(bja,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.O0,$CLJS.Ft],null),new $CLJS.P(null,2,5,$CLJS.Q,[Yia,$CLJS.$G],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.M0,$CLJS.$G],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.G0,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,V0],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lO,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,
$CLJS.$G],null)],null)],null));$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,$CLJS.Si,$CLJS.kj,$CLJS.rk,$CLJS.ti,$CLJS.T,$CLJS.jk],[Sia,dja,"metabase/lib/metadata/protocols.cljc",25,1,78,78,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Sq],null)),"Whether `x` is a valid [[MetadataProvider]].",$CLJS.n($CLJS.z0)?$CLJS.z0.H:null]);