var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.mbql.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var $Y,pha,bZ,dZ,eZ,fZ,qha,gZ,ZY,hZ,rha,sha,tha,lZ,mZ,oZ,pZ,qZ,rZ,sZ,tZ,aZ,vha,wha,wZ,xha,xZ,yha,zZ,zha,Aha,Bha,Cha,Dha,XY,uZ;$CLJS.YY=function(a,b){if("string"===typeof b)return XY(a,b);throw new TypeError("re-seq must match against a string.");};$Y=function(a){var b=ZY;return $CLJS.JD(function(c){return function(d,e,f){e=b.h?b.h(e):b.call(null,e);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};
pha=function(a){var b=aZ;return $CLJS.JD(function(c){return function(d,e,f){f=b.h?b.h(f):b.call(null,f);return c.j?c.j(d,e,f):c.call(null,d,e,f)}},a)};bZ=function(a){if($CLJS.nd(a))return a;throw $CLJS.hi("Invalid pattern: don't know how to handle symbol.",new $CLJS.h(null,1,[$CLJS.Hi,a],null));};
$CLJS.cZ=function(a,b,c){return $CLJS.xd(c)?$CLJS.dg.g(c,function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.ld(l,A),J=$CLJS.H(D,0,null),K=$CLJS.H(D,1,null);D=t;var X=$CLJS.Q,Z=J;J=$CLJS.$d.g(b,J);J=a.g?a.g(J,K):a.call(null,J,K);D.add(new $CLJS.P(null,2,5,X,[Z,J],null));A+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.kc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[v,function(){var A=$CLJS.$d.g(b,v),D=x;return a.g?a.g(A,D):a.call(null,A,D)}()],null),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):$CLJS.wd(c)?$CLJS.Kk.g($CLJS.Pe(a,$CLJS.B(c)instanceof $CLJS.M?$CLJS.$d.g(b,$CLJS.B(c)):b),c):c};dZ=function(a){var b=(b=a instanceof $CLJS.M)?$CLJS.ge(a):b;return $CLJS.n(b)?[$CLJS.ge(a),"/",$CLJS.fh(a)].join(""):$CLJS.fh(a)};
eZ=function(a,b){var c=$CLJS.wd(b)&&!$CLJS.wf(b)&&$CLJS.B(b)instanceof $CLJS.M;return c?$CLJS.ud(a)?(b=$CLJS.B(b),a=$CLJS.eh(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c};
fZ=function(a,b){return $CLJS.df(function(c){var d=$CLJS.ff;a:try{if($CLJS.zd(c)&&1<=$CLJS.E(c))try{var e=$CLJS.Pk.j(c,0,1);if($CLJS.zd(e)&&1===$CLJS.E(e))try{var f=$CLJS.bd(e,0);if($CLJS.n($CLJS.Pe($CLJS.F,a)(f))){var k=$CLJS.Pk.g(c,1);var l=new $CLJS.P(null,1,5,$CLJS.Q,[k],null)}else throw $CLJS.Y;}catch(t){if(t instanceof Error){var m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw t;}else throw $CLJS.Y;
}catch(t){if(t instanceof Error){m=t;if(m===$CLJS.Y){l=new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[c],null)],null);break a}throw m;}throw t;}return $CLJS.B($CLJS.z(d($CLJS.Ta,l)))},$CLJS.G([b]))};
qha=function(a){return function e(c,d){try{if($CLJS.n(function(){var Ir=bZ($CLJS.Dd);return Ir.h?Ir.h(d):Ir.call(null,d)}()))return e(c,$CLJS.Bf(d));throw $CLJS.Y;}catch(Ir){if(Ir instanceof Error){var f=Ir;if(f===$CLJS.Y)try{if(null===d)return null;throw $CLJS.Y;}catch(EL){if(EL instanceof Error)if(f=EL,f===$CLJS.Y)try{if($CLJS.zd(d)&&0<=$CLJS.E(d))try{var k=$CLJS.Pk.j(d,0,0);if($CLJS.zd(k)&&0===$CLJS.E(k))try{var l=$CLJS.Pk.g(d,0);if($CLJS.n($CLJS.Pe($CLJS.Le,$CLJS.sl)(l)))return null;throw $CLJS.Y;
}catch(Al){if(Al instanceof Error){var m=Al;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw Al;}else throw $CLJS.Y;}catch(Al){if(Al instanceof Error){m=Al;if(m===$CLJS.Y)throw $CLJS.Y;throw m;}throw Al;}else throw $CLJS.Y;}catch(Al){if(Al instanceof Error)if(m=Al,m===$CLJS.Y)try{if($CLJS.zd(d)&&0===$CLJS.E(d))return null;throw $CLJS.Y;}catch(FL){if(FL instanceof Error)if(k=FL,k===$CLJS.Y)try{if($CLJS.zd(d)&&1===$CLJS.E(d))try{var t=$CLJS.bd(d,0);if($CLJS.fe(t,$CLJS.vt))return null;throw $CLJS.Y;}catch(Jr){if(Jr instanceof
Error)if(k=Jr,k===$CLJS.Y)try{t=$CLJS.bd(d,0);if($CLJS.fe(t,$CLJS.ot))return null;throw $CLJS.Y;}catch(of){if(of instanceof Error){var u=of;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw of;}else throw k;else throw Jr;}else throw $CLJS.Y;}catch(Jr){if(Jr instanceof Error)if(k=Jr,k===$CLJS.Y)try{if($CLJS.zd(d)&&1<=$CLJS.E(d))try{var v=$CLJS.Pk.j(d,0,1);if($CLJS.zd(v)&&1===$CLJS.E(v))try{var x=$CLJS.bd(v,0);if($CLJS.n(function(){var of=new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.ot,null,$CLJS.vt,null],
null),null);return of.h?of.h(x):of.call(null,x)}()))try{var A=$CLJS.Pk.g(d,1);if($CLJS.n($CLJS.Pe($CLJS.Me,$CLJS.sl)(A))){var D=$CLJS.Pk.g(d,1),J=$CLJS.bd(v,0);return e(c,$CLJS.O.j($CLJS.Jt,J,$CLJS.uX($CLJS.Ta,D)))}throw $CLJS.Y;}catch(of){if(of instanceof Error){u=of;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw of;}else throw $CLJS.Y;}catch(of){if(of instanceof Error){u=of;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw of;}else throw $CLJS.Y;}catch(of){if(of instanceof Error){u=of;if(u===$CLJS.Y)throw $CLJS.Y;
throw u;}throw of;}else throw $CLJS.Y;}catch(of){if(of instanceof Error)if(u=of,u===$CLJS.Y)try{if($CLJS.zd(d)&&2===$CLJS.E(d))try{var K=$CLJS.bd(d,0);if($CLJS.fe(K,$CLJS.qt))try{var X=$CLJS.bd(d,1);if($CLJS.zd(X)&&1<=$CLJS.E(X))try{var Z=$CLJS.Pk.j(X,0,1);if($CLJS.zd(Z)&&1===$CLJS.E(Z))try{var fa=$CLJS.bd(Z,0);if($CLJS.fe(fa,$CLJS.vt))return D=$CLJS.Pk.g(X,1),e(c,$CLJS.O.j($CLJS.Jt,$CLJS.ot,$CLJS.cf.g(function(Nf){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qt,Nf],null)},D)));throw $CLJS.Y;}catch(Nf){if(Nf instanceof
Error){var ra=Nf;if(ra===$CLJS.Y)try{fa=$CLJS.bd(Z,0);if($CLJS.fe(fa,$CLJS.ot))return D=$CLJS.Pk.g(X,1),e(c,$CLJS.O.j($CLJS.Jt,$CLJS.vt,$CLJS.cf.g(function(Je){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qt,Je],null)},D)));throw $CLJS.Y;}catch(Je){if(Je instanceof Error){var xa=Je;if(xa===$CLJS.Y)throw $CLJS.Y;throw xa;}throw Je;}else throw ra;}else throw Nf;}else throw $CLJS.Y;}catch(Nf){if(Nf instanceof Error){ra=Nf;if(ra===$CLJS.Y)throw $CLJS.Y;throw ra;}throw Nf;}else throw $CLJS.Y;}catch(Nf){if(Nf instanceof
Error){ra=Nf;if(ra===$CLJS.Y)throw $CLJS.Y;throw ra;}throw Nf;}else throw $CLJS.Y;}catch(Nf){if(Nf instanceof Error)if(ra=Nf,ra===$CLJS.Y)try{K=$CLJS.bd(d,0);if($CLJS.fe(K,$CLJS.vt)){var tb=$CLJS.bd(d,1);return e(c,tb)}throw $CLJS.Y;}catch(Je){if(Je instanceof Error)if(xa=Je,xa===$CLJS.Y)try{K=$CLJS.bd(d,0);if($CLJS.fe(K,$CLJS.ot))return tb=$CLJS.bd(d,1),e(c,tb);throw $CLJS.Y;}catch(ve){if(ve instanceof Error){var Rb=ve;if(Rb===$CLJS.Y)throw $CLJS.Y;throw Rb;}throw ve;}else throw xa;else throw Je;
}else throw ra;else throw Nf;}else throw $CLJS.Y;}catch(Nf){if(Nf instanceof Error)if(ra=Nf,ra===$CLJS.Y)try{if($CLJS.zd(d)&&1<=$CLJS.E(d))try{var Vb=$CLJS.Pk.j(d,0,1);if($CLJS.zd(Vb)&&1===$CLJS.E(Vb))try{var Gc=$CLJS.bd(Vb,0);if($CLJS.fe(Gc,$CLJS.vt))try{var fc=$CLJS.Pk.g(d,1);if($CLJS.n($CLJS.Pe($CLJS.Me,$CLJS.Pe(eZ,$CLJS.vt))(fc)))return D=$CLJS.Pk.g(d,1),e(c,$CLJS.O.j($CLJS.Jt,$CLJS.vt,fZ($CLJS.vt,D)));throw $CLJS.Y;}catch(Je){if(Je instanceof Error){xa=Je;if(xa===$CLJS.Y)throw $CLJS.Y;throw xa;
}throw Je;}else throw $CLJS.Y;}catch(Je){if(Je instanceof Error)if(xa=Je,xa===$CLJS.Y)try{if(Gc=$CLJS.bd(Vb,0),$CLJS.fe(Gc,$CLJS.ot))try{fc=$CLJS.Pk.g(d,1);if($CLJS.n($CLJS.Pe($CLJS.Me,$CLJS.Pe(eZ,$CLJS.ot))(fc)))return D=$CLJS.Pk.g(d,1),e(c,$CLJS.O.j($CLJS.Jt,$CLJS.ot,fZ($CLJS.ot,D)));throw $CLJS.Y;}catch(ve){if(ve instanceof Error){Rb=ve;if(Rb===$CLJS.Y)throw $CLJS.Y;throw Rb;}throw ve;}else throw $CLJS.Y;}catch(ve){if(ve instanceof Error)if(Rb=ve,Rb===$CLJS.Y)try{if(Gc=$CLJS.bd(Vb,0),$CLJS.n(function(){var pf=
new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.ot,null,$CLJS.vt,null],null),null);return pf.h?pf.h(Gc):pf.call(null,Gc)}()))try{fc=$CLJS.Pk.g(d,1);if($CLJS.Sa($CLJS.O.g($CLJS.lF,fc))){D=$CLJS.Pk.g(d,1);var Hc=$CLJS.bd(Vb,0);return e(c,$CLJS.O.j($CLJS.Jt,Hc,$CLJS.bl.h(D)))}throw $CLJS.Y;}catch(pf){if(pf instanceof Error){var zb=pf;if(zb===$CLJS.Y)throw $CLJS.Y;throw zb;}throw pf;}else throw $CLJS.Y;}catch(pf){if(pf instanceof Error){zb=pf;if(zb===$CLJS.Y)throw $CLJS.Y;throw zb;}throw pf;}else throw Rb;
else throw ve;}else throw xa;else throw Je;}else throw $CLJS.Y;}catch(Je){if(Je instanceof Error){xa=Je;if(xa===$CLJS.Y)throw $CLJS.Y;throw xa;}throw Je;}else throw $CLJS.Y;}catch(Je){if(Je instanceof Error)if(xa=Je,xa===$CLJS.Y)try{if($CLJS.zd(d)&&2===$CLJS.E(d))try{var Mb=$CLJS.bd(d,0);if($CLJS.fe(Mb,$CLJS.qt))try{var gd=$CLJS.bd(d,1);if($CLJS.zd(gd)&&2===$CLJS.E(gd))try{var Ci=$CLJS.bd(gd,0);if($CLJS.fe(Ci,$CLJS.qt))return tb=$CLJS.bd(gd,1),e(c,tb);throw $CLJS.Y;}catch(ve){if(ve instanceof Error){Rb=
ve;if(Rb===$CLJS.Y)throw $CLJS.Y;throw Rb;}throw ve;}else throw $CLJS.Y;}catch(ve){if(ve instanceof Error){Rb=ve;if(Rb===$CLJS.Y)throw $CLJS.Y;throw Rb;}throw ve;}else throw $CLJS.Y;}catch(ve){if(ve instanceof Error){Rb=ve;if(Rb===$CLJS.Y)throw $CLJS.Y;throw Rb;}throw ve;}else throw $CLJS.Y;}catch(ve){if(ve instanceof Error)if(Rb=ve,Rb===$CLJS.Y)try{if($CLJS.zd(d)&&1<=$CLJS.E(d))try{var Af=$CLJS.Pk.j(d,0,1);if($CLJS.zd(Af)&&1===$CLJS.E(Af))try{var af=$CLJS.bd(Af,0);if($CLJS.fe(af,$CLJS.Yu))return a;
throw $CLJS.Y;}catch(pf){if(pf instanceof Error){zb=pf;if(zb===$CLJS.Y)throw $CLJS.Y;throw zb;}throw pf;}else throw $CLJS.Y;}catch(pf){if(pf instanceof Error){zb=pf;if(zb===$CLJS.Y)throw $CLJS.Y;throw zb;}throw pf;}else throw $CLJS.Y;}catch(pf){if(pf instanceof Error){zb=pf;if(zb===$CLJS.Y)return $CLJS.cZ(e,c,d);throw zb;}throw pf;}else throw Rb;else throw ve;}else throw xa;else throw Je;}else throw ra;else throw Nf;}else throw u;else throw of;}else throw k;else throw Jr;}else throw k;else throw FL;
}else throw m;else throw Al;}else throw f;else throw EL;}else throw f;}else throw Ir;}}($CLJS.zf,a)};gZ=function(a){var b=$CLJS.wd(a);return b?(b=!$CLJS.wf(a))?$CLJS.DD($CLJS.ee,$CLJS.Va)($CLJS.B(a)):b:b};ZY=function(a){return $CLJS.n($CLJS.DD($CLJS.ee,$CLJS.Va)(a))?$CLJS.yh.h($CLJS.HD(dZ(a).toLowerCase(),/_/,"-")):a};hZ=function(a,b){var c=gZ(b);return $CLJS.n(c)?(b=ZY($CLJS.B(b)),$CLJS.ud(a)?(a=$CLJS.eh(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,b)):c};
rha=function(a){return $CLJS.I.j(new $CLJS.h(null,3,[$CLJS.ci,$CLJS.Sd,$CLJS.jj,ZY,$CLJS.PP,ZY],null),a,function(b){var c=$CLJS.I,d=c.g;b=$CLJS.De([a,b]);b=iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ);return d.call(c,b,a)})};
sha=function(a){a=$CLJS.dg.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=ZY(c);var d=rha(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,d.h?d.h(b):d.call(null,b)],null)}),a);return $CLJS.F.g($CLJS.jj.h(a),$CLJS.fO)&&$CLJS.Sa($CLJS.PP.h(a))?$CLJS.R.j(a,$CLJS.PP,$CLJS.gT):a};tha=function(a){return $CLJS.dg.j($CLJS.N,$CLJS.cf.h(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);c=dZ(c);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.R.j(sha(b),$CLJS.S,c)],null)}),a)};
lZ=function(a){return $CLJS.kZ.j($CLJS.kZ.j($CLJS.ab(function(b,c){return $CLJS.kZ.j(b,c,$CLJS.yh)},a,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.TF,$CLJS.bba,$CLJS.CQ,uha,$CLJS.Wu,$CLJS.ri],null)),$CLJS.TE,$CLJS.Ik.g(aZ,iZ)),$CLJS.RP,$CLJS.yX)};mZ=function(a){return $CLJS.Fd(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,a,null],null):a};oZ=function(a){return nZ.h(mZ(a))};pZ=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return qha($CLJS.dg.g(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.cf.g(nZ,b)))};
qZ=function(a){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,oZ(b)],null),$CLJS.cf.g(nZ,c))};rZ=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(a)?new $CLJS.P(null,2,5,$CLJS.Q,[b,oZ(a)],null):new $CLJS.P(null,1,5,$CLJS.Q,[b],null)};sZ=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,oZ(a)],null)};
tZ=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[b,nZ.h(a)],null)};
aZ=function(a){return uZ(function(b){if($CLJS.xd(b))return pha(b);if($CLJS.Sa(gZ(b)))return b;try{return nZ.h(b)}catch(f){if(f instanceof Error){var c=f,d=$CLJS.ND($CLJS.eC);if($CLJS.n($CLJS.MD("metabase.mbql.normalize",d))){var e=$CLJS.pH("Invalid clause:");e instanceof Error?$CLJS.LD("metabase.mbql.normalize",d,$CLJS.vy.l($CLJS.G([b])),e):$CLJS.LD("metabase.mbql.normalize",d,$CLJS.vy.l($CLJS.G([e,b])),null)}throw $CLJS.gi($CLJS.SG("Invalid MBQL clause: {0}",$CLJS.G([$CLJS.vX(c)])),new $CLJS.h(null,
1,[$CLJS.bU,b],null),c);}throw f;}},a)};vha=function(a){return $CLJS.Bf($CLJS.Wl(aZ,vZ(a)))};wha=function(a){for(;;)if($CLJS.n(gZ(a)))a=new $CLJS.P(null,1,5,$CLJS.Q,[a],null);else return $CLJS.Fe($CLJS.Kk.g(mZ,a))};wZ=function(a){a=$CLJS.Ee(a);var b=$CLJS.I.g(a,$CLJS.fO);return $CLJS.n(b)?$CLJS.Lk.j(a,$CLJS.fO,nZ):a};
xha=function(a){return $CLJS.dg.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.ld(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,wZ(t)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.kc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.P(null,
2,5,$CLJS.Q,[l,wZ(f)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())};xZ=function(a){a=$CLJS.Ee(a);var b=$CLJS.I.g(a,$CLJS.GS);return $CLJS.n(b)?$CLJS.Lk.j(a,$CLJS.GS,xha):a};yha=function(a){var b=$CLJS.Ee(a);a=$CLJS.I.g(b,$CLJS.jS);b=$CLJS.Sa(a)?yZ.h?yZ.h(b):yZ.call(null,b):b;return $CLJS.n(a)?xZ(b):b};zZ=function(a){return $CLJS.ud(a)?$CLJS.z(a):null!=a};
zha=function(a){var b=$CLJS.Ee(a);a=$CLJS.I.g(b,$CLJS.FO);var c=$CLJS.I.g(b,$CLJS.tP),d=$CLJS.I.g(b,$CLJS.RQ),e=$CLJS.I.g(b,$CLJS.jS);try{if($CLJS.n(d)){var f=$CLJS.Ee(b),k=$CLJS.I.g(f,$CLJS.RQ);var l=$CLJS.RN($CLJS.Ak.g(f,$CLJS.RQ),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FO,$CLJS.RQ],null),k)}else l=b;var m=$CLJS.n(a)?$CLJS.Lk.j(l,$CLJS.FO,yZ):l,t=$CLJS.n(c)?$CLJS.Lk.j(m,$CLJS.tP,$CLJS.Pe($CLJS.Kk,aZ)):m,u=$CLJS.n(e)?$CLJS.Lk.j(t,$CLJS.jS,xZ):t;return aZ(u)}catch(v){if(v instanceof Error)throw m=v,$CLJS.gi($CLJS.SG("Error canonicalizing query: {0}",
$CLJS.G([$CLJS.vX(m)])),new $CLJS.h(null,1,[$CLJS.FO,a],null),m);throw v;}};
Aha=function(a){a=$CLJS.Ee(a);var b=$CLJS.I.g(a,$CLJS.FO),c=$CLJS.Ee(b);b=$CLJS.I.g(c,$CLJS.GQ);c=$CLJS.I.g(c,$CLJS.xH);return $CLJS.z(b)&&$CLJS.z(c)?(b=$CLJS.dg.j($CLJS.ah,$CLJS.eF,$CLJS.z($CLJS.ff($CLJS.Ta,function k(e,f){try{if($CLJS.zd(f)&&3===$CLJS.E(f))try{var l=$CLJS.bd(f,0);if($CLJS.fe(l,$CLJS.KI)){var m=$CLJS.bd(f,1),t=$CLJS.bd(f,2);return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[f,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,m,$CLJS.Ak.g(t,$CLJS.QI)],null)],null)],null)}throw $CLJS.Y;
}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw u;}else throw $CLJS.Y;}catch(u){if(u instanceof Error){l=u;if(l===$CLJS.Y)return $CLJS.DX(k,e,f);throw l;}throw u;}}($CLJS.zf,b)))),$CLJS.AZ.j(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FO,$CLJS.xH],null),$CLJS.Ik.g($CLJS.Bf,$CLJS.Pe($CLJS.Jk,b)))):a};
Bha=function(a){try{return Aha(a)}catch(c){if(c instanceof Error){var b=c;throw $CLJS.gi($CLJS.pH("Error performing whole query transformations"),new $CLJS.h(null,1,[$CLJS.FO,a],null),b);}throw c;}};
Cha=function(a,b){var c=$CLJS.dg.g($CLJS.kd(a),function(){return function f(e){return new $CLJS.le(null,function(){for(var k=e;;)if(k=$CLJS.z(k)){if($CLJS.Ad(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var A=0;;)if(A<m){var D=$CLJS.ld(l,A),J=$CLJS.H(D,0,null);D=$CLJS.H(D,1,null);var K=$CLJS.$d.g(b,J);D=BZ.g?BZ.g(D,K):BZ.call(null,D,K);null!=D&&t.add(new $CLJS.P(null,2,5,$CLJS.Q,[J,D],null));A+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.kc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k),v=$CLJS.H(u,0,null),x=$CLJS.H(u,1,null);u=function(){var A=x,D=$CLJS.$d.g(b,v);return BZ.g?BZ.g(A,D):BZ.call(null,A,D)}();if(null!=u)return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null),f($CLJS.Kc(k)));k=$CLJS.Kc(k)}else return null},null,null)}(a)}());return $CLJS.z(c)?c:null};Dha=function(a,b){a=$CLJS.Kk.g(function(c){var d=$CLJS.$d.g(b,CZ);return BZ.g?BZ.g(c,d):BZ.call(null,c,d)},a);return $CLJS.n($CLJS.Me($CLJS.Ta,a))?a:null};
XY=function XY(a,b){var d=a.exec(b);if(null==d)return null;var e=d[0],f=1===d.length?e:$CLJS.Bf(d);return $CLJS.ce(f,new $CLJS.le(null,function(){var k=e.length;k=d.index+(1>k?1:k);return k<=b.length?(k=b.substring(k),XY.g?XY.g(a,k):XY.call(null,a,k)):null},null,null))};
$CLJS.AZ=function AZ(a){switch(arguments.length){case 3:return AZ.j(arguments[0],arguments[1],arguments[2]);case 4:return AZ.A(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return AZ.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return AZ.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return AZ.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.AZ.j=function(a,b,c){var d=$CLJS.z(b);b=$CLJS.B(d);if(d=$CLJS.C(d))a=$CLJS.R.j(a,b,$CLJS.AZ.j($CLJS.I.g(a,b),d,c));else{d=$CLJS.R.j;var e=$CLJS.I.g(a,b);c=c.h?c.h(e):c.call(null,e);a=d.call($CLJS.R,a,b,c)}return a};
$CLJS.AZ.A=function(a,b,c,d){var e=$CLJS.z(b);b=$CLJS.B(e);if(e=$CLJS.C(e))a=$CLJS.R.j(a,b,$CLJS.AZ.A($CLJS.I.g(a,b),e,c,d));else{e=$CLJS.R.j;var f=$CLJS.I.g(a,b);c=c.g?c.g(f,d):c.call(null,f,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.AZ.N=function(a,b,c,d,e){var f=$CLJS.z(b);b=$CLJS.B(f);if(f=$CLJS.C(f))a=$CLJS.R.j(a,b,$CLJS.AZ.N($CLJS.I.g(a,b),f,c,d,e));else{f=$CLJS.R.j;var k=$CLJS.I.g(a,b);c=c.j?c.j(k,d,e):c.call(null,k,d,e);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.AZ.W=function(a,b,c,d,e,f){var k=$CLJS.z(b);b=$CLJS.B(k);if(k=$CLJS.C(k))a=$CLJS.R.j(a,b,$CLJS.AZ.W($CLJS.I.g(a,b),k,c,d,e,f));else{k=$CLJS.R.j;var l=$CLJS.I.g(a,b);c=c.A?c.A(l,d,e,f):c.call(null,l,d,e,f);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.AZ.l=function(a,b,c,d,e,f,k){var l=$CLJS.z(b);b=$CLJS.B(l);return(l=$CLJS.C(l))?$CLJS.R.j(a,b,$CLJS.O.l($CLJS.AZ,$CLJS.I.g(a,b),l,c,d,$CLJS.G([e,f,k]))):$CLJS.R.j(a,b,$CLJS.O.l(c,$CLJS.I.g(a,b),d,e,f,$CLJS.G([k])))};
$CLJS.AZ.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var k=$CLJS.C(f);f=$CLJS.B(k);k=$CLJS.C(k);return this.l(b,a,c,d,e,f,k)};$CLJS.AZ.v=6;uZ=function uZ(a,b){return $CLJS.wX($CLJS.Pe(uZ,a),$CLJS.Sd,a.h?a.h(b):a.call(null,b))};
$CLJS.kZ=function kZ(a){switch(arguments.length){case 3:return kZ.j(arguments[0],arguments[1],arguments[2]);case 4:return kZ.A(arguments[0],arguments[1],arguments[2],arguments[3]);case 5:return kZ.N(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4]);case 6:return kZ.W(arguments[0],arguments[1],arguments[2],arguments[3],arguments[4],arguments[5]);default:for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return kZ.l(arguments[0],arguments[1],arguments[2],
arguments[3],arguments[4],arguments[5],new $CLJS.y(c.slice(6),0,null))}};$CLJS.kZ.j=function(a,b,c){var d=$CLJS.Zl(a,b);if($CLJS.n(d)){var e=$CLJS.R.j;d=$CLJS.Ab(d);c=c.h?c.h(d):c.call(null,d);a=e.call($CLJS.R,a,b,c)}return a};$CLJS.kZ.A=function(a,b,c,d){var e=$CLJS.Zl(a,b);if($CLJS.n(e)){var f=$CLJS.R.j;e=$CLJS.Ab(e);c=c.g?c.g(e,d):c.call(null,e,d);a=f.call($CLJS.R,a,b,c)}return a};
$CLJS.kZ.N=function(a,b,c,d,e){var f=$CLJS.Zl(a,b);if($CLJS.n(f)){var k=$CLJS.R.j;f=$CLJS.Ab(f);c=c.j?c.j(f,d,e):c.call(null,f,d,e);a=k.call($CLJS.R,a,b,c)}return a};$CLJS.kZ.W=function(a,b,c,d,e,f){var k=$CLJS.Zl(a,b);if($CLJS.n(k)){var l=$CLJS.R.j;k=$CLJS.Ab(k);c=c.A?c.A(k,d,e,f):c.call(null,k,d,e,f);a=l.call($CLJS.R,a,b,c)}return a};$CLJS.kZ.l=function(a,b,c,d,e,f,k){var l=$CLJS.Zl(a,b);return $CLJS.n(l)?$CLJS.R.j(a,b,$CLJS.O.l(c,$CLJS.Ab(l),d,e,f,$CLJS.G([k]))):a};
$CLJS.kZ.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=$CLJS.B(d);var e=$CLJS.C(d);d=$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.B(f);var k=$CLJS.C(f);f=$CLJS.B(k);k=$CLJS.C(k);return this.l(b,a,c,d,e,f,k)};$CLJS.kZ.v=6;
var Eha=new $CLJS.M(null,"value_field","value_field",-980977878),DZ=new $CLJS.M(null,"ascending","ascending",-988350486),EZ=new $CLJS.M(null,"named","named",-422393479),FZ=new $CLJS.M(null,"values_source_config","values_source_config",-590570309),GZ=new $CLJS.M(null,"descending","descending",-24766135),HZ=new $CLJS.M(null,"binning-strategy","binning-strategy",2063329158),IZ=new $CLJS.M(null,"datetime-field","datetime-field",21731696),Fha=new $CLJS.M(null,"use-as-display-name?","use-as-display-name?",
686752941),JZ=new $CLJS.M(null,"field-literal","field-literal",-1295883554),KZ=new $CLJS.M(null,"viz-settings","viz-settings",256055379),Gha=new $CLJS.M(null,"rows","rows",850049680),Hha=new $CLJS.M(null,"special-fn","special-fn",1290649344),CZ=new $CLJS.M("metabase.mbql.normalize","sequence","metabase.mbql.normalize/sequence",-1700645683),jZ=new $CLJS.M(null,"ignore-path","ignore-path",944069061),Iha=new $CLJS.M(null,"label_field","label_field",-1573182765),Jha=new $CLJS.M(null,"fk-\x3e","fk-\x3e",
-499026738),Kha=new $CLJS.M(null,"joined-field","joined-field",-2048778268),uha=new $CLJS.M(null,"visibility_type","visibility_type",-508434247);var LZ,Lha=$CLJS.Re($CLJS.N),Mha=$CLJS.Re($CLJS.N),Nha=$CLJS.Re($CLJS.N),Oha=$CLJS.Re($CLJS.N),Pha=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));LZ=new $CLJS.di($CLJS.Eh.g("metabase.mbql.normalize","normalize-mbql-clause-tokens"),$CLJS.Ik.g(ZY,$CLJS.B),Pha,Lha,Mha,Nha,Oha);LZ.m(null,$CLJS.zI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);b=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zI,b],null);return null!=a?$CLJS.$d.g(b,a):b});
LZ.m(null,$CLJS.dE,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dE,a instanceof $CLJS.M?dZ(a):a],null)});LZ.m(null,HZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?$CLJS.$d.g(LZ.h(new $CLJS.P(null,3,5,$CLJS.Q,[HZ,b,c],null)),a):new $CLJS.P(null,3,5,$CLJS.Q,[HZ,iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ),ZY(c)],null)});
LZ.m(null,$CLJS.KI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=iZ.g?iZ.g(a,jZ):iZ.call(null,a,jZ);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,b,function(){var d=$CLJS.n($CLJS.gE.h(c))?$CLJS.Lk.j(c,$CLJS.gE,$CLJS.yh):c;d=$CLJS.n($CLJS.QI.h(c))?$CLJS.Lk.j(d,$CLJS.QI,$CLJS.yh):d;return $CLJS.n($CLJS.xT.h(c))?$CLJS.Lk.j(d,$CLJS.xT,function(e){return $CLJS.n($CLJS.jH.h(e))?$CLJS.Lk.j(e,$CLJS.jH,$CLJS.yh):e}):d}()],null)});
LZ.m(null,JZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[JZ,b instanceof $CLJS.M?dZ(b):b,$CLJS.yh.h(a)],null)});LZ.m(null,IZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[IZ,iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ),$CLJS.ru,ZY(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[IZ,iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ),ZY(c)],null)});
LZ.m(null,$CLJS.WI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);return $CLJS.n(a)?$CLJS.$d.g(LZ.h(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.WI,b,c,d],null)),iZ.g?iZ.g(a,jZ):iZ.call(null,a,jZ)):new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.WI,iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ),$CLJS.Fd(c)?c:ZY(c),ZY(d)],null)});
LZ.m(null,$CLJS.tL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tL,b,ZY(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tL,$CLJS.Lx],null)});LZ.m(null,$CLJS.rL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rL,b,ZY(a)],null)});
LZ.m(null,$CLJS.BL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.BL,iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ),c,ZY(a)],null)});LZ.m(null,$CLJS.JL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.JL,iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ),c,ZY(a)],null)});
LZ.m(null,$CLJS.zL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zL,iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ),ZY(a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zL,iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ)],null)});
LZ.m(null,$CLJS.HL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.n(a)?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.HL,iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ),ZY(c),ZY(a)],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HL,iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ),ZY(c)],null)});
LZ.m(null,$CLJS.qL,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.qL,iZ.g?iZ.g(b,jZ):iZ.call(null,b,jZ),iZ.g?iZ.g(c,jZ):iZ.call(null,c,jZ),ZY(a)],null)});LZ.m(null,$CLJS.Aj,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,b,a],null)});
LZ.m(null,$CLJS.ci,function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);return $CLJS.dg.j(new $CLJS.P(null,1,5,$CLJS.Q,[ZY(a)],null),$CLJS.cf.h(function(c){return iZ.g?iZ.g(c,jZ):iZ.call(null,c,jZ)}),b)});
var MZ=function MZ(a){if($CLJS.n($CLJS.DD($CLJS.ee,$CLJS.Va)(a))){var c=ZY(a);var d=new $CLJS.$g(null,new $CLJS.h(null,18,[$CLJS.Rn,null,$CLJS.Ss,null,$CLJS.$I,null,$CLJS.TI,null,$CLJS.tu,null,$CLJS.LI,null,$CLJS.dJ,null,$CLJS.eJ,null,$CLJS.qI,null,$CLJS.wI,null,$CLJS.Rs,null,$CLJS.rI,null,$CLJS.YI,null,$CLJS.RI,null,$CLJS.fk,null,$CLJS.TA,null,$CLJS.EI,null,$CLJS.XI,null],null),null);c=d.h?d.h(c):d.call(null,c)}else c=null;return $CLJS.n(c)?c:$CLJS.n(gZ(a))?(a=$CLJS.B(a),MZ.h?MZ.h(a):MZ.call(null,
a)):null},Qha=new $CLJS.h(null,8,[$CLJS.jj,ZY,$CLJS.jS,function(a){a=$Y(a);return $CLJS.z($CLJS.GS.h(a))?$CLJS.Lk.j(a,$CLJS.GS,tha):a},$CLJS.FO,new $CLJS.h(null,6,[$CLJS.zI,function NZ(a){if($CLJS.n($CLJS.DD($CLJS.ee,$CLJS.Va)(a)))return ZY(a);if($CLJS.n(hZ(EZ,a))){a=$CLJS.z(a);$CLJS.B(a);var c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[EZ,NZ.h?NZ.h(a):NZ.call(null,a)],null),c)}return $CLJS.n($CLJS.n(gZ(a))?MZ($CLJS.id(a)):null)?$CLJS.Kk.g(NZ,a):iZ.g?iZ.g(a,
jZ):iZ.call(null,a,jZ)},$CLJS.$R,function(a){return $CLJS.dg.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.ld(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[dZ(u),iZ.g?iZ.g(t,jZ):iZ.call(null,t,jZ)],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.kc(e))):$CLJS.re($CLJS.te(l),null)}f=
$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[dZ(l),iZ.g?iZ.g(f,jZ):iZ.call(null,f,jZ)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())},$CLJS.RT,function(a){return $CLJS.Bf(function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.ld(f,m);t=$CLJS.n(gZ(t))?LZ.h(t):$CLJS.ae(LZ.h($CLJS.ae(t)));l.add(t);m+=
1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.kc(e))):$CLJS.re($CLJS.te(l),null)}l=$CLJS.B(e);return $CLJS.ce($CLJS.n(gZ(l))?LZ.h(l):$CLJS.ae(LZ.h($CLJS.ae(l))),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())},$CLJS.JS,function(a){a=$Y(a);a=$CLJS.Ee(a);var b=$CLJS.I.g(a,$CLJS.jS);if($CLJS.n(b))return a=$CLJS.CX(a,new $CLJS.h(null,1,[$CLJS.jS,$CLJS.FO],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jS],null),a=iZ.g?iZ.g(a,b):iZ.call(null,a,b),$CLJS.CX(a,new $CLJS.h(null,1,[$CLJS.FO,$CLJS.jS],
null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.FO],null);return iZ.g?iZ.g(a,b):iZ.call(null,a,b)},$CLJS.RQ,new $CLJS.h(null,1,[CZ,lZ],null),$CLJS.CP,new $CLJS.h(null,1,[CZ,function(a){a=iZ.g?iZ.g(a,$CLJS.FO):iZ.call(null,a,$CLJS.FO);var b=$CLJS.Ee(a),c=$CLJS.I.g(b,$CLJS.jH),d=$CLJS.I.g(b,$CLJS.xH);a=$CLJS.I.g(b,$CLJS.BH);b=$CLJS.n(c)?$CLJS.Lk.j(b,$CLJS.jH,ZY):b;d=$CLJS.n($CLJS.DD($CLJS.ee,$CLJS.Va)(d))?$CLJS.Lk.j(b,$CLJS.xH,ZY):b;return $CLJS.n(a)?$CLJS.Lk.j(d,$CLJS.BH,dZ):d}],null)],null),$CLJS.iC,
new $CLJS.h(null,1,[$CLJS.nQ,$CLJS.Sd],null),$CLJS.tP,new $CLJS.h(null,1,[CZ,function(a){var b=$CLJS.Ee(a),c=$CLJS.I.g(b,$CLJS.jj),d=$CLJS.I.g(b,$CLJS.OL),e=$CLJS.I.g(b,$CLJS.Wi);a=$CLJS.I.g(b,FZ);b=$CLJS.n(e)?$CLJS.Lk.j(b,$CLJS.Wi,dZ):b;c=$CLJS.n(c)?$CLJS.Lk.j(b,$CLJS.jj,ZY):b;d=$CLJS.n(d)?$CLJS.Lk.j(c,$CLJS.OL,function(f){return iZ.g?iZ.g(f,jZ):iZ.call(null,f,jZ)}):c;d=$CLJS.n(a)?$CLJS.AZ.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[FZ,Iha],null),function(f){return iZ.g?iZ.g(f,jZ):iZ.call(null,f,jZ)}):d;return $CLJS.n(a)?
$CLJS.AZ.j(d,new $CLJS.P(null,2,5,$CLJS.Q,[FZ,Eha],null),function(f){return iZ.g?iZ.g(f,jZ):iZ.call(null,f,jZ)}):d}],null),$CLJS.oP,function(a){return null==a?null:ZY(a)},$CLJS.RQ,new $CLJS.h(null,1,[CZ,lZ],null),KZ,ZY],null),iZ=function iZ(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return iZ.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
iZ.l=function(a,b){b=$CLJS.H(b,0,null);var c=b instanceof $CLJS.M?new $CLJS.P(null,1,5,$CLJS.Q,[b],null):$CLJS.Bf(b);b=$CLJS.z(c)?$CLJS.ED(Qha,c):null;try{return $CLJS.nd(b)?b.h?b.h(a):b.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?$CLJS.dg.g($CLJS.N,function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.ld(m,v),A=$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);A=ZY(A);A=new $CLJS.P(null,
2,5,$CLJS.Q,[A,iZ.l(x,$CLJS.G([$CLJS.$d.g($CLJS.Bf(c),A)]))],null);u.add(A);v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.kc(l))):$CLJS.re($CLJS.te(u),null)}m=$CLJS.B(l);u=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);u=ZY(u);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[u,iZ.l(m,$CLJS.G([$CLJS.$d.g($CLJS.Bf(c),u)]))],null),k($CLJS.Kc(l)))}return null}},null,null)}(a)}()):$CLJS.n(gZ(a))?LZ.h(a):$CLJS.wd(a)?$CLJS.Kk.g(function(e){return iZ.l(e,$CLJS.G([$CLJS.$d.g($CLJS.Bf(c),CZ)]))},a):a}catch(e){if(e instanceof
Error){var d=e;throw $CLJS.gi($CLJS.SG("Error normalizing form: {0}",$CLJS.G([$CLJS.vX(d)])),new $CLJS.h(null,3,[$CLJS.Vu,a,$CLJS.hl,c,Hha,b],null),d);}throw e;}};iZ.v=1;iZ.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
var nZ=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.mbql.normalize","canonicalize-mbql-clause"),function(f){return $CLJS.n(gZ(f))?$CLJS.B(f):null},e,a,b,c,d)}();nZ.m(null,$CLJS.ci,function(a){return a});
nZ.m(null,$CLJS.KI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);if($CLJS.n(hZ($CLJS.KI,b))){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);return nZ.h(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,c,$CLJS.Fe($CLJS.$k.l($CLJS.G([b,a])))],null))}return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,b,$CLJS.Fe(a)],null)});
nZ.m(null,$CLJS.zI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.td(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zI,b],null):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zI,b,a],null)});nZ.m(null,$CLJS.FX,function(a){$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return $CLJS.n(gZ(a))?nZ.h(a):new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,a,null],null)});
nZ.m(null,JZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,b,new $CLJS.h(null,1,[$CLJS.gE,a],null)],null)});nZ.m(null,Jha,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);var c=oZ(b);$CLJS.H(c,0,null);b=$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=oZ(a);$CLJS.H(c,0,null);a=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,a,$CLJS.R.j(c,$CLJS.DP,b)],null)});
nZ.m(null,Kha,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);a=oZ(a);return $CLJS.O.A($CLJS.KX,a,$CLJS.R,$CLJS.G([$CLJS.tR,b]))});
nZ.m(null,IZ,function(a){var b=$CLJS.E(a);switch(b){case 3:$CLJS.H(a,0,null);b=$CLJS.H(a,1,null);var c=$CLJS.H(a,2,null);a=oZ(b);b=c;$CLJS.H(a,0,null);$CLJS.H(a,1,null);c=$CLJS.H(a,2,null);c=$CLJS.Ee(c);var d=$CLJS.I.g(c,$CLJS.gE);$CLJS.Sa(d)||$CLJS.NU.g(d,b)?a=$CLJS.O.A($CLJS.KX,a,$CLJS.R,$CLJS.G([$CLJS.QI,b])):(c=$CLJS.ND($CLJS.gC),$CLJS.n($CLJS.MD("metabase.mbql.util",c))&&(b=$CLJS.SG("{0} is not a valid temporal unit for {1}; not adding to clause {2}",$CLJS.G([b,d,$CLJS.Lh.l($CLJS.G([a]))])),
b instanceof Error?$CLJS.LD("metabase.mbql.util",c,$CLJS.vy(),b):$CLJS.LD("metabase.mbql.util",c,$CLJS.vy.l($CLJS.G([b])),null)));return a;case 4:return $CLJS.H(a,0,null),b=$CLJS.H(a,1,null),$CLJS.H(a,2,null),c=$CLJS.H(a,3,null),nZ.h(new $CLJS.P(null,3,5,$CLJS.Q,[IZ,b,c],null));default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}});
nZ.m(null,HZ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null),d=$CLJS.H(a,3,null);a=$CLJS.H(a,4,null);var e=oZ(b);$CLJS.H(e,0,null);b=$CLJS.H(e,1,null);e=$CLJS.H(e,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,b,$CLJS.R.j(e,$CLJS.xT,$CLJS.$k.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.jH,c],null),$CLJS.n(d)?$CLJS.De([c,d]):null,a])))],null)});
for(var OZ=$CLJS.z(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,$CLJS.ot,$CLJS.qt],null)),PZ=null,QZ=0,RZ=0;;)if(RZ<QZ){var SZ=PZ.X(null,RZ);nZ.m(null,SZ,function(){return function(a){return pZ(a)}}(OZ,PZ,QZ,RZ,SZ));RZ+=1}else{var TZ=$CLJS.z(OZ);if(TZ){var UZ=TZ;if($CLJS.Ad(UZ)){var VZ=$CLJS.jc(UZ),Rha=$CLJS.kc(UZ),Sha=VZ,Tha=$CLJS.E(VZ);OZ=Rha;PZ=Sha;QZ=Tha}else{var WZ=$CLJS.B(UZ);nZ.m(null,WZ,function(){return function(a){return pZ(a)}}(OZ,PZ,QZ,RZ,WZ,UZ,TZ));OZ=$CLJS.C(UZ);PZ=null;QZ=0}RZ=0}else break}
nZ.m(null,$CLJS.tI,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.dg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tI,oZ(a),oZ(b)],null),c)});nZ.m(null,$CLJS.WI,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=oZ(a);a=$CLJS.n(eZ($CLJS.KI,a))?$CLJS.KX.l(c,$CLJS.Ak,$CLJS.G([$CLJS.QI])):c;return $CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WI,a],null),b)});
for(var XZ=$CLJS.z(new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.BI,$CLJS.VI,$CLJS.JI,$CLJS.CI,$CLJS.Os,$CLJS.bJ,$CLJS.Ls,$CLJS.Ns,$CLJS.Hs,$CLJS.Js,$CLJS.pI,$CLJS.vI,$CLJS.nI,$CLJS.xI,$CLJS.oI],null)),YZ=null,ZZ=0,$Z=0;;)if($Z<ZZ){var a_=YZ.X(null,$Z);nZ.m(null,a_,function(){return function(a){return qZ(a)}}(XZ,YZ,ZZ,$Z,a_));$Z+=1}else{var b_=$CLJS.z(XZ);if(b_){var c_=b_;if($CLJS.Ad(c_)){var d_=$CLJS.jc(c_),Uha=$CLJS.kc(c_),Vha=d_,Wha=$CLJS.E(d_);XZ=Uha;YZ=Vha;ZZ=Wha}else{var e_=$CLJS.B(c_);nZ.m(null,e_,
function(){return function(a){return qZ(a)}}(XZ,YZ,ZZ,$Z,e_,c_,b_));XZ=$CLJS.C(c_);YZ=null;ZZ=0}$Z=0}else break}nZ.m(null,Gha,function(){return null});nZ.m(null,$CLJS.rO,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rO,nZ.h(b),a],null)});
nZ.m(null,EZ,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);var d=$CLJS.C(c);c=nZ.h;var e=$CLJS.Q;b=nZ.h(b);d=$CLJS.H(d,0,null);d=$CLJS.Ee(d);a=!1===$CLJS.I.g(d,Fha)?new $CLJS.h(null,1,[$CLJS.S,a],null):new $CLJS.h(null,1,[$CLJS.WG,a],null);return c.call(nZ,new $CLJS.P(null,3,5,e,[$CLJS.rO,b,a],null))});
for(var f_=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.LI],null)),g_=null,h_=0,i_=0;;)if(i_<h_){var j_=g_.X(null,i_);nZ.m(null,j_,function(){return function(a){return rZ(a)}}(f_,g_,h_,i_,j_));i_+=1}else{var k_=$CLJS.z(f_);if(k_){var l_=k_;if($CLJS.Ad(l_)){var m_=$CLJS.jc(l_),Xha=$CLJS.kc(l_),Yha=m_,Zha=$CLJS.E(m_);f_=Xha;g_=Yha;h_=Zha}else{var n_=$CLJS.B(l_);nZ.m(null,n_,function(){return function(a){return rZ(a)}}(f_,g_,h_,i_,n_,l_,k_));f_=$CLJS.C(l_);g_=null;h_=0}i_=0}else break}
for(var o_=$CLJS.z(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.EI,$CLJS.fJ,$CLJS.qI,$CLJS.$I,$CLJS.XI,$CLJS.Rn,$CLJS.fk,$CLJS.YI,$CLJS.rI],null)),p_=null,q_=0,r_=0;;)if(r_<q_){var s_=p_.X(null,r_);nZ.m(null,s_,function(){return function(a){return sZ(a)}}(o_,p_,q_,r_,s_));r_+=1}else{var t_=$CLJS.z(o_);if(t_){var u_=t_;if($CLJS.Ad(u_)){var v_=$CLJS.jc(u_),$ha=$CLJS.kc(u_),aia=v_,bia=$CLJS.E(v_);o_=$ha;p_=aia;q_=bia}else{var w_=$CLJS.B(u_);nZ.m(null,w_,function(){return function(a){return sZ(a)}}(o_,p_,q_,r_,
w_,u_,t_));o_=$CLJS.C(u_);p_=null;q_=0}r_=0}else break}nZ.m(null,$CLJS.wI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wI,oZ(b),a],null)});
for(var x_=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RI,$CLJS.TI],null)),y_=null,z_=0,A_=0;;)if(A_<z_){var B_=y_.X(null,A_);nZ.m(null,B_,function(){return function(a){return tZ(a)}}(x_,y_,z_,A_,B_));A_+=1}else{var C_=$CLJS.z(x_);if(C_){var D_=C_;if($CLJS.Ad(D_)){var E_=$CLJS.jc(D_),cia=$CLJS.kc(D_),dia=E_,eia=$CLJS.E(E_);x_=cia;y_=dia;z_=eia}else{var F_=$CLJS.B(D_);nZ.m(null,F_,function(){return function(a){return tZ(a)}}(x_,y_,z_,A_,F_,D_,C_));x_=$CLJS.C(D_);y_=null;z_=0}A_=0}else break}
nZ.m(null,$CLJS.dJ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dJ,nZ.h(b),nZ.h(a)],null)});
nZ.m(null,$CLJS.uI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.n(a)?$CLJS.$d.g(nZ.h(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uI,b],null)),iZ.l(a,$CLJS.G([jZ]))):new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uI,$CLJS.Bf(function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.ld(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.P(null,
2,5,$CLJS.Q,[nZ.h(v),nZ.h(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.kc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[nZ.h(m),nZ.h(k)],null),e($CLJS.Kc(f)))}return null}},null,null)}(b)}())],null)});
nZ.m(null,$CLJS.gI,function(a){a=$CLJS.z(a);$CLJS.B(a);var b=$CLJS.C(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.dg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gI,nZ.h(a),$CLJS.F.g(0,b)?1:nZ.h(b)],null),$CLJS.cf.g(nZ,c))});
var vZ=function vZ(a){return function f(d,e){try{if($CLJS.n(function(){var x=bZ($CLJS.Dd);return x.h?x.h(e):x.call(null,e)}()))return f(d,$CLJS.Bf(e));throw $CLJS.Y;}catch(x){if(x instanceof Error)if(d=x,d===$CLJS.Y)try{if($CLJS.n(function(){var A=bZ($CLJS.ee);return A.h?A.h(e):A.call(null,e)}()))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[e],null)],null);throw $CLJS.Y;}catch(A){if(A instanceof Error)if(d=A,d===$CLJS.Y)try{if($CLJS.zd(e)&&2<=$CLJS.E(e))try{var k=$CLJS.Pk.j(e,
0,2);if($CLJS.zd(k)&&2===$CLJS.E(k))try{var l=$CLJS.bd(k,0);if($CLJS.n($CLJS.KN($CLJS.ee,$CLJS.Ne(new $CLJS.$g(null,new $CLJS.h(null,5,[$CLJS.Ss,null,$CLJS.tu,null,$CLJS.eJ,null,$CLJS.Rs,null,EZ,null],null),null)))(l)))try{var m=$CLJS.bd(k,1);if($CLJS.n(MZ(m)))return $CLJS.dg.j($CLJS.zf,$CLJS.RG(vZ),a);throw $CLJS.Y;}catch(D){if(D instanceof Error){var t=D;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw D;}else throw $CLJS.Y;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw D;
}else throw $CLJS.Y;}catch(D){if(D instanceof Error){t=D;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw D;}else throw $CLJS.Y;}catch(D){if(D instanceof Error)if(t=D,t===$CLJS.Y)try{if($CLJS.zd(e)&&1<=$CLJS.E(e))try{var u=$CLJS.Pk.j(e,0,1);if($CLJS.zd(u)&&1===$CLJS.E(u))try{if($CLJS.bd(u,0)instanceof $CLJS.M)return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(J){if(J instanceof Error){var v=J;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw J;}else throw $CLJS.Y;}catch(J){if(J instanceof Error){v=
J;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw J;}else throw $CLJS.Y;}catch(J){if(J instanceof Error){v=J;if(v===$CLJS.Y)return e;throw v;}throw J;}else throw t;else throw D;}else throw d;else throw A;}else throw d;else throw x;}}($CLJS.zf,a)},G_=function G_(a){return function f(d,e){try{var k=bZ($CLJS.Dd);var l=k.h?k.h(e):k.call(null,e);if($CLJS.n(l))return f(d,$CLJS.Bf(e));throw $CLJS.Y;}catch(D){if(D instanceof Error)if(l=D,l===$CLJS.Y)try{if($CLJS.zd(e)&&2===$CLJS.E(e))try{var m=$CLJS.bd(e,1);
if($CLJS.fe(m,$CLJS.HI)){var t=$CLJS.bd(e,0);return f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HI,t],null))}throw $CLJS.Y;}catch(J){if(J instanceof Error)if(l=J,l===$CLJS.Y)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,$CLJS.AI))return t=$CLJS.bd(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AI,t],null));throw $CLJS.Y;}catch(K){if(K instanceof Error){var u=K;if(u===$CLJS.Y)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,DZ))return t=$CLJS.bd(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HI,t],null));throw $CLJS.Y;}catch(X){if(X instanceof
Error)if(l=X,l===$CLJS.Y)try{m=$CLJS.bd(e,1);if($CLJS.fe(m,GZ))return t=$CLJS.bd(e,0),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AI,t],null));throw $CLJS.Y;}catch(Z){if(Z instanceof Error)if(m=Z,m===$CLJS.Y)try{var v=$CLJS.bd(e,0);if($CLJS.fe(v,DZ))return t=$CLJS.bd(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HI,t],null));throw $CLJS.Y;}catch(fa){if(fa instanceof Error)if(m=fa,m===$CLJS.Y)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,GZ))return t=$CLJS.bd(e,1),f(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AI,t],null));
throw $CLJS.Y;}catch(ra){if(ra instanceof Error)if(m=ra,m===$CLJS.Y)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,$CLJS.HI))return t=$CLJS.bd(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HI,oZ(t)],null);throw $CLJS.Y;}catch(xa){if(xa instanceof Error)if(m=xa,m===$CLJS.Y)try{v=$CLJS.bd(e,0);if($CLJS.fe(v,$CLJS.AI))return t=$CLJS.bd(e,1),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AI,oZ(t)],null);throw $CLJS.Y;}catch(tb){if(tb instanceof Error){t=tb;if(t===$CLJS.Y)throw $CLJS.Y;throw t;}throw tb;}else throw m;else throw xa;
}else throw m;else throw ra;}else throw m;else throw fa;}else throw m;else throw Z;}else throw l;else throw X;}else throw u;}else throw K;}else throw l;else throw J;}else throw $CLJS.Y;}catch(J){if(J instanceof Error)if(l=J,l===$CLJS.Y)try{if($CLJS.zd(e)&&0<=$CLJS.E(e))try{var x=$CLJS.Pk.j(e,0,0);if($CLJS.zd(x)&&0===$CLJS.E(x))try{var A=$CLJS.Pk.g(e,0);if($CLJS.F.g(A,a))return $CLJS.Bf($CLJS.bl.h($CLJS.cf.g(G_,a)));throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;
}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)throw $CLJS.Y;throw u;}throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error){u=K;if(u===$CLJS.Y)return $CLJS.cZ(f,d,e);throw u;}throw K;}else throw l;else throw J;}else throw l;else throw D;}}($CLJS.zf,a)},yZ=$CLJS.Ik.g(aZ,function(a){var b=zZ($CLJS.zI.h(a))?$CLJS.Lk.j(a,$CLJS.zI,vha):a;b=zZ($CLJS.GQ.h(a))?$CLJS.Lk.j(b,$CLJS.GQ,wha):b;b=zZ($CLJS.xH.h(a))?$CLJS.Lk.j(b,$CLJS.xH,$CLJS.Pe($CLJS.Kk,mZ)):b;b=zZ($CLJS.RT.h(a))?
$CLJS.Lk.j(b,$CLJS.RT,G_):b;return zZ($CLJS.JS.h(a))?$CLJS.Lk.j(b,$CLJS.JS,yha):b}),fia=new $CLJS.h(null,3,[$CLJS.jS,$CLJS.Sd,$CLJS.FO,new $CLJS.h(null,2,[$CLJS.JS,function(a){a=$CLJS.Ee(a);var b=$CLJS.I.g(a,$CLJS.jS);if($CLJS.n(b))return a=$CLJS.CX(a,new $CLJS.h(null,1,[$CLJS.jS,$CLJS.FO],null)),b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.jS],null),a=BZ.g?BZ.g(a,b):BZ.call(null,a,b),$CLJS.CX(a,new $CLJS.h(null,1,[$CLJS.FO,$CLJS.jS],null));b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.FO],null);return BZ.g?BZ.g(a,
b):BZ.call(null,a,b)},$CLJS.CP,new $CLJS.h(null,1,[CZ,function(a){var b=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.FO],null);return BZ.g?BZ.g(a,b):BZ.call(null,a,b)}],null)],null),KZ,$CLJS.Sd],null),BZ=function BZ(a){switch(arguments.length){case 1:return BZ.h(arguments[0]);case 2:return BZ.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};BZ.h=function(a){return BZ.g(a,$CLJS.zf)};
BZ.g=function(a,b){try{var c=$CLJS.z(b)?$CLJS.ED(fia,b):null;return $CLJS.nd(c)?c.h?c.h(a):c.call(null,a):$CLJS.yd(a)?a:$CLJS.xd(a)?Cha(a,b):$CLJS.wd(a)?Dha(a,b):a}catch(d){if(d instanceof Error)throw $CLJS.gi("Error removing empty clauses from form.",new $CLJS.h(null,2,[$CLJS.Vu,a,$CLJS.hl,b],null),d);throw d;}};BZ.v=2;
$CLJS.H_=function(){var a=$CLJS.Ik.l(BZ,Bha,zha,$CLJS.G([iZ]));return function(b){try{return a(b)}catch(d){if(d instanceof Error){var c=d;throw $CLJS.gi($CLJS.SG("Error normalizing query: {0}",$CLJS.G([$CLJS.vX(c)])),new $CLJS.h(null,1,[$CLJS.FO,b],null),c);}throw d;}}}();$CLJS.I_=function I_(a,b){if($CLJS.Sa($CLJS.z(a)))a=(0,$CLJS.H_)(b);else{var d=$CLJS.I,e=d.g;var f=$CLJS.Mu(a);b=$CLJS.De([$CLJS.jd(a),b]);f=I_.g?I_.g(f,b):I_.call(null,f,b);a=e.call(d,f,$CLJS.jd(a))}return a};