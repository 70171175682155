var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.ref.js");require("./metabase.mbql.util.match.js");require("./metabase.util.malli.js");
'use strict';var e3,g3,i3,j3,Gja,Hja,Ija,p3,f3,q3;e3=function(a,b){var c=$CLJS.Gd,d=d3;for(a=$CLJS.z(a);;)if(null!=a){d=$CLJS.I.j(d,$CLJS.B(a),c);if(c===d)return b;a=$CLJS.C(a)}else return d};g3=function(a,b){var c=f3,d=$CLJS.qd(a);b=$CLJS.R.j?$CLJS.R.j(d,c,b):$CLJS.R.call(null,d,c,b);return $CLJS.pd(a,b)};
$CLJS.h3=function(a,b,c,d){a=$CLJS.FY(a);b=$CLJS.n(b)?b:-1;var e=$CLJS.HY(a,b);e=!($CLJS.z($CLJS.zI.h(e))||$CLJS.z($CLJS.GQ.h(e)));c=$CLJS.VY.l(a,b,$CLJS.Lk,$CLJS.G([c,function(f){return $CLJS.$d.g($CLJS.Bf(f),$CLJS.tY.h(d))}]));return e?$CLJS.Lk.N((0,$CLJS.VY)(c,b,function(f){return $CLJS.kZ.j($CLJS.Ak.l(f,$CLJS.RT,$CLJS.G([$CLJS.xH])),$CLJS.CP,function(k){return $CLJS.Kk.g(function(l){return $CLJS.Ak.g(l,$CLJS.xH)},k)})}),$CLJS.vN,$CLJS.Ik.g(function(f){return $CLJS.dg.g($CLJS.zf,f)},$CLJS.Pk),
0,$CLJS.GY(a,b)+1):c};i3=function(a){return $CLJS.dg.j($CLJS.ah,$CLJS.Jk.h(function(b){return $CLJS.ke(b)&&$CLJS.Gk.g(b,$CLJS.Bj)}),$CLJS.Wg(a))};j3=function(a){return $CLJS.dg.j($CLJS.N,$CLJS.$l(function(b,c){$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);c=$CLJS.Ee(c);c=$CLJS.I.g(c,$CLJS.fH);return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),$CLJS.zI.h(a))};
Gja=function(a){return $CLJS.oY(a,function(b){return $CLJS.dg.j($CLJS.N,$CLJS.Jk.h(function(c){var d=$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.ke(d)}),b)})};
$CLJS.k3=function(a){return $CLJS.B($CLJS.z($CLJS.ff($CLJS.Ta,function e(c,d){try{if($CLJS.zd(d)&&3===$CLJS.E(d))try{var f=$CLJS.bd(d,0);if($CLJS.fe(f,$CLJS.KI))try{var k=$CLJS.bd(d,2);if($CLJS.Il(k))return $CLJS.bd(d,2),$CLJS.bd(d,1),new $CLJS.P(null,1,5,$CLJS.Q,[d],null);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Y)return $CLJS.DX(e,c,d);throw l;}throw m;}}($CLJS.zf,a))))};
$CLJS.m3=function(a,b){for(var c=$CLJS.Sd,d=new $CLJS.P(null,6,5,$CLJS.Q,[function(){return function(k){return $CLJS.nH(k,$CLJS.kZ,$CLJS.G([$CLJS.xT,$CLJS.Ak,$CLJS.C0,$CLJS.Bj]))}}(c),Gja,function(){return function(k){return $CLJS.nH(k,$CLJS.Ak,$CLJS.G([$CLJS.gE,$CLJS.Fi]))}}(c),function(){return function(k){return $CLJS.nH(k,$CLJS.Ak,$CLJS.G([$CLJS.QI]))}}(c),function(){return function(k){return $CLJS.nH(k,$CLJS.Ak,$CLJS.G([$CLJS.xT]))}}(c),function(){return function(k){return $CLJS.nH(k,$CLJS.Ak,
$CLJS.G([$CLJS.tR]))}}(c)],null);;){var e=function(){var k=c.h?c.h(a):c.call(null,a);return $CLJS.WJ(function(l,m,t){return function(u){return l3.g(l.h?l.h(u):l.call(null,u),t)}}(c,d,k),b)}();if($CLJS.n(e))return e;if($CLJS.z(d)){e=$CLJS.Ik.g(c,$CLJS.B(d));var f=$CLJS.Kc(d);c=e;d=f}else return null}};
$CLJS.n3=function(a,b){if($CLJS.z(b)){var c=function(e){var f=$CLJS.m3(a,function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.jc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.ld(u,A),J=$CLJS.H(D,0,null);D=$CLJS.H(D,1,null);J=g3(e.h?e.h(D):e.call(null,D),J);x.add(J);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.kc(t))):$CLJS.re($CLJS.te(x),null)}u=$CLJS.B(t);x=$CLJS.H(u,0,null);u=$CLJS.H(u,
1,null);return $CLJS.ce(g3(e.h?e.h(u):e.call(null,u),x),m($CLJS.Kc(t)))}return null}},null,null)}($CLJS.Xl($CLJS.Jt,b))}());return $CLJS.n(f)?f3.h($CLJS.qd(f)):null},d=c($CLJS.qY);return $CLJS.n(d)?d:$CLJS.n($CLJS.k3(a))?c(function(e){return $CLJS.qY($CLJS.n($CLJS.Wi.h(e))?$CLJS.R.j(e,$CLJS.LY,$CLJS.D0):e)}):null}return null};$CLJS.o3=function(a,b){a=$CLJS.n3(a,b);return $CLJS.n(a)?$CLJS.bd(b,a):null};Hja=new $CLJS.M("metabase.lib.equality","no-right","metabase.lib.equality/no-right",2015744769);
Ija=new $CLJS.M("metabase.lib.equality","no-left","metabase.lib.equality/no-left",-1403666439);p3=new $CLJS.M(null,"left","left",-399115937);f3=new $CLJS.M("metabase.lib.equality","index","metabase.lib.equality/index",1826141378);q3=new $CLJS.M("metabase.lib.equality","different-dispatch-values","metabase.lib.equality/different-dispatch-values",-2107733395);var l3=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.equality","\x3d"),function(f,k){f=$CLJS.SE(f);k=$CLJS.SE(k);return $CLJS.Gk.g(f,k)?q3:f},e,a,b,c,d)}();l3.m(null,q3,function(){return!1});
l3.m(null,$CLJS.cE,function(a,b){var c=i3(a),d=i3(b);return $CLJS.F.g(c,d)&&$CLJS.Le(function(e){return l3.g($CLJS.I.g(a,e),$CLJS.I.g(b,e))},c)});l3.m(null,$CLJS.aE,function(a,b){var c=$CLJS.F.g($CLJS.E(a),$CLJS.E(b));if(c)for(c=$CLJS.z(a),$CLJS.B(c),$CLJS.C(c),c=$CLJS.z(b),$CLJS.B(c),$CLJS.C(c),c=a,a=b;;){c=$CLJS.z(c);b=$CLJS.B(c);var d=$CLJS.C(c);c=b;b=d;a=$CLJS.z(a);d=$CLJS.B(a);a=$CLJS.C(a);c=l3.g(c,d);if($CLJS.n(c)){if(c=$CLJS.td(b))return c;c=b}else return c}else return c});var d3=null;
l3.m(null,$CLJS.VX,function(a,b){var c=d3;d3=new $CLJS.h(null,2,[p3,j3(a),$CLJS.Yz,j3(b)],null);try{var d=$CLJS.ai(l3,$CLJS.cE);return d.g?d.g(a,b):d.call(null,a,b)}finally{d3=c}});
l3.m(null,$CLJS.zI,function(a,b){var c=$CLJS.H(a,0,null),d=$CLJS.H(a,1,null),e=$CLJS.H(a,2,null),f=$CLJS.H(b,0,null),k=$CLJS.H(b,1,null),l=$CLJS.H(b,2,null);return(a=$CLJS.F.l(3,$CLJS.E(a),$CLJS.G([$CLJS.E(b)])))?(c=$CLJS.F.g(c,f))?(d=l3.g(d,k),$CLJS.n(d)?$CLJS.n(d3)?$CLJS.F.g(e3(new $CLJS.P(null,2,5,$CLJS.Q,[p3,e],null),Ija),e3(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yz,l],null),Hja)):$CLJS.F.g(e,l):d):c:a});
l3.m(null,$CLJS.ci,function(a,b){if($CLJS.xd(a)){var c=$CLJS.ai(l3,$CLJS.cE);return c.g?c.g(a,b):c.call(null,a,b)}return $CLJS.wd(a)?(c=$CLJS.ai(l3,$CLJS.aE),c.g?c.g(a,b):c.call(null,a,b)):$CLJS.F.g(a,b)});
$CLJS.Jja=function(){function a(d,e,f,k){return $CLJS.o3($CLJS.x0.j(d,e,f),k)}function b(d,e,f){return c.A?c.A(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.A=a;return c}();