var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var RH,$H,XH,Bba;RH=function(a){if(null!=a&&null!=a.ia)a=a.cache;else{var b=RH[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=RH._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("Cached.-cache",a);}return a};
$CLJS.SH=function(a,b,c){if(null!=a&&$CLJS.r===a.Ta){var d=RH(a);var e=$CLJS.q(d);e=e.h?e.h(b):e.call(null,b);if($CLJS.n(e))return e;a=$CLJS.Nh.A(d,$CLJS.R,b,c.h?c.h(a):c.call(null,a));return a.h?a.h(b):a.call(null,b)}return c.h?c.h(a):c.call(null,a)};$CLJS.UH=function(a,b,c){a=$CLJS.TH.g(a,c);return a.h?a.h(b):a.call(null,b)};$CLJS.VH=function(a,b){return $CLJS.UH(a,b,null)};$CLJS.WH=function(a,b){$CLJS.Nh.A($CLJS.UE,$CLJS.UD,a,b)};$CLJS.YH=function(a,b){a=XH(a,b);return $CLJS.n(a)?a:$CLJS.xj};
$H=function(){return $CLJS.dg.j(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Oi,$CLJS.B,$CLJS.Fu,function(a){a=$CLJS.Ee(a);a=$CLJS.I.g(a,$CLJS.Aj);return $CLJS.zd(a)?["Invalid ",$CLJS.Lh.l($CLJS.G([$CLJS.B(a)]))," clause: ",$CLJS.Lh.l($CLJS.G([a]))].join(""):"not an MBQL clause"}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.So,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"not a known MBQL clause"],null),$CLJS.Oe(!1)],null)],null)],null),$CLJS.cf.h(function(a){return new $CLJS.P(null,
2,5,$CLJS.Q,[a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.yh.g("mbql.clause",$CLJS.fh(a))],null)],null)}),$CLJS.q(ZH))};$CLJS.cI=function(a,b){$CLJS.O.A(aI,bI,a,b)};$CLJS.eI=function(a,b){$CLJS.O.A(aI,dI,a,b)};$CLJS.rp.prototype.ia=$CLJS.va(26,function(){return this.cache});$CLJS.tp.prototype.ia=$CLJS.va(25,function(){return this.cache});$CLJS.vp.prototype.ia=$CLJS.va(24,function(){return this.cache});$CLJS.xp.prototype.ia=$CLJS.va(23,function(){return this.cache});
$CLJS.zp.prototype.ia=$CLJS.va(22,function(){return this.cache});$CLJS.Bp.prototype.ia=$CLJS.va(21,function(){return this.cache});$CLJS.Ep.prototype.ia=$CLJS.va(20,function(){return this.cache});$CLJS.Gp.prototype.ia=$CLJS.va(19,function(){return this.cache});$CLJS.Ip.prototype.ia=$CLJS.va(18,function(){return this.cache});$CLJS.Kp.prototype.ia=$CLJS.va(17,function(){return this.cache});$CLJS.Mp.prototype.ia=$CLJS.va(16,function(){return this.cache});$CLJS.Op.prototype.ia=$CLJS.va(15,function(){return this.cache});
$CLJS.Qp.prototype.ia=$CLJS.va(14,function(){return this.cache});$CLJS.Sp.prototype.ia=$CLJS.va(13,function(){return this.cache});$CLJS.Up.prototype.ia=$CLJS.va(12,function(){return this.cache});$CLJS.Wp.prototype.ia=$CLJS.va(11,function(){return this.cache});$CLJS.Zp.prototype.ia=$CLJS.va(10,function(){return this.cache});$CLJS.dq.prototype.ia=$CLJS.va(9,function(){return this.cache});$CLJS.fq.prototype.ia=$CLJS.va(8,function(){return this.cache});$CLJS.iq.prototype.ia=$CLJS.va(7,function(){return this.cache});
$CLJS.sq.prototype.ia=$CLJS.va(6,function(){return this.cache});$CLJS.JG.prototype.ia=$CLJS.va(5,function(){return this.cache});$CLJS.TH=function TH(a){switch(arguments.length){case 1:return TH.h(arguments[0]);case 2:return TH.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.TH.h=function(a){return $CLJS.TH.g(a,null)};$CLJS.TH.g=function(a,b){return $CLJS.SH($CLJS.qo.g(a,b),$CLJS.qk,$CLJS.on)};$CLJS.TH.v=2;
XH=function XH(a,b){return $CLJS.F.g(a,$CLJS.xj)?null:$CLJS.F.g(b,$CLJS.xj)?null:$CLJS.fF(a,b)?b:$CLJS.fF(b,a)?a:$CLJS.Me(function(d){return $CLJS.Me(function(e){return $CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,[d,e],null),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))?null:XH.g?XH.g(d,e):XH.call(null,d,e)},$CLJS.ce(b,$CLJS.Xh(b)))},$CLJS.ce(a,$CLJS.Xh(a)))};$CLJS.fI=new $CLJS.M(null,"exp","exp",-261706262);$CLJS.gI=new $CLJS.M(null,"substring","substring",1140866276);
$CLJS.hI=new $CLJS.M(null,"basic-aggregations","basic-aggregations",-1628959228);$CLJS.iI=new $CLJS.M(null,"ceil","ceil",-1824929952);$CLJS.jI=new $CLJS.M(null,"power","power",-937852079);$CLJS.kI=new $CLJS.M(null,"segment","segment",-964921196);$CLJS.lI=new $CLJS.M(null,"concat","concat",-2108183992);$CLJS.mI=new $CLJS.M(null,"abs","abs",-246026477);$CLJS.nI=new $CLJS.M(null,"is-null","is-null",-1997050930);$CLJS.oI=new $CLJS.M(null,"between","between",1131099276);
$CLJS.pI=new $CLJS.M(null,"is-empty","is-empty",-1040302908);$CLJS.qI=new $CLJS.M(null,"distinct","distinct",-1788879121);$CLJS.rI=new $CLJS.M(null,"var","var",-769682797);$CLJS.sI=new $CLJS.M(null,"next","next",-117701485);$CLJS.tI=new $CLJS.M(null,"inside","inside",1972503011);$CLJS.uI=new $CLJS.M(null,"case","case",1143702196);$CLJS.vI=new $CLJS.M(null,"not-empty","not-empty",388922063);$CLJS.wI=new $CLJS.M(null,"percentile","percentile",-601188752);
$CLJS.xI=new $CLJS.M(null,"not-null","not-null",-1326718535);$CLJS.yI=new $CLJS.M(null,"sqrt","sqrt",-1270051929);$CLJS.zI=new $CLJS.M(null,"aggregation","aggregation",1597476696);$CLJS.AI=new $CLJS.M(null,"desc","desc",2093485764);$CLJS.BI=new $CLJS.M(null,"starts-with","starts-with",366503009);$CLJS.CI=new $CLJS.M(null,"does-not-contain","does-not-contain",-274946853);$CLJS.DI=new $CLJS.M(null,"short","short",1928760516);$CLJS.EI=new $CLJS.M(null,"avg","avg",197406200);
$CLJS.FI=new $CLJS.M(null,"metric","metric",408798077);$CLJS.GI=new $CLJS.M(null,"percentile-aggregations","percentile-aggregations",1241763983);$CLJS.HI=new $CLJS.M(null,"asc","asc",356854569);$CLJS.II=new $CLJS.M(null,"floor","floor",1882041021);$CLJS.JI=new $CLJS.M(null,"contains","contains",676899812);$CLJS.KI=new $CLJS.M(null,"field","field",-1302436500);$CLJS.LI=new $CLJS.M(null,"cum-count","cum-count",-1964431543);$CLJS.MI=new $CLJS.M(null,"regex-match-first","regex-match-first",-657299718);
$CLJS.NI=new $CLJS.M(null,"coalesce","coalesce",654622029);$CLJS.OI=new $CLJS.M("metabase.lib.schema.ref","ref","metabase.lib.schema.ref/ref",-1124116498);$CLJS.PI=new $CLJS.M(null,"round","round",2009433328);$CLJS.QI=new $CLJS.M(null,"temporal-unit","temporal-unit",-1892306358);$CLJS.RI=new $CLJS.M(null,"share","share",-589433933);$CLJS.SI=new $CLJS.M(null,"case-sensitive","case-sensitive",-1005239404);$CLJS.TI=new $CLJS.M(null,"count-where","count-where",385407720);
$CLJS.UI=new $CLJS.M(null,"standard-deviation-aggregations","standard-deviation-aggregations",-1683333464);$CLJS.VI=new $CLJS.M(null,"ends-with","ends-with",-456791011);$CLJS.WI=new $CLJS.M(null,"time-interval","time-interval",704622015);$CLJS.XI=new $CLJS.M(null,"sum","sum",136986814);$CLJS.YI=new $CLJS.M(null,"median","median",569566131);Bba=new $CLJS.M("metabase.lib.schema.mbql-clause","update-schemas","metabase.lib.schema.mbql-clause/update-schemas",-447263953);
$CLJS.ZI=new $CLJS.M(null,"include-current","include-current",-1602371981);$CLJS.$I=new $CLJS.M(null,"stddev","stddev",-865474939);$CLJS.aJ=new $CLJS.M(null,"trim","trim",774319767);$CLJS.bJ=new $CLJS.M(null,"!\x3d","!\x3d",-1841737356);$CLJS.cJ=new $CLJS.M(null,"ltrim","ltrim",1654269283);$CLJS.dJ=new $CLJS.M(null,"sum-where","sum-where",2135348428);$CLJS.eJ=new $CLJS.M(null,"/","/",1282502798);$CLJS.fJ=new $CLJS.M(null,"cum-sum","cum-sum",2064902349);$CLJS.gJ=new $CLJS.M(null,"log","log",-1595516004);
$CLJS.hJ=new $CLJS.M(null,"replace","replace",-786587770);$CLJS.iJ=new $CLJS.M(null,"rtrim","rtrim",-661336449);var ZH=$CLJS.Re($CLJS.ah),dI;$CLJS.W($CLJS.tH,$H());$CLJS.VE(ZH,Bba,function(){return $CLJS.W($CLJS.tH,$H())});
$CLJS.jJ=function(){function a(d,e,f,k){c.g?c.g(d,k):c.call(null,d,k);$CLJS.rH.m(null,d,function(){return f});return null}function b(d,e){var f=$CLJS.yh.g("mbql.clause",$CLJS.fh(d));$CLJS.W(f,e);$CLJS.Hd($CLJS.q(ZH),d)||$CLJS.Nh.j(ZH,$CLJS.$d,d);return null}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.A=a;return c}();
dI=function dI(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return dI.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
dI.l=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,$CLJS.dg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dt,new $CLJS.h(null,1,[$CLJS.Du,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ej,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,a],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.eH],null)],null)],null)],null),b)],null)};dI.v=1;
dI.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};var bI=function bI(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return bI.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
bI.l=function(a,b){return $CLJS.dg.g(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.h(null,1,[$CLJS.Du,["Valid ",$CLJS.p.h(a)," clause"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.eH],null)],null),b)};bI.v=1;bI.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
var aI=function aI(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return aI.l(arguments[0],arguments[1],2<c.length?new $CLJS.y(c.slice(2),0,null):null)};aI.l=function(a,b,c){c=$CLJS.F.g($CLJS.B(c),$CLJS.tu)?$CLJS.ce($CLJS.id(c),$CLJS.We(2,c)):$CLJS.ce(null,c);var d=$CLJS.z(c);c=$CLJS.B(d);d=$CLJS.C(d);a=$CLJS.O.j(a,b,d);return $CLJS.n(c)?$CLJS.jJ.A(b,$CLJS.tu,c,a):$CLJS.jJ.g(b,a)};aI.v=2;
aI.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};