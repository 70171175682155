var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.schema.common.js");require("./metabase.mbql.predicates.js");require("./metabase.mbql.schema.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var xX,AX;$CLJS.uX=function(a,b){return $CLJS.dc($CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.cc(c,d):c},$CLJS.bc($CLJS.zf),b))};$CLJS.vX=function(a){return a instanceof Error?a.message:null};
$CLJS.wX=function(a,b,c){if($CLJS.Yd(c)){var d=$CLJS.O.g($CLJS.U,$CLJS.cf.g(a,c));return b.h?b.h(d):b.call(null,d)}return $CLJS.wf(c)?(d=new $CLJS.Xf(function(){var e=$CLJS.yb(c);return a.h?a.h(e):a.call(null,e)}(),function(){var e=$CLJS.Ab(c);return a.h?a.h(e):a.call(null,e)}()),b.h?b.h(d):b.call(null,d)):$CLJS.Dd(c)?(d=$CLJS.lh($CLJS.cf.g(a,c)),b.h?b.h(d):b.call(null,d)):$CLJS.yd(c)?(d=$CLJS.ab(function(e,f){return $CLJS.$d.g(e,a.h?a.h(f):a.call(null,f))},c,c),b.h?b.h(d):b.call(null,d)):$CLJS.ud(c)?
(d=$CLJS.dg.g($CLJS.kd(c),$CLJS.cf.g(a,c)),b.h?b.h(d):b.call(null,d)):b.h?b.h(c):b.call(null,c)};$CLJS.yX=function(a){function b(c){var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);return"string"===typeof d?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yh.h(d),c],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null)}return xX(function(c){return $CLJS.xd(c)?$CLJS.dg.g($CLJS.N,$CLJS.cf.g(b,c)):c},a)};$CLJS.zX=function(a){var b=new $CLJS.Fa;for(a=$CLJS.z(a);;)if(null!=a)b=b.append($CLJS.p.h($CLJS.B(a))),a=$CLJS.C(a);else return b.toString()};
$CLJS.BX=function(a,b,c){a=$CLJS.HD(a,/''/,"'");var d=$CLJS.tv(a,AX);d=$CLJS.F.g($CLJS.E(d),1)?new $CLJS.P(null,2,5,$CLJS.Q,[a,""],null):d;a=$CLJS.nh(/.*\{0\}.*/,a);return $CLJS.Iu.ngettext($CLJS.Iu.msgid($CLJS.cl(d),$CLJS.n(a)?c:""),$CLJS.HD($CLJS.HD(b,/''/,"'"),AX,$CLJS.p.h(c)),c)};$CLJS.CX=function(a,b){return $CLJS.ab(function(c,d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.Hd(a,e)?$CLJS.R.j(c,d,$CLJS.I.g(a,e)):c},$CLJS.O.j($CLJS.Ak,a,$CLJS.Wg(b)),b)};
$CLJS.DX=function(a,b,c){return $CLJS.xd(c)?$CLJS.df(function(d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);e=$CLJS.$d.g(b,e);return a.g?a.g(e,d):a.call(null,e,d)},$CLJS.G([c])):$CLJS.wd(c)?$CLJS.df($CLJS.Pe(a,$CLJS.B(c)instanceof $CLJS.M?$CLJS.$d.g(b,$CLJS.B(c)):b),$CLJS.G([c])):null};xX=function xX(a,b){return $CLJS.wX($CLJS.Pe(xX,a),a,b)};AX=/\{0\}/;$CLJS.EX=new $CLJS.M(null,"unique-alias-fn","unique-alias-fn",32555420);$CLJS.FX=new $CLJS.M(null,"field-id","field-id",-353751335);
$CLJS.GX=new $CLJS.M("clojure.core.match","not-found","clojure.core.match/not-found",1553053780);$CLJS.HX=new $CLJS.M(null,"name-key-fn","name-key-fn",-1634839199);var Oga=$CLJS.Hg([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.LL,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mL,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zL,$CLJS.sL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zL,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IL,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yL,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CL,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fL,null],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DL,null],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.zL,$CLJS.jL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zL,$CLJS.xL],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lL,null],null)],[$CLJS.zi,$CLJS.AJ,$CLJS.ML,$CLJS.pL,$CLJS.vi,$CLJS.Dj,$CLJS.fj,$CLJS.hk,$CLJS.Gi,$CLJS.pL,$CLJS.NL,$CLJS.xJ]),JX;$CLJS.eh($CLJS.cf.g($CLJS.B,$CLJS.Wg(Oga)));var IX,Pga=$CLJS.Re($CLJS.N),Qga=$CLJS.Re($CLJS.N),Rga=$CLJS.Re($CLJS.N),Sga=$CLJS.Re($CLJS.N),Tga=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));
IX=new $CLJS.di($CLJS.Eh.g("metabase.mbql.util","negate*"),$CLJS.B,Tga,Pga,Qga,Rga,Sga);IX.m(null,$CLJS.qt,function(a){$CLJS.H(a,0,null);return $CLJS.H(a,1,null)});IX.m(null,$CLJS.vt,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.dg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ot],null),$CLJS.cf.g(IX,a))});IX.m(null,$CLJS.ot,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);return $CLJS.dg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vt],null),$CLJS.cf.g(IX,a))});
IX.m(null,$CLJS.Os,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bJ,b,a],null)});IX.m(null,$CLJS.bJ,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Os,b,a],null)});IX.m(null,$CLJS.Hs,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ns,b,a],null)});
IX.m(null,$CLJS.Ls,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Js,b,a],null)});IX.m(null,$CLJS.Js,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ls,b,a],null)});IX.m(null,$CLJS.Ns,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,b,a],null)});
IX.m(null,$CLJS.oI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null),c=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ls,b,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hs,b,a],null)],null)});IX.m(null,$CLJS.JI,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qt,a],null)});IX.m(null,$CLJS.BI,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qt,a],null)});
IX.m(null,$CLJS.VI,function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qt,a],null)});
JX=function JX(a){return $CLJS.xd(a)?$CLJS.Fe($CLJS.dg.g($CLJS.N,function(){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.Ad(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.ld(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);u=JX.h?JX.h(u):JX.call(null,u);null!=u&&m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null));t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.kc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);
m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);k=JX.h?JX.h(k):JX.call(null,k);if(null!=k)return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[m,k],null),e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(a)}())):$CLJS.wd(a)?$CLJS.Fe($CLJS.dg.g($CLJS.kd(a),$CLJS.ff($CLJS.Ta,$CLJS.cf.g(JX,a)))):a};
$CLJS.KX=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){var f=$CLJS.H(c,0,null),k=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);d=$CLJS.Fe(JX($CLJS.O.j(d,c,e)));return $CLJS.n(d)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,d],null):$CLJS.F.g(f,$CLJS.KI)?new $CLJS.P(null,3,5,$CLJS.Q,[f,k,null],null):new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}a.v=2;a.B=function(c){var d=
$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();