var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.core.protocols.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.util.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var m9,o9,n9,Uqa,u9,v9,Vqa,Wqa,Xqa,p9,Yqa,A9,Zqa,$qa,ara;m9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Da(a,b):null};o9=function(a,b){return n9(a,b,$CLJS.N)};
n9=function(a,b,c){c=$CLJS.Ee(c);c=$CLJS.I.j(c,p9,!0);if($CLJS.xd(b))return $CLJS.dg.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=m9(b,"_plainObject");d=null==d?null:$CLJS.pD(d);return null==d?null:$CLJS.Fe(d)}():null;return $CLJS.n(c)?$CLJS.dg.j($CLJS.N,a,c):$CLJS.dg.j($CLJS.N,$CLJS.Ik.j($CLJS.cf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,m9(b,d)],null)}),$CLJS.Jk.h(function(d){$CLJS.H(d,0,null);return"function"===typeof $CLJS.H(d,1,null)}),a),$CLJS.Ca(b))};
Uqa=function(a){var b=q9.h(a),c=r9.h(a),d=s9.h(a);return $CLJS.Ik.j($CLJS.cf.h(function(e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);var k=$CLJS.Q;f=$CLJS.yh.h((0,$CLJS.SD)(f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.td(b)?$CLJS.Sd:$CLJS.Jk.h(function(e){var f=$CLJS.H(e,0,null);$CLJS.H(e,1,null);return $CLJS.Hd(b,f)}),$CLJS.Sa(c)?$CLJS.Sd:$CLJS.cf.h(function(e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return new $CLJS.P(null,
2,5,$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};
u9=function(a,b){var c=Uqa(a),d=t9.h(a);return function(e){try{var f=$CLJS.R.j(n9(c,e,b),$CLJS.Bj,d),k=$CLJS.ND($CLJS.dC);$CLJS.n($CLJS.MD("metabase.lib.js.metadata",k))&&$CLJS.LD("metabase.lib.js.metadata",k,$CLJS.wY("Parsed metadata %s %s\n%s",$CLJS.G([a,$CLJS.Wi.h(f),$CLJS.xY(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.ND($CLJS.eC),$CLJS.n($CLJS.MD("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.LD("metabase.lib.js.metadata",k,$CLJS.wY("Error parsing %s %s: %s",
$CLJS.G([a,$CLJS.Lh.l($CLJS.G([e])),$CLJS.vX(f)])),f):$CLJS.LD("metabase.lib.js.metadata",k,$CLJS.wY(f,$CLJS.G(["Error parsing %s %s: %s",a,$CLJS.Lh.l($CLJS.G([e])),$CLJS.vX(f)])),null)),null;throw l;}}};v9=function(a){return $CLJS.Kk.g(u9($CLJS.KI,$CLJS.N),a)};Vqa=new $CLJS.M(null,"plain-object","plain-object",-943264281);$CLJS.w9=new $CLJS.M(null,"cards","cards",169174038);Wqa=new $CLJS.M(null,"db","db",993250759);$CLJS.x9=new $CLJS.M(null,"metrics","metrics",394093469);
Xqa=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.y9=new $CLJS.M(null,"segments","segments",1937535949);p9=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);Yqa=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.z9=new $CLJS.M(null,"tables","tables",1334623052);A9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);Zqa=new $CLJS.M(null,"schema-name","schema-name",1666725119);$qa=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);
ara=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);$CLJS.B9=new $CLJS.M(null,"databases","databases",2040134125);var q9,bra=$CLJS.Re($CLJS.N),cra=$CLJS.Re($CLJS.N),dra=$CLJS.Re($CLJS.N),era=$CLJS.Re($CLJS.N),fra=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null)),D9,vra,wra,xra,yra,zra;q9=new $CLJS.di($CLJS.Eh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.yh,fra,bra,cra,dra,era);q9.m(null,$CLJS.ci,function(){return null});var r9,gra=$CLJS.Re($CLJS.N),hra=$CLJS.Re($CLJS.N),ira=$CLJS.Re($CLJS.N),jra=$CLJS.Re($CLJS.N),kra=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));
r9=new $CLJS.di($CLJS.Eh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.yh,kra,gra,hra,ira,jra);r9.m(null,$CLJS.ci,function(){return null});var t9,lra=$CLJS.Re($CLJS.N),mra=$CLJS.Re($CLJS.N),nra=$CLJS.Re($CLJS.N),ora=$CLJS.Re($CLJS.N),pra=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));t9=new $CLJS.di($CLJS.Eh.g("metabase.lib.js.metadata","lib-type"),$CLJS.yh,pra,lra,mra,nra,ora);
var s9,qra=$CLJS.Re($CLJS.N),rra=$CLJS.Re($CLJS.N),sra=$CLJS.Re($CLJS.N),tra=$CLJS.Re($CLJS.N),ura=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));s9=new $CLJS.di($CLJS.Eh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Sd,ura,qra,rra,sra,tra);s9.m(null,$CLJS.ci,function(){return null});
$CLJS.C9=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.yh.h(f)},e,a,b,c,d)}();vra=$CLJS.Re($CLJS.N);wra=$CLJS.Re($CLJS.N);xra=$CLJS.Re($CLJS.N);yra=$CLJS.Re($CLJS.N);zra=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));
D9=new $CLJS.di($CLJS.Eh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.yh,zra,vra,wra,xra,yra);$CLJS.C9.m(null,$CLJS.ci,function(a,b){var c=u9(a,$CLJS.N);return o9($CLJS.cf.h(function(d){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vY(e),new $CLJS.Ih(function(){return c.h?c.h(f):c.call(null,f)})],null)}),m9(b,D9.h(a)))});t9.m(null,$CLJS.UR,function(){return $CLJS.oha});
q9.m(null,$CLJS.UR,function(){return new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.xH,null,$CLJS.z9,null],null),null)});r9.m(null,$CLJS.UR,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "dbms-version":return $CLJS.oD(b,$CLJS.G([$CLJS.yi,!0]));case "features":return $CLJS.dg.j($CLJS.ah,$CLJS.cf.h($CLJS.yh),b);case "native-permissions":return $CLJS.yh.h(b);default:return b}}});D9.m(null,$CLJS.UR,function(){return"databases"});t9.m(null,$CLJS.SY,function(){return $CLJS.NY});
q9.m(null,$CLJS.SY,function(){return new $CLJS.$g(null,new $CLJS.h(null,5,[A9,null,$CLJS.xH,null,$CLJS.y9,null,$CLJS.UR,null,$CLJS.x9,null],null),null)});r9.m(null,$CLJS.SY,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "entity-type":return $CLJS.yh.h(b);case "field-order":return $CLJS.yh.h(b);case "initial-sync-status":return $CLJS.yh.h(b);case "visibility-type":return $CLJS.yh.h(b);default:return b}}});
$CLJS.C9.m(null,$CLJS.SY,function(a,b){var c=u9(a,$CLJS.N);return o9($CLJS.Ik.g($CLJS.Jk.h(function(d){var e=$CLJS.H(d,0,null);$CLJS.H(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.cf.h(function(d){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vY(e),new $CLJS.Ih(function(){return c.h?c.h(f):c.call(null,f)})],null)})),m9(b,"tables"))});t9.m(null,$CLJS.KI,function(){return $CLJS.rY});
q9.m(null,$CLJS.KI,function(){return new $CLJS.$g(null,new $CLJS.h(null,7,[A9,null,$CLJS.SY,null,$CLJS.PY,null,ara,null,$CLJS.UR,null,$qa,null,$CLJS.x9,null],null),null)});s9.m(null,$CLJS.KI,function(){return new $CLJS.h(null,1,[$CLJS.Wu,$CLJS.LY],null)});
r9.m(null,$CLJS.KI,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "base-type":return $CLJS.yh.h(b);case "coercion-strategy":return $CLJS.yh.h(b);case "effective-type":return $CLJS.yh.h(b);case "fingerprint":return $CLJS.xd(b)?$CLJS.yX(b):$CLJS.oD(b,$CLJS.G([$CLJS.yi,!0]));case "has-field-values":return $CLJS.yh.h(b);case "lib/source":return $CLJS.yh.g("source",b);case "semantic-type":return $CLJS.yh.h(b);case "visibility-type":return $CLJS.yh.h(b);case "id":return $CLJS.zd(b)?
$CLJS.id(b):b;default:return b}}});
$CLJS.C9.m(null,$CLJS.KI,function(a,b){var c=u9(a,$CLJS.N),d=m9(b,"fields");return o9($CLJS.Ql(function(e){var f=$CLJS.H(e,0,null),k=$CLJS.H(e,1,null);e=function(){var l=$CLJS.vY(f);if($CLJS.n(l))return l;l=$CLJS.mh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.H(l,0,null);l=$CLJS.H(l,1,null);var m=null==m9(d,l);return m?$CLJS.vY(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.Ih(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});t9.m(null,$CLJS.uP,function(){return $CLJS.TY});
q9.m(null,$CLJS.uP,function(){return new $CLJS.$g(null,new $CLJS.h(null,11,[A9,null,$CLJS.il,null,Xqa,null,$CLJS.SY,null,Wqa,null,Vqa,null,$CLJS.y9,null,$CLJS.UR,null,Yqa,null,$CLJS.x9,null,Zqa,null],null),null)});
r9.m(null,$CLJS.uP,function(){return function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "result-metadata":return $CLJS.n($CLJS.DD($CLJS.wd,$CLJS.Ra)(b))?v9(b):$CLJS.oD(b,$CLJS.G([$CLJS.yi,!0]));case "fields":return v9(b);case "visibility-type":return $CLJS.yh.h(b);case "dataset-query":return $CLJS.oD(b,$CLJS.G([$CLJS.yi,!0]));case "dataset":return b;default:return b}}});
$CLJS.C9.m(null,$CLJS.uP,function(a,b){return $CLJS.dg.j($CLJS.N,$CLJS.cf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.Ih(function(){var d=u9($CLJS.uP,new $CLJS.h(null,1,[p9,!1],null)),e=u9($CLJS.uP,$CLJS.N),f=$CLJS.$k.l;d=$CLJS.R.j(d(m9(m9(b,"tables"),["card__",$CLJS.p.h(c)].join(""))),$CLJS.Wi,c);var k=m9(m9(b,"questions"),$CLJS.p.h(c));var l=m9(k,"_card");k=$CLJS.n(l)?l:k;e=e.h?e.h(k):e.call(null,k);return f.call($CLJS.$k,$CLJS.G([d,e]))})],null)}),$CLJS.dg.j($CLJS.dg.j($CLJS.ah,
$CLJS.Ql($CLJS.IY),$CLJS.Ca(m9(b,"tables"))),$CLJS.cf.h($CLJS.vY),$CLJS.Ca(m9(b,"questions"))))});t9.m(null,$CLJS.FI,function(){return $CLJS.sY});q9.m(null,$CLJS.FI,function(){return new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.SY,null,$CLJS.UR,null],null),null)});r9.m(null,$CLJS.FI,function(){return function(a,b){return b}});D9.m(null,$CLJS.FI,function(){return"metrics"});t9.m(null,$CLJS.kI,function(){return $CLJS.MY});
q9.m(null,$CLJS.kI,function(){return new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.SY,null,$CLJS.UR,null],null),null)});r9.m(null,$CLJS.kI,function(){return function(a,b){return b}});D9.m(null,$CLJS.kI,function(){return"segments"});$CLJS.E9=u9($CLJS.KI,$CLJS.N);