var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var fD=function(){},gD=function(a){return $CLJS.I.g($CLJS.VB,a).value},iaa=function(a){var b=gD(a);return $CLJS.n(function(){var c=gD($CLJS.lC);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.RC:$CLJS.n(function(){var c=gD($CLJS.kC);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.aD:$CLJS.n(function(){var c=gD($CLJS.iC);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.UC:$CLJS.n(function(){var c=gD($CLJS.cC);return $CLJS.Dk.g?
$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.$C:$CLJS.n(function(){var c=gD($CLJS.jC);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.bD:$CLJS.n(function(){var c=gD($CLJS.fC);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.XC:$CLJS.n(function(){var c=gD($CLJS.$B);return $CLJS.Dk.g?$CLJS.Dk.g(c,b):$CLJS.Dk.call(null,c,b)}())?$CLJS.YC:$CLJS.ZC},hD=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},iD=function(a,b,c){var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.$d.l(a,["color:",$CLJS.p.h($CLJS.I.g($CLJS.eD,c))].join(""),$CLJS.G(["color:black"]))],null)},jD=function(a,b,c){var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.$d.l(a,["color:",$CLJS.p.h($CLJS.I.g($CLJS.eD,$CLJS.cD)),";background-color:",$CLJS.p.h($CLJS.I.g($CLJS.eD,
c))].join(""),$CLJS.G(["color:black;background-color:inherit"]))],null)},jaa=function(a){function b(c,d){return d>=c}a=gD(a);if(b(gD($CLJS.lC),a))return"error";if(b(gD($CLJS.kC),a))return"warn";if(b(gD($CLJS.iC),a))return"info";b(gD($CLJS.cC),a);return"log"},kD=function(a){return function(b){var c=$CLJS.Ee(b),d=$CLJS.I.g(c,$CLJS.aC),e=$CLJS.I.g(c,$CLJS.gv);b=$CLJS.I.g(c,$CLJS.mC);e=jaa(e);e=$CLJS.Da(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.O.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.A?e.A(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},lD=function lD(a,b){for(;;){if($CLJS.F.g($CLJS.QC,b))return iD(a,", ",$CLJS.ZC);if($CLJS.F.g($CLJS.SC,b))return hD(a);if(b instanceof $CLJS.M)return iD(a,b,$CLJS.UC);if(b instanceof $CLJS.w)return iD(a,b,$CLJS.$C);if("string"===typeof b)return iD(a,$CLJS.Lh.l($CLJS.G([b])),$CLJS.TC);if($CLJS.wf(b)){var d=hD(function(){var f=a,k=$CLJS.yb(b);return lD.g?lD.g(f,k):lD.call(null,f,k)}()),e=$CLJS.Ab(b);
return lD.g?lD.g(d,e):lD.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Gg)return d=a,d=iD(d,"{",$CLJS.dD),d=$CLJS.ab(lD,d,$CLJS.$e($CLJS.QC,b)),iD(d,"}",$CLJS.dD);if($CLJS.xd(b))return d=a,d=iD(d,["#",$CLJS.p.h(function(){var f=$CLJS.Xa(b),k=f.name;return $CLJS.td(k)?$CLJS.Lh.l($CLJS.G([f])):k}())," "].join(""),$CLJS.VC),d=iD(d,"{",$CLJS.dD),d=$CLJS.ab(lD,d,$CLJS.$e($CLJS.QC,b)),iD(d,"}",$CLJS.dD);if($CLJS.vd(b))return d=a,d=iD(d,"#{",$CLJS.dD),d=$CLJS.ab(lD,d,$CLJS.$e($CLJS.SC,b)),iD(d,
"}",$CLJS.dD);if($CLJS.zd(b))return d=a,d=iD(d,"[",$CLJS.dD),d=$CLJS.ab(lD,d,$CLJS.$e($CLJS.SC,b)),iD(d,"]",$CLJS.dD);if(b instanceof $CLJS.Qf)d=iD(a,"#queue ",$CLJS.VC),e=$CLJS.dg.g($CLJS.zf,b),a=d,b=e;else{if($CLJS.Dd(b))return d=a,d=iD(d,"(",$CLJS.VC),d=$CLJS.ab(lD,d,$CLJS.$e($CLJS.SC,b)),iD(d,")",$CLJS.VC);if(null!=b?b.I&16384||$CLJS.r===b.Ei||(b.I?0:$CLJS.Wa(fD,b)):$CLJS.Wa(fD,b))d=iD(a,"#atom ",$CLJS.VC),e=$CLJS.q(b),a=d,b=e;else if($CLJS.ei(b))d=iD(a,"#uuid ",$CLJS.VC),e=$CLJS.p.h(b),a=d,b=
e;else if($CLJS.Ua(b))d=iD(a,"#js ",$CLJS.VC),e=$CLJS.ab(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.yh.h(m),$CLJS.Da(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ra(b))d=iD(a,"#js ",$CLJS.VC),e=$CLJS.dg.g($CLJS.zf,b),a=d,b=e;else return iD(a,$CLJS.Lh.l($CLJS.G([b])),$CLJS.WC)}}};$CLJS.kaa=kD(function(a){a=$CLJS.Ee(a);$CLJS.I.g(a,$CLJS.gv);var b=$CLJS.I.g(a,$CLJS.aC),c=$CLJS.I.g(a,$CLJS.Qi);$CLJS.I.g(a,$CLJS.mC);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.laa=kD(function(a){var b=$CLJS.Ee(a),c=$CLJS.I.g(b,$CLJS.gv);a=$CLJS.I.g(b,$CLJS.aC);var d=$CLJS.I.g(b,$CLJS.Qi);$CLJS.I.g(b,$CLJS.mC);b=iaa(c);d=lD(hD(jD(jD(jD(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.zf],null),"[",b),a,b),"]",b)),d);a=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.ce(a,d)});
$CLJS.maa=kD(function(a){a=$CLJS.Ee(a);$CLJS.I.g(a,$CLJS.gv);var b=$CLJS.I.g(a,$CLJS.aC),c=$CLJS.I.g(a,$CLJS.Qi);$CLJS.I.g(a,$CLJS.mC);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Lh.l($CLJS.G([c]))],null)});