var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var Sba,Tba,Uba,Vba,Wba,Xba,Yba,Zba,$ba;Sba=function(a,b){a.sort(b||$CLJS.Ea)};Tba=function(a,b){const c=Array(a.length);for(let e=0;e<a.length;e++)c[e]={index:e,value:a[e]};const d=b||$CLJS.Ea;Sba(c,function(e,f){return d(e.value,f.value)||e.index-f.index});for(b=0;b<a.length;b++)a[b]=c[b].value};
$CLJS.uJ=function(a){return $CLJS.F.g(a,$CLJS.Id)?$CLJS.Id:function(b,c){var d=a.g?a.g(b,c):a.call(null,b,c);return"number"===typeof d?d:$CLJS.n(d)?-1:$CLJS.n(a.g?a.g(c,b):a.call(null,c,b))?1:0}};$CLJS.vJ=function(a,b){if($CLJS.z(b)){var c=$CLJS.ue.h?$CLJS.ue.h(b):$CLJS.ue.call(null,b);Tba(c,$CLJS.uJ(a));return $CLJS.pd($CLJS.z(c),$CLJS.qd(b))}return $CLJS.Jc};$CLJS.wJ=new $CLJS.M(null,"second","second",-444702010);$CLJS.xJ=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);
$CLJS.yJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.zJ=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.AJ=new $CLJS.M(null,"year-of-era","year-of-era",682445876);Uba=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);
Vba=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.BJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);$CLJS.CJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);
$CLJS.DJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);Wba=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);Xba=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);
Yba=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);Zba=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);$CLJS.EJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.interval","metabase.lib.schema.temporal-bucketing/unit.date-time.interval",659301368);
$CLJS.FJ=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.GJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit","metabase.lib.schema.temporal-bucketing/unit",1808010880);$CLJS.HJ=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);
$ba=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var IJ=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Gi,$CLJS.Dj,$CLJS.Cj,$CLJS.pi,$CLJS.hk,$CLJS.zi,$CLJS.ji,$CLJS.AJ],null),JJ=$CLJS.eh(IJ),aca,bca,cca;$CLJS.W(Yba,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid date extraction unit"],null)],null),JJ));var KJ=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gv,$CLJS.lj,$CLJS.uj,$CLJS.oi,$CLJS.ji],null),LJ=$CLJS.eh(KJ);
$CLJS.W(Xba,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid date truncation unit"],null)],null),LJ));$CLJS.MJ=$CLJS.dg.j($CLJS.zf,$CLJS.bl.o(),$CLJS.bf.g(KJ,IJ));aca=$CLJS.eh($CLJS.MJ);$CLJS.W($CLJS.DJ,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid date bucketing unit"],null)],null),aca));var NJ=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xJ,$CLJS.fj,$CLJS.vi],null),OJ=$CLJS.eh(NJ);
$CLJS.W(Wba,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid time extraction unit"],null)],null),OJ));var PJ=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zJ,$CLJS.wJ,$CLJS.Ev,$CLJS.Fv],null),QJ=$CLJS.eh(PJ);$CLJS.W($ba,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid time truncation unit"],null)],null),QJ));$CLJS.RJ=$CLJS.dg.j($CLJS.zf,$CLJS.bl.o(),$CLJS.bf.g(PJ,NJ));bca=$CLJS.eh($CLJS.RJ);
$CLJS.W(Vba,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid time bucketing unit"],null)],null),bca));$CLJS.SJ=$CLJS.dg.j($CLJS.zf,$CLJS.bl.o(),$CLJS.bf.l(PJ,KJ,$CLJS.G([NJ,IJ])));cca=$CLJS.eh($CLJS.SJ);$CLJS.W($CLJS.yJ,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid datetime bucketing unit"],null)],null),$CLJS.SJ));var dca=$CLJS.$d.g(cca,$CLJS.ci);
$CLJS.W($CLJS.GJ,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid temporal bucketing unit"],null)],null),dca));var eca=$CLJS.Hv.g(LJ,QJ);$CLJS.W($CLJS.BJ,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid datetime truncation unit"],null)],null),eca));$CLJS.TJ=$CLJS.Hv.g(JJ,OJ);$CLJS.W(Uba,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid datetime extraction unit"],null)],null),$CLJS.TJ));
var UJ=$CLJS.$d.g(LJ,$CLJS.ji);$CLJS.W($CLJS.HJ,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid date interval unit"],null)],null),UJ));$CLJS.W($CLJS.CJ,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid time interval unit"],null)],null),QJ));var fca=$CLJS.Hv.g(UJ,QJ);$CLJS.W($CLJS.EJ,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid datetime interval unit"],null)],null),fca));
$CLJS.W(Zba,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.FJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ri,$CLJS.GJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null)],null));