var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");
'use strict';var Ara=function(a){return $CLJS.dc($CLJS.ab(function(b,c){return $CLJS.gg.j(b,c,$CLJS.I.j(b,c,0)+1)},$CLJS.bc($CLJS.N),a))},G9=function(a,b){return new $CLJS.Ih(function(){try{return $CLJS.C9.g(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.ND($CLJS.eC);$CLJS.n($CLJS.MD("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.LD("metabase.lib.js.metadata",d,$CLJS.wY("Error parsing %s objects: %s",$CLJS.G([a,$CLJS.vX(c)])),c):$CLJS.LD("metabase.lib.js.metadata",d,$CLJS.wY(c,$CLJS.G(["Error parsing %s objects: %s",
a,$CLJS.vX(c)])),null));return null}throw e;}})},Bra=function(a,b){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.Ad(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.ld(k,x);$CLJS.H(A,0,null);var D=$CLJS.H(A,1,null),J=function(){var K=D;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=J;return $CLJS.n(K)?$CLJS.F.g($CLJS.QY.h(J),b):K}())&&m.add(J);x+=1}else return!0}()?$CLJS.re($CLJS.te(m),
e($CLJS.kc(f))):$CLJS.re($CLJS.te(m),null)}var t=$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.F.g($CLJS.QY.h(v),b):x}()))return $CLJS.ce(v,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.xH.h(a);return null==d?null:$CLJS.q(d)}())},Cra=function(a,b){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.Ad(f)){var k=
$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.ld(k,x);$CLJS.H(A,0,null);var D=$CLJS.H(A,1,null),J=function(){var K=D;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=J;return $CLJS.n(K)?$CLJS.F.g($CLJS.QY.h(J),b):K}())&&m.add(J);x+=1}else return!0}()?$CLJS.re($CLJS.te(m),e($CLJS.kc(f))):$CLJS.re($CLJS.te(m),null)}var t=$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=
v;return $CLJS.n(x)?$CLJS.F.g($CLJS.QY.h(v),b):x}()))return $CLJS.ce(v,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.x9.h(a);return null==d?null:$CLJS.q(d)}())},Dra=function(a,b){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.Ad(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);return function(){for(var x=0;;)if(x<l){var A=$CLJS.ld(k,x);$CLJS.H(A,0,null);var D=$CLJS.H(A,1,null),J=function(){var K=D;
return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=J;return $CLJS.n(K)?$CLJS.F.g($CLJS.QY.h(J),b):K}())&&m.add(J);x+=1}else return!0}()?$CLJS.re($CLJS.te(m),e($CLJS.kc(f))):$CLJS.re($CLJS.te(m),null)}var t=$CLJS.B(f);$CLJS.H(t,0,null);var u=$CLJS.H(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.F.g($CLJS.QY.h(v),b):x}()))return $CLJS.ce(v,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}(function(){var d=null==
a?null:$CLJS.y9.h(a);return null==d?null:$CLJS.q(d)}())},H9=function(a,b,c,d){this.Mf=a;this.li=b;this.lc=c;this.Ph=d;this.C=393216;this.I=0},I9=function(a,b){if($CLJS.z0(b))a=b;else{var c=new $CLJS.h(null,6,[$CLJS.B9,G9($CLJS.UR,b),$CLJS.z9,G9($CLJS.SY,b),$CLJS.xH,G9($CLJS.KI,b),$CLJS.w9,G9($CLJS.uP,b),$CLJS.x9,G9($CLJS.FI,b),$CLJS.y9,G9($CLJS.kI,b)],null),d=$CLJS.ND($CLJS.dC);$CLJS.n($CLJS.MD("metabase.lib.js.metadata",d))&&$CLJS.LD("metabase.lib.js.metadata",d,$CLJS.vy.l($CLJS.G(["Created metadata provider for metadata"])),
null);a=new H9(a,b,c,$CLJS.N)}return a},J9=function(a,b){return $CLJS.kZ.j($CLJS.kZ.j((0,$CLJS.H_)($CLJS.F9($CLJS.pD(a))),$CLJS.jS,function(c){return $CLJS.$k.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.GS,$CLJS.N],null),c]))}),$CLJS.FO,function(c){var d=function(){var e=$CLJS.xH.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.le(null,function(){for(;;){var m=$CLJS.z(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.jc(m),u=$CLJS.E(t),v=$CLJS.oe(u);a:for(var x=0;;)if(x<u){var A=$CLJS.ld(t,x);v.add(new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.KI,A,null],null));x+=1}else{t=!0;break a}return t?$CLJS.re($CLJS.te(v),l($CLJS.kc(m))):$CLJS.re($CLJS.te(v),null)}v=$CLJS.B(m);return $CLJS.ce(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,v,null],null),l($CLJS.Kc(m)))}return null}},null,null)}(b)}();return $CLJS.R.j(c,$CLJS.xH,Ara(d))})},K9=function(a){return function(b){return new $CLJS.h(null,2,[$CLJS.nJ,(a.h?a.h(b):a.call(null,b)).name,$CLJS.Aj,b.value],null)}};$CLJS.g=H9.prototype;
$CLJS.g.P=function(a,b){return new H9(this.Mf,this.li,this.lc,b)};$CLJS.g.O=function(){return this.Ph};$CLJS.g.Qh=$CLJS.r;$CLJS.g.bg=function(a,b){a=this.lc;a=null==a?null:$CLJS.x9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.cg=function(a,b){return Cra(this.lc,b)};$CLJS.g.fg=function(a,b){a=this.lc;a=null==a?null:$CLJS.z9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};
$CLJS.g.ag=function(a,b){return Bra(this.lc,b)};$CLJS.g.$f=function(a,b){a=this.lc;a=null==a?null:$CLJS.xH.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.eg=function(a,b){return Dra(this.lc,b)};$CLJS.g.Yf=function(a,b){a=this.lc;a=null==a?null:$CLJS.w9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};
$CLJS.g.dg=function(a,b){a=this.lc;a=null==a?null:$CLJS.y9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.Zf=function(){var a=this.lc,b=this.Mf;a=null==a?null:$CLJS.B9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.I.g(a,b);return null==b?null:$CLJS.q(b)};var L9=function L9(a){switch(arguments.length){case 1:return L9.h(arguments[0]);case 2:return L9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.extract_template_tags",L9);L9.h=function(a){return L9.g(a,$CLJS.N)};L9.g=function(a,b){return $CLJS.j9.l($CLJS.G([$CLJS.Bqa.l($CLJS.G([a,$CLJS.i9.l($CLJS.G([b]))]))]))};L9.v=2;
var M9=function M9(a){return $CLJS.ke(a)?[$CLJS.ge(a),"/",$CLJS.fh(a)].join(""):$CLJS.xd(a)?$CLJS.Ou(a,M9):$CLJS.wd(a)?$CLJS.cf.g(M9,a):a},N9=function N9(a){return $CLJS.xd(a)?$CLJS.Ou($CLJS.FD(a,$CLJS.xaa),N9):$CLJS.wd(a)?$CLJS.cf.g(N9,a):a},O9=function O9(a){switch(arguments.length){case 1:return O9.h(arguments[0]);case 2:return O9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.order_by_clause",O9);
O9.h=function(a){return O9.g(a,$CLJS.HI)};O9.g=function(a,b){return $CLJS.Eqa.l($CLJS.G([$CLJS.k9.l($CLJS.G([$CLJS.oD(a,$CLJS.G([$CLJS.yi,!0]))])),$CLJS.yh.h(b)]))};O9.v=2;var P9=function P9(a){switch(arguments.length){case 2:return P9.g(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.available_binning_strategies",P9);
P9.g=function(a,b){return $CLJS.ue($CLJS.h9.l($CLJS.G([a,b])))};P9.j=function(a,b,c){return $CLJS.ue($CLJS.h9.l($CLJS.G([a,b,c])))};P9.v=3;var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.g(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.available_temporal_buckets",Q9);Q9.g=function(a,b){return $CLJS.ue($CLJS.l9.l($CLJS.G([a,b])))};
Q9.j=function(a,b,c){return $CLJS.ue($CLJS.l9.l($CLJS.G([a,b,c])))};Q9.v=3;var R9=function R9(a){switch(arguments.length){case 2:return R9.g(arguments[0],arguments[1]);case 3:return R9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.query_EQ_",R9);R9.g=function(a,b){return R9.j(a,b,null)};R9.j=function(a,b,c){a=J9(a,c);b=J9(b,c);return $CLJS.F.g(a,b)};R9.v=3;
var S9=function S9(a){switch(arguments.length){case 1:return S9.h(arguments[0]);case 2:return S9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.aggregation_clause",S9);S9.h=function(a){return $CLJS.g9.l($CLJS.G([a]))};S9.g=function(a,b){return $CLJS.g9.l($CLJS.G([a,b]))};S9.v=2;
var T9=function T9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return T9.l(arguments[0],arguments[1],2<c.length?new $CLJS.y(c.slice(2),0,null):null)};$CLJS.Aa("metabase.lib.js.filter_clause",T9);T9.l=function(a,b,c){return $CLJS.O.A($CLJS.Vpa,a,b,c)};T9.v=2;T9.B=function(a){var b=$CLJS.B(a),c=$CLJS.C(a);a=$CLJS.B(c);c=$CLJS.C(c);return this.l(b,a,c)};
var U9=function U9(a){switch(arguments.length){case 2:return U9.g(arguments[0],arguments[1]);case 3:return U9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.expressionable_columns",U9);U9.g=function(a,b){return U9.g(a,b)};U9.j=function(a,b,c){return $CLJS.ue($CLJS.Ipa.l($CLJS.G([a,b,c])))};U9.v=3;
var V9=function V9(a){switch(arguments.length){case 3:return V9.j(arguments[0],arguments[1],arguments[2]);case 4:return V9.A(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.Aa("metabase.lib.js.with_different_database",V9);V9.j=function(a,b,c){return V9.A(a,b,c,null)};V9.A=function(a,b,c,d){return $CLJS.Aqa.l($CLJS.G([a,I9(b,c),$CLJS.oD(d,$CLJS.G([$CLJS.yi,!0]))]))};V9.v=4;
var Era=K9(function(a){return a.col}),Fra=K9(function(a){return a.column});
module.exports={extract_template_tags:L9,field_id:function(a){return $CLJS.Lpa.l($CLJS.G([a]))},filter_operator:function(a,b,c){return $CLJS.Wpa.l($CLJS.G([a,b,c]))},binning:function(a){return $CLJS.spa.l($CLJS.G([a]))},query:function(a,b,c){c=$CLJS.oD(c,$CLJS.G([$CLJS.yi,!0]));c=$CLJS.n($CLJS.jj.h(c))?c:$CLJS.R.j(c,$CLJS.jj,$CLJS.FO);c=(0,$CLJS.H_)(c);c=$CLJS.b0.h(c);var d=$CLJS.ND($CLJS.dC);$CLJS.n($CLJS.MD("metabase.lib.js",d))&&$CLJS.LD("metabase.lib.js",d,$CLJS.wY("query map: %s",$CLJS.G([$CLJS.Lh.l($CLJS.G([c]))])),
null);return $CLJS.Gqa.l($CLJS.G([I9(a,b),c]))},columns_group_columns:function(a){return $CLJS.ue($CLJS.xpa.l($CLJS.G([a])))},join_fields:function(a){a=$CLJS.gqa.l($CLJS.G([a]));return a instanceof $CLJS.M?$CLJS.OD(a):$CLJS.ue(a)},aggregate:function(a,b,c){return $CLJS.npa.l($CLJS.G([a,b,$CLJS.oD(c,$CLJS.G([$CLJS.yi,!0]))]))},join_conditions:function(a){return $CLJS.ue($CLJS.fqa.l($CLJS.G([a])))},query_EQ_:R9,temporal_bucket:function(a){return $CLJS.Sqa.l($CLJS.G([a]))},available_drill_thrus:function(a,
b,c,d,e,f){return $CLJS.ue($CLJS.Bpa.l($CLJS.G([a,b,$CLJS.$k.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.Si,$CLJS.E9.h?$CLJS.E9.h(c):$CLJS.E9.call(null,c),$CLJS.Aj,void 0===d?null:null==d?$CLJS.o7:d],null),$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.B6,$CLJS.Kk.g(Era,e)],null):null,$CLJS.n($CLJS.Fe(f))?new $CLJS.h(null,1,[$CLJS.PY,$CLJS.Kk.g(Fra,f)],null):null]))])))},remove_clause:function(a,b,c){return $CLJS.Iqa.l($CLJS.G([a,b,$CLJS.k9.l($CLJS.G([$CLJS.oD(c,$CLJS.G([$CLJS.yi,!0]))]))]))},suggested_join_condition:function(a,
b,c){return $CLJS.lqa.l($CLJS.G([a,b,c]))},replace_clause:function(a,b,c,d){return $CLJS.Lqa.l($CLJS.G([a,b,$CLJS.k9.l($CLJS.G([$CLJS.oD(c,$CLJS.G([$CLJS.yi,!0]))])),$CLJS.k9.l($CLJS.G([$CLJS.oD(d,$CLJS.G([$CLJS.yi,!0]))]))]))},drop_stage:function(a){return $CLJS.Oqa.l($CLJS.G([a]))},join_lhs_display_name:function(a,b,c,d){return $CLJS.hqa.l($CLJS.G([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.Kpa.l($CLJS.G([a,b,c]))},pivot_types:function(a){return $CLJS.ue($CLJS.Epa.l($CLJS.G([a])))},native_extras:function(a){return $CLJS.cl($CLJS.yqa.l($CLJS.G([a])))},
available_temporal_buckets:Q9,with_binning:function(a,b){return $CLJS.tpa.l($CLJS.G([a,b]))},append_stage:function(a){return $CLJS.Nqa.l($CLJS.G([a]))},join:function(a,b,c){return $CLJS.$pa.l($CLJS.G([a,b,c]))},aggregations:function(a,b){return $CLJS.ue($CLJS.ppa.l($CLJS.G([a,b])))},drill_thru:function(a,b,c){return $CLJS.Cpa.l($CLJS.G([a,b,c]))},raw_native_query:function(a){return $CLJS.tqa.l($CLJS.G([a]))},filterable_column_operators:function(a){return $CLJS.ue($CLJS.Upa.l($CLJS.G([a])))},with_temporal_bucket:function(a,
b){return $CLJS.Tqa.l($CLJS.G([a,b]))},with_join_conditions:function(a,b){return $CLJS.oqa.l($CLJS.G([a,$CLJS.oD(b,$CLJS.G([$CLJS.yi,!0]))]))},available_metrics:function(a){return $CLJS.ue($CLJS.rqa.l($CLJS.G([a])))},with_different_database:V9,with_fields:function(a,b,c){return $CLJS.Qpa.l($CLJS.G([a,b,c]))},join_clause:function(a,b){return $CLJS.aqa.l($CLJS.G([a,b]))},aggregation_clause:S9,external_op:function(a){a=$CLJS.zpa.l($CLJS.G([a]));var b=$CLJS.Ee(a);a=$CLJS.I.g(b,$CLJS.cH);var c=$CLJS.I.g(b,
$CLJS.cj);b=$CLJS.I.g(b,$CLJS.$t);return{operator:a,options:$CLJS.cl(c),args:$CLJS.ue(b)}},with_join_fields:function(a,b){return $CLJS.mqa.l($CLJS.G([a,"string"===typeof b?$CLJS.yh.h(b):b]))},find_visible_column_for_legacy_ref:function(a,b,c){return $CLJS.Opa.l($CLJS.G([a,b,c]))},available_binning_strategies:P9,expression:function(a,b,c,d){return $CLJS.Gpa.l($CLJS.G([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.yh.h(a):a;b="string"===typeof b?$CLJS.yh.h(b):b;return $CLJS.Rqa.l($CLJS.G([a,
b]))},order_bys:function(a,b){return $CLJS.ue($CLJS.Fqa.l($CLJS.G([a,b])))},display_info:function(a,b,c){return $CLJS.cl.l(N9($CLJS.pqa.l($CLJS.G([$CLJS.U4(a,b),b,c]))),$CLJS.G([$CLJS.ok,$CLJS.OD]))},fieldable_columns:function(a,b){return $CLJS.ue($CLJS.Mpa.l($CLJS.G([a,b])))},template_tags:function(a){return $CLJS.j9.l($CLJS.G([$CLJS.vqa.l($CLJS.G([a]))]))},selected_aggregation_operators:function(a,b){return $CLJS.ue($CLJS.rpa.l($CLJS.G([$CLJS.z(a),b])))},order_by_clause:O9,filterable_columns:function(a,
b){return $CLJS.ue($CLJS.Tpa.l($CLJS.G([a,b])))},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.ue($CLJS.dqa.l($CLJS.G([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.eqa.l($CLJS.G([a,b,c,d]))},filters:function(a,b){return $CLJS.ue($CLJS.Spa.l($CLJS.G([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.ue($CLJS.bqa.l($CLJS.G([a,b,c,d,e])))},table_or_card_metadata:function(a,b){var c=$CLJS.IY(b);return $CLJS.n(c)?$CLJS.T1(a,c):
$CLJS.Q1(a,b)},orderable_columns:function(a,b){return $CLJS.ue($CLJS.t7.g(a,b))},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.Xpa.l($CLJS.G([a,b,$CLJS.oD(c,$CLJS.G([$CLJS.yi,!0]))]))},suggestedName:function(a){return $CLJS.qqa.l($CLJS.G([a]))},find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.Fpa.l($CLJS.G([a,b,c,d]))},metadataProvider:I9,filter_clause:T9,fields:function(a,b){return $CLJS.ue($CLJS.Npa.l($CLJS.G([a,b])))},available_aggregation_operators:function(a,b){return $CLJS.ue($CLJS.qpa.l($CLJS.G([a,
b])))},breakouts:function(a,b){return $CLJS.ue($CLJS.wpa.l($CLJS.G([a,b])))},joinable_columns:function(a,b,c){return $CLJS.ue($CLJS.jqa.l($CLJS.G([a,b,c])))},group_columns:function(a){return $CLJS.ue($CLJS.ypa.l($CLJS.G([a])))},describe_temporal_unit:function(a,b){b="string"===typeof b?$CLJS.yh.h(b):b;return $CLJS.Pqa.l($CLJS.G([a,b]))},joins:function(a,b){return $CLJS.ue($CLJS.kqa.l($CLJS.G([a,b])))},native_query:function(a,b,c){return $CLJS.sqa.l($CLJS.G([I9(a,b),c]))},available_join_strategies:function(a,
b){return $CLJS.ue($CLJS.Zpa.l($CLJS.G([a,b])))},legacy_query:function(a){return $CLJS.cl.l(M9($CLJS.T_.h(a)),$CLJS.G([$CLJS.ok,$CLJS.OD]))},with_native_extras:function(a,b){return $CLJS.zqa.l($CLJS.G([a,$CLJS.oD(b,$CLJS.G([$CLJS.yi,!0]))]))},join_strategy:function(a){return $CLJS.iqa.l($CLJS.G([a]))},picker_info:function(a,b){var c=$CLJS.Bj.h(b);switch(c instanceof $CLJS.M?c.T:null){case "metadata/table":return{databaseId:$CLJS.UR.h(a),tableId:$CLJS.Wi.h(b)};case "metadata/card":return{databaseId:$CLJS.UR.h(a),
tableId:["card__",$CLJS.p.h($CLJS.Wi.h(b))].join(""),cardId:$CLJS.Wi.h(b),isModel:$CLJS.Wia.h(b)};default:return a=$CLJS.ND($CLJS.gC),$CLJS.n($CLJS.MD("metabase.lib.js",a))&&$CLJS.LD("metabase.lib.js",a,$CLJS.vy.l($CLJS.G(["Cannot provide picker-info for",$CLJS.Bj.h(b)])),null),null}},aggregation_operator_columns:function(a){return $CLJS.ue($CLJS.opa.l($CLJS.G([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.Ypa.l($CLJS.G([a,b,c]))},available_segments:function(a){return $CLJS.ue($CLJS.Mqa.l($CLJS.G([a])))},
join_condition_operators:function(a,b,c,d){return $CLJS.ue($CLJS.cqa.l($CLJS.G([a,b,c,d])))},expressionable_columns:U9,filter:function(a,b,c){return $CLJS.Rpa.l($CLJS.G([a,b,$CLJS.oD(c,$CLJS.G([$CLJS.yi,!0]))]))},change_direction:function(a,b){return $CLJS.Cqa.l($CLJS.G([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.Dpa.l($CLJS.G([a,b]))},remove_field:function(a,b,c){return $CLJS.Ppa.l($CLJS.G([a,b,c]))},with_template_tags:function(a,b){return $CLJS.wqa.l($CLJS.G([a,$CLJS.i9.l($CLJS.G([b]))]))},
expressions:function(a,b){return $CLJS.ue($CLJS.Hpa.l($CLJS.G([a,b])))},filter_parts:function(a,b,c){a=$CLJS.Jpa.l($CLJS.G([a,b,c]));var d=$CLJS.Ee(a);a=$CLJS.I.g(d,$CLJS.cH);b=$CLJS.I.g(d,$CLJS.cj);c=$CLJS.I.g(d,$CLJS.Si);d=$CLJS.I.g(d,$CLJS.$t);return{operator:a,options:$CLJS.cl($CLJS.Yl(b,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SI,$CLJS.ZI],null))),column:c,args:$CLJS.ue(d)}},with_join_strategy:function(a,b){return $CLJS.nqa.l($CLJS.G([a,b]))},order_by:function(a,b,c,d){return $CLJS.Dqa.l($CLJS.G([a,
b,c,$CLJS.yh.h(d)]))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.yh.h(a):a;b="string"===typeof b?$CLJS.yh.h(b):b;return $CLJS.Qqa.l($CLJS.G([a,b]))},breakout:function(a,b,c){return $CLJS.upa.l($CLJS.G([a,b,$CLJS.Hqa.l($CLJS.G([c]))]))},database_id:function(a){return $CLJS.Apa.l($CLJS.G([a]))},required_native_extras:function(a,b){return $CLJS.ue($CLJS.cf.g($CLJS.OD,$CLJS.xqa.l($CLJS.G([I9(a,b)]))))},remove_join:function(a,b,c){return $CLJS.Jqa.l($CLJS.G([a,b,c]))},joined_thing:function(a,
b){return $CLJS.N4(a,b)},with_native_query:function(a,b){return $CLJS.uqa.l($CLJS.G([a,b]))},breakoutable_columns:function(a,b){return $CLJS.ue($CLJS.vpa.l($CLJS.G([a,b])))},rename_join:function(a,b,c,d){return $CLJS.Kqa.l($CLJS.G([a,b,c,d]))}};