var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.util.malli.registry.js");
'use strict';var OK,PK,Mca,Nca,Oca,SK,TK,UK,WK,Pca,YK;OK=function(a){switch(arguments.length){case 2:return $CLJS.VH(arguments[0],arguments[1]);case 3:return $CLJS.UH(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};PK=function(a){return["(?:",$CLJS.p.h($CLJS.O.g($CLJS.p,a)),")?"].join("")};Mca=new $CLJS.M("metabase.lib.schema.literal","temporal","metabase.lib.schema.literal/temporal",1227892305);
Nca=new $CLJS.M("metabase.lib.schema.literal","non-integer-real","metabase.lib.schema.literal/non-integer-real",1396696695);$CLJS.QK=new $CLJS.M("metabase.lib.schema.literal","string.year","metabase.lib.schema.literal/string.year",-936788792);Oca=new $CLJS.M("metabase.lib.schema.literal","string","metabase.lib.schema.literal/string",-1434211409);$CLJS.RK=new $CLJS.M("metabase.lib.schema.literal","time","metabase.lib.schema.literal/time",1928421901);
SK=new $CLJS.M("metabase.lib.schema.literal","string.time","metabase.lib.schema.literal/string.time",1501090167);TK=new $CLJS.M("metabase.lib.schema.literal","string.date","metabase.lib.schema.literal/string.date",1716358402);UK=new $CLJS.M("metabase.lib.schema.literal","string.datetime","metabase.lib.schema.literal/string.datetime",-1597850940);$CLJS.VK=new $CLJS.M("metabase.lib.schema.literal","date","metabase.lib.schema.literal/date",-906463835);
WK=new $CLJS.M("metabase.lib.schema.literal","value.options","metabase.lib.schema.literal/value.options",-1918568845);Pca=new $CLJS.M("metabase.lib.schema.literal","integer","metabase.lib.schema.literal/integer",-1120986283);$CLJS.XK=new $CLJS.M("metabase.lib.schema.literal","string.year-month","metabase.lib.schema.literal/string.year-month",-1014149016);YK=new $CLJS.M("metabase.lib.schema.literal","boolean","metabase.lib.schema.literal/boolean",1793502815);
$CLJS.ZK=new $CLJS.M("metabase.lib.schema.literal","datetime","metabase.lib.schema.literal/datetime",985830761);$CLJS.rH.m(null,$CLJS.jE,function(){return $CLJS.xj});$CLJS.W(YK,$CLJS.Ft);$CLJS.rH.m(null,$CLJS.nE,function(){return $CLJS.Ij});$CLJS.W(YK,$CLJS.Ft);$CLJS.W(Pca,$CLJS.Bt);$CLJS.rH.m(null,$CLJS.ZD,function(){return $CLJS.tj});$CLJS.W(Nca,$CLJS.At);$CLJS.rH.m(null,$CLJS.XD,function(){return $CLJS.zG});$CLJS.W(Oca,$CLJS.Wj);
var Qca=[":\\d{2}",PK($CLJS.G(["\\.\\d{1,6}"]))].join(""),$K=["\\d{2}:\\d{2}",PK($CLJS.G([Qca]))].join(""),aL=["\\d{4}-\\d{2}-\\d{2}T",$K].join(""),bL=["(?:Z|(?:[+-]",$K,"))"].join(""),Rca=$CLJS.oh("^\\d{4}-\\d{2}-\\d{2}$"),Sca=$CLJS.oh(["^",$K,"$"].join("")),Tca=$CLJS.oh(["^",$K,bL,"$"].join("")),Uca=$CLJS.oh(["^",aL,"$"].join("")),Vca=$CLJS.oh(["^",aL,bL,"$"].join(""));$CLJS.W(TK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Du,"date string literal"],null),Rca],null));
$CLJS.W(SK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Du,"local time string literal"],null),Sca],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Du,"offset time string literal"],null),Tca],null)],null));
$CLJS.W(UK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Du,"local date time string literal"],null),Uca],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Du,"offset date time string literal"],null),Vca],null)],null));
$CLJS.rH.m(null,$CLJS.YD,function(a){return $CLJS.n($CLJS.VH?$CLJS.VH(UK,a):OK.call(null,UK,a))?new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.ak,null,$CLJS.dG,null],null),null):$CLJS.n($CLJS.VH?$CLJS.VH(TK,a):OK.call(null,TK,a))?new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.GF,null,$CLJS.ak,null],null),null):$CLJS.n($CLJS.VH?$CLJS.VH(SK,a):OK.call(null,SK,a))?new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.CG,null,$CLJS.ak,null],null),null):$CLJS.ak});$CLJS.W($CLJS.VK,TK);$CLJS.W($CLJS.RK,SK);
$CLJS.W($CLJS.ZK,UK);$CLJS.W(Mca,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ot,$CLJS.VK,$CLJS.RK,$CLJS.ZK],null));$CLJS.cL=$CLJS.oh("^\\d{4}-\\d{2}$");$CLJS.W($CLJS.XK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Du,"year-month string literal"],null),$CLJS.cL],null));$CLJS.dL=$CLJS.oh("^\\d{4}$");$CLJS.W($CLJS.QK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Du,"year string literal"],null),$CLJS.dL],null));
$CLJS.W(WK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.eH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Fi,$CLJS.hH],null)],null)],null));$CLJS.jJ.g($CLJS.Aj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.rt,new $CLJS.h(null,1,[$CLJS.Du,"Value :value clause"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.Aj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,WK],null),$CLJS.vl],null));