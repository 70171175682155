var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./inflections.core.js");require("./medley.core.js");require("./metabase.lib.card.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.join.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Fka,Gka,Hka,v4,Ika,C4,D4,E4,F4,Jka,Kka,Lka,Mka,Nka,Oka,Pka,Qka,Rka,Q4,R4,S4,T4,Ska,Tka,P4,O4,Uka;$CLJS.t4=function(a,b){$CLJS.ab(function(c,d){return a.h?a.h(d):a.call(null,d)},null,b)};
Fka=function(a){return function(b){var c=$CLJS.Ue(!0);return function(){function d(l,m){var t=$CLJS.q(c);if($CLJS.n($CLJS.n(t)?a.h?a.h(m):a.call(null,m):t))return l;$CLJS.yl(c,null);return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=
f;k.h=e;k.g=d;return k}()}};Gka=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;c=arguments[0];d=arguments[1];var e=arguments[2];b=3<b.length?new $CLJS.y(b.slice(3),0,null):null;return $CLJS.Sa($CLJS.z($CLJS.ED(c,d)))?c:$CLJS.O.N($CLJS.AZ,c,d,e,b)};$CLJS.u4=function(a,b){return $CLJS.zY(a)&&$CLJS.F.g($CLJS.B(a),b)};
Hka=function(a,b){return $CLJS.B($CLJS.Wl(function(c){if($CLJS.n($CLJS.n(c)?b:c)){var d=$CLJS.Su.h(c);c=$CLJS.Xu.h(c);d=$CLJS.n($CLJS.nh(d,b))?$CLJS.HD(b,d,c):null}else d=null;return d},a))};v4=function(a){a=$CLJS.W2(a);var b=$CLJS.ID(a);b||($CLJS.n(a)?(b=$CLJS.n(a)?!$CLJS.Hd($CLJS.q($CLJS.b3),$CLJS.W2(a).toLowerCase()):null,b=$CLJS.Sa(b)):b=null);return $CLJS.n(b)?a:Hka($CLJS.Yb($CLJS.q($CLJS.Y2)),a)};
$CLJS.w4=function(a,b){if($CLJS.z(a)){b=$CLJS.Kk.g($CLJS.qY,b);var c=$CLJS.dg.j($CLJS.ah,$CLJS.cf.h(function(d){return $CLJS.n3(d,a)}),b);return $CLJS.Kk.g(function(d){var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.R.j(d,$CLJS.L0,$CLJS.Hd(c,e))},$CLJS.Xl($CLJS.Jt,a))}return null};$CLJS.x4=function(a){a=$CLJS.C3.h(a);return $CLJS.n(a)?new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.FJ,$CLJS.ri,a],null):null};$CLJS.y4=function(a){return $CLJS.F.g($CLJS.SE(a),$CLJS.qN)};
$CLJS.A4=function(a){var b=$CLJS.SE(a);b=b instanceof $CLJS.M?b.T:null;switch(b){case "dispatch-type/nil":return null;case "field":return $CLJS.tR.h($CLJS.mH(a));case "metadata/column":return $CLJS.z4.h(a);case "mbql/join":return $CLJS.BH.h(a);default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}};
Ika=function(a,b){return $CLJS.n($CLJS.xH.h(a))?$CLJS.Lk.j(a,$CLJS.xH,function(c){return $CLJS.wd(c)?$CLJS.Kk.g(function(d){return $CLJS.B4.g?$CLJS.B4.g(d,b):$CLJS.B4.call(null,d,b)},c):c}):a};
C4=function(a){if($CLJS.n(a)){var b=$CLJS.ff;a:try{if($CLJS.zd(a)&&4===$CLJS.E(a))try{if($CLJS.bd(a,0)instanceof $CLJS.M)try{var c=$CLJS.bd(a,2);if($CLJS.zd(c)&&3===$CLJS.E(c))try{var d=$CLJS.bd(c,0);if($CLJS.fe(d,$CLJS.KI))try{var e=$CLJS.bd(a,3);if($CLJS.zd(e)&&3===$CLJS.E(e))try{var f=$CLJS.bd(e,0);if($CLJS.fe(f,$CLJS.KI)){$CLJS.bd(e,1);$CLJS.bd(e,2);$CLJS.bd(c,1);$CLJS.bd(c,2);$CLJS.bd(a,0);$CLJS.bd(a,1);var k=new $CLJS.P(null,1,5,$CLJS.Q,[!0],null)}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){var l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof Error){l=m;if(l===$CLJS.Y)throw $CLJS.Y;throw l;}throw m;}else throw $CLJS.Y;}catch(m){if(m instanceof
Error){l=m;if(l===$CLJS.Y){k=new $CLJS.P(null,1,5,$CLJS.Q,[!1],null);break a}throw l;}throw m;}a=$CLJS.B($CLJS.z(b($CLJS.Ta,k)))}else a=null;return a};D4=function(a){if($CLJS.n(C4(a))){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);$CLJS.H(a,3,null);return b}return null};E4=function(a){return $CLJS.n(C4(a))?($CLJS.H(a,0,null),$CLJS.H(a,1,null),$CLJS.H(a,2,null),$CLJS.H(a,3,null)):null};
F4=function(a,b){var c=$CLJS.B4;if($CLJS.Sa(C4(a)))return a;var d=$CLJS.H(a,0,null),e=$CLJS.H(a,1,null),f=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return new $CLJS.P(null,4,5,$CLJS.Q,[d,e,f,$CLJS.O.j(c,a,b)],null)};
Jka=function(a,b,c){return $CLJS.td($CLJS.yN.h(a))?a:$CLJS.n(b)?Gka(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.yN],null),function(d){return function l(f,k){try{if($CLJS.zd(k)&&3===$CLJS.E(k))try{var m=$CLJS.bd(k,0);if($CLJS.fe(m,$CLJS.KI))try{var t=$CLJS.bd(k,1);if(null!=t?t.C&256||$CLJS.r===t.We||(t.C?0:$CLJS.Wa($CLJS.qb,t)):$CLJS.Wa($CLJS.qb,t))try{var u=$CLJS.I.j(t,$CLJS.tR,$CLJS.GX);if($CLJS.F.g(u,b))return $CLJS.bd(k,2),$CLJS.B4.g?$CLJS.B4.g(k,c):$CLJS.B4.call(null,k,c);throw $CLJS.Y;}catch(x){if(x instanceof
Error){var v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw x;}else throw $CLJS.Y;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Y)return $CLJS.cZ(l,f,k);throw v;}throw x;}}($CLJS.zf,d)}):$CLJS.Lk.j(a,$CLJS.yN,function(d){return $CLJS.Kk.g(function(e){return F4(e,$CLJS.G([c]))},d)})};
$CLJS.B4=function(a,b){var c=$CLJS.SE(a);switch(c instanceof $CLJS.M?c.T:null){case "field":return $CLJS.nH(a,$CLJS.lH,$CLJS.G([$CLJS.tR,b]));case "metadata/column":return $CLJS.lH(a,$CLJS.z4,b);case "mbql/join":return c=$CLJS.A4(a),Jka(Ika($CLJS.lH(a,$CLJS.BH,b),b),c,b);default:return b=$CLJS.ND($CLJS.eC),$CLJS.n($CLJS.MD("metabase.lib.join",b))&&$CLJS.LD("metabase.lib.join",b,$CLJS.vy.l($CLJS.G(["with-join-value should not be called with",$CLJS.Lh.l($CLJS.G([a]))])),null),a}};
$CLJS.G4=function(a,b){return $CLJS.ha.j?$CLJS.ha.j("%s__%s",a,b):$CLJS.ha.call(null,"%s__%s",a,b)};$CLJS.H4=function(a,b,c){var d=$CLJS.R.l,e=$CLJS.DD($CLJS.A0,$CLJS.S)(c);a=$CLJS.G4($CLJS.BH.h(a),$CLJS.DD($CLJS.A0,$CLJS.S)(c));b=b.h?b.h(a):b.call(null,a);return d.call($CLJS.R,c,$CLJS.A0,e,$CLJS.G([$CLJS.J0,b]))};Kka=function(a,b){return $CLJS.Sa(b)?a:$CLJS.Kk.g(function(c){var d=E4(c);d=$CLJS.n(d)?$CLJS.n($CLJS.A4(d))?null:F4(c,$CLJS.G([b])):null;return $CLJS.n(d)?d:c},a)};
$CLJS.I4=function(a,b){b=Kka($CLJS.Kk.g($CLJS.tY,b),$CLJS.A4(a));return $CLJS.lH(a,$CLJS.yN,$CLJS.Fe(b))};$CLJS.J4=function(a,b){b=b instanceof $CLJS.M?b:$CLJS.F.g(b,$CLJS.zf)?$CLJS.$u:$CLJS.Fe($CLJS.dg.j($CLJS.zf,$CLJS.Ik.g($CLJS.cf.h($CLJS.qY),function(){var c=$CLJS.A4(a);return $CLJS.n(c)?$CLJS.cf.h(function(d){return $CLJS.B4(d,c)}):$CLJS.Sd}()),b));return $CLJS.lH(a,$CLJS.xH,b)};
Lka=function(a,b){var c=$CLJS.dg.j($CLJS.N,$CLJS.Ql(function(e){var f=$CLJS.m3($CLJS.qY(e),b);return $CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[f,e],null):null}),a);c=$CLJS.cf.g(c,b);var d=$CLJS.WJ($CLJS.DD($CLJS.A1,$CLJS.B1),c);if($CLJS.n(d))return d;c=$CLJS.B(c);if($CLJS.n(c))return c;c=$CLJS.WJ($CLJS.A1,a);if($CLJS.n(c))return c;c=$CLJS.WJ($CLJS.B1,a);return $CLJS.n(c)?c:$CLJS.B(a)};Mka=function(a){return"string"===typeof a?(0,$CLJS.ma)($CLJS.HD(a,/ id$/i,"")):null};
Nka=function(a,b){var c="string"===typeof a;if(c){if(c="string"===typeof b){c=new $CLJS.Ih(function(){return v4(b)});var d=new $CLJS.Ih(function(){return v4(a)});return $CLJS.F.g(a,b)||$CLJS.F.g(a,$CLJS.q(c))||$CLJS.F.g($CLJS.q(d),b)||$CLJS.F.g($CLJS.q(d),$CLJS.q(c))}return c}return c};
Oka=function(a,b,c){var d=$CLJS.V1.g($CLJS.F.g($CLJS.Bj.h(b),$CLJS.gY)?b:a,b),e=$CLJS.n(c)?Mka($CLJS.V1.g(a,c)):null,f=Nka(d,e);return function(){var k=function(){if($CLJS.n(d)){if($CLJS.n(e)){var l=$CLJS.Sa($CLJS.mh(/id/i,e));return l?(l=!f)?[$CLJS.p.h(d)," - ",e].join(""):l:l}return e}return d}();return $CLJS.n(k)?k:$CLJS.n(d)?d:$CLJS.n(e)?e:"source"}()};
Pka=function(a,b,c){return function k(e,f){try{if($CLJS.u4(f,$CLJS.KI)&&$CLJS.Ed($CLJS.o3(f,c)))return $CLJS.B4(f,b);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.cZ(k,e,f);throw l;}throw m;}}($CLJS.zf,a)};
Qka=function(a,b,c,d){a=Pka(a,b,d);return function l(f,k){try{if($CLJS.zd(k)&&4===$CLJS.E(k))try{var m=$CLJS.bd(k,2);if($CLJS.u4(m,$CLJS.KI))try{var t=$CLJS.bd(k,3);if($CLJS.u4(t,$CLJS.KI)){var u=$CLJS.bd(k,3),v=$CLJS.bd(k,2),x=$CLJS.bd(k,0),A=$CLJS.bd(k,1),D=$CLJS.A4(v),J=$CLJS.A4(u);if($CLJS.Sa($CLJS.n(D)?D:J))return $CLJS.n($CLJS.o3(u,c))?new $CLJS.P(null,4,5,$CLJS.Q,[x,A,$CLJS.B4(v,b),u],null):new $CLJS.P(null,4,5,$CLJS.Q,[x,A,v,$CLJS.B4(u,b)],null);if($CLJS.F.g(D,b)&&$CLJS.F.g(J,b)){var K=$CLJS.nH(v,
$CLJS.Ak,$CLJS.G([$CLJS.tR])),X=$CLJS.nH(u,$CLJS.Ak,$CLJS.G([$CLJS.tR])),Z=null==$CLJS.o3(K,c);var fa=Z?$CLJS.n3(X,c):Z;return $CLJS.n(fa)?new $CLJS.P(null,4,5,$CLJS.Q,[x,A,v,X],null):new $CLJS.P(null,4,5,$CLJS.Q,[x,A,K,u],null)}return k}throw $CLJS.Y;}catch(xa){if(xa instanceof Error){var ra=xa;if(ra===$CLJS.Y)throw $CLJS.Y;throw ra;}throw xa;}else throw $CLJS.Y;}catch(xa){if(xa instanceof Error){ra=xa;if(ra===$CLJS.Y)throw $CLJS.Y;throw ra;}throw xa;}else throw $CLJS.Y;}catch(xa){if(xa instanceof
Error){ra=xa;if(ra===$CLJS.Y)return $CLJS.cZ(l,f,k);throw ra;}throw xa;}}($CLJS.zf,a)};Rka=function(a,b){var c=$CLJS.KY();$CLJS.t4(c,b);return c(a)};
$CLJS.K4=function(a,b,c){if($CLJS.Hd(c,$CLJS.BH))return c;var d=$CLJS.HY(a,b),e=$CLJS.A2.j(a,b,d);b=$CLJS.z($CLJS.ff($CLJS.Ta,function u(m,t){try{if($CLJS.zd(t)&&1<=$CLJS.E(t))try{var v=$CLJS.Pk.j(t,0,1);if($CLJS.zd(v)&&1===$CLJS.E(v))try{var x=$CLJS.bd(v,0);if($CLJS.fe(x,$CLJS.KI))return new $CLJS.P(null,1,5,$CLJS.Q,[t],null);throw $CLJS.Y;}catch(D){if(D instanceof Error){var A=D;if(A===$CLJS.Y)throw $CLJS.Y;throw A;}throw D;}else throw $CLJS.Y;}catch(D){if(D instanceof Error){A=D;if(A===$CLJS.Y)throw $CLJS.Y;
throw A;}throw D;}else throw $CLJS.Y;}catch(D){if(D instanceof Error){A=D;if(A===$CLJS.Y)return $CLJS.DX(u,m,t);throw A;}throw D;}}($CLJS.zf,$CLJS.yN.h(c))));b=Lka(e,b);var f=Rka(Oka(a,c,b),$CLJS.Wl($CLJS.BH,$CLJS.CP.h(d))),k=$CLJS.y2.h($CLJS.G2.g(a,$CLJS.vN.h(c)));return $CLJS.B4($CLJS.Lk.j(c,$CLJS.yN,function(m){return $CLJS.Kk.g(function(t){return Qka(t,f,e,k)},m)}),f)};$CLJS.L4=function(a){return $CLJS.yN.h(a)};
$CLJS.M4=function(a){return $CLJS.$k.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.zN,$CLJS.jH,a],null),$CLJS.F.g(a,$CLJS.HH)?new $CLJS.h(null,1,[$CLJS.ci,!0],null):null]))};$CLJS.N4=function(a,b){b=$CLJS.B($CLJS.vN.h(b));return $CLJS.n($CLJS.QX.h(b))?$CLJS.T1(a,$CLJS.QX.h(b)):$CLJS.n($CLJS.eR.h(b))?$CLJS.Q1(a,$CLJS.eR.h(b)):null};
Q4=function(a){a=$CLJS.VJ(function(d){return $CLJS.B1(d)?O4:$CLJS.A1(d)?P4:$CLJS.xv},a);var b=$CLJS.Ee(a);a=$CLJS.I.g(b,O4);var c=$CLJS.I.g(b,P4);b=$CLJS.I.g(b,$CLJS.xv);return $CLJS.bf.l(a,c,$CLJS.G([b]))};R4=function(a,b){return $CLJS.Sa(a)?b:$CLJS.Kk.g(function(c){return $CLJS.n($CLJS.L0.h(c))?$CLJS.z3(c,$CLJS.x4(a)):c},$CLJS.w4(b,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)))};S4=function(a,b,c){return $CLJS.WJ($CLJS.B1,$CLJS.A2.j(a,b,c))};
T4=function(a,b){var c=$CLJS.Wi.h(a);return $CLJS.n(c)?$CLJS.WJ(function(d){d=$CLJS.Ee(d);var e=$CLJS.I.g(d,$CLJS.K0);return $CLJS.A1(d)&&$CLJS.F.g(e,c)},b):null};Ska={};Tka=new $CLJS.M("metabase.lib.join","join-fields","metabase.lib.join/join-fields",-2085751377);P4=new $CLJS.M(null,"fk","fk",398442651);$CLJS.z4=new $CLJS.M("metabase.lib.join","join-alias","metabase.lib.join/join-alias",1166233023);O4=new $CLJS.M(null,"pk","pk",-771936732);
Uka=new $CLJS.w("metabase.lib.stage","ensure-previous-stages-have-metadata","metabase.lib.stage/ensure-previous-stages-have-metadata",2048342183,null);$CLJS.p2.m(null,$CLJS.qN,function(a,b,c){b=$CLJS.Ee(c);b=$CLJS.I.g(b,$CLJS.vN);b=$CLJS.H(b,0,null);c=$CLJS.Ee(b);b=$CLJS.I.g(c,$CLJS.eR);c=$CLJS.I.g(c,$CLJS.QX);a=$CLJS.n(b)?$CLJS.WG.h($CLJS.Q1(a,b)):null;if($CLJS.n(a))return a;a=$CLJS.n(c)?$CLJS.L2(c):null;return $CLJS.n(a)?a:$CLJS.pH("Native Query")});$CLJS.w2.m(null,$CLJS.qN,function(a,b,c){a=$CLJS.V1.j(a,b,c);c=$CLJS.BH.h(c);return new $CLJS.h(null,2,[$CLJS.S,$CLJS.n(c)?c:a,$CLJS.WG,a],null)});
$CLJS.v2.m(null,$CLJS.qN,function(){throw $CLJS.hi("You can't calculate a metadata map for a join! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.p2.m(null,$CLJS.zN,function(a,b,c){a=$CLJS.Ee(c);a=$CLJS.I.g(a,$CLJS.jH);a=a instanceof $CLJS.M?a.T:null;switch(a){case "left-join":return $CLJS.pH("Left outer join");case "right-join":return $CLJS.pH("Right outer join");case "inner-join":return $CLJS.pH("Inner join");case "full-join":return $CLJS.pH("Full outer join");default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});
$CLJS.w2.m(null,$CLJS.zN,function(a,b,c){var d=$CLJS.Ee(c),e=$CLJS.I.g(d,$CLJS.jH);c=$CLJS.I.g(d,$CLJS.ci);a=new $CLJS.h(null,2,[$CLJS.n2,$CLJS.OD(e),$CLJS.WG,$CLJS.V1.j(a,b,d)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.ci,!0):a});
$CLJS.x2.m(null,$CLJS.qN,function(a,b,c,d){var e=$CLJS.Ee(c),f=$CLJS.I.g(e,$CLJS.BH),k=$CLJS.I.j(e,$CLJS.xH,$CLJS.$u),l=$CLJS.I.g(e,$CLJS.vN);c=$CLJS.Ee(d);var m=$CLJS.I.g(c,$CLJS.l2);if($CLJS.F.g(k,$CLJS.$u))return null;var t="undefined"!==typeof $CLJS.aO&&"undefined"!==typeof Ska&&"undefined"!==typeof $CLJS.Vka&&"undefined"!==typeof $CLJS.U4?new $CLJS.Dc(function(){return $CLJS.U4},$CLJS.pd(Uka,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),null):null,u=function(){var v=$CLJS.R.j(a,$CLJS.vN,l);return $CLJS.n(t)?
t.g?t.g(v,-1):t.call(null,v,-1):v}();c=$CLJS.F.g(k,$CLJS.ZB)?$CLJS.y2.A(u,-1,$CLJS.rd(l),c):function(){return function A(x){return new $CLJS.le(null,function(){for(;;){var D=$CLJS.z(x);if(D){if($CLJS.Ad(D)){var J=$CLJS.jc(D),K=$CLJS.E(J),X=$CLJS.oe(K);a:for(var Z=0;;)if(Z<K){var fa=$CLJS.ld(J,Z);fa=$CLJS.nH(fa,$CLJS.Ak,$CLJS.G([$CLJS.tR]));fa=$CLJS.U1.j(u,-1,fa);X.add(fa);Z+=1}else{J=!0;break a}return J?$CLJS.re($CLJS.te(X),A($CLJS.kc(D))):$CLJS.re($CLJS.te(X),null)}X=$CLJS.B(D);X=$CLJS.nH(X,$CLJS.Ak,
$CLJS.G([$CLJS.tR]));return $CLJS.ce($CLJS.U1.j(u,-1,X),A($CLJS.Kc(D)))}return null}},null,null)}(k)}();return $CLJS.Kk.g(function(v){v=$CLJS.R.j(v,$CLJS.W0,f);v=$CLJS.B4($CLJS.R.l(v,$CLJS.WG,$CLJS.V1.j(a,b,v),$CLJS.G([$CLJS.LY,$CLJS.I0])),f);return $CLJS.H4(e,m,v)},c)});$CLJS.z2.m(null,$CLJS.qN,function(a,b,c,d){return $CLJS.y2.A(a,b,$CLJS.R.j(c,$CLJS.xH,$CLJS.ZB),d)});
var V4,Wka=$CLJS.Re($CLJS.N),Xka=$CLJS.Re($CLJS.N),Yka=$CLJS.Re($CLJS.N),Zka=$CLJS.Re($CLJS.N),$ka=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));V4=new $CLJS.di($CLJS.Eh.g("metabase.lib.join","join-clause-method"),$CLJS.SE,$ka,Wka,Xka,Yka,Zka);V4.m(null,$CLJS.qN,function(a){return a});V4.m(null,$CLJS.gY,function(a){return $CLJS.pY(new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.qN,$CLJS.vN,$CLJS.vN.h($CLJS.FY(a))],null))});
V4.m(null,$CLJS.VX,function(a){return $CLJS.pY(new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.qN,$CLJS.vN,new $CLJS.P(null,1,5,$CLJS.Q,[a],null)],null))});V4.m(null,$CLJS.TY,function(a){return $CLJS.pY(new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.qN,$CLJS.vN,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.QX,$CLJS.Wi.h(a),$CLJS.Bj,$CLJS.VX],null)],null)],null))});
V4.m(null,$CLJS.NY,function(a){var b=$CLJS.Ee(a),c=$CLJS.I.g(b,$CLJS.z4);a=$CLJS.I.g(b,Tka);b=V4.h(new $CLJS.h(null,3,[$CLJS.Bj,$CLJS.VX,$CLJS.hE,new $CLJS.h(null,1,[$CLJS.fH,$CLJS.p.h($CLJS.oH())],null),$CLJS.eR,$CLJS.Wi.h(b)],null));c=$CLJS.n(c)?$CLJS.B4(b,c):b;return $CLJS.n(a)?$CLJS.J4.g?$CLJS.J4.g(c,a):$CLJS.J4.call(null,c,a):c});
$CLJS.W4=function(){function a(d,e){return $CLJS.I4(c.h?c.h(d):c.call(null,d),e)}function b(d){return $CLJS.w3(V4.h(d),$CLJS.xH,$CLJS.ZB)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.ala=function(){function a(d,e,f){var k=$CLJS.W4.h(f);f=$CLJS.td($CLJS.L4.h?$CLJS.L4.h(k):$CLJS.L4.call(null,k))?function(){var m=$CLJS.N4.g?$CLJS.N4.g(d,k):$CLJS.N4.call(null,d,k);return $CLJS.X4.j?$CLJS.X4.j(d,e,m):$CLJS.X4.call(null,d,e,m)}():null;f=$CLJS.n(f)?$CLJS.I4(k,new $CLJS.P(null,1,5,$CLJS.Q,[f],null)):k;var l=$CLJS.K4(d,e,f);return $CLJS.VY.l(d,e,$CLJS.Lk,$CLJS.G([$CLJS.CP,function(m){return $CLJS.$d.g($CLJS.Bf(m),l)}]))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}
var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Y4=function(){function a(d,e){return $CLJS.Fe($CLJS.I.g($CLJS.HY(d,e),$CLJS.CP))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.bla=function(){function a(d){d=$CLJS.K1($CLJS.P1(d));d=$CLJS.bv.h(d);return $CLJS.dg.j($CLJS.zf,$CLJS.Ik.g($CLJS.ef($CLJS.Pe($CLJS.Hd,d)),$CLJS.cf.h($CLJS.M4)),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.HH,$CLJS.wH,$CLJS.LH,$CLJS.NH],null))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.cla=function(){function a(d,e,f,k){var l=$CLJS.y4(f)?$CLJS.A4(f):null,m=$CLJS.dg.j($CLJS.ah,$CLJS.Ik.g($CLJS.cf.h($CLJS.A4),Fka(function(t){return $CLJS.Gk.g(t,l)})),$CLJS.Y4.g(d,e));f=$CLJS.n(k)?k:$CLJS.y4(f)?D4($CLJS.B($CLJS.L4(f))):null;return Q4(R4(f,$CLJS.Jk.g(function(t){t=$CLJS.A4(t);return $CLJS.n(t)?$CLJS.Hd(m,t):null},$CLJS.A2.A(d,e,$CLJS.HY(d,e),new $CLJS.h(null,1,[$CLJS.h2,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,
e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.A=b;c.N=a;return c}();
$CLJS.dla=function(){function a(d,e,f,k,l){k=$CLJS.y4(f)?$CLJS.N4(d,f):f;var m=$CLJS.y4(f)?$CLJS.A4(f):null;f=$CLJS.n(l)?l:$CLJS.y4(f)?E4($CLJS.B($CLJS.L4(f))):null;return Q4(R4(f,$CLJS.cf.g(function(t){t=$CLJS.R.j(t,$CLJS.LY,$CLJS.I0);return $CLJS.n(m)?$CLJS.B4(t,m):t},$CLJS.A2.A(d,e,k,new $CLJS.h(null,1,[$CLJS.h2,!1],null)))))}function b(d,e,f,k){return c.N?c.N(d,-1,e,f,k):c.call(null,d,-1,e,f,k)}var c=null;c=function(d,e,f,k,l){switch(arguments.length){case 4:return b.call(this,d,e,f,k);case 5:return a.call(this,
d,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};c.A=b;c.N=a;return c}();$CLJS.ela=function(){function a(c,d,e){return b.A?b.A(c,-1,d,e):b.call(null,c,-1,d,e)}var b=null;b=function(c,d,e,f){switch(arguments.length){case 3:return a.call(this,c,d,e);case 4:return $CLJS.v3}throw Error("Invalid arity: "+arguments.length);};b.j=a;b.A=function(){return $CLJS.v3};return b}();
$CLJS.X4=function(){function a(d,e,f){function k(t,u){return $CLJS.s4.l($CLJS.t3.h($CLJS.Os),t,$CLJS.G([u]))}var l=function(){var t=S4(d,e,f);if($CLJS.n(t)){if($CLJS.n(t)){var u=$CLJS.A2.j(d,e,$CLJS.HY(d,e));u=T4(t,u)}else u=null;return $CLJS.n(u)?k(u,t):null}return null}();if($CLJS.n(l))return l;l=S4(d,e,$CLJS.HY(d,e));if($CLJS.n(l)){var m=T4(l,$CLJS.A2.j(d,e,f));return $CLJS.n(m)?k(l,m):null}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.fla=function(){function a(e,f,k,l){l=$CLJS.n(l)?l:$CLJS.y4(k)?D4($CLJS.B($CLJS.L4(k))):null;$CLJS.n(l)?(l=$CLJS.Y1.j(e,f,l),l=$CLJS.ED(l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SY,$CLJS.WG],null))):l=null;if($CLJS.n(l))return l;if(l=0===$CLJS.GY(e,f)){l=$CLJS.Y4.g(e,f);var m=$CLJS.td(l);k=m?m:$CLJS.y4(k)?$CLJS.F.g($CLJS.BH.h(k),$CLJS.BH.h($CLJS.B(l))):null;k=$CLJS.n(k)?$CLJS.J1(e):k}else k=l;$CLJS.n(k)?(k=$CLJS.J1(e),k=$CLJS.Q1(e,k),e=$CLJS.V1.j(e,f,k)):e=null;return $CLJS.n(e)?e:$CLJS.pH("Previous results")}
function b(e,f,k){return d.A?d.A(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.j?d.j(e,f,null):d.call(null,e,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.A=a;return d}();
$CLJS.gla=function(){function a(d,e,f,k){f=$CLJS.tY.h(f);$CLJS.H(f,0,null);$CLJS.H(f,1,null);var l=$CLJS.H(f,2,null),m=$CLJS.H(f,3,null);k=k instanceof $CLJS.M?k:$CLJS.ri.h(k);e=$CLJS.GY(d,e);l=$CLJS.F3.j(d,e,l);d=$CLJS.F3.j(d,e,m);m=null==k||$CLJS.Hd($CLJS.eh($CLJS.cf.g($CLJS.ri,l)),k);d=null==k||$CLJS.Hd($CLJS.eh($CLJS.cf.g($CLJS.ri,d)),k);f=m?$CLJS.Lk.A(f,2,$CLJS.z3,k):f;return d?$CLJS.Lk.A(f,3,$CLJS.z3,k):f}function b(d,e,f){return c.A?c.A(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,
e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.A=a;return c}();$CLJS.s2.m(null,$CLJS.CP,function(a,b){var c=$CLJS.Fe($CLJS.Y4.g(a,b));c=null==c?null:$CLJS.cf.g(function(d){return $CLJS.V1.j(a,b,d)},c);return null==c?null:$CLJS.Pu(" + ",c)});