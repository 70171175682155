var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.filter.operator.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var eka,fka,K3,L3,gka,M3,N3;
$CLJS.G3=function(a){return $CLJS.n($CLJS.u1.g?$CLJS.u1.g($CLJS.n1,a):$CLJS.u1.call(null,$CLJS.n1,a))?$CLJS.u3:$CLJS.n($CLJS.u1.g?$CLJS.u1.g($CLJS.j1,a):$CLJS.u1.call(null,$CLJS.j1,a))?$CLJS.u3:$CLJS.n($CLJS.u1.g?$CLJS.u1.g($CLJS.g1,a):$CLJS.u1.call(null,$CLJS.g1,a))?$CLJS.Pja:$CLJS.n($CLJS.u1.g?$CLJS.u1.g($CLJS.q1,a):$CLJS.u1.call(null,$CLJS.q1,a))?$CLJS.Qja:$CLJS.n($CLJS.u1.g?$CLJS.u1.g($CLJS.p1,a):$CLJS.u1.call(null,$CLJS.p1,a))?$CLJS.Rja:$CLJS.n($CLJS.u1.g?$CLJS.u1.g($CLJS.f1,a):$CLJS.u1.call(null,
$CLJS.f1,a))?$CLJS.Sja:$CLJS.n($CLJS.u1.g?$CLJS.u1.g($CLJS.c1,a):$CLJS.u1.call(null,$CLJS.c1,a))?$CLJS.Tja:$CLJS.n($CLJS.u1.g?$CLJS.u1.g($CLJS.e1,a):$CLJS.u1.call(null,$CLJS.e1,a))?$CLJS.Uja:$CLJS.n($CLJS.u1.g?$CLJS.u1.g($CLJS.k1,a):$CLJS.u1.call(null,$CLJS.k1,a))?$CLJS.Vja:$CLJS.Wja};$CLJS.H3=function(a){if("number"===typeof a)return a;switch(a instanceof $CLJS.M?a.T:null){case "current":return 0;case "next":return 1;case "last":return-1;default:return 0}};
$CLJS.I3=function(a,b){a=$CLJS.H3(a);b=$CLJS.n(b)?b:$CLJS.Gv;return 0===a?$CLJS.F.g(b,$CLJS.Gv)?$CLJS.pH("Today"):$CLJS.SG("This {0}",$CLJS.G([$CLJS.B3.h(b)])):$CLJS.F.g(a,1)?$CLJS.F.g(b,$CLJS.Gv)?$CLJS.pH("Tomorrow"):$CLJS.SG("Next {0}",$CLJS.G([$CLJS.B3.h(b)])):$CLJS.F.g(a,-1)?$CLJS.F.g(b,$CLJS.Gv)?$CLJS.pH("Yesterday"):$CLJS.SG("Previous {0}",$CLJS.G([$CLJS.B3.h(b)])):0>a?$CLJS.SG("Previous {0} {1}",$CLJS.G([Math.abs(a),$CLJS.B3.g(Math.abs(a),b)])):0<a?$CLJS.SG("Next {0} {1}",$CLJS.G([a,$CLJS.B3.g(a,
b)])):null};$CLJS.J3=function(a){var b=$CLJS.G3(a);b=$CLJS.Fe(b);return null==b?a:$CLJS.R.j(a,$CLJS.E0,b)};eka=new $CLJS.M(null,"legacy-filter","legacy-filter",-948552546);fka=new $CLJS.M(null,"query-filters","query-filters",409521440);K3=new $CLJS.M("metabase.lib.filter","unary","metabase.lib.filter/unary",-336199667);L3=new $CLJS.M("metabase.lib.filter","varargs","metabase.lib.filter/varargs",1417033079);gka=new $CLJS.M(null,"matching-filters","matching-filters",-326129659);
$CLJS.hka=new $CLJS.M("mbql","filter-parts","mbql/filter-parts",893592485);M3=new $CLJS.M("metabase.lib.filter","compound","metabase.lib.filter/compound",1924103780);N3=new $CLJS.M("metabase.lib.filter","binary","metabase.lib.filter/binary",-1921360491);for(var O3=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vt,$CLJS.ot],null)),P3=null,Q3=0,R3=0;;)if(R3<Q3){var ika=P3.X(null,R3);$CLJS.WH(ika,M3);R3+=1}else{var S3=$CLJS.z(O3);if(S3){var T3=S3;if($CLJS.Ad(T3)){var U3=$CLJS.jc(T3),jka=$CLJS.kc(T3),kka=U3,lka=$CLJS.E(U3);O3=jka;P3=kka;Q3=lka}else{var mka=$CLJS.B(T3);$CLJS.WH(mka,M3);O3=$CLJS.C(T3);P3=null;Q3=0}R3=0}else break}
for(var V3=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.bJ],null)),W3=null,X3=0,Y3=0;;)if(Y3<X3){var nka=W3.X(null,Y3);$CLJS.WH(nka,L3);Y3+=1}else{var Z3=$CLJS.z(V3);if(Z3){var $3=Z3;if($CLJS.Ad($3)){var a4=$CLJS.jc($3),oka=$CLJS.kc($3),pka=a4,qka=$CLJS.E(a4);V3=oka;W3=pka;X3=qka}else{var rka=$CLJS.B($3);$CLJS.WH(rka,L3);V3=$CLJS.C($3);W3=null;X3=0}Y3=0}else break}
for(var b4=$CLJS.z(new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Ls,$CLJS.Ns,$CLJS.Hs,$CLJS.Js,$CLJS.BI,$CLJS.VI,$CLJS.JI,$CLJS.CI],null)),c4=null,d4=0,e4=0;;)if(e4<d4){var ska=c4.X(null,e4);$CLJS.WH(ska,N3);e4+=1}else{var f4=$CLJS.z(b4);if(f4){var g4=f4;if($CLJS.Ad(g4)){var h4=$CLJS.jc(g4),tka=$CLJS.kc(g4),uka=h4,vka=$CLJS.E(h4);b4=tka;c4=uka;d4=vka}else{var wka=$CLJS.B(g4);$CLJS.WH(wka,N3);b4=$CLJS.C(g4);c4=null;d4=0}e4=0}else break}
for(var i4=$CLJS.z(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.nI,$CLJS.xI,$CLJS.pI,$CLJS.vI,$CLJS.qt],null)),j4=null,k4=0,l4=0;;)if(l4<k4){var xka=j4.X(null,l4);$CLJS.WH(xka,K3);l4+=1}else{var m4=$CLJS.z(i4);if(m4){var n4=m4;if($CLJS.Ad(n4)){var o4=$CLJS.jc(n4),yka=$CLJS.kc(n4),zka=o4,Aka=$CLJS.E(o4);i4=yka;j4=zka;k4=Aka}else{var Bka=$CLJS.B(n4);$CLJS.WH(Bka,K3);i4=$CLJS.C(n4);j4=null;k4=0}l4=0}else break}
$CLJS.s2.m(null,$CLJS.hY,function(a,b){var c=$CLJS.Fe($CLJS.hY.h($CLJS.HY(a,b)));return $CLJS.n(c)?$CLJS.SG("Filtered by {0}",$CLJS.G([$CLJS.x3($CLJS.pH("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.ld(l,u);v=$CLJS.V1.A(a,b,v,$CLJS.W1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.kc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);
return $CLJS.ce($CLJS.V1.A(a,b,t,$CLJS.W1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}())])):null});
$CLJS.p2.m(null,M3,function(a,b,c,d){c=$CLJS.z(c);var e=$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var f=$CLJS.C(c);return $CLJS.x3(function(){var k=e instanceof $CLJS.M?e.T:null;switch(k){case "and":return $CLJS.pH("and");case "or":return $CLJS.pH("or");default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}(),function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.jc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<
v){var D=$CLJS.ld(u,A);D=$CLJS.V1.A(a,b,D,d);x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.kc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.V1.A(a,b,x,d),m($CLJS.Kc(t)))}return null}},null,null)}(f)}())});
$CLJS.p2.m(null,L3,function(a,b,c,d){var e=$CLJS.z(c);c=$CLJS.B(e);e=$CLJS.C(e);$CLJS.B(e);var f=$CLJS.C(e);e=$CLJS.cf.g(function(k){return $CLJS.V1.A(a,b,k,d)},f);if($CLJS.F.g($CLJS.E(f),2))switch(f=$CLJS.H(e,0,null),e=$CLJS.H(e,1,null),c=c instanceof $CLJS.M?c.T:null,c){case "\x3d":return $CLJS.SG("{0} equals {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.SG("{0} does not equal {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}else switch(f=$CLJS.B(e),e=$CLJS.x3($CLJS.pH("or"),
$CLJS.Kc(e)),c=c instanceof $CLJS.M?c.T:null,c){case "\x3d":return $CLJS.SG("{0} equals any of {1}",$CLJS.G([f,e]));case "!\x3d":return $CLJS.SG("{0} does not equal any of {1}",$CLJS.G([f,e]));default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.p2.m(null,N3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);var f=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);f=$CLJS.V1.A(a,b,f,d);a=$CLJS.V1.A(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "\x3c":return $CLJS.SG("{0} is less than {1}",$CLJS.G([f,a]));case "\x3c\x3d":return $CLJS.SG("{0} is less than or equal to {1}",$CLJS.G([f,a]));case "\x3e":return $CLJS.SG("{0} is greater than {1}",$CLJS.G([f,a]));case "\x3e\x3d":return $CLJS.SG("{0} is greater than or equal to {1}",$CLJS.G([f,
a]));case "starts-with":return $CLJS.SG("{0} starts with {1}",$CLJS.G([f,a]));case "ends-with":return $CLJS.SG("{0} ends with {1}",$CLJS.G([f,a]));case "contains":return $CLJS.SG("{0} contains {1}",$CLJS.G([f,a]));case "does-not-contain":return $CLJS.SG("{0} does not contain {1}",$CLJS.G([f,a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.p2.m(null,$CLJS.oI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.SG("{0} is between {1} and {2}",$CLJS.G([$CLJS.V1.A(a,b,e,d),$CLJS.V1.A(a,b,f,d),$CLJS.V1.A(a,b,c,d)]))});
$CLJS.p2.m(null,$CLJS.tI,function(a,b,c,d){$CLJS.H(c,0,null);var e=$CLJS.H(c,1,null),f=$CLJS.H(c,2,null),k=$CLJS.H(c,3,null),l=$CLJS.H(c,4,null),m=$CLJS.H(c,5,null),t=$CLJS.H(c,6,null);c=$CLJS.H(c,7,null);return $CLJS.V1.A(a,b,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vt,e,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.oI,e,f,t,l],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.oI,e,k,m,c],null)],null),d)});
$CLJS.p2.m(null,K3,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.V1.A(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "is-null":return $CLJS.SG("{0} is empty",$CLJS.G([a]));case "not-null":return $CLJS.SG("{0} is not empty",$CLJS.G([a]));case "is-empty":return $CLJS.SG("{0} is empty",$CLJS.G([a]));case "not-empty":return $CLJS.SG("{0} is not empty",$CLJS.G([a]));case "not":return $CLJS.SG("not {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",
$CLJS.p.h(e)].join(""));}});$CLJS.p2.m(null,$CLJS.WI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);return $CLJS.SG("{0} is within {1}",$CLJS.G([$CLJS.V1.A(a,b,e,d),$CLJS.I3(f,c).toLowerCase()]))});
$CLJS.p4=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.SE(f),$CLJS.MY))f=$CLJS.qY(f);else return e=$CLJS.n(e)?e:-1,f=$CLJS.tY.h(f),$CLJS.VY.l(d,e,$CLJS.Lk,$CLJS.G([$CLJS.hY,$CLJS.EG($CLJS.$d,$CLJS.zf),f]))}function b(d,e){return c.j?c.j(d,null,e):c.call(null,d,null,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.q4=function(){function a(d,e){return $CLJS.Fe($CLJS.hY.h($CLJS.HY(d,$CLJS.n(e)?e:-1)))}function b(d){return c.g?c.g(d,null):c.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.r4=function(){function a(d,e){var f=$CLJS.HY(d,e);return $CLJS.Fe($CLJS.dg.j($CLJS.zf,$CLJS.Ik.g($CLJS.cf.h($CLJS.J3),$CLJS.ef($CLJS.E0)),$CLJS.A2.j(d,e,f)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.s4=function(){function a(c,d,e){var f=null;if(2<arguments.length){f=0;for(var k=Array(arguments.length-2);f<k.length;)k[f]=arguments[f+2],++f;f=new $CLJS.y(k,0,null)}return b.call(this,c,d,f)}function b(c,d,e){return $CLJS.pY($CLJS.dg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DI.h(c),$CLJS.N,$CLJS.tY.h(d)],null),$CLJS.cf.g($CLJS.tY,e)))}a.v=2;a.B=function(c){var d=$CLJS.B(c);c=$CLJS.C(c);var e=$CLJS.B(c);c=$CLJS.Kc(c);return b(d,e,c)};a.l=b;return a}();
$CLJS.Cka=function(){function a(d,e,f){var k=$CLJS.H(f,0,null);$CLJS.H(f,1,null);f=$CLJS.H(f,2,null);var l=$CLJS.HY(d,e);d=$CLJS.A2.j(d,e,l);d=$CLJS.o3(f,d);d=$CLJS.WJ(function(m){return $CLJS.F.g($CLJS.DI.h(m),k)},$CLJS.G3(d));return $CLJS.n(d)?d:$CLJS.t3.h(k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;
return c}();
$CLJS.Dka=function(){function a(d,e,f){var k=$CLJS.I_(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FO,$CLJS.gP],null),f);d=$CLJS.Bf($CLJS.q4.g(d,e));e=$CLJS.ff(function(l){return $CLJS.F.g($CLJS.I_(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FO,$CLJS.gP],null),$CLJS.T_.h(l)),k)},d);if($CLJS.z(e)){if($CLJS.C(e))throw $CLJS.hi("Multiple matching filters found",new $CLJS.h(null,3,[eka,k,fka,d,gka,e],null));return $CLJS.B(e)}return null}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,
e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Eka=function(){function a(d,e,f){f=$CLJS.x0.j(d,e,f);d=$CLJS.r4.g(d,e);return $CLJS.o3(f,d)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();