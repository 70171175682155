var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.shared.formatting.internal.numbers.js");require("./metabase.shared.formatting.internal.numbers_core.js");require("./metabase.util.js");
'use strict';var Ola=function(a,b){return new $CLJS.le(null,function(){a:for(var c=a,d=b;;){d=$CLJS.z(d);var e;if(e=d)e=$CLJS.B(d),e=c.h?c.h(e):c.call(null,e);if($CLJS.n(e))d=$CLJS.Kc(d);else break a}return d},null,null)},Pla=function(a,b){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ew(a,b),Ola(a,b)],null)},W5=function(a){var b=$CLJS.$k.l;var c=$CLJS.Ee(a);var d=$CLJS.I.g(c,$CLJS.P5);c=$CLJS.I.g(c,$CLJS.Q5);if($CLJS.n($CLJS.n(d)?$CLJS.F.g(c,"currency"):d)){c=$CLJS.K5.h;d=$CLJS.yh.h(d);var e=$CLJS.q($CLJS.H5);
d=e.h?e.h(d):e.call(null,d);c=c.call($CLJS.K5,d);c=new $CLJS.h(null,2,[$CLJS.O5,c,$CLJS.R5,c],null)}else c=new $CLJS.h(null,1,[$CLJS.R5,2],null);b=b.call($CLJS.$k,$CLJS.G([c,a]));return $CLJS.n($CLJS.S5.h(a))?$CLJS.R.l($CLJS.Ak.g(b,$CLJS.S5),$CLJS.R5,$CLJS.S5.h(a),$CLJS.G([$CLJS.O5,$CLJS.S5.h(a)])):b},X5=function(a,b){if(null!=a&&null!=a.De)a=a.De(a,b);else{var c=X5[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=X5._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Ya("NumberFormatter.format-number-basic",
a);}return a},Qla=function(a,b){var c=$CLJS.oD(a.formatToParts(b),$CLJS.G([new $CLJS.h(null,1,[$CLJS.yi,!0],null)]));a=$CLJS.n($CLJS.Me(function(d){return $CLJS.F.g($CLJS.jj.h(d),"exponentMinusSign")},c))?c:function(){var d=Pla(function(f){return $CLJS.Gk.g($CLJS.jj.h(f),"exponentInteger")},c),e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.bf.l(e,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.jj,"exponentPlusSign",$CLJS.Aj,"+"],null)],null),$CLJS.G([d]))}();return $CLJS.O.g($CLJS.p,
$CLJS.cf.g(function(d){var e=$CLJS.Ee(d);d=$CLJS.I.g(e,$CLJS.jj);e=$CLJS.I.g(e,$CLJS.Aj);switch(d){case "exponentSeparator":return"e";default:return e}},a))},Rla=function(a,b){switch($CLJS.Q5.h(b)){case "scientific":return function(c){return Qla(a,c)};default:return function(c){return a.format(c)}}},Y5=function(a,b,c,d,e){this.options=a;this.gg=b;this.jg=c;this.Ud=d;this.Oh=e;this.C=393216;this.I=0},Sla=function(a,b){return $CLJS.O.g($CLJS.p,function(){return function e(d){return new $CLJS.le(null,
function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.Ad(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);return function(){for(var v=0;;)if(v<l){var x=$CLJS.ld(k,v),A=$CLJS.Ee(x);x=$CLJS.I.g(A,$CLJS.jj);A=$CLJS.I.g(A,$CLJS.Aj);var D=new $CLJS.$g(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);D=D.h?D.h(x):D.call(null,x);$CLJS.n(D)&&(x=$CLJS.F.g(x,"integer")?b:A,m.add(x));v+=1}else return!0}()?$CLJS.re($CLJS.te(m),e($CLJS.kc(f))):$CLJS.re($CLJS.te(m),null)}var t=$CLJS.B(f);t=$CLJS.Ee(t);
var u=$CLJS.I.g(t,$CLJS.jj);t=$CLJS.I.g(t,$CLJS.Aj);if($CLJS.n(function(){var v=new $CLJS.$g(null,new $CLJS.h(null,2,["currency",null,"integer",null],null),null);return v.h?v.h(u):v.call(null,u)}()))return $CLJS.ce($CLJS.F.g(u,"integer")?b:t,e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}($CLJS.oD(a.gg.formatToParts(1),$CLJS.G([$CLJS.yi,!0])))}())},Z5=function(a){var b=$CLJS.F.g($CLJS.Q5.h(a),"currency")?2:null;b=new Intl.NumberFormat("en",$CLJS.cl($CLJS.KD($CLJS.Ta,$CLJS.Hg([$CLJS.Lla,
$CLJS.Dla,$CLJS.Gla,$CLJS.Ila,$CLJS.Nla,$CLJS.P5,$CLJS.Hla,$CLJS.Jla,$CLJS.Ela,$CLJS.Fla],[$CLJS.R5.g(a,b),$CLJS.U5.h(a),$CLJS.T5.h(a),$CLJS.Kla.h(a),$CLJS.F.g($CLJS.Q5.h(a),"scientific")?null:$CLJS.Q5.g(a,"decimal"),$CLJS.P5.h(a),!0,$CLJS.O5.g(a,b),$CLJS.F.g($CLJS.Q5.h(a),"scientific")?"scientific":null,$CLJS.V5.h(a)]))));var c=$CLJS.U5.h(a);var d=$CLJS.P5.h(a);c=$CLJS.n(d)?null==c||$CLJS.F.g(c,"symbol"):d;d=Rla(b,a);return new Y5(a,b,c,d,$CLJS.N)},$5=function(a,b){return Z5(W5(b)).De(null,a)},b6=
function(a,b){var c=$CLJS.Ak.l(b,$CLJS.oE,$CLJS.G([$CLJS.Q5])),d=Math.abs(a);if(0===a)return"0";if(1E3>d)return $CLJS.a6.g?$CLJS.a6.g(a,c):$CLJS.a6.call(null,a,c);b=$CLJS.B($CLJS.ff(function(f){return d>=$CLJS.B(f)},Tla));var e=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return[$CLJS.p.h(function(){var f=a/e,k=$CLJS.$k.l($CLJS.G([c,new $CLJS.h(null,2,[$CLJS.O5,1,$CLJS.R5,1],null)]));return $CLJS.a6.g?$CLJS.a6.g(f,k):$CLJS.a6.call(null,f,k)}()),$CLJS.p.h(b)].join("")},Ula=function(a,b){var c=W5(b);if($CLJS.n(c6.h(c)))b=
c6.h(c);else if($CLJS.Sa($CLJS.S5.h(c))&&$CLJS.Sa($CLJS.O5.h(c))&&$CLJS.Gk.g($CLJS.Q5.h(c),"currency")&&Math.abs(a)<($CLJS.F.g($CLJS.Q5.h(c),"percent")?.01:1)){b=$CLJS.R.j;var d=$CLJS.Ak.g(c,$CLJS.R5);c=$CLJS.V5.g(c,0);b=Z5(b.call($CLJS.R,d,$CLJS.T5,2>c?2:c))}else b=Z5(c);return X5(b,a)};Y5.prototype.P=function(a,b){return new Y5(this.options,this.gg,this.jg,this.Ud,b)};Y5.prototype.O=function(){return this.Oh};
Y5.prototype.De=function(a,b){a=this.Ud.h?this.Ud.h(b):this.Ud.call(null,b);var c=$CLJS.Mla.h(this.options);$CLJS.n($CLJS.n(c)?$CLJS.Gk.g(c,".,"):c)&&(b=$CLJS.B(c),c=$CLJS.id(c),a=$CLJS.HD(a,/[\.,]/,new $CLJS.h(null,2,[",",$CLJS.n(c)?c:"",".",b],null)));$CLJS.n(this.jg)&&(b=$CLJS.P5.h(this.options),c=$CLJS.I5(b),a=$CLJS.HD($CLJS.HD(a,[$CLJS.fh(b)," "].join(""),c),$CLJS.fh(b),c));return a};
var c6=new $CLJS.M(null,"number-formatter","number-formatter",929788393),d6=new $CLJS.M(null,"scale","scale",-230427353),e6=new $CLJS.M(null,"negative-in-parentheses","negative-in-parentheses",-1676084141),Vla=new $CLJS.M(null,"scientific","scientific",316285837);var f6=new $CLJS.h(null,1,[$CLJS.U5,"symbol"],null),Wla=$CLJS.cl(f6),Tla=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[1E12,"T"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E9,"B"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E6,"M"],null),new $CLJS.P(null,2,5,$CLJS.Q,[1E3,"k"],null)],null),g6=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.shared.formatting.numbers",
"format-number-compact*"),function(f,k){f=$CLJS.Ee(k);return $CLJS.I.g(f,$CLJS.Q5)},e,a,b,c,d)}();g6.m(null,$CLJS.ci,function(a,b){return b6(a,b)});g6.m(null,"percent",function(a,b){return[$CLJS.p.h(b6(100*a,b)),"%"].join("")});g6.m(null,"currency",function(a,b){b=$CLJS.$k.l($CLJS.G([b,f6]));var c=Z5(b);return 1E3>Math.abs(a)?c.De(null,a):Sla(c,b6(a,b))});g6.m(null,"scientific",function(a,b){return $5(a,$CLJS.$k.l($CLJS.G([b,new $CLJS.h(null,2,[$CLJS.R5,1,$CLJS.O5,1],null)])))});
$CLJS.a6=function a6(a,b){b=$CLJS.TD(b);var d=$CLJS.Ee(b);b=$CLJS.I.g(d,$CLJS.oE);var e=$CLJS.I.g(d,e6),f=$CLJS.I.g(d,$CLJS.Q5),k=$CLJS.I.g(d,d6);return $CLJS.n($CLJS.n(k)?!isNaN(k):k)?(b=k*a,f=$CLJS.Ak.g(d,d6),a6.g?a6.g(b,f):a6.call(null,b,f)):$CLJS.n(function(){var l=0>a;return l?e:l}())?["(",$CLJS.p.h(function(){var l=-a,m=$CLJS.R.j(d,e6,!1);return a6.g?a6.g(l,m):a6.call(null,l,m)}()),")"].join(""):$CLJS.n(b)?g6.g(a,W5($CLJS.Ak.g(d,$CLJS.oE))):$CLJS.F.g($CLJS.yh.h(f),Vla)?$5(a,d):Ula(a,d)};
module.exports={compact_currency_options_js:Wla,format_number:$CLJS.a6};