var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.aggregation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var i5,k5,l5,m5,nla;$CLJS.h5=function(a,b){return $CLJS.dg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,new $CLJS.h(null,1,[$CLJS.fH,$CLJS.p.h($CLJS.oH())],null)],null),$CLJS.cf.h($CLJS.tY),b)};i5=function(a,b,c){var d=$CLJS.HY(a,b);d=$CLJS.Ee(d);d=$CLJS.I.g(d,$CLJS.zI);d=$CLJS.WJ($CLJS.Ik.j($CLJS.bh([c]),$CLJS.fH,$CLJS.id),d);if(!$CLJS.n(d))throw $CLJS.hi($CLJS.SG("No aggregation with uuid {0}",$CLJS.G([c])),new $CLJS.h(null,3,[$CLJS.Et,c,$CLJS.FO,a,$CLJS.OY,b],null));return d};
$CLJS.j5=function(a){return $CLJS.h5($CLJS.XI,new $CLJS.P(null,1,5,$CLJS.Q,[a],null))};k5=new $CLJS.M("metabase.lib.aggregation","unary-aggregation","metabase.lib.aggregation/unary-aggregation",1064133592);l5=new $CLJS.M("metabase.lib.aggregation","count-aggregation","metabase.lib.aggregation/count-aggregation",-21314018);m5=new $CLJS.M("metabase.lib.aggregation","aggregation","metabase.lib.aggregation/aggregation",1701573815);nla=new $CLJS.M(null,"aggregation-operator","aggregation-operator",-1978700834);$CLJS.s2.m(null,$CLJS.zI,function(a,b){var c=$CLJS.Fe($CLJS.zI.h($CLJS.HY(a,b)));return $CLJS.n(c)?$CLJS.x3($CLJS.pH("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.ld(l,u);v=$CLJS.V1.A(a,b,v,$CLJS.W1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.kc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);return $CLJS.ce($CLJS.V1.A(a,
b,t,$CLJS.W1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}()):null});$CLJS.v2.m(null,$CLJS.zI,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.Ee(d);d=$CLJS.I.g(e,$CLJS.gE);e=$CLJS.I.g(e,$CLJS.Fi);c=$CLJS.H(c,2,null);c=i5(a,b,c);return $CLJS.$k.l($CLJS.G([$CLJS.U1.j(a,b,c),new $CLJS.h(null,2,[$CLJS.LY,$CLJS.UY,$CLJS.A3,$CLJS.fH.h($CLJS.id(c))],null),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.gE,d],null):null,$CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Fi,e],null):null]))});
$CLJS.p2.m(null,$CLJS.zI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.V1.A(a,b,i5(a,b,c),d)});$CLJS.WH(l5,m5);
for(var n5=$CLJS.z(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TA,$CLJS.LI],null)),o5=null,p5=0,q5=0;;)if(q5<p5){var ola=o5.X(null,q5);$CLJS.WH(ola,l5);q5+=1}else{var r5=$CLJS.z(n5);if(r5){var s5=r5;if($CLJS.Ad(s5)){var t5=$CLJS.jc(s5),pla=$CLJS.kc(s5),qla=t5,rla=$CLJS.E(t5);n5=pla;o5=qla;p5=rla}else{var sla=$CLJS.B(s5);$CLJS.WH(sla,l5);n5=$CLJS.C(s5);o5=null;p5=0}q5=0}else break}
$CLJS.p2.m(null,l5,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);if($CLJS.n(c))switch(a=$CLJS.V1.A(a,b,c,d),e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.SG("Count of {0}",$CLJS.G([a]));case "cum-count":return $CLJS.SG("Cumulative count of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}else switch(e=e instanceof $CLJS.M?e.T:null,e){case "count":return $CLJS.pH("Count");case "cum-count":return $CLJS.pH("Cumulative count");
default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.q2.m(null,l5,function(a,b,c){a=$CLJS.H(c,0,null);a=a instanceof $CLJS.M?a.T:null;switch(a){case "count":return"count";case "cum-count":return"cum_count";default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}});$CLJS.v2.m(null,l5,function(a,b,c){var d=$CLJS.R.j,e=$CLJS.ai($CLJS.v2,m5);a=e.j?e.j(a,b,c):e.call(null,a,b,c);return d.call($CLJS.R,a,$CLJS.Sj,$CLJS.HF)});$CLJS.p2.m(null,$CLJS.uI,function(){return $CLJS.pH("Case")});
$CLJS.q2.m(null,$CLJS.uI,function(){return"case"});$CLJS.WH(k5,m5);for(var u5=$CLJS.z(new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.EI,$CLJS.fJ,$CLJS.qI,$CLJS.fk,$CLJS.YI,$CLJS.Rn,$CLJS.$I,$CLJS.XI,$CLJS.rI],null)),v5=null,w5=0,x5=0;;)if(x5<w5){var tla=v5.X(null,x5);$CLJS.WH(tla,k5);x5+=1}else{var y5=$CLJS.z(u5);if(y5){var z5=y5;if($CLJS.Ad(z5)){var A5=$CLJS.jc(z5),ula=$CLJS.kc(z5),vla=A5,wla=$CLJS.E(A5);u5=ula;v5=vla;w5=wla}else{var xla=$CLJS.B(z5);$CLJS.WH(xla,k5);u5=$CLJS.C(z5);v5=null;w5=0}x5=0}else break}
$CLJS.q2.m(null,k5,function(a,b,c){a=$CLJS.H(c,0,null);$CLJS.H(c,1,null);$CLJS.H(c,2,null);c=a instanceof $CLJS.M?a.T:null;switch(c){case "avg":return"avg";case "cum-sum":return"sum";case "distinct":return"count";case "max":return"max";case "median":return"median";case "min":return"min";case "stddev":return"stddev";case "sum":return"sum";case "var":return"var";default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}});
$CLJS.p2.m(null,k5,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.V1.A(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "avg":return $CLJS.SG("Average of {0}",$CLJS.G([a]));case "cum-sum":return $CLJS.SG("Cumulative sum of {0}",$CLJS.G([a]));case "distinct":return $CLJS.SG("Distinct values of {0}",$CLJS.G([a]));case "max":return $CLJS.SG("Max of {0}",$CLJS.G([a]));case "median":return $CLJS.SG("Median of {0}",$CLJS.G([a]));case "min":return $CLJS.SG("Min of {0}",
$CLJS.G([a]));case "stddev":return $CLJS.SG("Standard deviation of {0}",$CLJS.G([a]));case "sum":return $CLJS.SG("Sum of {0}",$CLJS.G([a]));case "var":return $CLJS.SG("Variance of {0}",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});$CLJS.p2.m(null,$CLJS.wI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);c=$CLJS.H(c,3,null);return $CLJS.SG("{0}th percentile of {1}",$CLJS.G([c,$CLJS.V1.A(a,b,e,d)]))});$CLJS.q2.m(null,$CLJS.wI,function(){return"percentile"});
$CLJS.WH($CLJS.wI,m5);$CLJS.p2.m(null,$CLJS.dJ,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.SG("Sum of {0} matching condition",$CLJS.G([$CLJS.V1.A(a,b,e,d)]))});$CLJS.q2.m(null,$CLJS.dJ,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return["sum_where_",$CLJS.p.h($CLJS.r2.j(a,b,d))].join("")});$CLJS.WH($CLJS.dJ,m5);$CLJS.p2.m(null,$CLJS.RI,function(){return $CLJS.pH("Share of rows matching condition")});
$CLJS.q2.m(null,$CLJS.RI,function(){return"share"});$CLJS.WH($CLJS.RI,m5);$CLJS.p2.m(null,$CLJS.TI,function(){return $CLJS.pH("Count of rows matching condition")});$CLJS.q2.m(null,$CLJS.TI,function(){return"count-where"});$CLJS.WH($CLJS.TI,m5);
$CLJS.v2.m(null,m5,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.$k.l;d=$CLJS.n(d)?$CLJS.Yl($CLJS.U1.j(a,b,d),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.KS],null)):null;var f=$CLJS.ai($CLJS.v2,$CLJS.ci);a=f.j?f.j(a,b,c):f.call(null,a,b,c);return e.call($CLJS.$k,$CLJS.G([d,a]))});
$CLJS.yla=function(){function a(d){return $CLJS.h5($CLJS.TA,new $CLJS.P(null,1,5,$CLJS.Q,[d],null))}function b(){return $CLJS.h5($CLJS.TA,$CLJS.zf)}var c=null;c=function(d){switch(arguments.length){case 0:return b.call(this);case 1:return a.call(this,d)}throw Error("Invalid arity: "+arguments.length);};c.o=b;c.h=a;return c}();$CLJS.nY.m(null,$CLJS.zI,function(a){return a});
$CLJS.B5=function(){function a(d,e,f){for(;;)if($CLJS.F.g($CLJS.SE(f),$CLJS.sY))f=$CLJS.qY(f);else return $CLJS.h3(d,e,$CLJS.zI,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.C5=function(){function a(d,e){return $CLJS.Fe($CLJS.zI.h($CLJS.HY(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.D5=function(){function a(d,e){var f=$CLJS.Fe($CLJS.zI.h($CLJS.HY(d,e)));return null==f?null:$CLJS.dg.j($CLJS.zf,$CLJS.cf.h(function(k){var l=$CLJS.U1.j(d,e,k),m=$CLJS.R.l,t=$CLJS.gE.h(l);return m.call($CLJS.R,$CLJS.w3(l,$CLJS.Fi,$CLJS.n(t)?t:$CLJS.xj),$CLJS.LY,$CLJS.UY,$CLJS.G([$CLJS.A3,$CLJS.fH.h($CLJS.id(k))]))}),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+
arguments.length);};c.h=b;c.g=a;return c}();$CLJS.p2.m(null,$CLJS.sJ,function(a,b,c){a=$CLJS.Ee(c);a=$CLJS.I.g(a,$CLJS.oJ);return $CLJS.WG.h(a.o?a.o():a.call(null))});$CLJS.w2.m(null,$CLJS.sJ,function(a,b,c){var d=$CLJS.Ee(c);a=$CLJS.I.g(d,$CLJS.DI);b=$CLJS.I.g(d,$CLJS.oJ);c=$CLJS.I.g(d,$CLJS.mJ);d=$CLJS.I.g(d,$CLJS.L0);a=$CLJS.R.l(b.o?b.o():b.call(null),$CLJS.n2,$CLJS.OD(a),$CLJS.G([$CLJS.m2,c]));return null!=d?$CLJS.R.j(a,$CLJS.c2,d):a});
$CLJS.zla=function(){function a(d,e){var f=function(){var m=$CLJS.bv.h($CLJS.K1($CLJS.P1(d)));return $CLJS.n(m)?m:$CLJS.ah}(),k=$CLJS.HY(d,e),l=$CLJS.A2.j(d,e,k);return $CLJS.Fe($CLJS.dg.j($CLJS.zf,$CLJS.Ik.j($CLJS.ef(function(m){m=$CLJS.qJ.h(m);var t=null==m;return t?t:f.h?f.h(m):f.call(null,m)}),$CLJS.Ql(function(m){m=$CLJS.Ee(m);var t=$CLJS.I.g(m,$CLJS.mJ),u=$CLJS.I.g(m,$CLJS.kJ);if($CLJS.Sa(t))return m;if($CLJS.F.g(u,$CLJS.Gt))return $CLJS.R.j(m,$CLJS.EY,l);t=$CLJS.Fe($CLJS.uX(function(v){return $CLJS.u1(u,
v)},l));return $CLJS.n(t)?$CLJS.R.j(m,$CLJS.EY,t):null}),$CLJS.cf.h(function(m){return $CLJS.R.j(m,$CLJS.Bj,$CLJS.sJ)})),$CLJS.tJ))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Ala=function(){function a(d,e){return $CLJS.pY(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DI.h(d),$CLJS.N,$CLJS.tY.h(e)],null))}function b(d){if($CLJS.Sa($CLJS.mJ.h(d)))return $CLJS.pY(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DI.h(d),$CLJS.N],null));var e=$CLJS.DI.h(d);e=$CLJS.ha.g?$CLJS.ha.g("aggregation operator %s requires an argument",e):$CLJS.ha.call(null,"aggregation operator %s requires an argument",e);throw $CLJS.hi(e,new $CLJS.h(null,1,[nla,d],null));}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,
d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();