var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var hja,I1,ija,L1,M1,N1,O1,f2,jja,kja,i2,Z1,k2,lja,a2;hja=function(a){return $CLJS.z(a)?a.normalize("NFKD").replace(RegExp("[̀-ͯ]","gu"),""):null};I1=function(a,b){return 128>a.charCodeAt(0)?$CLJS.Hd($CLJS.yaa,a)?a:"_":$CLJS.n(b)?encodeURIComponent(a):a};
ija=function(a){var b=$CLJS.Ee(new $CLJS.h(null,1,[$CLJS.iE,!0],null)),c=$CLJS.I.g(b,$CLJS.waa),d=$CLJS.I.g(b,$CLJS.iE);return $CLJS.z(a)?(b=$CLJS.zX(function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.ld(m,v);x=I1(x,$CLJS.Sa(d));u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.kc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);return $CLJS.ce(I1(u,
$CLJS.Sa(d)),k($CLJS.Kc(l)))}return null}},null,null)}(hja($CLJS.PD(a)))}()),$CLJS.n(c)?$CLJS.zX($CLJS.Ve(c,b)):b):null};$CLJS.J1=function(a){return $CLJS.eR.h($CLJS.B($CLJS.vN.h(a)))};$CLJS.K1=function(a){if(null!=a&&null!=a.Zf)a=a.Zf(a);else{var b=$CLJS.K1[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=$CLJS.K1._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("MetadataProvider.database",a);}return a};
L1=function(a,b){if(null!=a&&null!=a.fg)a=a.fg(a,b);else{var c=L1[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=L1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Ya("MetadataProvider.table",a);}return a};M1=function(a,b){if(null!=a&&null!=a.$f)a=a.$f(a,b);else{var c=M1[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=M1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Ya("MetadataProvider.field",a);}return a};
N1=function(a,b){if(null!=a&&null!=a.Yf)a=a.Yf(a,b);else{var c=N1[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=N1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Ya("MetadataProvider.card",a);}return a};O1=function(a,b){if(null!=a&&null!=a.ag)a=a.ag(a,b);else{var c=O1[$CLJS.wa(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=O1._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.Ya("MetadataProvider.fields",a);}return a};
$CLJS.P1=function(a){return $CLJS.z0(a)?a:$CLJS.pX.h(a)};$CLJS.Q1=function(a,b){return L1($CLJS.P1(a),b)};$CLJS.R1=function(a,b){return O1($CLJS.P1(a),b)};$CLJS.S1=function(a,b){return M1($CLJS.P1(a),b)};$CLJS.T1=function(a,b){return N1($CLJS.P1(a),b)};
f2=function(a,b,c){var d=$CLJS.U1.j(a,b,c);return $CLJS.$k.l($CLJS.G([$CLJS.Yl(d,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,$CLJS.WG,$CLJS.Sj],null)),function(){var e=$CLJS.V1.A(a,b,c,$CLJS.W1);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.X1,e],null):null}(),function(){var e=$CLJS.DD($CLJS.Fi,$CLJS.gE)(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.Fi,e],null):null}(),function(){var e=$CLJS.QY.h(d);return $CLJS.n(e)?new $CLJS.h(null,1,[$CLJS.SY,$CLJS.Y1.j(a,b,$CLJS.Fd(e)?$CLJS.Q1(a,e):"string"===typeof e?
$CLJS.T1(a,$CLJS.IY(e)):null)],null):null}(),function(){var e=$CLJS.LY.h(d);return $CLJS.n(e)?new $CLJS.h(null,4,[Z1,$CLJS.F.g(e,$CLJS.B0),$CLJS.$1,$CLJS.F.g(e,$CLJS.I0),a2,$CLJS.F.g(e,$CLJS.F0),$CLJS.b2,$CLJS.F.g(e,$CLJS.H0)],null):null}(),function(){var e=$CLJS.L0.h(d);return null==e?null:new $CLJS.h(null,1,[$CLJS.c2,e],null)}(),$CLJS.Yl(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.d2,$CLJS.e2],null))]))};$CLJS.g2=new $CLJS.M(null,"direction","direction",-633359395);
$CLJS.b2=new $CLJS.M(null,"is-implicitly-joinable","is-implicitly-joinable",2054926844);$CLJS.d2=new $CLJS.M(null,"breakout-position","breakout-position",-760153191);$CLJS.h2=new $CLJS.M(null,"include-implicitly-joinable?","include-implicitly-joinable?",-772701866);$CLJS.c2=new $CLJS.M(null,"selected","selected",574897764);jja=new $CLJS.M(null,"is-order-by-column","is-order-by-column",1320802549);
kja=new $CLJS.w("metabase.lib.metadata.calculation","display-name-method","metabase.lib.metadata.calculation/display-name-method",37675198,null);i2=new $CLJS.M("metabase.lib.metadata.calculation","display-info","metabase.lib.metadata.calculation/display-info",-2072755534);$CLJS.j2=new $CLJS.M(null,"include-joined?","include-joined?",-410632824);Z1=new $CLJS.M(null,"is-from-previous-stage","is-from-previous-stage",956780376);k2=new $CLJS.M(null,"is-source-table","is-source-table",1559075056);
$CLJS.l2=new $CLJS.M(null,"unique-name-fn","unique-name-fn",-755402870);$CLJS.$1=new $CLJS.M(null,"is-from-join","is-from-join",-1107917905);$CLJS.m2=new $CLJS.M(null,"requires-column","requires-column",934105295);$CLJS.e2=new $CLJS.M(null,"order-by-position","order-by-position",-1307229997);lja=new $CLJS.M(null,"is-breakout-column","is-breakout-column",46784229);$CLJS.X1=new $CLJS.M(null,"long-display-name","long-display-name",1550784464);$CLJS.W1=new $CLJS.M(null,"long","long",-171452093);
$CLJS.n2=new $CLJS.M(null,"short-name","short-name",-1767085022);$CLJS.o2=new $CLJS.M(null,"include-expressions?","include-expressions?",-1817639288);a2=new $CLJS.M(null,"is-calculated","is-calculated",-86687269);$CLJS.p2=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.metadata.calculation","display-name-method"),function(f,k,l){return $CLJS.SE(l)},e,a,b,c,d)}();
$CLJS.q2=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.metadata.calculation","column-name-method"),function(f,k,l){return $CLJS.SE(l)},e,a,b,c,d)}();
$CLJS.V1=function(){function a(f,k,l,m){var t=$CLJS.WG.h($CLJS.mH(l));if($CLJS.n(t))return t;try{return $CLJS.p2.A(f,k,l,m)}catch(u){if(u instanceof Error)throw k=u,$CLJS.gi($CLJS.SG("Error calculating display name for {0}: {1}",$CLJS.G([$CLJS.Lh.l($CLJS.G([l])),$CLJS.vX(k)])),new $CLJS.h(null,2,[$CLJS.FO,f,$CLJS.gH,l],null),k);throw u;}}function b(f,k,l){return e.A?e.A(f,k,l,$CLJS.ci):e.call(null,f,k,l,$CLJS.ci)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){return e.g?
e.g(f,f):e.call(null,f,f)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.A=a;return e}();
$CLJS.r2=function(){function a(d,e,f){var k=$CLJS.S.h($CLJS.mH(f));if($CLJS.n(k))return k;try{return $CLJS.q2.j(d,e,f)}catch(l){if(l instanceof Error)throw k=l,$CLJS.gi($CLJS.SG("Error calculating column name for {0}: {1}",$CLJS.G([$CLJS.Lh.l($CLJS.G([f])),$CLJS.vX(k)])),new $CLJS.h(null,3,[$CLJS.gH,f,$CLJS.FO,d,$CLJS.OY,e],null),k);throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,
d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();$CLJS.p2.m(null,$CLJS.ci,function(a,b,c){a=$CLJS.ND($CLJS.gC);$CLJS.n($CLJS.MD("metabase.lib.metadata.calculation",a))&&$CLJS.LD("metabase.lib.metadata.calculation",a,$CLJS.wY("Don't know how to calculate display name for %s. Add an impl for %s for %s",$CLJS.G([$CLJS.Lh.l($CLJS.G([c])),kja,$CLJS.SE(c)])),null);return $CLJS.zd(c)&&$CLJS.B(c)instanceof $CLJS.M?$CLJS.fh($CLJS.B(c)):$CLJS.Lh.l($CLJS.G([c]))});
$CLJS.q2.m(null,$CLJS.ci,function(a,b,c){return ija($CLJS.HD($CLJS.V1.j(a,b,c),/[\(\)]/,""))});$CLJS.s2=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.metadata.calculation","describe-top-level-key-method"),function(f,k,l){return l},e,a,b,c,d)}();
$CLJS.mja=function(){function a(d,e,f){return $CLJS.s2.j(d,e,$CLJS.yh.h(f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.t2=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.metadata.calculation","type-of-method"),function(f,k,l){return $CLJS.SE(l)},e,a,b,c,d)}();
$CLJS.u2=function(){function a(d,e,f){var k=$CLJS.mH(f);k=$CLJS.Ee(k);var l=$CLJS.I.g(k,$CLJS.QI),m=$CLJS.Fi.h(k);if($CLJS.n(m))return m;l=$CLJS.n($CLJS.n(l)?$CLJS.Hd($CLJS.TJ,l):l)?$CLJS.tj:null;if($CLJS.n(l))return l;k=$CLJS.gE.h(k);if($CLJS.n(k))return k;d=$CLJS.t2.j(d,e,f);return $CLJS.fF(d,$CLJS.xj)?d:$CLJS.xj}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();$CLJS.t2.m(null,$CLJS.ci,function(a,b,c){return $CLJS.sH(c)});$CLJS.t2.m(null,$CLJS.JH,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.u2.j(a,b,c)});$CLJS.t2.m(null,$CLJS.oL,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);return"string"===typeof d?$CLJS.sH(c):$CLJS.u2.j(a,b,d)});
$CLJS.v2=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.metadata.calculation","metadata-method"),function(f,k,l){return $CLJS.SE(l)},e,a,b,c,d)}();
$CLJS.v2.m(null,$CLJS.ci,function(a,b,c){try{return new $CLJS.h(null,4,[$CLJS.Bj,$CLJS.rY,$CLJS.gE,$CLJS.u2.j(a,b,c),$CLJS.S,$CLJS.r2.j(a,b,c),$CLJS.WG,$CLJS.V1.j(a,b,c)],null)}catch(e){if(e instanceof Error){var d=e;throw $CLJS.gi($CLJS.SG("Error calculating metadata for {0}: {1}",$CLJS.G([$CLJS.Lh.l($CLJS.G([$CLJS.SE(c)])),$CLJS.vX(d)])),new $CLJS.h(null,3,[$CLJS.FO,a,$CLJS.OY,b,$CLJS.gH,c],null),d);}throw e;}});
$CLJS.U1=function(){function a(e,f,k){return $CLJS.v2.j(e,f,k)}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return d.j?d.j(e,-1,e):d.call(null,e,-1,e)}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();
$CLJS.w2=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.metadata.calculation","display-info-method"),function(f,k,l){return $CLJS.SE(l)},e,a,b,c,d)}();
$CLJS.W(i2,new $CLJS.P(null,19,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WG,$CLJS.Wj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.X1,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Wj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SY,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,i2],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Z1,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Ft],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$1,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Ft],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[a2,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Ft],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.b2,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Ft],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[k2,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Ft],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[lja,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Ft],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[jja,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Ft],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Wj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nJ,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Wj],null),
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iv,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Wj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.n2,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Wj],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.m2,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.c2,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.g2,
new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,$CLJS.HI,$CLJS.AI],null)],null)],null));
$CLJS.Y1=function(){function a(d,e,f){try{return $CLJS.w2.j(d,e,f)}catch(l){if(l instanceof Error){var k=l;throw $CLJS.gi($CLJS.SG("Error calculating display info for {0}: {1}",$CLJS.G([$CLJS.SE(f),$CLJS.vX(k)])),new $CLJS.h(null,3,[$CLJS.FO,d,$CLJS.OY,e,$CLJS.gH,f],null),k);}throw l;}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.w2.m(null,$CLJS.ci,function(a,b,c){return f2(a,b,c)});$CLJS.w2.m(null,$CLJS.NY,function(a,b,c){return $CLJS.$k.l($CLJS.G([f2(a,b,c),new $CLJS.h(null,1,[k2,$CLJS.F.g($CLJS.J1(a),$CLJS.Wi.h(c))],null)]))});
$CLJS.x2=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.metadata.calculation","returned-columns-method"),function(f,k,l){return $CLJS.SE(l)},e,a,b,c,d)}();$CLJS.x2.m(null,$CLJS.jE,function(){return $CLJS.zf});
$CLJS.y2=function(){function a(f,k,l,m){m=$CLJS.$k.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.l2,$CLJS.KY()],null),m]));return $CLJS.x2.A(f,k,l,m)}function b(f,k,l){return e.A?e.A(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.HY(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,
f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.A=a;return e}();$CLJS.z2=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.metadata.calculation","visible-columns-method"),function(f,k,l){return $CLJS.SE(l)},e,a,b,c,d)}();$CLJS.z2.m(null,$CLJS.jE,function(){return $CLJS.zf});
$CLJS.z2.m(null,$CLJS.ci,function(a,b,c,d){return $CLJS.x2.A(a,b,c,d)});
$CLJS.A2=function(){function a(f,k,l,m){m=$CLJS.$k.l($CLJS.G([$CLJS.$k.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.l2,$CLJS.KY()],null),new $CLJS.h(null,3,[$CLJS.j2,!0,$CLJS.o2,!0,$CLJS.h2,!0],null)])),m]));return $CLJS.z2.A(f,k,l,m)}function b(f,k,l){return e.A?e.A(f,k,l,null):e.call(null,f,k,l,null)}function c(f,k){return e.j?e.j(f,-1,k):e.call(null,f,-1,k)}function d(f){var k=$CLJS.HY(f,-1);return e.g?e.g(f,k):e.call(null,f,k)}var e=null;e=function(f,k,l,m){switch(arguments.length){case 1:return d.call(this,
f);case 2:return c.call(this,f,k);case 3:return b.call(this,f,k,l);case 4:return a.call(this,f,k,l,m)}throw Error("Invalid arity: "+arguments.length);};e.h=d;e.g=c;e.j=b;e.A=a;return e}();module.exports={display_name:$CLJS.V1};