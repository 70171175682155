var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.breakout.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.order_by.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';var rma=function(a){a=$CLJS.Ee(a);var b=$CLJS.I.g(a,$CLJS.gE);return $CLJS.Me(function(c){return $CLJS.fF(b,c)},$CLJS.QH)},p7=new $CLJS.M("metabase.lib.order-by","order-by-clause","metabase.lib.order-by/order-by-clause",774979647);$CLJS.WH($CLJS.HI,p7);$CLJS.WH($CLJS.AI,p7);
$CLJS.s2.m(null,$CLJS.RT,function(a,b){var c=$CLJS.Fe($CLJS.RT.h($CLJS.HY(a,b)));return $CLJS.n(c)?$CLJS.SG("Sorted by {0}",$CLJS.G([$CLJS.x3($CLJS.pH("and"),function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.ld(l,u);v=$CLJS.V1.A(a,b,v,$CLJS.W1);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.kc(k))):$CLJS.re($CLJS.te(t),null)}t=$CLJS.B(k);
return $CLJS.ce($CLJS.V1.A(a,b,t,$CLJS.W1),f($CLJS.Kc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.p2.m(null,p7,function(a,b,c,d){var e=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);a=$CLJS.V1.A(a,b,c,d);e=e instanceof $CLJS.M?e.T:null;switch(e){case "asc":return $CLJS.SG("{0} ascending",$CLJS.G([a]));case "desc":return $CLJS.SG("{0} descending",$CLJS.G([a]));default:throw Error(["No matching clause: ",$CLJS.p.h(e)].join(""));}});
$CLJS.w2.m(null,p7,function(a,b,c){var d=$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);return $CLJS.R.j($CLJS.Y1.j(a,b,c),$CLJS.g2,d)});var q7,sma=$CLJS.Re($CLJS.N),tma=$CLJS.Re($CLJS.N),uma=$CLJS.Re($CLJS.N),vma=$CLJS.Re($CLJS.N),wma=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));q7=new $CLJS.di($CLJS.Eh.g("metabase.lib.order-by","order-by-clause-method"),$CLJS.SE,wma,sma,tma,uma,vma);q7.m(null,p7,function(a){return $CLJS.pY(a)});
q7.m(null,$CLJS.ci,function(a){if(null==a)throw $CLJS.hi($CLJS.pH("Can''t order by nil"),$CLJS.N);return $CLJS.pY(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HI,$CLJS.qY(a)],null))});
$CLJS.xma=function(){function a(d,e){d=q7.h(d);e=$CLJS.n(e)?e:$CLJS.HI;return $CLJS.R.j($CLJS.Bf(d),0,e)}function b(d){return c.g?c.g(d,$CLJS.HI):c.call(null,d,$CLJS.HI)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.r7=function(){function a(e,f,k,l){f=$CLJS.n(f)?f:-1;var m=function(){var t=q7.h(k);return $CLJS.n(l)?$CLJS.R.j($CLJS.Bf(t),0,l):t}();return $CLJS.VY.l(e,f,$CLJS.Lk,$CLJS.G([$CLJS.RT,function(t){return $CLJS.$d.g($CLJS.Bf(t),m)}]))}function b(e,f,k){return d.A?d.A(e,-1,f,k):d.call(null,e,-1,f,k)}function c(e,f){return d.A?d.A(e,-1,f,null):d.call(null,e,-1,f,null)}var d=null;d=function(e,f,k,l){switch(arguments.length){case 2:return c.call(this,e,f);case 3:return b.call(this,e,f,k);case 4:return a.call(this,
e,f,k,l)}throw Error("Invalid arity: "+arguments.length);};d.g=c;d.j=b;d.A=a;return d}();$CLJS.s7=function(){function a(d,e){return $CLJS.Fe($CLJS.I.g($CLJS.HY(d,e),$CLJS.RT))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.t7=function(){function a(d,e){var f=$CLJS.Fe($CLJS.q6(d,e)),k=$CLJS.Fe($CLJS.D5.g(d,e));f=$CLJS.n($CLJS.n(f)?f:k)?$CLJS.bf.g(f,k):function(){var t=$CLJS.HY(d,e);return $CLJS.A2.j(d,e,t)}();var l=$CLJS.ff(rma,f);f=$CLJS.s7.g(d,e);if($CLJS.td(l))return null;if($CLJS.td(f))return $CLJS.Bf(l);var m=$CLJS.dg.j($CLJS.N,$CLJS.$l(function(t,u){$CLJS.H(u,0,null);$CLJS.H(u,1,null);u=$CLJS.H(u,2,null);u=$CLJS.n3($CLJS.qY(u),l);return $CLJS.n(u)?new $CLJS.P(null,2,5,$CLJS.Q,[u,t],null):null}),f);return $CLJS.Kk.g(function(t){var u=
$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=m.h?m.h(u):m.call(null,u);return $CLJS.n(u)?$CLJS.R.j(t,$CLJS.e2,u):t},$CLJS.Xl($CLJS.Jt,l))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.u7=new $CLJS.h(null,2,[$CLJS.HI,$CLJS.AI,$CLJS.AI,$CLJS.HI],null);