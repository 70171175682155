var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.domain_entities.converters.js");require("./clojure.set.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.options.js");require("./metabase.lib.query.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.humanization.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var doa,eoa,goa,hoa,ioa,joa,loa;doa=function(a,b){return $CLJS.JD(function(c){return function(d,e,f){return $CLJS.n(a.h?a.h(e):a.call(null,e))?c.j?c.j(d,e,f):c.call(null,d,e,f):d}},b)};eoa=function(a,b){return $CLJS.dc($CLJS.ab(function(c,d){return $CLJS.gg.j(c,a.h?a.h(d):a.call(null,d),d)},$CLJS.bc($CLJS.N),b))};goa=function(a){return $CLJS.dg.j($CLJS.ah,$CLJS.Ik.g($CLJS.RG(function(b){return $CLJS.YY(b,a)}),$CLJS.cf.h($CLJS.id)),foa)};
hoa=function(a){return new $CLJS.h(null,3,[$CLJS.jj,$CLJS.kE,$CLJS.S,a,$CLJS.Wi,$CLJS.p.h($CLJS.oH())],null)};
ioa=function(a){a=$CLJS.Ee(a);var b=$CLJS.I.g(a,$CLJS.S);return $CLJS.$k.l($CLJS.G([a,function(){var c=$CLJS.mh(/^#(\d+)(-[a-z0-9-]*)?$/,b);$CLJS.n(c)?($CLJS.H(c,0,null),c=$CLJS.H(c,1,null),c=$CLJS.vY(c)):c=null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.jj,$CLJS.uP,$CLJS.eT,c],null):null}(),function(){var c=0==b.lastIndexOf("snippet:",0)?(0,$CLJS.ma)(b.substring(8)):null;return $CLJS.n(c)?new $CLJS.h(null,2,[$CLJS.jj,$CLJS.DO,$CLJS.pO,c],null):null}(),$CLJS.n($CLJS.WG.h(a))?null:new $CLJS.h(null,
1,[$CLJS.WG,$CLJS.J2.g($CLJS.lE,b)],null)]))};$CLJS.G8=function(a){a=$CLJS.K1($CLJS.P1(a));return $CLJS.n($CLJS.ED(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bv,joa],null)))?$CLJS.$d.g($CLJS.ah,$CLJS.kQ):$CLJS.ah};$CLJS.H8=function(a,b){var c=$CLJS.G8(a);return(0,$CLJS.VY)(a,0,function(d){var e=$CLJS.Pv.g(koa,c);d=$CLJS.O.j($CLJS.Ak,d,e);d=$CLJS.$k.l($CLJS.G([d,$CLJS.Yl(b,c)]));$CLJS.Pv.g(c,$CLJS.eh($CLJS.Wg(b)));$CLJS.HY(a,0);return d})};
joa=new $CLJS.M(null,"native-requires-specified-collection","native-requires-specified-collection",1239149925);loa=new $CLJS.M("metabase.lib.native","native-extras","metabase.lib.native/native-extras",1125545077);var I8,foa,koa;
I8=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tt,$CLJS.Wj,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kt,$CLJS.kE,$CLJS.DO,$CLJS.uP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,$CLJS.Wj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.$G],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WG,new $CLJS.h(null,2,[$CLJS.GE,"display-name",$CLJS.Mt,!0],null),$CLJS.$G],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pO,new $CLJS.h(null,2,[$CLJS.GE,
"snippet-name",$CLJS.Mt,!0],null),$CLJS.$G],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eT,new $CLJS.h(null,2,[$CLJS.GE,"card-id",$CLJS.Mt,!0],null),$CLJS.Bt],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fO,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Gt],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PP,new $CLJS.h(null,2,[$CLJS.GE,"widget-type",$CLJS.Mt,!0],null),$CLJS.Wj],null)],null)],null);
foa=new $CLJS.P(null,3,5,$CLJS.Q,[/\{\{\s*([A-Za-z0-9_\.]+)\s*\}\}/,/\{\{\s*(snippet:\s*[^}]+)\s*\}\}/,/\{\{\s*(#([0-9]*)(-[a-z0-9-]*)?)\s*\}\}/],null);
$CLJS.J8=function(){function a(d,e){d=$CLJS.Fe(goa(d));var f=$CLJS.Fe($CLJS.eh($CLJS.Wg(e)));if($CLJS.n($CLJS.n(d)?d:f)){var k=$CLJS.Pv.g(d,f);d=$CLJS.Pv.g(f,d);if($CLJS.F.l(1,$CLJS.E(k),$CLJS.G([$CLJS.E(d)]))){d=$CLJS.B(d);k=$CLJS.B(k);f=$CLJS.I.g(e,d);var l=$CLJS.F.g($CLJS.WG.h(f),$CLJS.J2.g($CLJS.lE,d))?$CLJS.J2.g($CLJS.lE,k):$CLJS.WG.h(f);f=$CLJS.R.l($CLJS.Ak.l(f,$CLJS.pO,$CLJS.G([$CLJS.eT])),$CLJS.WG,l,$CLJS.G([$CLJS.S,k]));e=$CLJS.R.j($CLJS.Ak.g(e,d),k,f)}else e=$CLJS.$k.l($CLJS.G([doa($CLJS.Ne(d),
e),eoa($CLJS.S,$CLJS.cf.g(hoa,k))]));e=$CLJS.Ou(e,ioa)}else e=$CLJS.N;return e}function b(d){return $CLJS.J8.g?$CLJS.J8.g(d,null):$CLJS.J8.call(null,d,null)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.moa=$CLJS.zE(I8);$CLJS.noa=$CLJS.yE(I8);koa=new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.kQ,null],null),null);
$CLJS.W(loa,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kQ,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.$G],null)],null));
$CLJS.K8=function(){function a(d,e,f,k){var l=$CLJS.J8.h(e);return $CLJS.H8($CLJS.G2.g(d,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.pY(new $CLJS.h(null,4,[$CLJS.Bj,$CLJS.aY,$CLJS.qX,f,$CLJS.GS,l,$CLJS.jS,e],null))],null)),k)}function b(d,e){return $CLJS.K8.A?$CLJS.K8.A(d,e,null,null):$CLJS.K8.call(null,d,e,null,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 2:return b.call(this,d,e);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.A=a;return c}();
$CLJS.L8=function(){function a(d,e,f){$CLJS.HY(d,0);return $CLJS.H8($CLJS.G2.g(e,$CLJS.vN.h(d)),f)}function b(d,e){return $CLJS.L8.j?$CLJS.L8.j(d,e,null):$CLJS.L8.call(null,d,e,null)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();