var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var Cba,Dba,Eba,Fba,Gba,Hba,Iba,pJ,Jba,Kba,Lba,Mba,Nba,Oba,Pba,Qba,rJ,Rba;$CLJS.kJ=new $CLJS.M(null,"supported-field","supported-field",-2061545519);Cba=new $CLJS.M("mbql.clause","distinct","mbql.clause/distinct",843025748);$CLJS.lJ=new $CLJS.M("metabase.lib.schema.aggregation","aggregations","metabase.lib.schema.aggregation/aggregations",-203649172);Dba=new $CLJS.M("mbql.clause","count-where","mbql.clause/count-where",2010666417);
Eba=new $CLJS.M("mbql.clause","sum-where","mbql.clause/sum-where",-1599647617);$CLJS.mJ=new $CLJS.M(null,"requires-column?","requires-column?",1623131448);$CLJS.nJ=new $CLJS.M(null,"column-name","column-name",551523580);Fba=new $CLJS.M("metabase.lib.schema.aggregation","operator","metabase.lib.schema.aggregation/operator",2070038764);$CLJS.oJ=new $CLJS.M(null,"display-info","display-info",-816930907);Gba=new $CLJS.M("mbql.clause","max","mbql.clause/max",1615385533);
Hba=new $CLJS.M("mbql.clause","cum-count","mbql.clause/cum-count",-1408720642);Iba=new $CLJS.M("mbql.clause","median","mbql.clause/median",-1026386338);pJ=new $CLJS.M("metabase.lib.schema.aggregation","percentile.percentile","metabase.lib.schema.aggregation/percentile.percentile",-144637711);Jba=new $CLJS.M("mbql.clause","min","mbql.clause/min",2074448531);Kba=new $CLJS.M("mbql.clause","percentile","mbql.clause/percentile",2064253651);Lba=new $CLJS.M("mbql.clause","stddev","mbql.clause/stddev",659381076);
Mba=new $CLJS.M("mbql.clause","var","mbql.clause/var",809434920);Nba=new $CLJS.M("mbql.clause","share","mbql.clause/share",-37921592);Oba=new $CLJS.M("mbql.clause","count","mbql.clause/count",-454225910);Pba=new $CLJS.M("mbql.clause","cum-sum","mbql.clause/cum-sum",468410018);$CLJS.qJ=new $CLJS.M(null,"driver-feature","driver-feature",1905324248);Qba=new $CLJS.M("mbql.clause","avg","mbql.clause/avg",1826863173);
rJ=new $CLJS.M("metabase.lib.schema.aggregation","aggregation","metabase.lib.schema.aggregation/aggregation",-736862750);$CLJS.sJ=new $CLJS.M("operator","aggregation","operator/aggregation",-1961510924);Rba=new $CLJS.M("mbql.clause","sum","mbql.clause/sum",1691012107);$CLJS.eI($CLJS.TA,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DH],null)],null)],null)],null)]));$CLJS.eI($CLJS.LI,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DH],null)],null)],null)],null)]));
$CLJS.cI($CLJS.EI,$CLJS.G([$CLJS.tu,$CLJS.zG,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));$CLJS.cI($CLJS.qI,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DH],null)],null)]));$CLJS.cI($CLJS.TI,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.CH],null)],null)]));
$CLJS.cI($CLJS.fk,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.KH],null)],null)]));$CLJS.WH($CLJS.fk,$CLJS.JH);$CLJS.cI($CLJS.YI,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));$CLJS.WH($CLJS.YI,$CLJS.JH);$CLJS.cI($CLJS.Rn,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.KH],null)],null)]));$CLJS.WH($CLJS.Rn,$CLJS.JH);
$CLJS.W(pJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vt,new $CLJS.h(null,1,[$CLJS.Du,"valid percentile"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"percentile must be between zero and one"],null),function(a){return 0<=a&&1>=a}],null)],null));$CLJS.cI($CLJS.wI,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pJ],null)]));$CLJS.WH($CLJS.wI,$CLJS.JH);
$CLJS.cI($CLJS.RI,$CLJS.G([$CLJS.tu,$CLJS.zG,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.CH],null)],null)]));$CLJS.cI($CLJS.$I,$CLJS.G([$CLJS.tu,$CLJS.zG,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));$CLJS.cI($CLJS.XI,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));
$CLJS.cI($CLJS.fJ,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));$CLJS.WH($CLJS.XI,$CLJS.JH);$CLJS.WH($CLJS.fJ,$CLJS.JH);$CLJS.cI($CLJS.dJ,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.CH],null)],null)]));$CLJS.WH($CLJS.dJ,$CLJS.JH);
$CLJS.cI($CLJS.rI,$CLJS.G([$CLJS.tu,$CLJS.zG,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));$CLJS.W(rJ,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ot,Qba,Oba,Hba,Dba,Cba,Gba,Iba,Jba,Kba,Nba,Lba,Rba,Pba,Eba,Mba,$CLJS.vl],null));$CLJS.W($CLJS.lJ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,rJ],null)],null));
$CLJS.tJ=new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.h(null,4,[$CLJS.DI,$CLJS.TA,$CLJS.mJ,!1,$CLJS.qJ,$CLJS.hI,$CLJS.oJ,function(){return new $CLJS.h(null,3,[$CLJS.WG,$CLJS.pH("Count of rows"),$CLJS.nJ,$CLJS.pH("Count"),$CLJS.iv,$CLJS.pH("Total number of rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.DI,$CLJS.XI,$CLJS.kJ,$CLJS.ck,$CLJS.mJ,!0,$CLJS.qJ,$CLJS.hI,$CLJS.oJ,function(){return new $CLJS.h(null,3,[$CLJS.WG,$CLJS.pH("Sum of ..."),$CLJS.nJ,$CLJS.pH("Sum"),$CLJS.iv,$CLJS.pH("Sum of all the values of a column.")],
null)}],null),new $CLJS.h(null,5,[$CLJS.DI,$CLJS.EI,$CLJS.kJ,$CLJS.ck,$CLJS.mJ,!0,$CLJS.qJ,$CLJS.hI,$CLJS.oJ,function(){return new $CLJS.h(null,3,[$CLJS.WG,$CLJS.pH("Average of ..."),$CLJS.nJ,$CLJS.pH("Average"),$CLJS.iv,$CLJS.pH("Average of all the values of a column")],null)}],null),new $CLJS.h(null,5,[$CLJS.DI,$CLJS.YI,$CLJS.kJ,$CLJS.ck,$CLJS.mJ,!0,$CLJS.qJ,$CLJS.GI,$CLJS.oJ,function(){return new $CLJS.h(null,3,[$CLJS.WG,$CLJS.pH("Median of ..."),$CLJS.nJ,$CLJS.pH("Median"),$CLJS.iv,$CLJS.pH("Median of all the values of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.DI,$CLJS.qI,$CLJS.kJ,$CLJS.Gt,$CLJS.mJ,!0,$CLJS.qJ,$CLJS.hI,$CLJS.oJ,function(){return new $CLJS.h(null,3,[$CLJS.WG,$CLJS.pH("Number of distinct values of ..."),$CLJS.nJ,$CLJS.pH("Distinct values"),$CLJS.iv,$CLJS.pH("Number of unique values of a column among all the rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.DI,$CLJS.fJ,$CLJS.kJ,$CLJS.ck,$CLJS.mJ,!0,$CLJS.qJ,$CLJS.hI,$CLJS.oJ,function(){return new $CLJS.h(null,3,[$CLJS.WG,$CLJS.pH("Cumulative sum of ..."),
$CLJS.nJ,$CLJS.pH("Sum"),$CLJS.iv,$CLJS.pH("Additive sum of all the values of a column.\ne.x. total revenue over time.")],null)}],null),new $CLJS.h(null,4,[$CLJS.DI,$CLJS.LI,$CLJS.mJ,!1,$CLJS.qJ,$CLJS.hI,$CLJS.oJ,function(){return new $CLJS.h(null,3,[$CLJS.WG,$CLJS.pH("Cumulative count of rows"),$CLJS.nJ,$CLJS.pH("Count"),$CLJS.iv,$CLJS.pH("Additive count of the number of rows.\ne.x. total number of sales over time.")],null)}],null),new $CLJS.h(null,5,[$CLJS.DI,$CLJS.$I,$CLJS.kJ,$CLJS.ck,$CLJS.mJ,
!0,$CLJS.qJ,$CLJS.UI,$CLJS.oJ,function(){return new $CLJS.h(null,3,[$CLJS.WG,$CLJS.pH("Standard deviation of ..."),$CLJS.nJ,$CLJS.pH("SD"),$CLJS.iv,$CLJS.pH("Number which expresses how much the values of a column vary among all rows in the answer.")],null)}],null),new $CLJS.h(null,5,[$CLJS.DI,$CLJS.Rn,$CLJS.kJ,$CLJS.Vi,$CLJS.mJ,!0,$CLJS.qJ,$CLJS.hI,$CLJS.oJ,function(){return new $CLJS.h(null,3,[$CLJS.WG,$CLJS.pH("Minimum of ..."),$CLJS.nJ,$CLJS.pH("Min"),$CLJS.iv,$CLJS.pH("Minimum value of a column")],
null)}],null),new $CLJS.h(null,5,[$CLJS.DI,$CLJS.fk,$CLJS.kJ,$CLJS.Vi,$CLJS.mJ,!0,$CLJS.qJ,$CLJS.hI,$CLJS.oJ,function(){return new $CLJS.h(null,3,[$CLJS.WG,$CLJS.pH("Maximum of ..."),$CLJS.nJ,$CLJS.pH("Max"),$CLJS.iv,$CLJS.pH("Maximum value of a column")],null)}],null)],null);
$CLJS.W(Fba,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.sJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DI,$CLJS.dg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kt],null),$CLJS.cf.h($CLJS.DI),$CLJS.tJ)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kJ,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Ui],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mJ,$CLJS.Ft],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.qJ,$CLJS.Ui],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oJ,$CLJS.nd],null)],null));