var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.drill_thru.column_filter.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.drill_thru.distribution.js");require("./metabase.lib.drill_thru.foreign_key.js");require("./metabase.lib.drill_thru.object_details.js");require("./metabase.lib.drill_thru.pivot.js");require("./metabase.lib.drill_thru.quick_filter.js");require("./metabase.lib.drill_thru.sort.js");require("./metabase.lib.drill_thru.summarize_column.js");require("./metabase.lib.drill_thru.summarize_column_by_time.js");require("./metabase.lib.drill_thru.underlying_records.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.util.malli.js");
'use strict';var v7,Bma,Cma,Dma,Ema,y7,Fma,z7,Gma,Hma,Ima,Jma,Kma,Lma,x7;v7=function(a){var b=$CLJS.J1(a);return $CLJS.n(b)?$CLJS.ff($CLJS.B1,$CLJS.R1(a,b)):$CLJS.zf};$CLJS.w7=function(a,b,c){var d=$CLJS.m3(b,c);return $CLJS.n(d)?d:$CLJS.n($CLJS.n(a)?$CLJS.k3(b):a)?($CLJS.H(b,0,null),d=$CLJS.H(b,1,null),b=$CLJS.H(b,2,null),a=$CLJS.S.h($CLJS.S1(a,b)),$CLJS.n(a)?$CLJS.m3(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,d,a],null),c):null):null};
Bma=function(a,b,c){var d=$CLJS.Ee(c);c=$CLJS.I.g(d,$CLJS.Si);d=$CLJS.I.g(d,$CLJS.Aj);a=(a=$CLJS.l7(a,b))?$CLJS.n(c)?null==d&&!$CLJS.C1(c)&&null!=$CLJS.Wi.h(c):c:a;return $CLJS.n(a)?(a=$CLJS.v1(c)?null:$CLJS.R.j($CLJS.B($CLJS.G3(c)),$CLJS.Bj,$CLJS.mM),new $CLJS.h(null,4,[$CLJS.Bj,$CLJS.f7,$CLJS.jj,$CLJS.L6,$CLJS.Si,c,$CLJS.J6,a],null)):null};
Cma=function(a,b,c){var d=$CLJS.Ee(c);c=$CLJS.I.g(d,$CLJS.Si);d=$CLJS.I.g(d,$CLJS.Aj);a=(a=$CLJS.l7(a,b))?$CLJS.n(c)?null==d&&!$CLJS.B1(c)&&!$CLJS.C1(c)&&!$CLJS.H1(c)&&!$CLJS.z1(c):c:a;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.Bj,$CLJS.f7,$CLJS.jj,$CLJS.Y6,$CLJS.Si,c],null):null};
Dma=function(a,b,c){var d=$CLJS.Ee(c);c=$CLJS.I.g(d,$CLJS.Si);d=$CLJS.I.g(d,$CLJS.Aj);a=(a=$CLJS.l7(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.B1(c)&&$CLJS.A1(c):c:a;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.Bj,$CLJS.f7,$CLJS.jj,$CLJS.N6,$CLJS.gP,$CLJS.pY(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Os,$CLJS.N,$CLJS.qY(c),d],null))],null):null};Ema=function(a,b,c){c=$CLJS.Ee(c);var d=$CLJS.I.g(c,$CLJS.Si),e=$CLJS.I.g(c,$CLJS.Aj),f=$CLJS.l7(a,b);return $CLJS.n(f?$CLJS.n(d)?null!=e:d:f)?x7(a,b,c,1<$CLJS.E(v7(a))):null};
y7=function(a,b,c,d){var e=$CLJS.Ee(c);c=$CLJS.I.g(e,$CLJS.Si);e=$CLJS.I.g(e,$CLJS.Aj);var f=$CLJS.l7(a,b);c=f?$CLJS.n(c)?null!=e&&$CLJS.F.g($CLJS.LY.h(c),$CLJS.UY):c:f;return $CLJS.n(c)?$CLJS.ff(d,$CLJS.t6.g(a,b)):null};
Fma=function(a,b,c){var d=$CLJS.Ee(c),e=$CLJS.I.g(d,$CLJS.Si),f=$CLJS.I.g(d,$CLJS.Aj);if($CLJS.n(function(){var m=$CLJS.l7(a,b);return m?$CLJS.n(e)?null!=f&&$CLJS.F.g($CLJS.LY.h(e),$CLJS.UY)&&0<$CLJS.E($CLJS.C5.g(a,b)):e:m}())){var k=function(){var m=$CLJS.Kk.g(function(t){t=$CLJS.U1.j(a,b,t);return $CLJS.v1(t)?$CLJS.BR:$CLJS.E1(t)?$CLJS.n7:$CLJS.y1(t)?$CLJS.gT:null},$CLJS.s6.g(a,b));return $CLJS.F.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.BR],null),m)?new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.gT,null,
$CLJS.G6,null],null),null):$CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BR,$CLJS.gT],null),m)?new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.gT,null,$CLJS.G6,null],null),null):$CLJS.F.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.n7],null),m)?new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.gT,null,$CLJS.hC,null],null),null):$CLJS.F.g($CLJS.zf,m)?new $CLJS.$g(null,new $CLJS.h(null,3,[$CLJS.gT,null,$CLJS.hC,null,$CLJS.G6,null],null),null):$CLJS.F.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.gT],null),m)?new $CLJS.$g(null,
new $CLJS.h(null,3,[$CLJS.gT,null,$CLJS.hC,null,$CLJS.G6,null],null),null):$CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gT,$CLJS.gT],null),m)?new $CLJS.$g(null,new $CLJS.h(null,3,[$CLJS.gT,null,$CLJS.hC,null,$CLJS.G6,null],null),null):$CLJS.ah}();c=$CLJS.n(k.h?k.h($CLJS.gT):k.call(null,$CLJS.gT))?y7(a,b,d,$CLJS.KN($CLJS.y1,$CLJS.Ne($CLJS.E1))):null;var l=$CLJS.n(k.h?k.h($CLJS.G6):k.call(null,$CLJS.G6))?y7(a,b,d,$CLJS.E1):null;d=$CLJS.n(k.h?k.h($CLJS.hC):k.call(null,$CLJS.hC))?y7(a,b,d,$CLJS.v1):
null;c=$CLJS.$k.l($CLJS.G([$CLJS.z(c)?new $CLJS.h(null,1,[$CLJS.gT,c],null):null,$CLJS.z(l)?new $CLJS.h(null,1,[$CLJS.G6,l],null):null,$CLJS.z(d)?new $CLJS.h(null,1,[$CLJS.hC,d],null):null]));return $CLJS.td(c)?null:new $CLJS.h(null,3,[$CLJS.Bj,$CLJS.f7,$CLJS.jj,$CLJS.e7,$CLJS.E6,c],null)}return null};z7=function(a,b){return $CLJS.pY($CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.N],null),b))};
Gma=function(a,b){var c=$CLJS.qY(a);if($CLJS.C1(a))return $CLJS.zf;if($CLJS.F.g(b,$CLJS.o7))return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.S,"\x3d",$CLJS.gP,z7($CLJS.nI,$CLJS.G([c]))],null),new $CLJS.h(null,2,[$CLJS.S,"≠",$CLJS.gP,z7($CLJS.xI,$CLJS.G([c]))],null)],null);if($CLJS.w1(a)||$CLJS.v1(a))return a=function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.ld(l,
u),x=$CLJS.H(v,0,null);v=$CLJS.H(v,1,null);x=new $CLJS.h(null,2,[$CLJS.S,v,$CLJS.gP,z7(x,$CLJS.G([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),f($CLJS.kc(k))):$CLJS.re($CLJS.te(t),null)}l=$CLJS.B(k);t=$CLJS.H(l,0,null);l=$CLJS.H(l,1,null);return $CLJS.ce(new $CLJS.h(null,2,[$CLJS.S,l,$CLJS.gP,z7(t,$CLJS.G([c,b]))],null),f($CLJS.Kc(k)))}return null}},null,null)},a(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ls,"\x3c"],null),new $CLJS.P(null,2,5,
$CLJS.Q,[$CLJS.Hs,"\x3e"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,"≠"],null)],null));a=function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);a:for(var u=0;;)if(u<m){var v=$CLJS.ld(l,u),x=$CLJS.H(v,0,null);v=$CLJS.H(v,1,null);x=new $CLJS.h(null,2,[$CLJS.S,v,$CLJS.gP,z7(x,$CLJS.G([c,b]))],null);t.add(x);u+=1}else{l=!0;break a}return l?$CLJS.re($CLJS.te(t),
f($CLJS.kc(k))):$CLJS.re($CLJS.te(t),null)}l=$CLJS.B(k);t=$CLJS.H(l,0,null);l=$CLJS.H(l,1,null);return $CLJS.ce(new $CLJS.h(null,2,[$CLJS.S,l,$CLJS.gP,z7(t,$CLJS.G([c,b]))],null),f($CLJS.Kc(k)))}return null}},null,null)};return a(new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,"\x3d"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bJ,"≠"],null)],null))};
Hma=function(a,b,c){var d=$CLJS.Ee(c);c=$CLJS.I.g(d,$CLJS.Si);d=$CLJS.I.g(d,$CLJS.Aj);a=(a=$CLJS.l7(a,b))?$CLJS.n(c)?null!=d&&!$CLJS.B1(c)&&!$CLJS.A1(c):c:a;return $CLJS.n(a)?new $CLJS.h(null,3,[$CLJS.Bj,$CLJS.f7,$CLJS.jj,$CLJS.A6,$CLJS.E0,Gma(c,d)],null):null};
Ima=function(a,b,c){c=$CLJS.Ee(c);var d=$CLJS.I.g(c,$CLJS.Si),e=$CLJS.I.g(c,$CLJS.Aj);if($CLJS.n(function(){var l=$CLJS.l7(a,b);return l?$CLJS.n(d)?(l=null==e)?(l=!$CLJS.C1(d))?(l=$CLJS.LY.h(d),$CLJS.n(l)?l:$CLJS.Wi.h(d)):l:l:d:l}())){var f=$CLJS.eh($CLJS.cf.g($CLJS.Wi,$CLJS.t7.g(a,b))),k=$CLJS.B(function(){return function t(m){return new $CLJS.le(null,function(){for(var u=m;;)if(u=$CLJS.z(u)){if($CLJS.Ad(u)){var v=$CLJS.jc(u),x=$CLJS.E(v),A=$CLJS.oe(x);a:for(var D=0;;)if(D<x){var J=$CLJS.ld(v,D),
K=$CLJS.H(J,0,null);$CLJS.H(J,1,null);J=$CLJS.H(J,2,null);$CLJS.n($CLJS.w7(a,$CLJS.qY(d),new $CLJS.P(null,1,5,$CLJS.Q,[J],null)))&&A.add(K);D+=1}else{v=!0;break a}return v?$CLJS.re($CLJS.te(A),t($CLJS.kc(u))):$CLJS.re($CLJS.te(A),null)}v=$CLJS.B(u);A=$CLJS.H(v,0,null);$CLJS.H(v,1,null);v=$CLJS.H(v,2,null);if($CLJS.n($CLJS.w7(a,$CLJS.qY(d),new $CLJS.P(null,1,5,$CLJS.Q,[v],null))))return $CLJS.ce(A,t($CLJS.Kc(u)));u=$CLJS.Kc(u)}else return null},null,null)}($CLJS.s7.g(a,b))}());return $CLJS.n(function(){var l=
$CLJS.Wi.h(d);return f.h?f.h(l):f.call(null,l)}())?new $CLJS.h(null,4,[$CLJS.Bj,$CLJS.f7,$CLJS.jj,$CLJS.P6,$CLJS.Si,d,$CLJS.R6,function(){switch(k instanceof $CLJS.M?k.T:null){case "asc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.AI],null);case "desc":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.HI],null);default:return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HI,$CLJS.AI],null)}}()],null):null}return null};
Jma=function(a,b,c){var d=$CLJS.Ee(c);c=$CLJS.I.g(d,$CLJS.Si);d=$CLJS.I.g(d,$CLJS.Aj);a=(a=$CLJS.l7(a,b))?$CLJS.n(c)?null==d&&!$CLJS.C1(c):c:a;return $CLJS.n(a)?(a=$CLJS.bf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.qI],null),$CLJS.x1(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.XI,$CLJS.EI],null):null),new $CLJS.h(null,4,[$CLJS.Bj,$CLJS.f7,$CLJS.jj,$CLJS.Q6,$CLJS.Si,c,$CLJS.T6,a],null)):null};
Kma=function(a,b,c){var d=$CLJS.Ee(c);c=$CLJS.I.g(d,$CLJS.Si);d=$CLJS.I.g(d,$CLJS.Aj);var e=$CLJS.l7(a,b);d=e?$CLJS.n(c)?null==d&&!$CLJS.C1(c)&&$CLJS.x1(c):c:e;return $CLJS.n(d)?(a=$CLJS.B($CLJS.ff($CLJS.v1,$CLJS.t6.g(a,b))),$CLJS.n(a)?new $CLJS.h(null,4,[$CLJS.Bj,$CLJS.f7,$CLJS.jj,$CLJS.c7,$CLJS.Si,c,$CLJS.GQ,a],null):null):null};
Lma=function(a,b,c){c=$CLJS.Ee(c);var d=$CLJS.I.g(c,$CLJS.Si),e=$CLJS.I.g(c,$CLJS.PY),f=$CLJS.I.g(c,$CLJS.Aj);return $CLJS.n(function(){var k=$CLJS.l7(a,b);return k?$CLJS.n(d)?(k=null!=f)?(k=$CLJS.Fe(e),$CLJS.n(k)?!$CLJS.C1(d):k):k:d:k}())?new $CLJS.h(null,4,[$CLJS.Bj,$CLJS.f7,$CLJS.jj,$CLJS.Z6,$CLJS.W6,"number"===typeof f?f:2,$CLJS.M0,function(){var k=$CLJS.J1(a);k=null==k?null:$CLJS.Q1(a,k);return null==k?null:$CLJS.V1.j(a,b,k)}()],null):null};
x7=function x7(a,b,c,d){c=$CLJS.Ee(c);var f=$CLJS.I.g(c,$CLJS.Si),k=$CLJS.I.g(c,$CLJS.B6),l=$CLJS.I.g(c,$CLJS.Aj);l=new $CLJS.h(null,5,[$CLJS.Bj,$CLJS.f7,$CLJS.jj,$CLJS.S6,$CLJS.Si,f,$CLJS.C6,l,$CLJS.x6,d],null);if($CLJS.n(function(){var t=$CLJS.B1(f);return t?d:t}()))return $CLJS.R.j(l,$CLJS.jj,$CLJS.S6);if($CLJS.B1(f))return $CLJS.R.j(l,$CLJS.jj,$CLJS.j7);if($CLJS.A1(f))return $CLJS.R.j(l,$CLJS.jj,$CLJS.X6);if($CLJS.n(function(){var t=$CLJS.Sa(d);return t?(t=$CLJS.Fe(k),$CLJS.n(t)?$CLJS.td($CLJS.C5.g(a,
b)):t):t}())){l=v7(a);var m=$CLJS.H(l,0,null);l=$CLJS.Aj.h($CLJS.B($CLJS.ff(function(t){return $CLJS.F.g($CLJS.nJ.h(t),$CLJS.S.h(m))},k)));if($CLJS.n($CLJS.n(l)?$CLJS.Gk.g($CLJS.S.h(f),$CLJS.S.h(m)):l))return c=$CLJS.R.l(c,$CLJS.Si,m,$CLJS.G([$CLJS.Aj,l])),x7.A?x7.A(a,b,c,d):x7.call(null,a,b,c,d)}return null};$CLJS.w2.m(null,$CLJS.f7,function(a,b,c){return $CLJS.w6.j(a,b,c)});$CLJS.Mma=function(){function a(d,e,f){return $CLJS.Wl(function(k){return k.j?k.j(d,e,f):k.call(null,d,e,f)},new $CLJS.P(null,10,5,$CLJS.Q,[Cma,Bma,Dma,Ema,Fma,Hma,Ima,Jma,Kma,Lma],null))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Nma=function(){function a(d,e){return b.j?b.j(d,-1,e):b.call(null,d,-1,e)}var b=null,c=function(){function d(f,k,l,m){var t=null;if(3<arguments.length){t=0;for(var u=Array(arguments.length-3);t<u.length;)u[t]=arguments[t+3],++t;t=new $CLJS.y(u,0,null)}return e.call(this,f,k,l,t)}function e(f,k,l,m){return $CLJS.O.N($CLJS.v6,f,k,l,m)}d.v=3;d.B=function(f){var k=$CLJS.B(f);f=$CLJS.C(f);var l=$CLJS.B(f);f=$CLJS.C(f);var m=$CLJS.B(f);f=$CLJS.Kc(f);return e(k,l,m,f)};d.l=e;return d}();b=function(d,
e,f,k){switch(arguments.length){case 2:return a.call(this,d,e);default:var l=null;if(3<arguments.length){l=0;for(var m=Array(arguments.length-3);l<m.length;)m[l]=arguments[l+3],++l;l=new $CLJS.y(m,0,null)}return c.l(d,e,f,l)}throw Error("Invalid arity: "+arguments.length);};b.v=3;b.B=c.B;b.g=a;b.l=c.l;return b}();