var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.breakout.js");require("./metabase.lib.expression.js");require("./metabase.lib.field.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var O8,poa,P8,Q8,qoa,roa,soa,toa,uoa,voa,woa,R8,xoa,T8,U8,yoa,S8,V8;
O8=function(a){return function(b){var c=$CLJS.Ue($CLJS.ah);return function(){function d(l,m){var t=a.h?a.h(m):a.call(null,m);if($CLJS.Hd($CLJS.q(c),t))return l;c.Sd(null,$CLJS.$d.g(c.Xb(null),t));return b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);
};k.o=f;k.h=e;k.g=d;return k}()}};poa=function(a,b,c){return $CLJS.dg.j($CLJS.zf,$CLJS.RG(function(d){return $CLJS.A2.A(a,b,d,new $CLJS.h(null,2,[$CLJS.l2,c,$CLJS.h2,!1],null))}),$CLJS.CP.h($CLJS.HY(a,b)))};P8=function(a,b,c){return $CLJS.dg.j($CLJS.zf,$CLJS.RG(function(d){return $CLJS.y2.A(a,b,d,c)}),$CLJS.CP.h($CLJS.HY(a,b)))};
Q8=function(a,b){var c=$CLJS.A4(b);if($CLJS.n(c))a=c;else a:{var d=$CLJS.Ee(b);c=$CLJS.I.g(d,$CLJS.HS);d=$CLJS.I.g(d,$CLJS.QY);if($CLJS.n($CLJS.n(c)?d:c)&&(d=$CLJS.u8(a,d),$CLJS.n(d))){d=$CLJS.S.h(d);a=$CLJS.S.h($CLJS.S1(a,c));a=$CLJS.ha.j?$CLJS.ha.j("%s__via__%s",d,a):$CLJS.ha.call(null,"%s__via__%s",d,a);break a}a=null}return $CLJS.n(a)?$CLJS.G4(a,$CLJS.S.h(b)):$CLJS.S.h(b)};
$CLJS.U4=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.VY.l(c,d,$CLJS.R,$CLJS.G([$CLJS.o8,$CLJS.y2.j(c,d,$CLJS.HY(c,d))]))},a,$CLJS.dw(0,$CLJS.GY(a,b)))};
qoa=function(a,b){a=$CLJS.HY(a,b);a=$CLJS.Ee(a);var c=$CLJS.I.g(a,$CLJS.Bj),d=$CLJS.I.g(a,$CLJS.QX);b=$CLJS.o8.h(a);if($CLJS.n(b))return b;var e=$CLJS.qX.h(a);if($CLJS.n(e)&&$CLJS.n(function(){var k=$CLJS.F.g(c,$CLJS.aY);return k?k:d}())){var f=function(){var k=c instanceof $CLJS.M?c.T:null;switch(k){case "mbql.stage/native":return $CLJS.S0;case "mbql.stage/mbql":return $CLJS.N0;default:throw Error(["No matching clause: ",$CLJS.p.h(k)].join(""));}}();return $CLJS.Fe(function(){return function m(l){return new $CLJS.le(null,
function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.jc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.ld(u,A);D=$CLJS.$k.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.A0,$CLJS.S.h(D),$CLJS.J0,$CLJS.S.h(D)],null),D,new $CLJS.h(null,1,[$CLJS.LY,f],null)]));x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.kc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.$k.l($CLJS.G([new $CLJS.h(null,2,[$CLJS.A0,$CLJS.S.h(x),$CLJS.J0,$CLJS.S.h(x)],
null),x,new $CLJS.h(null,1,[$CLJS.LY,f],null)])),m($CLJS.Kc(t)))}return null}},null,null)}($CLJS.EY.h(e))}())}return null};
roa=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.ld(l,v),A=t,D=$CLJS.R,J=D.l,K=x,X=$CLJS.DD($CLJS.A0,$CLJS.S)(x);x=Q8(a,x);x=c.h?c.h(x):c.call(null,x);D=J.call(D,K,$CLJS.LY,$CLJS.RY,$CLJS.G([$CLJS.A0,X,$CLJS.J0,x]));A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.kc(k))):$CLJS.re($CLJS.te(t),
null)}var u=$CLJS.B(k);return $CLJS.ce($CLJS.R.l(u,$CLJS.LY,$CLJS.RY,$CLJS.G([$CLJS.A0,$CLJS.DD($CLJS.A0,$CLJS.S)(u),$CLJS.J0,function(){var v=Q8(a,u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.q6(a,b))}())};
soa=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.ld(l,v),A=t,D=$CLJS.R,J=D.l,K=x,X=$CLJS.S.h(x);x=$CLJS.S.h(x);x=c.h?c.h(x):c.call(null,x);D=J.call(D,K,$CLJS.LY,$CLJS.UY,$CLJS.G([$CLJS.A0,X,$CLJS.J0,x]));A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),f($CLJS.kc(k))):$CLJS.re($CLJS.te(t),null)}var u=
$CLJS.B(k);return $CLJS.ce($CLJS.R.l(u,$CLJS.LY,$CLJS.UY,$CLJS.G([$CLJS.A0,$CLJS.S.h(u),$CLJS.J0,function(){var v=$CLJS.S.h(u);return c.h?c.h(v):c.call(null,v)}()])),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.D5.g(a,b))}())};
toa=function(a,b,c){var d=$CLJS.HY(a,b);if($CLJS.n(d)){d=$CLJS.Ee(d);var e=$CLJS.I.g(d,$CLJS.xH);return $CLJS.Fe(function(){return function l(k){return new $CLJS.le(null,function(){for(;;){var m=$CLJS.z(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.jc(m),u=$CLJS.E(t),v=$CLJS.oe(u);return function(){for(var K=0;;)if(K<u){var X=$CLJS.ld(t,K),Z=$CLJS.H(X,0,null),fa=function(){var xa=Z;xa=xa instanceof $CLJS.M?xa.T:null;switch(xa){case "field":return $CLJS.P0;case "expression":return $CLJS.F0;default:throw Error(["No matching clause: ",
$CLJS.p.h(xa)].join(""));}}(),ra=$CLJS.U1.j(a,b,X);$CLJS.se(v,$CLJS.R.l(ra,$CLJS.LY,fa,$CLJS.G([$CLJS.A0,$CLJS.r2.j(a,b,ra),$CLJS.J0,function(){var xa=Q8(a,ra);return c.h?c.h(xa):c.call(null,xa)}()])));K+=1}else return!0}()?$CLJS.re($CLJS.te(v),l($CLJS.kc(m))):$CLJS.re($CLJS.te(v),null)}var x=$CLJS.B(m),A=$CLJS.H(x,0,null),D=function(){var K=A;K=K instanceof $CLJS.M?K.T:null;switch(K){case "field":return $CLJS.P0;case "expression":return $CLJS.F0;default:throw Error(["No matching clause: ",$CLJS.p.h(K)].join(""));
}}(),J=$CLJS.U1.j(a,b,x);return $CLJS.ce($CLJS.R.l(J,$CLJS.LY,D,$CLJS.G([$CLJS.A0,$CLJS.r2.j(a,b,J),$CLJS.J0,function(){var K=Q8(a,J);return c.h?c.h(K):c.call(null,K)}()])),l($CLJS.Kc(m)))}return null}},null,null)}(e)}())}return null};uoa=function(a,b,c){return $CLJS.Fe($CLJS.dg.j($CLJS.zf,$CLJS.RG(function(d){return d.j?d.j(a,b,c):d.call(null,a,b,c)}),new $CLJS.P(null,2,5,$CLJS.Q,[roa,soa],null)))};
voa=function(a,b,c){var d=$CLJS.j8(a,b);return $CLJS.n(d)?$CLJS.Fe(function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);return function(){for(var A=0;;)if(A<t){var D=$CLJS.ld(m,A);var J=$CLJS.DD($CLJS.J0,$CLJS.A0)(D);J=$CLJS.n(J)?J:$CLJS.r2.j(a,b,D);D=$CLJS.Ak.g($CLJS.$k.l($CLJS.G([D,new $CLJS.h(null,3,[$CLJS.LY,$CLJS.B0,$CLJS.A0,J,$CLJS.J0,c.h?c.h(J):c.call(null,J)],null),$CLJS.n($CLJS.N2.h(D))?
$CLJS.Yl(D,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.N2],null)):null])),$CLJS.q8);u.add(D);A+=1}else return!0}()?$CLJS.re($CLJS.te(u),k($CLJS.kc(l))):$CLJS.re($CLJS.te(u),null)}var v=$CLJS.B(l),x=function(){var A=$CLJS.DD($CLJS.J0,$CLJS.A0)(v);return $CLJS.n(A)?A:$CLJS.r2.j(a,b,v)}();return $CLJS.ce($CLJS.Ak.g($CLJS.$k.l($CLJS.G([v,new $CLJS.h(null,3,[$CLJS.LY,$CLJS.B0,$CLJS.A0,x,$CLJS.J0,c.h?c.h(x):c.call(null,x)],null),$CLJS.n($CLJS.N2.h(v))?$CLJS.Yl(v,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.N2],null)):null])),
$CLJS.q8),k($CLJS.Kc(l)))}return null}},null,null)}($CLJS.y2.j(a,d,$CLJS.HY(a,d)))}()):null};woa=function(a,b,c,d){return $CLJS.n(c)?(c=$CLJS.T1(a,c),$CLJS.n(c)?$CLJS.Fe($CLJS.Kk.g(function(e){return $CLJS.Ak.g(e,$CLJS.K0)},$CLJS.A2.A(a,b,c,new $CLJS.h(null,2,[$CLJS.l2,d,$CLJS.h2,!1],null)))):null):null};
R8=function(a,b,c){return $CLJS.Fe(function(){return function f(e){return new $CLJS.le(null,function(){for(;;){var k=$CLJS.z(e);if(k){if($CLJS.Ad(k)){var l=$CLJS.jc(k),m=$CLJS.E(l),t=$CLJS.oe(m);return function(){for(var v=0;;)if(v<m){var x=$CLJS.ld(l,v),A=t;var D=$CLJS.gE.h(x);var J=$CLJS.R.l,K=x,X=$CLJS.S.h(x);x=$CLJS.S.h(x);x=c.h?c.h(x):c.call(null,x);D=$CLJS.w3(J.call($CLJS.R,K,$CLJS.LY,$CLJS.F0,$CLJS.G([$CLJS.A0,X,$CLJS.J0,x])),$CLJS.Fi,$CLJS.n(D)?D:$CLJS.xj);A.add(D);v+=1}else return!0}()?$CLJS.re($CLJS.te(t),
f($CLJS.kc(k))):$CLJS.re($CLJS.te(t),null)}var u=$CLJS.B(k);return $CLJS.ce(function(){var v=$CLJS.gE.h(u),x=$CLJS.R.l,A=u,D=$CLJS.S.h(u);var J=$CLJS.S.h(u);J=c.h?c.h(J):c.call(null,J);return $CLJS.w3(x.call($CLJS.R,A,$CLJS.LY,$CLJS.F0,$CLJS.G([$CLJS.A0,D,$CLJS.J0,J])),$CLJS.Fi,$CLJS.n(v)?v:$CLJS.xj)}(),f($CLJS.Kc(k)))}return null}},null,null)}($CLJS.Zma.g(a,b))}())};
xoa=function(a,b,c,d){var e=$CLJS.dg.j($CLJS.ah,$CLJS.cf.h($CLJS.QY),c);return $CLJS.dg.j($CLJS.zf,$CLJS.Ik.l($CLJS.ef($CLJS.K0),O8($CLJS.K0),$CLJS.cf.h(function(f){var k=$CLJS.Ee(f);f=$CLJS.I.g(k,$CLJS.Wi);k=$CLJS.I.g(k,$CLJS.K0);return $CLJS.R.j($CLJS.S1(a,k),S8,f)}),$CLJS.G([$CLJS.Jk.h(function(f){return $CLJS.Hd(e,$CLJS.QY.h(f))}),O8($CLJS.QY),$CLJS.RG(function(f){f=$CLJS.Ee(f);var k=$CLJS.I.g(f,$CLJS.QY),l=$CLJS.I.g(f,S8);f=$CLJS.Q1(a,k);return function u(t){return new $CLJS.le(null,function(){for(;;){var v=
$CLJS.z(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.jc(v),A=$CLJS.E(x),D=$CLJS.oe(A);return function(){for(var X=0;;)if(X<A){var Z=$CLJS.ld(x,X),fa=$CLJS.R.l(Z,$CLJS.HS,l,$CLJS.G([$CLJS.LY,$CLJS.H0,$CLJS.A0,$CLJS.S.h(Z)]));Z=D;var ra=$CLJS.R,xa=ra.j,tb=fa;fa=Q8(a,fa);fa=d.h?d.h(fa):d.call(null,fa);ra=xa.call(ra,tb,$CLJS.J0,fa);Z.add(ra);X+=1}else return!0}()?$CLJS.re($CLJS.te(D),u($CLJS.kc(v))):$CLJS.re($CLJS.te(D),null)}var J=$CLJS.B(v),K=$CLJS.R.l(J,$CLJS.HS,l,$CLJS.G([$CLJS.LY,$CLJS.H0,$CLJS.A0,$CLJS.S.h(J)]));
return $CLJS.ce($CLJS.R.j(K,$CLJS.J0,function(){var X=Q8(a,K);return d.h?d.h(X):d.call(null,X)}()),u($CLJS.Kc(v)))}return null}},null,null)}($CLJS.z2.A(a,b,f,new $CLJS.h(null,2,[$CLJS.l2,d,$CLJS.h2,!1],null)))})])),c)};
T8=function(a,b,c){var d=$CLJS.Ee(c),e=$CLJS.I.g(d,$CLJS.l2);return $CLJS.Kk.g(function(f){return $CLJS.Ak.l(f,$CLJS.z4,$CLJS.G([$CLJS.q8,$CLJS.p8,$CLJS.HS]))},function(){var f=voa(a,b,e);if($CLJS.n(f))return f;f=$CLJS.HY(a,b);f=$CLJS.Ee(f);var k=$CLJS.I.g(f,$CLJS.eR),l=$CLJS.I.g(f,$CLJS.QX),m=$CLJS.n(k)?function(){var t=$CLJS.Q1(a,k);return $CLJS.A2.A(a,b,t,d)}():null;if($CLJS.n(m))return m;l=$CLJS.n(l)?woa(a,b,l,e):null;return $CLJS.n(l)?l:function v(u){return new $CLJS.le(null,function(){for(;;){var x=
$CLJS.z(u);if(x){if($CLJS.Ad(x)){var A=$CLJS.jc(x),D=$CLJS.E(A),J=$CLJS.oe(D);return function(){for(var X=0;;)if(X<D){var Z=$CLJS.ld(A,X),fa=J,ra=$CLJS.R,xa=ra.l,tb=Z,Rb=$CLJS.S.h(Z);Z=$CLJS.S.h(Z);Z=e.h?e.h(Z):e.call(null,Z);ra=xa.call(ra,tb,$CLJS.LY,$CLJS.S0,$CLJS.G([$CLJS.A0,Rb,$CLJS.J0,Z]));fa.add(ra);X+=1}else return!0}()?$CLJS.re($CLJS.te(J),v($CLJS.kc(x))):$CLJS.re($CLJS.te(J),null)}var K=$CLJS.B(x);return $CLJS.ce($CLJS.R.l(K,$CLJS.LY,$CLJS.S0,$CLJS.G([$CLJS.A0,$CLJS.S.h(K),$CLJS.J0,function(){var X=
$CLJS.S.h(K);return e.h?e.h(X):e.call(null,X)}()])),v($CLJS.Kc(x)))}return null}},null,null)}($CLJS.EY.h($CLJS.qX.h(f)))}())};
U8=function(a,b){var c=$CLJS.H(a,0,null);$CLJS.H(a,1,null);var d=$CLJS.H(a,2,null);switch(c instanceof $CLJS.M?c.T:null){case "field":if("number"===typeof d||"string"===typeof d)return $CLJS.F.g(d,$CLJS.Wi.h(b));throw $CLJS.hi("unknown type of :field ref in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.bU,a,$CLJS.Si,b],null));case "expression":return $CLJS.F.g(d,$CLJS.S.h(b));default:throw $CLJS.hi("unknown clause in lib.stage/ref-to?",new $CLJS.h(null,2,[$CLJS.bU,a,$CLJS.Si,b],null));}};
yoa=function(a,b,c){var d=$CLJS.GQ.h($CLJS.HY(a,b));return $CLJS.n(d)?function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){var m=l;if($CLJS.Ad(m)){var t=$CLJS.jc(m),u=$CLJS.E(t),v=$CLJS.oe(u);return function(){for(var A=0;;)if(A<u){var D=$CLJS.ld(t,A);$CLJS.se(v,function(){var J=$CLJS.WJ(function(Z,fa){return function(ra){return U8(ra,fa)}}(A,D,t,u,v,m,l,d,d),d);if($CLJS.n(J)){var K=$CLJS.k8(J);J=$CLJS.x4(J);J=$CLJS.Ee(J);J=$CLJS.I.g(J,$CLJS.ri);var X=D;K=$CLJS.n(K)?$CLJS.i6(X,
K):X;return $CLJS.n(J)?$CLJS.z3(K,J):K}return D}());A+=1}else return!0}()?$CLJS.re($CLJS.te(v),k($CLJS.kc(m))):$CLJS.re($CLJS.te(v),null)}var x=$CLJS.B(m);return $CLJS.ce(function(){var A=$CLJS.WJ(function(K){return function(X){return U8(X,K)}}(x,m,l,d,d),d);if($CLJS.n(A)){var D=$CLJS.k8(A);A=$CLJS.x4(A);A=$CLJS.Ee(A);A=$CLJS.I.g(A,$CLJS.ri);var J=x;D=$CLJS.n(D)?$CLJS.i6(J,D):J;return $CLJS.n(A)?$CLJS.z3(D,A):D}return x}(),k($CLJS.Kc(m)))}return null}},null,null)}(c):c};
S8=new $CLJS.M("metabase.lib.stage","source-field-id","metabase.lib.stage/source-field-id",-962431705);V8=new $CLJS.M("metabase.lib.stage","stage","metabase.lib.stage/stage",1448689281);$CLJS.Vka={};$CLJS.WH($CLJS.VX,V8);$CLJS.WH($CLJS.aY,V8);$CLJS.E2.m(null,$CLJS.VX,function(a){return $CLJS.D2(a,new $CLJS.h(null,2,[$CLJS.zI,$CLJS.Pe($CLJS.Kk,$CLJS.E2),$CLJS.hY,$CLJS.Pe($CLJS.Kk,$CLJS.E2)],null))});$CLJS.v2.m(null,V8,function(){throw $CLJS.hi("You can't calculate a metadata map for a stage! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});
$CLJS.z2.m(null,V8,function(a,b,c,d){var e=$CLJS.Ee(d);c=$CLJS.I.g(e,$CLJS.l2);d=$CLJS.I.g(e,$CLJS.h2);a=$CLJS.U4(a,b);e=$CLJS.Ee(e);var f=$CLJS.I.g(e,$CLJS.l2),k=$CLJS.I.g(e,$CLJS.j2),l=$CLJS.I.g(e,$CLJS.o2);e=$CLJS.bf.l(T8(a,b,e),$CLJS.n(l)?R8(a,b,f):null,$CLJS.G([$CLJS.n(k)?poa(a,b,f):null]));return yoa(a,b,$CLJS.bf.g(e,$CLJS.n(d)?xoa(a,b,e,c):null))});
$CLJS.x2.m(null,V8,function(a,b,c,d){c=$CLJS.Ee(d);d=$CLJS.I.g(c,$CLJS.l2);var e=qoa(a,b);if($CLJS.n(e))return e;a=$CLJS.U4(a,b);e=uoa(a,b,d);var f=toa(a,b,d);return $CLJS.n(e)?$CLJS.dg.g(e,f):$CLJS.n(f)?($CLJS.lh(f),$CLJS.dg.j($CLJS.zf,O8(function(k){return $CLJS.Ak.l(k,$CLJS.W0,$CLJS.G([$CLJS.LY,$CLJS.A3,$CLJS.J0]))}),$CLJS.bf.g(f,P8(a,b,c)))):$CLJS.bf.l(T8(a,b,new $CLJS.h(null,2,[$CLJS.h2,!1,$CLJS.l2,d],null)),R8(a,b,d),$CLJS.G([P8(a,b,c)]))});$CLJS.p2.m(null,$CLJS.aY,function(){return $CLJS.pH("Native query")});
var W8=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eR,$CLJS.QX,$CLJS.CP],null),X8=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zI,$CLJS.GQ,$CLJS.hY,$CLJS.RT,$CLJS.nU],null);
$CLJS.p2.m(null,$CLJS.VX,function(a,b,c,d){var e=$CLJS.U4(a,b);a=$CLJS.Fe(function(){var f=$CLJS.dg.j($CLJS.N,$CLJS.Ik.g($CLJS.eF,$CLJS.cf.h(function(l){return new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.mja.j(e,b,l)],null)})),new $CLJS.P(null,2,5,$CLJS.Q,[W8,X8],null)),k=$CLJS.Pu(" + ",$CLJS.Jk.g($CLJS.ID,$CLJS.cf.g(f,W8)));f=$CLJS.cf.g(f,X8);return $CLJS.Pu(", ",$CLJS.Jk.g($CLJS.ID,$CLJS.ce(k,f)))}());if($CLJS.n(a))return a;a=$CLJS.j8(e,b);return $CLJS.n(a)?$CLJS.V1.A(e,a,$CLJS.HY(e,a),d):null});