var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var uba,YG,aH,kH;$CLJS.RG=function(a){return $CLJS.Ik.g($CLJS.cf.h(a),$CLJS.eF)};$CLJS.SG=function(a,b){a=$CLJS.tv($CLJS.HD(a,/''/,"'"),/\{\d+\}/);return $CLJS.O.j($CLJS.Iu.t,$CLJS.cl(a),$CLJS.cl(b))};$CLJS.TG=new $CLJS.M("metabase.lib.schema.common","positive-int","metabase.lib.schema.common/positive-int",-733946956);$CLJS.UG=new $CLJS.M(null,"num-bins","num-bins",847672055);$CLJS.VG=new $CLJS.M(null,"mbql","mbql",69346710);$CLJS.WG=new $CLJS.M(null,"display-name","display-name",694513143);
uba=new $CLJS.M("metabase.lib.schema.common","external-op","metabase.lib.schema.common/external-op",1918684841);$CLJS.XG=new $CLJS.M("metabase.lib.schema.common","semantic-or-relation-type","metabase.lib.schema.common/semantic-or-relation-type",-158604052);YG=new $CLJS.M("metabase.lib.schema.common","relation-type","metabase.lib.schema.common/relation-type",1412287664);$CLJS.ZG=new $CLJS.M("lib","external-op","lib/external-op",-1470923877);
$CLJS.$G=new $CLJS.M("metabase.lib.schema.common","non-blank-string","metabase.lib.schema.common/non-blank-string",-2032366619);aH=new $CLJS.M("metabase.lib.schema.common","semantic-type","metabase.lib.schema.common/semantic-type",-1623124128);$CLJS.bH=new $CLJS.M(null,"database-type","database-type",-426840562);$CLJS.cH=new $CLJS.M(null,"operator","operator",-1860875338);
$CLJS.dH=new $CLJS.M("metabase.lib.schema.common","int-greater-than-or-equal-to-zero","metabase.lib.schema.common/int-greater-than-or-equal-to-zero",-1646435681);$CLJS.eH=new $CLJS.M("metabase.lib.schema.common","options","metabase.lib.schema.common/options",-1078359294);$CLJS.fH=new $CLJS.M("lib","uuid","lib/uuid",-2145250720);$CLJS.gH=new $CLJS.M(null,"x","x",2099068185);$CLJS.hH=new $CLJS.M("metabase.lib.schema.common","base-type","metabase.lib.schema.common/base-type",999524206);
$CLJS.iH=new $CLJS.M(null,"bin-width","bin-width",1377922579);$CLJS.jH=new $CLJS.M(null,"strategy","strategy",-1471631918);kH=new $CLJS.M("metabase.lib.schema.common","uuid","metabase.lib.schema.common/uuid",1172938098);$CLJS.W($CLJS.$G,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wj,new $CLJS.h(null,1,[$CLJS.Rn,1],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"non-blank string"],null),$CLJS.Ne($CLJS.ID)],null)],null));$CLJS.W($CLJS.dH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bt,new $CLJS.h(null,1,[$CLJS.Rn,0],null)],null));$CLJS.W($CLJS.TG,$CLJS.Il);$CLJS.W(kH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wj,new $CLJS.h(null,2,[$CLJS.Rn,36,$CLJS.fk,36],null)],null));
$CLJS.W(aH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.Du,"valid semantic type",$CLJS.Fu,function(a){a=$CLJS.Ee(a);a=$CLJS.I.g(a,$CLJS.Aj);return["Not a valid semantic type: ",$CLJS.Lh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.fF(a,$CLJS.pj)}],null));
$CLJS.W(YG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.Du,"valid relation type",$CLJS.Fu,function(a){a=$CLJS.Ee(a);a=$CLJS.I.g(a,$CLJS.Aj);return["Not a valid relation type: ",$CLJS.Lh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.fF(a,$CLJS.mi)}],null));$CLJS.W($CLJS.XG,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,aH],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,YG],null)],null));
$CLJS.W($CLJS.hH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.Du,"valid base type",$CLJS.Fu,function(a){a=$CLJS.Ee(a);a=$CLJS.I.g(a,$CLJS.Aj);return["Not a valid base type: ",$CLJS.Lh.l($CLJS.G([a]))].join("")}],null),function(a){return $CLJS.fF(a,$CLJS.xj)}],null));
$CLJS.W($CLJS.eH,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fH,kH],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gE,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.hH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Fi,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.hH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Sj,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,
$CLJS.XG],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bH,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.$G],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.$G],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WG,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.$G],null)],null)],null));
$CLJS.W(uba,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.ZG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.Wj,$CLJS.Ui],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$t,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,$CLJS.Gt],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.eH],null)],null));