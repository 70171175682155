var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var Pna,l8,m8,Qna,Rna,Sna,Tna,r8,s8,v8,w8,Una,y8,z8,Vna,Wna;$CLJS.j8=function(a,b){a=$CLJS.GY(a,b);return 0<a?a-1:null};Pna=function(a,b){b=$CLJS.T1(a,b);return $CLJS.n(b)?$CLJS.M2(a,b):null};$CLJS.k8=function(a){return $CLJS.n6.h(a)};l8=function(a,b){return $CLJS.F.g($CLJS.VG.h(a),$CLJS.Yl(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jH,$CLJS.UG,$CLJS.iH],null)))};
m8=function(a){var b=new $CLJS.h(null,3,[$CLJS.fH,$CLJS.p.h($CLJS.oH()),$CLJS.gE,$CLJS.gE.h(a),$CLJS.Fi,$CLJS.DD($CLJS.Fi,$CLJS.gE)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dE,b,$CLJS.S.h(a)],null)};Qna=function(a){return $CLJS.D2(a,new $CLJS.h(null,1,[$CLJS.jH,$CLJS.yh],null))};
Rna=function(a,b,c){var d=$CLJS.$k.l;b=$CLJS.Sa($CLJS.j8(a,b))?function(){var f=$CLJS.QX.h($CLJS.B($CLJS.vN.h(a)));return $CLJS.n(f)?(f=Pna(a,f),$CLJS.n(f)?$CLJS.WJ(function(k){return $CLJS.F.g($CLJS.Wi.h(k),c)},f):null):null}():null;try{var e=$CLJS.S1(a,c)}catch(f){e=null}return d.call($CLJS.$k,$CLJS.G([b,e]))};
Sna=function(a,b){var c=$CLJS.WJ(function(d){return $CLJS.F.g($CLJS.J0.h(d),a)},b);if($CLJS.n(c))return c;c=$CLJS.WJ(function(d){return $CLJS.F.g($CLJS.S.h(d),a)},b);if($CLJS.n(c))return c;c=$CLJS.ND($CLJS.gC);$CLJS.n($CLJS.MD("metabase.lib.field",c))&&(b=$CLJS.SG("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.G([$CLJS.Lh.l($CLJS.G([a])),$CLJS.Lh.l($CLJS.G([$CLJS.Kk.g($CLJS.J0,b)]))])),b instanceof Error?$CLJS.LD("metabase.lib.field",c,$CLJS.vy(),b):$CLJS.LD("metabase.lib.field",
c,$CLJS.vy.l($CLJS.G([b])),null));return null};
Tna=function(a,b,c){if($CLJS.n(n8))return null;var d=n8;n8=!0;try{var e=$CLJS.j8(a,b),f=$CLJS.n(e)?$CLJS.HY(a,e):$CLJS.HY(a,b),k=function(){var m=$CLJS.o8.h(f);if($CLJS.n(m))return m;m=$CLJS.ED(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qX,$CLJS.EY],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.QX.h(f);if($CLJS.n(u))return u;u=$CLJS.eR.h(f);if($CLJS.n(u))return u;u=$CLJS.$R.h(f);return $CLJS.n(u)?u:$CLJS.xH.h(f)}())?$CLJS.A2.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.ND($CLJS.gC);if($CLJS.n($CLJS.MD("metabase.lib.field",
m))){var t=$CLJS.SG("Cannot resolve column {0}: stage has no metadata",$CLJS.G([$CLJS.Lh.l($CLJS.G([c]))]));return t instanceof Error?$CLJS.LD("metabase.lib.field",m,$CLJS.vy(),t):$CLJS.LD("metabase.lib.field",m,$CLJS.vy.l($CLJS.G([t])),null)}return null}(),l=function(){var m=$CLJS.z(k);return m?Sna(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.B4($CLJS.Ak.l(l,$CLJS.Wi,$CLJS.G([$CLJS.QY,$CLJS.p8,$CLJS.q8])),null),$CLJS.S,function(){var m=$CLJS.J0.h(l);return $CLJS.n(m)?m:$CLJS.S.h(l)}()),
$CLJS.LY,$CLJS.B0):l:null}finally{n8=d}};
r8=function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.Ee(d);d=$CLJS.I.g(e,$CLJS.tR);var f=$CLJS.H(c,2,null);c=$CLJS.$k.l($CLJS.G([function(){var k=$CLJS.gE.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.gE,k],null):null}(),function(){var k=$CLJS.DD($CLJS.Fi,$CLJS.gE)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Fi,k],null):null}(),function(){var k=$CLJS.xT.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.p8,k],null):null}(),function(){var k=$CLJS.QI.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.q8,k],null):null}(),$CLJS.Fd(f)?function(){var k=Rna(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.rY,$CLJS.S,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.rY,$CLJS.S,f],null):function(){var k=Tna(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.rY,$CLJS.S,f],null)}()]));return $CLJS.n(d)?$CLJS.B4(c,d):c};s8=function(a){a=$CLJS.Ee(a);var b=$CLJS.I.g(a,$CLJS.q8);return $CLJS.n($CLJS.n(b)?$CLJS.Hd($CLJS.TJ,b):b)?$CLJS.tj:$CLJS.DD($CLJS.Fi,$CLJS.gE)(a)};
$CLJS.t8=function(a,b,c,d){$CLJS.H(d,0,null);var e=$CLJS.H(d,1,null),f=$CLJS.Ee(e),k=$CLJS.I.g(f,$CLJS.fH),l=$CLJS.I.g(f,$CLJS.gE),m=$CLJS.I.g(f,$CLJS.xT),t=$CLJS.I.g(f,$CLJS.Fi);e=$CLJS.I.g(f,$CLJS.tR);var u=$CLJS.I.g(f,$CLJS.DP),v=$CLJS.I.g(f,$CLJS.QI),x=$CLJS.$k.l;k=new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.rY,$CLJS.A3,k],null);f=$CLJS.WG.h(f);a=$CLJS.n(f)?f:$CLJS.V1.j(a,b,d);c=x.call($CLJS.$k,$CLJS.G([k,c,new $CLJS.h(null,1,[$CLJS.WG,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Fi,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.gE,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.q8,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.p8,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.HS,u):m;return $CLJS.n(e)?$CLJS.B4(u,e):u};$CLJS.u8=function(a,b){return"string"===typeof b?$CLJS.T1(a,$CLJS.IY(b)):$CLJS.Fd(b)?$CLJS.Q1(a,b):null};v8=function(a,b,c){return $CLJS.n($CLJS.Me(function(d){return $CLJS.F.g($CLJS.ri.h(d),c)},a))?$CLJS.Kk.g(function(d){var e=$CLJS.Ak.g(d,b);return $CLJS.F.g($CLJS.ri.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
w8=function(a){var b=$CLJS.n($CLJS.N2.h(a))?null:function(){var d=$CLJS.LY.h(a),e=new $CLJS.$g(null,new $CLJS.h(null,3,[$CLJS.N0,null,$CLJS.S0,null,$CLJS.B0,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.$k.l($CLJS.G([new $CLJS.h(null,3,[$CLJS.fH,$CLJS.p.h($CLJS.oH()),$CLJS.gE,$CLJS.gE.h(a),$CLJS.Fi,s8(a)],null),function(){var d=$CLJS.A4(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.tR,d],null):null}(),function(){var d=$CLJS.q8.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.QI,d],
null):null}(),function(){var d=$CLJS.p8.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.xT,d],null):null}(),function(){var d=$CLJS.HS.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.DP,d],null):null}()]));b=($CLJS.n(b)?$CLJS.DD($CLJS.J0,$CLJS.S):$CLJS.DD($CLJS.Wi,$CLJS.S))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,c,b],null)};
Una=function(a,b){return function e(d){return new $CLJS.le(null,function(){for(var f=d;;)if(f=$CLJS.z(f)){if($CLJS.Ad(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.ld(k,t);$CLJS.F.g($CLJS.LY.h(u),$CLJS.F0)&&(u=$CLJS.qY(u),m.add(u));t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.kc(f))):$CLJS.re($CLJS.te(m),null)}m=$CLJS.B(f);if($CLJS.F.g($CLJS.LY.h(m),$CLJS.F0))return $CLJS.ce($CLJS.qY(m),e($CLJS.Kc(f)));f=$CLJS.Kc(f)}else return null},null,null)}($CLJS.A2.A(a,
b,$CLJS.HY(a,b),new $CLJS.h(null,3,[$CLJS.j2,!1,$CLJS.o2,!0,$CLJS.h2,!1],null)))};$CLJS.q8=new $CLJS.M("metabase.lib.field","temporal-unit","metabase.lib.field/temporal-unit",602312727);$CLJS.x8=new $CLJS.M(null,"stage","stage",1843544772);y8=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.o8=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);
z8=new $CLJS.M("metabase.lib.field","original-effective-type","metabase.lib.field/original-effective-type",34164372);Vna=new $CLJS.M(null,"earliest","earliest",-1928154382);$CLJS.p8=new $CLJS.M("metabase.lib.field","binning","metabase.lib.field/binning",-222751823);Wna=new $CLJS.M(null,"latest","latest",24323665);var Zna;$CLJS.E2.m(null,$CLJS.KI,function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yh.h(b),$CLJS.D2(c,new $CLJS.h(null,2,[$CLJS.QI,$CLJS.yh,$CLJS.xT,Qna],null)),a],null)});var n8=!1,A8=function A8(a,b){var d=$CLJS.S1(a,y8.h(b));a=$CLJS.n(y8.h(d))?A8.g?A8.g(a,d):A8.call(null,a,d):d;a=$CLJS.Ee(a);var e=$CLJS.I.g(a,$CLJS.S);return $CLJS.Lk.j(b,$CLJS.S,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.t2.m(null,$CLJS.rY,function(a,b,c){return s8(c)});$CLJS.t2.m(null,$CLJS.KI,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null);d=$CLJS.Ee(d);d=$CLJS.I.g(d,$CLJS.QI);$CLJS.H(c,2,null);c=r8(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.q8,d):c;return $CLJS.u2.j(a,b,c)});$CLJS.v2.m(null,$CLJS.rY,function(a,b,c){a=$CLJS.Ee(c);b=$CLJS.I.g(a,$CLJS.S);return $CLJS.R.j(a,$CLJS.S,b)});$CLJS.v2.m(null,$CLJS.KI,function(a,b,c){var d=r8(a,b,c);b=$CLJS.t8(a,b,d,c);return $CLJS.n(y8.h(b))?A8(a,b):b});
$CLJS.p2.m(null,$CLJS.rY,function(a,b,c,d){c=$CLJS.Ee(c);var e=$CLJS.I.g(c,$CLJS.WG),f=$CLJS.I.g(c,$CLJS.S),k=$CLJS.I.g(c,$CLJS.ri),l=$CLJS.I.g(c,$CLJS.p8),m=$CLJS.I.g(c,$CLJS.W0),t=$CLJS.I.g(c,$CLJS.HS),u=$CLJS.I.g(c,$CLJS.QY);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.J2.g($CLJS.lE,f):$CLJS.p.h(f);$CLJS.F.g(d,$CLJS.W1)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.S1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.HD($CLJS.WG.h($CLJS.Y1.j(a,b,t)),$CLJS.WY,"")):(u=$CLJS.u8(a,u),a=$CLJS.V1.A(a,b,u,d))):a=null,m=
$CLJS.n(a)?a:$CLJS.n(m)?m:$CLJS.A4(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.QD($CLJS.HD($CLJS.fh(k),"-"," ")),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.l6(l,c),$CLJS.ha.j?$CLJS.ha.j("%s: %s",m,c):$CLJS.ha.call(null,"%s: %s",m,c)):m});
$CLJS.p2.m(null,$CLJS.KI,function(a,b,c,d){$CLJS.H(c,0,null);var e=$CLJS.H(c,1,null),f=$CLJS.Ee(e);e=$CLJS.I.g(f,$CLJS.xT);var k=$CLJS.I.g(f,$CLJS.tR),l=$CLJS.I.g(f,$CLJS.QI);f=$CLJS.I.g(f,$CLJS.DP);$CLJS.H(c,2,null);c=r8(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.W0,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ri,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.p8,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.HS,f):e;return $CLJS.n(e)?$CLJS.V1.A(a,b,e,d):$CLJS.pH("[Unknown Field]")});
$CLJS.q2.m(null,$CLJS.rY,function(a,b,c){a=$CLJS.Ee(c);return $CLJS.I.g(a,$CLJS.S)});$CLJS.q2.m(null,$CLJS.KI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=r8(a,b,c);return $CLJS.n(c)?$CLJS.r2.j(a,b,c):"unknown_field"});
$CLJS.w2.m(null,$CLJS.rY,function(a,b,c){return $CLJS.$k.l($CLJS.G([function(){var d=$CLJS.ai($CLJS.w2,$CLJS.ci);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.F.g($CLJS.LY.h(c),$CLJS.N0)?function(){var d=$CLJS.U0.h(c);return $CLJS.n(d)?(d=$CLJS.T1(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.SY,new $CLJS.h(null,2,[$CLJS.S,$CLJS.S.h(d),$CLJS.WG,$CLJS.S.h(d)],null)],null):null):null}():null]))});$CLJS.C3.m(null,$CLJS.KI,function(a){$CLJS.H(a,0,null);var b=$CLJS.H(a,1,null);$CLJS.H(a,2,null);return $CLJS.QI.h(b)});
$CLJS.C3.m(null,$CLJS.rY,function(a){return $CLJS.q8.h(a)});$CLJS.y3.m(null,$CLJS.KI,function(a,b){$CLJS.H(a,0,null);var c=$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);if($CLJS.n(b)){var d=$CLJS.Hd($CLJS.TJ,b),e=$CLJS.dF(z8,$CLJS.Fi,$CLJS.gE)(c);c=$CLJS.R.l(c,$CLJS.QI,b,$CLJS.G([$CLJS.Fi,d?$CLJS.tj:e,z8,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,c,a],null)}b=z8.h(c);c=$CLJS.n(b)?$CLJS.Ak.g($CLJS.R.j(c,$CLJS.Fi,b),z8):c;c=$CLJS.Ak.g(c,$CLJS.QI);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,c,a],null)});
$CLJS.y3.m(null,$CLJS.rY,function(a,b){return $CLJS.n(b)?$CLJS.R.j(a,$CLJS.q8,b):$CLJS.Ak.g(a,$CLJS.q8)});$CLJS.E3.m(null,$CLJS.KI,function(a,b,c){return $CLJS.F3.j(a,b,r8(a,b,c))});
$CLJS.E3.m(null,$CLJS.rY,function(a,b,c){if($CLJS.Gk.g($CLJS.LY.h(c),$CLJS.F0)){a=$CLJS.DD($CLJS.Fi,$CLJS.gE)(c);b=null==c?null:$CLJS.RP.h(c);if(null==b)var d=null;else try{var e=$CLJS.dG.h($CLJS.jj.h(b));if($CLJS.n(e)){var f=$CLJS.Ee(e),k=$CLJS.I.g(f,Vna),l=$CLJS.I.g(f,Wna),m=$CLJS.Ona.l($CLJS.G([$CLJS.i8.h(k),$CLJS.i8.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Is.g?$CLJS.Is.g(1,m):$CLJS.Is.call(null,1,m))?$CLJS.Ev:$CLJS.n($CLJS.Is.g?$CLJS.Is.g(31,m):$CLJS.Is.call(null,31,m))?$CLJS.Gv:$CLJS.n($CLJS.Is.g?
$CLJS.Is.g(365,m):$CLJS.Is.call(null,365,m))?$CLJS.lj:$CLJS.uj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.fF(a,$CLJS.dG)?$CLJS.dka:$CLJS.fF(a,$CLJS.GF)?$CLJS.cka:$CLJS.fF(a,$CLJS.CG)?$CLJS.bka:$CLJS.zf;d=$CLJS.n(d)?v8(e,$CLJS.ci,d):e;return $CLJS.n($CLJS.q8.h(c))?v8(d,$CLJS.c2,$CLJS.q8.h(c)):d}return $CLJS.zf});
$CLJS.n6.m(null,$CLJS.KI,function(a){var b=null==a?null:$CLJS.mH(a);b=null==b?null:$CLJS.xT.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Bj,$CLJS.m6,$CLJS.G([$CLJS.C0,function(c,d){return r8(c,d,a)}]))});$CLJS.n6.m(null,$CLJS.rY,function(a){var b=null==a?null:$CLJS.p8.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Bj,$CLJS.m6,$CLJS.G([$CLJS.C0,$CLJS.Oe(a)]))});$CLJS.h6.m(null,$CLJS.KI,function(a,b){return $CLJS.nH(a,$CLJS.lH,$CLJS.G([$CLJS.xT,b]))});
$CLJS.h6.m(null,$CLJS.rY,function(a,b){return $CLJS.lH(a,$CLJS.p8,b)});$CLJS.o6.m(null,$CLJS.KI,function(a,b,c){return $CLJS.p6.j(a,b,r8(a,b,c))});
$CLJS.o6.m(null,$CLJS.rY,function(a,b,c){b=$CLJS.Ee(c);c=$CLJS.I.g(b,$CLJS.Fi);var d=$CLJS.I.g(b,$CLJS.RP),e=$CLJS.I.g(b,$CLJS.Sj);if($CLJS.Gk.g($CLJS.LY.h(b),$CLJS.F0)){var f=function(){var m=null==a?null:$CLJS.K1($CLJS.P1(a));m=null==m?null:$CLJS.bv.h(m);return null==m?null:$CLJS.Hd(m,$CLJS.xT)}(),k=$CLJS.ED(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,$CLJS.Mj],null)),l=$CLJS.k8(b);return function u(t){return new $CLJS.le(null,function(){for(;;){var v=$CLJS.z(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.jc(v),
A=$CLJS.E(x),D=$CLJS.oe(A);return function(){for(var K=0;;)if(K<A){var X=$CLJS.ld(x,K),Z=D,fa=X;X=l8(X,l)?$CLJS.R.j(fa,$CLJS.c2,!0):fa;Z.add(X);K+=1}else return!0}()?$CLJS.re($CLJS.te(D),u($CLJS.kc(v))):$CLJS.re($CLJS.te(D),null)}var J=$CLJS.B(v);return $CLJS.ce(function(){var K=J;return l8(J,l)?$CLJS.R.j(K,$CLJS.c2,!0):K}(),u($CLJS.Kc(v)))}return null}},null,null)}($CLJS.Sa(function(){if($CLJS.n(f)){var t=$CLJS.Rn.h(k);return $CLJS.n(t)?$CLJS.fk.h(k):t}return f}())?null:$CLJS.fF(e,$CLJS.vj)?$CLJS.q($CLJS.cma):
$CLJS.fF(c,$CLJS.Mj)&&!$CLJS.fF(e,$CLJS.mi)?$CLJS.q($CLJS.bma):null)}return $CLJS.zf});$CLJS.nY.m(null,$CLJS.KI,function(a){return a});
$CLJS.nY.m(null,$CLJS.rY,function(a){a=$CLJS.Ee(a);var b=$CLJS.I.g(a,$CLJS.LY);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.fH,$CLJS.p.h($CLJS.oH()),$CLJS.Fi,$CLJS.DD($CLJS.Fi,$CLJS.gE)(a)],null),a=$CLJS.A3.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zI,b,a],null);case "source/expressions":return m8(a);case "source/breakouts":return $CLJS.Hd(a,$CLJS.FH)?m8(a):w8(a);default:return w8(a)}});
$CLJS.Xna=function(){function a(e,f,k){var l=$CLJS.Fe($CLJS.Kk.g($CLJS.qY,k));k=function(){return null==l?null:$CLJS.dg.j(l,$CLJS.Jk.h(function(m){return $CLJS.m3(m,l)}),Una(e,f))}();return $CLJS.VY.l(e,f,$CLJS.lH,$CLJS.G([$CLJS.xH,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,e);case 2:return b.call(this,e,f);case 3:return a.call(this,
e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.B8=function(){function a(d,e){return $CLJS.xH.h($CLJS.HY(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Yna=function(){function a(d,e){var f=$CLJS.A2.A(d,e,$CLJS.HY(d,e),new $CLJS.h(null,3,[$CLJS.j2,!1,$CLJS.o2,!1,$CLJS.h2,!1],null));d=$CLJS.B8.g(d,e);return $CLJS.td(d)?$CLJS.Kk.g(function(k){return $CLJS.R.j(k,$CLJS.L0,!0)},f):$CLJS.w4(f,d)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
Zna=function(){function a(d,e,f){var k=$CLJS.HY(d,e),l=$CLJS.F.g($CLJS.SE(f),$CLJS.zI)?$CLJS.y2:$CLJS.A2;d=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.o3(f,d)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.$na=function(){function a(d,e,f){f=$CLJS.x0.j(d,e,f);return Zna.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();