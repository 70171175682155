var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.id.js");require("./metabase.mbql.schema.js");require("./metabase.util.malli.registry.js");
'use strict';var eX,fX,gX,iX,jX,kX,lX,mX,nX,oX;eX=new $CLJS.M("metabase.lib.schema.template-tag","widget-type","metabase.lib.schema.template-tag/widget-type",2021141560);fX=new $CLJS.M("metabase.lib.schema.template-tag","raw-value","metabase.lib.schema.template-tag/raw-value",-530604923);gX=new $CLJS.M("metabase.lib.schema.template-tag","template-tag","metabase.lib.schema.template-tag/template-tag",-1178540449);
$CLJS.hX=new $CLJS.M("metabase.lib.schema.template-tag","template-tag-map","metabase.lib.schema.template-tag/template-tag-map",1471181299);iX=new $CLJS.M("metabase.lib.schema.template-tag","type","metabase.lib.schema.template-tag/type",1926679295);jX=new $CLJS.M("metabase.lib.schema.template-tag","field-filter","metabase.lib.schema.template-tag/field-filter",1376344463);kX=new $CLJS.M("metabase.lib.schema.template-tag","snippet","metabase.lib.schema.template-tag/snippet",190689821);
lX=new $CLJS.M("metabase.lib.schema.template-tag","common","metabase.lib.schema.template-tag/common",1713198790);mX=new $CLJS.M("metabase.lib.schema.template-tag","value.common","metabase.lib.schema.template-tag/value.common",-974864959);nX=new $CLJS.M("metabase.lib.schema.template-tag","raw-value.type","metabase.lib.schema.template-tag/raw-value.type",-805651412);oX=new $CLJS.M("metabase.lib.schema.template-tag","source-query","metabase.lib.schema.template-tag/source-query",479361081);$CLJS.W(eX,$CLJS.dg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"Valid template tag :widget-type"],null),$CLJS.$u],null),$CLJS.Wg($CLJS.$W)));$CLJS.W(iX,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kt,$CLJS.DO,$CLJS.uP,$CLJS.fO,$CLJS.vv,$CLJS.kE,$CLJS.BR],null));
$CLJS.W(lX,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.$G],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WG,$CLJS.$G],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.$G,$CLJS.Et],null)],null)],null));
$CLJS.W(mX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lX],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.vl],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WP,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null)],null)],null));
$CLJS.W(jX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mX],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.fO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.GH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eX],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,new $CLJS.h(null,1,[$CLJS.Mt,!0],
null),$CLJS.ek],null)],null)],null));$CLJS.W(kX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lX],null),new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.DO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pO,$CLJS.$G],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TR,$CLJS.nN],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UR,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.lN],null)],null)],null));
$CLJS.W(oX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lX],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.uP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eT,$CLJS.kN],null)],null)],null));$CLJS.W(nX,$CLJS.dg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kt],null),$CLJS.OW));
$CLJS.W(fX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mX],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nX],null)],null)],null)],null));
$CLJS.W(gX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iX],null)],null)],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.jj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jX],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kX],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uP,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,oX],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.So,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fX],null)],null)],null)],null));
$CLJS.W($CLJS.hX,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tt,$CLJS.$G,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gX],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,$CLJS.S.h(b))},a)}],null)],null));