var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.join.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.match.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var ana,L7,bna,cna,dna,N7,O7,P7,Q7,ena,fna,T7,R7,S7,U7,V7,gna,hna,ina,W7,jna,kna,X7,lna,mna;ana=function(a,b){return $CLJS.E(a)<=$CLJS.E(b)&&$CLJS.Le(function(c){return $CLJS.Hd(b,c)},a)};L7=function(a,b){if(b=$CLJS.z(b)){var c=$CLJS.z(b);b=$CLJS.B(c);c=$CLJS.C(c);return $CLJS.z(c)?(c=L7($CLJS.I.g(a,b),c),$CLJS.td(c)?$CLJS.Ak.g(a,b):$CLJS.R.j(a,b,c)):$CLJS.Ak.g(a,b)}return a};
bna=function(a,b,c,d){return function t(f,k,l,m){var u=$CLJS.z(k);k=$CLJS.B(u);u=$CLJS.C(u);var v=$CLJS.Zl(f,k);return $CLJS.n(v)?u?$CLJS.R.j(f,k,t($CLJS.Ab(v),u,l,m)):$CLJS.R.j(f,k,$CLJS.O.j(l,$CLJS.Ab(v),m)):f}(a,b,c,d)};cna=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return bna(arguments[0],arguments[1],arguments[2],3<b.length?new $CLJS.y(b.slice(3),0,null):null)};$CLJS.M7=function(a){return $CLJS.fH.h($CLJS.mH(a))};
dna=function(a,b,c,d){var e=$CLJS.F.g($CLJS.$R,$CLJS.B(b))?$CLJS.BY(d,$CLJS.AY(c)):d;return cna(a,b,function(f){return $CLJS.Bf(function(){return function m(l){return new $CLJS.le(null,function(){for(;;){var t=$CLJS.z(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.jc(t),v=$CLJS.E(u),x=$CLJS.oe(v);a:for(var A=0;;)if(A<v){var D=$CLJS.ld(u,A);D=$CLJS.F.g($CLJS.M7(D),$CLJS.M7(c))?e:D;x.add(D);A+=1}else{u=!0;break a}return u?$CLJS.re($CLJS.te(x),m($CLJS.kc(t))):$CLJS.re($CLJS.te(x),null)}x=$CLJS.B(t);return $CLJS.ce($CLJS.F.g($CLJS.M7(x),
$CLJS.M7(c))?e:x,m($CLJS.Kc(t)))}return null}},null,null)}(f)}())})};
N7=function(a,b,c){var d=$CLJS.ED(a,b);if($CLJS.n(d)){var e=$CLJS.M7(c),f=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.B(b),$CLJS.jd(b)],null);c=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);d=$CLJS.dg.j($CLJS.zf,$CLJS.Jk.h($CLJS.Ik.g($CLJS.bh([e]),$CLJS.M7)),d);d=$CLJS.F.g(b,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xH],null))&&$CLJS.Le(function(k){return $CLJS.u4(k,$CLJS.dE)},d)?null:d;if($CLJS.z(d))return $CLJS.RN(a,b,d);if($CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CP,$CLJS.yN],null),new $CLJS.P(null,2,5,$CLJS.Q,
[c,f],null)))throw $CLJS.hi($CLJS.pH("Cannot remove the final join condition"),new $CLJS.h(null,1,[$CLJS.yN,$CLJS.ED(a,b)],null));return $CLJS.F.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.CP,$CLJS.xH],null),new $CLJS.P(null,2,5,$CLJS.Q,[c,f],null))?$CLJS.AZ.A(a,$CLJS.sd(b),$CLJS.Ak,f):L7(a,b)}return a};O7=function(a,b){a=$CLJS.Ee(a);a=$CLJS.I.g(a,$CLJS.vN);b=0>b?$CLJS.E(a)+b:b;return b+1<$CLJS.E(a)?b+1:null};
P7=function(a,b){var c=$CLJS.Y4.g(a,b),d=$CLJS.kh(0,$CLJS.E(c));return $CLJS.bf.l(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RT],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.GQ],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.hY],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.xH],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.zI],null),new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.$R],null)],null),function(){return function k(f){return new $CLJS.le(null,function(){for(var l=f;;)if(l=$CLJS.z(l)){if($CLJS.Ad(l)){var m=
$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.ld(m,v),A=$CLJS.bd(c,x);$CLJS.xH.h(A)instanceof $CLJS.M||u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CP,x,$CLJS.xH],null));v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.kc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);m=$CLJS.bd(c,u);if($CLJS.xH.h(m)instanceof $CLJS.M)l=$CLJS.Kc(l);else return $CLJS.ce(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CP,u,$CLJS.xH],null),k($CLJS.Kc(l)))}else return null},null,null)}(d)}(),$CLJS.G([function(){return function k(f){return new $CLJS.le(null,
function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.ld(m,v);u.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CP,x,$CLJS.yN],null));v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.kc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);return $CLJS.ce(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CP,u,$CLJS.yN],null),k($CLJS.Kc(l)))}return null}},null,null)}(d)}()]))};
Q7=function(a,b,c){var d=$CLJS.H(c,0,null),e=$CLJS.H(c,1,null);e=$CLJS.Ee(e);var f=$CLJS.I.g(e,$CLJS.QI),k=$CLJS.I.g(e,$CLJS.xT),l=$CLJS.H(c,2,null);return $CLJS.B($CLJS.WJ(function(m){$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);$CLJS.H(m,0,null);$CLJS.H(m,1,null);m=$CLJS.H(m,2,null);return $CLJS.F.g($CLJS.B(m),d)&&$CLJS.F.g($CLJS.QI.h($CLJS.id(m)),f)&&$CLJS.F.g($CLJS.xT.h($CLJS.id(m)),k)&&$CLJS.F.g($CLJS.jd(m),l)},$CLJS.Xl($CLJS.Jt,$CLJS.RT.h($CLJS.HY(a,b)))))};
ena=function(a,b,c,d){c=Q7(a,b,c);return $CLJS.n(c)?$CLJS.VY.l(a,b,$CLJS.AZ,$CLJS.G([new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.RT,c,2,1],null),$CLJS.Ik.g(function(e){return $CLJS.KD($CLJS.Ne($CLJS.sl),e)},$CLJS.$k),d])):a};fna=function(a,b,c){c=Q7(a,b,c);return $CLJS.n(c)?$CLJS.VY.l(a,b,N7,$CLJS.G([new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.RT],null),$CLJS.ED($CLJS.HY(a,b),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.RT,c],null))])):a};
T7=function(a,b,c,d,e,f){var k=$CLJS.VY.l(a,b,f,$CLJS.G([d,e])),l=$CLJS.M7(e);return $CLJS.Gk.g(a,k)?$CLJS.B($CLJS.z($CLJS.ff($CLJS.Ta,function(m,t){try{if($CLJS.zd(t)&&1===$CLJS.E(t))try{var u=$CLJS.bd(t,0);if($CLJS.fe(u,$CLJS.$R))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var K=$CLJS.AY(e);K=R7.W?R7.W(k,b,c,$CLJS.dE,$CLJS.N,K):R7.call(null,k,b,c,$CLJS.dE,$CLJS.N,K);return S7.A?S7.A(K,b,c,l):S7.call(null,K,b,c,l)}()],null);throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{u=
$CLJS.bd(t,0);if($CLJS.fe(u,$CLJS.zI))return new $CLJS.P(null,1,5,$CLJS.Q,[function(){var X=R7.W?R7.W(k,b,c,$CLJS.zI,$CLJS.N,l):R7.call(null,k,b,c,$CLJS.zI,$CLJS.N,l);return S7.A?S7.A(X,b,c,l):S7.call(null,X,b,c,l)}()],null);throw $CLJS.Y;}catch(X){if(X instanceof Error){var v=X;if(v===$CLJS.Y)throw $CLJS.Y;throw v;}throw X;}else throw m;else throw K;}else throw $CLJS.Y;}catch(K){if(K instanceof Error)if(m=K,m===$CLJS.Y)try{if($CLJS.zd(t)&&1===$CLJS.E(t))try{var x=$CLJS.bd(t,0);if($CLJS.fe(x,$CLJS.GQ))return new $CLJS.P(null,
1,5,$CLJS.Q,[S7.A?S7.A(k,b,c,l):S7.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(X){if(X instanceof Error)if(v=X,v===$CLJS.Y)try{x=$CLJS.bd(t,0);if($CLJS.fe(x,$CLJS.xH))return new $CLJS.P(null,1,5,$CLJS.Q,[S7.A?S7.A(k,b,c,l):S7.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){var A=Z;if(A===$CLJS.Y)throw $CLJS.Y;throw A;}throw Z;}else throw v;else throw X;}else throw $CLJS.Y;}catch(X){if(X instanceof Error)if(v=X,v===$CLJS.Y)try{if($CLJS.zd(t)&&3===$CLJS.E(t))try{var D=$CLJS.bd(t,
0);if($CLJS.fe(D,$CLJS.CP))try{var J=$CLJS.bd(t,2);if($CLJS.fe(J,$CLJS.xH))return new $CLJS.P(null,1,5,$CLJS.Q,[S7.A?S7.A(k,b,c,l):S7.call(null,k,b,c,l)],null);throw $CLJS.Y;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.Y)throw $CLJS.Y;throw A;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.Y)throw $CLJS.Y;throw A;}throw Z;}else throw $CLJS.Y;}catch(Z){if(Z instanceof Error){A=Z;if(A===$CLJS.Y)return new $CLJS.P(null,1,5,$CLJS.Q,[k],null);throw A;}throw Z;}else throw v;
else throw X;}else throw m;else throw K;}}($CLJS.zf,d)))):k};
R7=function(a,b,c,d,e,f){var k=$CLJS.HY(a,b),l=$CLJS.df(function(m){var t=$CLJS.ED(k,m);return $CLJS.n(t)?$CLJS.Wl(function(u){return $CLJS.B($CLJS.z($CLJS.ff($CLJS.Ta,function D(x,A){try{if($CLJS.zd(A)&&3===$CLJS.E(A))try{var J=$CLJS.bd(A,0);if($CLJS.F.g(J,d))try{var K=$CLJS.bd(A,1);if($CLJS.td(e)||ana($CLJS.eh(e),$CLJS.eh(K)))try{var X=$CLJS.bd(A,2);if($CLJS.F.g(X,f))return new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[m,u],null)],null);throw $CLJS.Y;}catch(fa){if(fa instanceof Error){var Z=
fa;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw fa;}else throw $CLJS.Y;}catch(fa){if(fa instanceof Error){Z=fa;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw fa;}else throw $CLJS.Y;}catch(fa){if(fa instanceof Error){Z=fa;if(Z===$CLJS.Y)throw $CLJS.Y;throw Z;}throw fa;}else throw $CLJS.Y;}catch(fa){if(fa instanceof Error){Z=fa;if(Z===$CLJS.Y)return $CLJS.DX(D,x,A);throw Z;}throw fa;}}($CLJS.zf,u))))},t):null},$CLJS.G([P7(a,b)]));return $CLJS.ab(function(m,t){var u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);
return T7(m,b,c,u,t,N7)},a,l)};S7=function(a,b,c,d){b=O7(c,b);if($CLJS.n(b)){var e=$CLJS.HY(c,b);c=$CLJS.Me(function(f){f=$CLJS.Ee(f);var k=$CLJS.I.g(f,$CLJS.LY),l=$CLJS.I.g(f,$CLJS.A3);return $CLJS.F.g($CLJS.B0,k)&&$CLJS.F.g(d,l)?$CLJS.J0.h(f):null},$CLJS.A2.j(c,b,e));return $CLJS.n(c)?R7(a,b,a,$CLJS.KI,$CLJS.N,c):a}return a};
U7=function(a,b,c,d,e){var f=$CLJS.tY.h(c),k=$CLJS.HY(a,b);c=$CLJS.WJ(function(t){var u=$CLJS.ED(k,t);if($CLJS.n(u)){var v=$CLJS.M7(f);return $CLJS.n($CLJS.Me($CLJS.Ik.j($CLJS.bh([v]),$CLJS.fH,$CLJS.id),u))?t:null}return null},P7(a,b));var l=(d=$CLJS.F.g($CLJS.hJ,d))?$CLJS.tY.h(e):null;e=d?function(t,u,v){return dna(t,u,v,l)}:N7;var m=$CLJS.F.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.GQ],null),c);a=d&&m&&$CLJS.F.g($CLJS.B(f),$CLJS.B(l))&&$CLJS.F.g($CLJS.jd(f),$CLJS.jd(l))?ena(a,b,f,$CLJS.Yl($CLJS.id(l),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.xT,$CLJS.QI],null))):m?fna(a,b,f):a;return $CLJS.n(c)?T7(a,b,a,c,f,e):a};V7=function(a,b){return $CLJS.u4(a,$CLJS.KI)&&$CLJS.F.g($CLJS.A4(a),b)};gna=function(a,b,c){return function k(e,f){try{if(V7(f,b))return $CLJS.B4(f,c);throw $CLJS.Y;}catch(m){if(m instanceof Error){var l=m;if(l===$CLJS.Y)return $CLJS.cZ(k,e,f);throw l;}throw m;}}($CLJS.zf,a)};
hna=function(a,b,c){var d=$CLJS.CP.h(a),e=-1<b&&b<$CLJS.E(d)?new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.ED(d,new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.BH],null))],null):null;b=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);if($CLJS.n($CLJS.n(b)?$CLJS.Gk.g(e,c):b)){var f=$CLJS.KY();$CLJS.t4(f,$CLJS.cf.g($CLJS.BH,d));c=f(c);return gna($CLJS.RN(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CP,b,$CLJS.BH],null),c),e,c)}return a};
ina=function(a,b,c){if($CLJS.Fd(c))return c;var d=function(){var e=$CLJS.bh([c]);return"string"===typeof c?$CLJS.Ik.g(e,$CLJS.BH):e}();return $CLJS.Me(function(e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return $CLJS.n(d.h?d.h(e):d.call(null,e))?f:null},$CLJS.Xl($CLJS.Jt,$CLJS.CP.h($CLJS.HY(a,b))))};
W7=function(a,b,c,d){b=$CLJS.Pv.g($CLJS.eh($CLJS.A2.j(b,c,$CLJS.HY(b,c))),$CLJS.eh($CLJS.A2.j(a,c,$CLJS.HY(a,c))));return $CLJS.ab(function(e,f){return $CLJS.O.N(R7,e,c,a,d.h?d.h(f):d.call(null,f))},a,b)};jna=function(a,b,c){a=W7(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,new $CLJS.h(null,1,[$CLJS.tR,$CLJS.z4.h(d)],null),$CLJS.Wi.h(d)],null)});c=O7(a,c);return $CLJS.n(c)?W7(a,b,c,function(d){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KI,$CLJS.N,$CLJS.J0.h(d)],null)}):a};
kna=function(a,b,c){return $CLJS.Fd(c)?$CLJS.ED($CLJS.HY(a,b),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CP,c,$CLJS.BH],null)):$CLJS.xd(c)?$CLJS.BH.h(c):c};X7=function(a,b,c,d){var e=kna(a,b,c);return $CLJS.n(e)?(c=function(){var f=(0,$CLJS.VY)(a,b,function(k){var l=$CLJS.CP.h(k);l=d.g?d.g(l,e):d.call(null,l,e);return $CLJS.lH(k,$CLJS.CP,l)});return(0,$CLJS.VY)(f,b,function(k){return $CLJS.kZ.j(k,$CLJS.CP,function(l){return $CLJS.Kk.g(function(m){return $CLJS.K4(f,b,m)},l)})})}(),jna(c,a,b)):a};
lna=function(a,b){return null!=$CLJS.B($CLJS.z($CLJS.ff($CLJS.Ta,function f(d,e){try{if(V7(e,b))return new $CLJS.P(null,1,5,$CLJS.Q,[e],null);throw $CLJS.Y;}catch(l){if(l instanceof Error){var k=l;if(k===$CLJS.Y)return $CLJS.DX(f,d,e);throw k;}throw l;}}($CLJS.zf,a))))};mna=new $CLJS.M(null,"remove","remove",-131428414);$CLJS.nna=function(){function a(d,e,f){return $CLJS.xd(f)&&$CLJS.F.g($CLJS.Bj.h(f),$CLJS.qN)?$CLJS.Y7.j?$CLJS.Y7.j(d,e,f):$CLJS.Y7.call(null,d,e,f):U7(d,e,f,mna,null)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.ona=function(){function a(d,e,f,k){return $CLJS.xd(f)&&$CLJS.F.g($CLJS.Bj.h(f),$CLJS.qN)?$CLJS.Z7.A?$CLJS.Z7.A(d,e,f,k):$CLJS.Z7.call(null,d,e,f,k):U7(d,e,f,$CLJS.hJ,k)}function b(d,e,f){return c.A?c.A(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.A=a;return c}();
$CLJS.pna=function(){function a(d,e,f,k){f=ina(d,e,f);return $CLJS.n(f)?$CLJS.VY.l(d,e,hna,$CLJS.G([f,k])):d}function b(d,e,f){return c.A?c.A(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.A=a;return c}();
$CLJS.Y7=function(){function a(d,e,f){return X7(d,e,f,function(k,l){return $CLJS.Fe($CLJS.uX(function(m){return!($CLJS.F.g($CLJS.BH.h(m),l)||lna(m,l))},k))})}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Z7=function(){function a(d,e,f,k){return null==k?$CLJS.Y7.j(d,e,f):X7(d,e,f,function(l,m){return $CLJS.Kk.g(function(t){return $CLJS.F.g($CLJS.BH.h(t),m)?k:t},l)})}function b(d,e,f){return c.A?c.A(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.A=a;return c}();