var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var XJ,YJ,gca,$J,hca,ica,jca,aK,ZJ;$CLJS.VJ=function(a,b){return $CLJS.dc($CLJS.ab(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.gg.j(c,e,$CLJS.$d.g($CLJS.I.j(c,e,$CLJS.zf),d))},$CLJS.bc($CLJS.N),b))};$CLJS.WJ=function(a,b){return $CLJS.ab(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Rc(d):null},null,b)};
XJ=function(a,b){$CLJS.H(a,0,null);$CLJS.H(a,1,null);$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);b=$CLJS.fF(b,$CLJS.GF)?$CLJS.HJ:$CLJS.fF(b,$CLJS.CG)?$CLJS.CJ:$CLJS.fF(b,$CLJS.dG)?$CLJS.EJ:null;return $CLJS.n(b)?$CLJS.VH(b,a):!0};
YJ=function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);a=$CLJS.VJ(function(d){return $CLJS.fF($CLJS.sH(d),$CLJS.RF)},a);var b=$CLJS.Ee(a);a=$CLJS.I.g(b,!1);b=$CLJS.I.g(b,!0);if($CLJS.Gk.g($CLJS.E(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.E(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.sH($CLJS.B(a));return $CLJS.Me(function(d){$CLJS.H(d,0,null);$CLJS.H(d,1,null);$CLJS.H(d,2,null);
var e=$CLJS.H(d,3,null);return $CLJS.n(XJ(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
gca=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vt,new $CLJS.h(null,1,[$CLJS.Du,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.at,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.eH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZJ],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Zs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZJ],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Fu,function(b){b=$CLJS.Ee(b);b=$CLJS.I.g(b,$CLJS.Aj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(YJ(b))].join("")}],null),$CLJS.Ne(YJ)],null)],null)};
$J=function(a){var b=$CLJS.Q,c=gca(a);a=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.at,new $CLJS.h(null,1,[$CLJS.Du,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.eH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zs,new $CLJS.h(null,1,[$CLJS.Rn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)],null)],null);
return new $CLJS.P(null,3,5,b,[$CLJS.ot,c,a],null)};hca=function(a){return $CLJS.Pd($CLJS.YH,$CLJS.cf.g(function(b){var c=$CLJS.sH(b),d=$CLJS.fF(c,$CLJS.EH);b=d?$CLJS.VH($CLJS.OI,b):d;return $CLJS.n(b)?$CLJS.Mj:c},a))};ica=function(a){a=$CLJS.WJ(function(b){return!$CLJS.fF(b,$CLJS.RF)},$CLJS.cf.g($CLJS.sH,a));return $CLJS.fF(a,$CLJS.EH)?$CLJS.Tj:a};jca=function(a){return $CLJS.n($CLJS.Me(function(b){return $CLJS.fF($CLJS.sH(b),$CLJS.RF)},a))?ica(a):hca(a)};
aK=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);$CLJS.bK=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);ZJ=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.W(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.AH],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Rs,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,ZJ],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.z(a);a=$CLJS.B(b);b=$CLJS.C(b);var c=$CLJS.sH(a);return $CLJS.Le(function(d){return XJ(d,c)},b)}],null)],null));
$CLJS.W(aK,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Zs,new $CLJS.h(null,1,[$CLJS.Rn,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)],null));$CLJS.jJ.g($CLJS.Rs,$J($CLJS.Rs));$CLJS.jJ.g($CLJS.tu,$J($CLJS.tu));$CLJS.eI($CLJS.Ss,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$t,aK],null)]));$CLJS.eI($CLJS.eJ,$CLJS.G([$CLJS.tu,$CLJS.zG,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$t,aK],null)]));
for(var cK=$CLJS.z(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rs,$CLJS.tu,$CLJS.Ss],null)),dK=null,eK=0,fK=0;;)if(fK<eK){var kca=dK.X(null,fK);$CLJS.WH(kca,$CLJS.bK);fK+=1}else{var gK=$CLJS.z(cK);if(gK){var hK=gK;if($CLJS.Ad(hK)){var iK=$CLJS.jc(hK),lca=$CLJS.kc(hK),mca=iK,nca=$CLJS.E(iK);cK=lca;dK=mca;eK=nca}else{var oca=$CLJS.B(hK);$CLJS.WH(oca,$CLJS.bK);cK=$CLJS.C(hK);dK=null;eK=0}fK=0}else break}$CLJS.rH.m(null,$CLJS.bK,function(a){a=$CLJS.z(a);$CLJS.B(a);a=$CLJS.C(a);$CLJS.B(a);a=$CLJS.C(a);return jca(a)});
$CLJS.cI($CLJS.mI,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));$CLJS.WH($CLJS.mI,$CLJS.JH);
for(var jK=$CLJS.z(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,$CLJS.fI,$CLJS.yI],null)),kK=null,lK=0,mK=0;;)if(mK<lK){var pca=kK.X(null,mK);$CLJS.cI(pca,$CLJS.G([$CLJS.tu,$CLJS.zG,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));mK+=1}else{var nK=$CLJS.z(jK);if(nK){var oK=nK;if($CLJS.Ad(oK)){var pK=$CLJS.jc(oK),qca=$CLJS.kc(oK),rca=pK,sca=$CLJS.E(pK);jK=qca;kK=rca;lK=sca}else{var tca=$CLJS.B(oK);$CLJS.cI(tca,$CLJS.G([$CLJS.tu,$CLJS.zG,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));jK=$CLJS.C(oK);kK=null;lK=0}mK=0}else break}
for(var qK=$CLJS.z(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iI,$CLJS.II,$CLJS.PI],null)),rK=null,sK=0,tK=0;;)if(tK<sK){var uca=rK.X(null,tK);$CLJS.cI(uca,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));tK+=1}else{var uK=$CLJS.z(qK);if(uK){var vK=uK;if($CLJS.Ad(vK)){var wK=$CLJS.jc(vK),vca=$CLJS.kc(vK),wca=wK,xca=$CLJS.E(wK);qK=vca;rK=wca;sK=xca}else{var yca=$CLJS.B(vK);$CLJS.cI(yca,$CLJS.G([$CLJS.tu,$CLJS.tj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));qK=$CLJS.C(vK);rK=null;sK=0}tK=0}else break}$CLJS.cI($CLJS.jI,$CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.OH],null)],null)]));
$CLJS.rH.m(null,$CLJS.jI,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);var b=$CLJS.H(a,2,null);a=$CLJS.H(a,3,null);return $CLJS.fF($CLJS.sH(b),$CLJS.tj)&&$CLJS.fF($CLJS.sH(a),$CLJS.tj)?$CLJS.tj:$CLJS.zG});