var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.common.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var B7=function(a){return $CLJS.n(A7)?["(",$CLJS.p.h(a),")"].join(""):a},Oma=function(a,b,c){var d=$CLJS.HY(a,b);a=$CLJS.A2.j(a,b,d);var e=$CLJS.PD(c);return $CLJS.Me(function(f){return $CLJS.F.g($CLJS.PD($CLJS.S.h(f)),e)},a)},Pma=function(a,b){var c=$CLJS.Lk.A(a,$CLJS.$R,$CLJS.EG($CLJS.$d,$CLJS.zf),b);return $CLJS.zd($CLJS.xH.h(a))?$CLJS.Lk.A(c,$CLJS.xH,$CLJS.$d,$CLJS.pY(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dE,$CLJS.N,$CLJS.AY(b)],null))):c},Qma=function(a,b,c){var d=$CLJS.AY(c);return $CLJS.R.l($CLJS.U1.j(a,
b,c),$CLJS.LY,$CLJS.F0,$CLJS.G([$CLJS.S,d,$CLJS.WG,d]))},C7=new $CLJS.M("metabase.lib.expression","infix-operator","metabase.lib.expression/infix-operator",246733613);var Rma=function(){function a(d,e,f){var k=$CLJS.HY(d,e);k=$CLJS.WJ($CLJS.Ik.g($CLJS.bh([f]),$CLJS.AY),$CLJS.$R.h(k));if($CLJS.n(k))return k;throw $CLJS.hi($CLJS.SG("No expression named {0}",$CLJS.G([$CLJS.Lh.l($CLJS.G([f]))])),new $CLJS.h(null,3,[$CLJS.cU,f,$CLJS.FO,d,$CLJS.OY,e],null));}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);
};c.g=b;c.j=a;return c}();$CLJS.t2.m(null,$CLJS.dE,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);c=Rma.j(a,b,c);return $CLJS.u2.j(a,b,c)});$CLJS.v2.m(null,$CLJS.dE,function(a,b,c){$CLJS.H(c,0,null);var d=$CLJS.H(c,1,null),e=$CLJS.H(c,2,null);return new $CLJS.h(null,7,[$CLJS.Bj,$CLJS.rY,$CLJS.A3,$CLJS.fH.h(d),$CLJS.S,e,$CLJS.FH,e,$CLJS.WG,$CLJS.V1.j(a,b,c),$CLJS.gE,$CLJS.u2.j(a,b,c),$CLJS.LY,$CLJS.F0],null)});$CLJS.p2.m(null,$CLJS.ZD,function(a,b,c){return $CLJS.p.h(c)});
$CLJS.p2.m(null,$CLJS.XD,function(a,b,c){return $CLJS.p.h(c)});$CLJS.p2.m(null,$CLJS.YD,function(a,b,c){return['"',$CLJS.p.h(c),'"'].join("")});$CLJS.p2.m(null,$CLJS.dE,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.H(c,2,null)});$CLJS.q2.m(null,$CLJS.dE,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);return $CLJS.H(c,2,null)});
for(var A7=!1,Sma=new $CLJS.h(null,4,[$CLJS.Rs,"+",$CLJS.tu,"-",$CLJS.Ss,"×",$CLJS.eJ,"÷"],null),D7=$CLJS.z(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Rs,$CLJS.tu,$CLJS.eJ,$CLJS.Ss],null)),E7=null,F7=0,G7=0;;)if(G7<F7){var Tma=E7.X(null,G7);$CLJS.WH(Tma,C7);G7+=1}else{var H7=$CLJS.z(D7);if(H7){var I7=H7;if($CLJS.Ad(I7)){var J7=$CLJS.jc(I7),Uma=$CLJS.kc(I7),Vma=J7,Wma=$CLJS.E(J7);D7=Uma;E7=Vma;F7=Wma}else{var Xma=$CLJS.B(I7);$CLJS.WH(Xma,C7);D7=$CLJS.C(I7);E7=null;F7=0}G7=0}else break}
$CLJS.p2.m(null,C7,function(a,b,c){var d=$CLJS.z(c);c=$CLJS.B(d);d=$CLJS.C(d);$CLJS.B(d);d=$CLJS.C(d);c=$CLJS.I.g(Sma,c);a:{var e=A7;A7=!0;try{var f=$CLJS.Pu([" ",$CLJS.fh(c)," "].join(""),$CLJS.cf.g($CLJS.Iv($CLJS.V1,a,b),d));break a}finally{A7=e}f=void 0}return B7(f)});$CLJS.q2.m(null,C7,function(){return"expression"});
$CLJS.t2.m(null,$CLJS.bK,function(a,b,c){c=$CLJS.z(c);$CLJS.B(c);c=$CLJS.C(c);$CLJS.B(c);var d=$CLJS.C(c);return $CLJS.Pd($CLJS.YH,function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.ld(m,v);x=$CLJS.u2.j(a,b,x);u.add(x);v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.kc(l))):$CLJS.re($CLJS.te(u),null)}u=$CLJS.B(l);return $CLJS.ce($CLJS.u2.j(a,
b,u),k($CLJS.Kc(l)))}return null}},null,null)}(d)}())});$CLJS.p2.m(null,$CLJS.BL,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null),f=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);a=$CLJS.p.h($CLJS.V1.A(a,b,e,d));b=$CLJS.p;d=b.h;c=$CLJS.B3.g(f,c).toLowerCase();0<f?f=$CLJS.ha.j?$CLJS.ha.j("+ %d %s",f,c):$CLJS.ha.call(null,"+ %d %s",f,c):(f=Math.abs(f),f=$CLJS.ha.j?$CLJS.ha.j("- %d %s",f,c):$CLJS.ha.call(null,"- %d %s",f,c));f=B7(f);return[a," ",d.call(b,f)].join("")});
$CLJS.q2.m(null,$CLJS.BL,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null),e=$CLJS.H(c,3,null);c=$CLJS.H(c,4,null);a=$CLJS.p.h($CLJS.r2.j(a,b,d));b=$CLJS.p;d=b.h;c=$CLJS.B3.g(e,c).toLowerCase();0<e?e=$CLJS.ha.j?$CLJS.ha.j("plus_%s_%s",e,c):$CLJS.ha.call(null,"plus_%s_%s",e,c):(e=Math.abs(e),e=$CLJS.ha.j?$CLJS.ha.j("minus_%d_%s",e,c):$CLJS.ha.call(null,"minus_%d_%s",e,c));return[a,"_",d.call(b,e)].join("")});
$CLJS.p2.m(null,$CLJS.NI,function(a,b,c,d){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var e=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.V1.A(a,b,e,d)});$CLJS.q2.m(null,$CLJS.NI,function(a,b,c){$CLJS.H(c,0,null);$CLJS.H(c,1,null);var d=$CLJS.H(c,2,null);$CLJS.H(c,3,null);return $CLJS.r2.j(a,b,d)});
$CLJS.Yma=function(){function a(d,e,f,k){e=$CLJS.n(e)?e:-1;if($CLJS.n(Oma(d,e,f)))throw $CLJS.hi("Expression name conflicts with a column in the same query stage",new $CLJS.h(null,1,[$CLJS.cU,f],null));return $CLJS.VY.l(d,e,Pma,$CLJS.G([$CLJS.BY($CLJS.tY.h(k),f)]))}function b(d,e,f){return c.A?c.A(d,-1,e,f):c.call(null,d,-1,e,f)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,d,e,f,k)}throw Error("Invalid arity: "+arguments.length);
};c.j=b;c.A=a;return c}();$CLJS.Zma=function(){function a(d,e){var f=$CLJS.Fe($CLJS.$R.h($CLJS.HY(d,e)));return null==f?null:$CLJS.Kk.g($CLJS.Iv(Qma,d,e),f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.K7=function(){function a(d,e){return $CLJS.Fe($CLJS.$R.h($CLJS.HY(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();$CLJS.nY.m(null,$CLJS.dE,function(a){return a});
$CLJS.$ma=function(){function a(d,e,f){var k=$CLJS.dg.g($CLJS.N,$CLJS.Xl(function(m,t){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.AY(t),m],null)},$CLJS.K7.g(d,e))),l=$CLJS.HY(d,e);d=$CLJS.A2.j(d,e,l);return $CLJS.Fe($CLJS.uX(function(m){return $CLJS.Sa(f)||$CLJS.Gk.g($CLJS.LY.h(m),$CLJS.F0)||$CLJS.I.g(k,$CLJS.S.h(m))<f},d))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+
arguments.length);};c.g=b;c.j=a;return c}();