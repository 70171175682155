var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");
'use strict';var Vv,Wv,Xv,Yv,Zv,$v,aw,bw,iw,jw,kw,lw,mw,nw,qw,tw,uw,vw,xw,yw,zw,Aw,Bw,Cw,Hw,Kw,Mw,Pw,Rw,Sw,Ww,Xw,Zw,$w,ax,gx,hx,sx,wx,Cx,Ex,Jx,Nx,Sx,Ux,Vx,Px,Xx,Yx,$x,Zx,ay,fy,gy,qy,ry,ky,sy,wy,zy,By,Cy,Ey,Gy,Hy,Ky,Oy,Py,Qy,Ry,Sy,Xy,$y,az,cz,fz,gz,hz,kz,lz,mz,nz,pz,qz,oz,uz,vz,wz,xz,yz,zz,Az,Bz,Cz,Dz,Ez,Gz,Hz,Jz,Nz,Pz,Sz,Tz,Xz,$z,Uz,dA,Ox,Rx,fA,gA,jA,kA,nA,oA,qA,rA,sA,uA,vA,wA,tA,xA,Fw,yA,Vw,Zy,zA,Gx,AA,BA,Iw,Tw,lA,CA,ux,ey,bx,DA,EA,rx,FA,Vz,fx,dy,px,dz,GA,Uw,HA,tz,Mz,IA,JA,Fy,LA,Jy,tx,cy,Ew,Lz,nx,
Fx,lx,Lw,MA,Wx,NA,ny,Iy,Zz,OA,bA,mx,PA,QA,RA,Kx,SA,rz,Ix,UA,oy,my,VA,Nw,WA,iA,cA,cx,XA,My,YA,ZA,ty,$A,ex,aB,hA,bB,Mx,jz,by,cB,Ny,mA,eB,iz,fB,ly,Qw,gB,vx,hB,Ly,iB,Jw,Kz,Hx,kx,Rz,Ow,Ty,Wz,Yy,Uy,Wy,sz,uy,jB,kB,lB,ww,mB,sw,Dw,nB,dx,oB;Vv=function(a){return 1==a.length&&" "<=a&&"~">=a||""<=a&&"�">=a};Wv=function(){};
Xv=function(a){if(null!=a&&null!=a.jc)a=a.jc(a);else{var b=Xv[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Xv._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("IWriter.-flush",a);}return a};Yv=function(){};Zv=function(a){if(null!=a&&null!=a.Oc)a=a.Oc(a);else{var b=Zv[$CLJS.wa(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Zv._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("IPending.-realized?",a);}return a};
$v=function(a){if("number"===typeof a)return String.fromCharCode(a);if("string"===typeof a&&1===a.length)return a;throw Error("Argument to char must be a character or number");};aw=function(a,b){return a-b*$CLJS.Ud(a,b)};bw=function(a,b,c){if(null!=a&&null!=a.m)a.m(a,b,c);else{var d=bw[$CLJS.wa(null==a?null:a)];if(null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else if(d=bw._,null!=d)d.j?d.j(a,b,c):d.call(null,a,b,c);else throw $CLJS.Ya("IMultiFn.-add-method",a);}};
$CLJS.cw=function(a,b){var c=$CLJS.bc($CLJS.N);a=$CLJS.z(a);for(b=$CLJS.z(b);;)if(a&&b)c=$CLJS.gg.j(c,$CLJS.B(a),$CLJS.B(b)),a=$CLJS.C(a),b=$CLJS.C(b);else return $CLJS.dc(c)};$CLJS.dw=function(a,b){return $CLJS.kh(a,b)};$CLJS.ew=function(a,b){return new $CLJS.le(null,function(){var c=$CLJS.z(b);if(c){var d=$CLJS.B(c);d=a.h?a.h(d):a.call(null,d);c=$CLJS.n(d)?$CLJS.ce($CLJS.B(c),$CLJS.ew(a,$CLJS.Kc(c))):null}else c=null;return c},null,null)};
$CLJS.fw=function(a,b){return $CLJS.cf.j(function(c){return c},b,$CLJS.We(a,b))};iw=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;$CLJS.O.g(gw,0<b.length?new $CLJS.y(b.slice(0),0,null):null);$CLJS.Zb($CLJS.hw,"\n")};jw=function(a){if("number"===typeof a)return a;if("string"===typeof a&&1===a.length)return a.charCodeAt(0);throw Error("Argument to char must be a character or number");};
kw=function(a,b,c){var d=c;for(c=$CLJS.zf;;){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null);var e=$CLJS.B(d);d=$CLJS.C(d);e=$CLJS.O.g(a,new $CLJS.P(null,2,5,$CLJS.Q,[e,b],null));b=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);c=$CLJS.$d.g(c,b);b=e}};lw=function(a,b){var c=b;for(b=$CLJS.zf;;){var d=$CLJS.O.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[c],null));c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);if($CLJS.Sa(c))return new $CLJS.P(null,2,5,$CLJS.Q,[b,d],null);b=$CLJS.$d.g(b,c);c=d}};
mw=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dg.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.ld(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);var v=$CLJS.H(t,0,null);$CLJS.H(t,1,null);l.add(new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null));m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.kc(e))):$CLJS.re($CLJS.te(l),null)}f=
$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);k=$CLJS.H(f,0,null);$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}()),$CLJS.dg.g($CLJS.N,function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.ld(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);l.add(new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null));m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.kc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[l,f],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())],null)};
nw=function(a,b){return $CLJS.dg.g($CLJS.N,function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);a:for(var t=0;;)if(t<l){var u=$CLJS.ld(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);m.add(new $CLJS.P(null,2,5,$CLJS.Q,[v,new $CLJS.P(null,2,5,$CLJS.Q,[u,b],null)],null));t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.kc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);
k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[m,new $CLJS.P(null,2,5,$CLJS.Q,[k,b],null)],null),e($CLJS.Kc(f)))}return null}},null,null)}(a)}())};$CLJS.ow=function(){var a=$CLJS.hw;if(null!=a&&null!=a.cf)a.cf(a);else{var b=$CLJS.ow[$CLJS.wa(null==a?null:a)];if(null!=b)b.h?b.h(a):b.call(null,a);else if(b=$CLJS.ow._,null!=b)b.h?b.h(a):b.call(null,a);else throw $CLJS.Ya("IPrettyFlush.-ppflush",a);}};$CLJS.pw=function(a,b){a=$CLJS.q($CLJS.q(a));return b.h?b.h(a):b.call(null,a)};
qw=function(a,b,c){$CLJS.Nh.A($CLJS.q(a),$CLJS.R,b,c)};tw=function(a,b){$CLJS.F.g(b,"\n")?(qw(a,$CLJS.rw,0),qw(a,$CLJS.kj,$CLJS.pw(a,$CLJS.kj)+1)):qw(a,$CLJS.rw,$CLJS.pw(a,$CLJS.rw)+1);return $CLJS.Zb($CLJS.pw(a,sw),b)};uw=function(a,b,c,d){this.Aa=a;this.uf=b;this.Rc=c;this.jh=d;this.C=1074167808;this.I=0};
vw=function(a,b,c,d,e,f,k,l,m,t,u,v,x){this.parent=a;this.zb=b;this.qb=c;this.pb=d;this.sb=e;this.tb=f;this.prefix=k;this.xb=l;this.Ab=m;this.ub=t;this.S=u;this.G=v;this.J=x;this.C=2230716170;this.I=139264};xw=function(a,b){for(b=ww.h(b);;){if(null==b)return!1;if(a===b)return!0;b=ww.h(b)}};yw=function(a,b,c,d,e,f,k,l){this.ba=a;this.data=b;this.Wb=c;this.aa=d;this.$=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};
zw=function(a,b,c,d,e,f,k,l){this.ba=a;this.type=b;this.ea=c;this.aa=d;this.$=e;this.S=f;this.G=k;this.J=l;this.C=2230716170;this.I=139264};Aw=function(a,b,c,d,e,f,k){this.ba=a;this.ea=b;this.aa=c;this.$=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};Bw=function(a,b,c,d,e,f,k){this.ba=a;this.ea=b;this.aa=c;this.$=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
Cw=function(a,b,c,d,e,f,k,l,m){this.ba=a;this.ea=b;this.Rb=c;this.offset=d;this.aa=e;this.$=f;this.S=k;this.G=l;this.J=m;this.C=2230716170;this.I=139264};
Hw=function(a,b,c){b=$CLJS.z(b);for(var d=null,e=0,f=0;;)if(f<e){var k=d.X(null,f);if(!$CLJS.F.g(Dw.h(k),Ew)){var l=Fw.h($CLJS.q($CLJS.q(a)));$CLJS.n(l)&&$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),l)}Gw.g(a,k);$CLJS.Nh.A($CLJS.q(a),$CLJS.R,Fw,Fw.h(k));k=Fw.h($CLJS.q($CLJS.q(a)));$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),k),$CLJS.Nh.A($CLJS.q(a),$CLJS.R,Fw,null));f+=1}else if(b=$CLJS.z(b))$CLJS.Ad(b)?(d=$CLJS.jc(b),b=$CLJS.kc(b),k=d,e=$CLJS.E(d),d=k):(k=$CLJS.B(b),$CLJS.F.g(Dw.h(k),Ew)||
(d=Fw.h($CLJS.q($CLJS.q(a))),$CLJS.n(d)&&$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),d)),Gw.g(a,k),$CLJS.Nh.A($CLJS.q(a),$CLJS.R,Fw,Fw.h(k)),k=Fw.h($CLJS.q($CLJS.q(a))),$CLJS.n($CLJS.n(c)?k:c)&&($CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),k),$CLJS.Nh.A($CLJS.q(a),$CLJS.R,Fw,null)),b=$CLJS.C(b),d=null,e=0),f=0;else break};Kw=function(a,b){var c=$CLJS.pw(sw.h($CLJS.q($CLJS.q(a))),$CLJS.fk),d;(d=null==c)||(a=$CLJS.pw(sw.h($CLJS.q($CLJS.q(a))),$CLJS.rw),b=(b=$CLJS.z(b))?Iw.h($CLJS.jd(b))-Jw.h($CLJS.B(b)):0,d=a+b<c);return d};
Mw=function(a,b,c){b=$CLJS.q(Lw.h(b));return $CLJS.n(b)?b:!Kw(a,c)};Pw=function(a,b,c){var d=Nw.h($CLJS.q($CLJS.q(a))),e=$CLJS.pw(sw.h($CLJS.q($CLJS.q(a))),$CLJS.fk);return $CLJS.n(d)?$CLJS.n(e)?(d=$CLJS.q(Ow.h(b))>=e-d)?Mw(a,b,c):d:e:d};Rw=function(a){var b=$CLJS.B(a),c=Qw.h(b);b=$CLJS.z($CLJS.ew(function(d){return!($CLJS.F.g(Dw.h(d),Ew)&&xw(Qw.h(d),c))},$CLJS.C(a)));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.z($CLJS.We($CLJS.E(b)+1,a))],null)};
Sw=function(a){var b=$CLJS.B(a),c=Qw.h(b);return $CLJS.z($CLJS.ew(function(d){var e=Qw.h(d);return!($CLJS.F.g(Dw.h(d),Ew)&&($CLJS.F.g(e,c)||xw(e,c)))},$CLJS.C(a)))};
Ww=function(a,b){$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.Nh.A($CLJS.q(a),$CLJS.R,Fw,null);b=Qw.h(b);var c=Tw.h(b);$CLJS.n(c)&&$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),c);c=$CLJS.O.g($CLJS.p,$CLJS.Ye($CLJS.q(Uw.h(b))-$CLJS.E(c)," "));$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),c);a:for($CLJS.Se(Vw.h(b),!0),$CLJS.Se(Lw.h(b),!0),a=ww.h(b);;)if($CLJS.n(a))$CLJS.Se(Lw.h(a),!0),$CLJS.Se(Vw.h(a),!0),a=ww.h(a);else break a;return null};
Xw=function(a){var b=$CLJS.z($CLJS.ew(function(c){return!$CLJS.F.g(Dw.h(c),Ew)},a));return new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.z($CLJS.We($CLJS.E(b),a))],null)};Zw=function(a){for(var b=$CLJS.Uu.h($CLJS.q($CLJS.q(a)));;){$CLJS.Nh.A($CLJS.q(a),$CLJS.R,$CLJS.Uu,$CLJS.dg.g($CLJS.zf,b));if(Kw(a,b))return null;var c=Yw(a,b);if(b!==c)b=c;else return null}};
$w=function(a,b){$CLJS.Nh.A($CLJS.q(a),$CLJS.R,$CLJS.Uu,$CLJS.$d.g($CLJS.Uu.h($CLJS.q($CLJS.q(a))),b));return Kw(a,$CLJS.Uu.h($CLJS.q($CLJS.q(a))))?null:Zw(a)};ax=function(a){var b=Fw.h($CLJS.q($CLJS.q(a)));return $CLJS.n(b)?($CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),b),$CLJS.Nh.A($CLJS.q(a),$CLJS.R,Fw,null)):null};
gx=function(a,b){var c=$CLJS.Ru(b,"\n",-1);if($CLJS.F.g($CLJS.E(c),1))return b;b=Tw.h($CLJS.B(bx.h($CLJS.q($CLJS.q(a)))));var d=$CLJS.B(c);if($CLJS.F.g(cx,$CLJS.xi.h($CLJS.q($CLJS.q(a))))){var e=dx.h($CLJS.q($CLJS.q(a))),f=e+$CLJS.E(d);$CLJS.Nh.A($CLJS.q(a),$CLJS.R,dx,f);$w(a,new yw(ex,d,null,e,f,null,null,null));Zw(a);d=$CLJS.Uu.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(Hw(a,d,!0),$CLJS.Nh.A($CLJS.q(a),$CLJS.R,$CLJS.Uu,$CLJS.zf))}else ax(a),$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),d);$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),
"\n");d=$CLJS.z($CLJS.C($CLJS.Mu(c)));e=null;for(var k=f=0;;)if(k<f){var l=e.X(null,k);$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),l);$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),"\n");$CLJS.n(b)&&$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),b);k+=1}else if(d=$CLJS.z(d))e=d,$CLJS.Ad(e)?(d=$CLJS.jc(e),k=$CLJS.kc(e),e=d,f=$CLJS.E(d),d=k):(d=$CLJS.B(e),$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),d),$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),"\n"),$CLJS.n(b)&&$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),b),d=$CLJS.C(e),e=null,f=0),k=0;else break;$CLJS.Nh.A($CLJS.q(a),
$CLJS.R,cx,fx);return $CLJS.jd(c)};hx=function(a,b,c,d,e,f){this.Aa=a;this.uf=b;this.Rh=c;this.Sg=d;this.Rc=e;this.rh=f;this.C=1074167808;this.I=0};
$CLJS.ox=function(a){var b=$CLJS.ix,c=jx,d=new vw(null,null,$CLJS.Re(0),$CLJS.Re(0),$CLJS.Re(!1),$CLJS.Re(!1),null,null,null,null,null,null,null),e=$CLJS.Re($CLJS.Hg([bx,Nw,kx,lx,mx,$CLJS.xi,dx,Fw,sw,nx,$CLJS.Uu],[d,c,d,!0,null,fx,0,null,new uw(a,b,$CLJS.Re(new $CLJS.h(null,4,[$CLJS.fk,b,$CLJS.rw,0,$CLJS.kj,0,sw,a],null)),$CLJS.N),1,$CLJS.zf]));return new hx(a,b,c,d,e,$CLJS.N)};
sx=function(a,b){var c=$CLJS.hw;b=new vw(bx.h($CLJS.q($CLJS.q(c))),null,$CLJS.Re(0),$CLJS.Re(0),$CLJS.Re(!1),$CLJS.Re(!1),a,null,b,null,null,null,null);$CLJS.Nh.A($CLJS.q(c),$CLJS.R,bx,b);if($CLJS.F.g($CLJS.xi.h($CLJS.q($CLJS.q(c))),fx)){ax(c);var d=px.h($CLJS.q($CLJS.q(c)));$CLJS.n(d)&&(d.h?d.h($CLJS.qx):d.call(null,$CLJS.qx));$CLJS.n(a)&&$CLJS.Zb(sw.h($CLJS.q($CLJS.q(c))),a);c=$CLJS.pw(sw.h($CLJS.q($CLJS.q(c))),$CLJS.rw);$CLJS.Se(b.qb,c);$CLJS.Se(b.pb,c)}else d=dx.h($CLJS.q($CLJS.q(c))),a=d+($CLJS.n(a)?
$CLJS.E(a):0),$CLJS.Nh.A($CLJS.q(c),$CLJS.R,dx,a),$w(c,new Aw(rx,b,d,a,null,null,null))};
wx=function(){var a=$CLJS.hw,b=bx.h($CLJS.q($CLJS.q(a))),c=tx.h(b);if($CLJS.F.g($CLJS.xi.h($CLJS.q($CLJS.q(a))),fx)){ax(a);$CLJS.n(c)&&$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),c);var d=px.h($CLJS.q($CLJS.q(a)));$CLJS.n(d)&&(d.h?d.h(ux):d.call(null,ux))}else d=dx.h($CLJS.q($CLJS.q(a))),c=d+($CLJS.n(c)?$CLJS.E(c):0),$CLJS.Nh.A($CLJS.q(a),$CLJS.R,dx,c),$w(a,new Bw(vx,b,d,c,null,null,null));$CLJS.Nh.A($CLJS.q(a),$CLJS.R,bx,ww.h(b))};
$CLJS.xx=function(a){var b=null!=a?a.C&32768||$CLJS.r===a.Hf?!0:a.C?!1:$CLJS.Wa(Wv,a):$CLJS.Wa(Wv,a);return b?lx.h($CLJS.q($CLJS.q(a))):b};$CLJS.Bx=function(a){var b=yx;$CLJS.n(b)&&(b=$CLJS.Ma,b=$CLJS.n(b)?yx>=$CLJS.Ma:b);$CLJS.Sa($CLJS.zx)?gw.call(null,a):$CLJS.n(b)?$CLJS.Zb($CLJS.hw,"..."):($CLJS.n(yx)&&(yx+=1),Ax.call(null,a));return b};Cx=function(a,b){if($CLJS.Sa(b.h?b.h(a):b.call(null,a)))throw Error(["Bad argument: ",$CLJS.p.h(a),". It must be one of ",$CLJS.p.h(b)].join(""));};
Ex=function(){var a=$CLJS.ph;return $CLJS.n(a)?Dx>=$CLJS.ph:a};Jx=function(a){Cx(a,new $CLJS.$g(null,new $CLJS.h(null,4,[Fx,null,Gx,null,Hx,null,Ix,null],null),null));var b=$CLJS.hw;$CLJS.Nh.A($CLJS.q(b),$CLJS.R,$CLJS.xi,cx);var c=dx.h($CLJS.q($CLJS.q(b))),d=bx.h($CLJS.q($CLJS.q(b)));$w(b,new zw(Ew,a,d,c,c,null,null,null))};
Nx=function(a,b){Cx(a,new $CLJS.$g(null,new $CLJS.h(null,2,[Kx,null,$CLJS.Lx,null],null),null));var c=$CLJS.hw,d=bx.h($CLJS.q($CLJS.q(c)));if($CLJS.F.g($CLJS.xi.h($CLJS.q($CLJS.q(c))),fx)){ax(c);var e=Uw.h(d);if($CLJS.n($CLJS.F.g?$CLJS.F.g(Kx,a):$CLJS.F.call(null,Kx,a)))a=$CLJS.q(Ow.h(d));else if($CLJS.n($CLJS.F.g?$CLJS.F.g($CLJS.Lx,a):$CLJS.F.call(null,$CLJS.Lx,a)))a=$CLJS.pw(sw.h($CLJS.q($CLJS.q(c))),$CLJS.rw);else throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));$CLJS.Se(e,b+a)}else e=
dx.h($CLJS.q($CLJS.q(c))),$w(c,new Cw(Mx,d,a,b,e,e,null,null,null))};Sx=function(a,b,c){b="string"===typeof b?Ox(b):b;c=Px(c);a:{var d=new $CLJS.Fa,e=$CLJS.Sa(a)||!0===a?new $CLJS.qc(d):a,f=Qx(b)&&$CLJS.Sa($CLJS.xx(e))?$CLJS.n($CLJS.xx(e))?e:$CLJS.ox(e):e,k=$CLJS.hw;$CLJS.hw=f;try{try{Rx(b,c)}finally{e!==f&&Xv(f)}var l=$CLJS.Sa(a)?$CLJS.p.h(d):!0===a?$CLJS.th($CLJS.p.h(d)):null;break a}finally{$CLJS.hw=k}l=void 0}return l};
Ux=function(a,b){a=[$CLJS.p.h(a),"\n",$CLJS.p.h(Tx),"\n",$CLJS.p.h($CLJS.O.g($CLJS.p,$CLJS.Ye(b," "))),"^\n"].join("");throw Error(a);};Vx=function(a,b,c,d,e,f){this.mc=a;this.mb=b;this.Vb=c;this.S=d;this.G=e;this.J=f;this.C=2230716170;this.I=139264};Px=function(a){a=$CLJS.z(a);return new Vx(a,a,0,null,null,null)};
Xx=function(a){var b=$CLJS.Pi.h(a);if($CLJS.n(b))return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.B(b),new Vx(Wx.h(a),$CLJS.C(b),dx.h(a)+1,null,null,null)],null);throw Error("Not enough arguments for format definition");};Yx=function(a){var b=Xx(a);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);a="string"===typeof a?Ox(a):a;return new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null)};$x=function(a,b){return b>=dx.h(a)?(b=dx.h(a)-b,Zx.g?Zx.g(a,b):Zx.call(null,a,b)):new Vx(Wx.h(a),$CLJS.We(b,Wx.h(a)),b,null,null,null)};
Zx=function(a,b){var c=dx.h(a)+b;return 0>b?$x(a,c):new Vx(Wx.h(a),$CLJS.We(b,$CLJS.Pi.h(a)),c,null,null,null)};ay=function(a,b,c,d,e,f,k){this.$b=a;this.Zb=b;this.fc=c;this.offset=d;this.S=e;this.G=f;this.J=k;this.C=2230716170;this.I=139264};
fy=function(a,b){var c=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);var d=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);d=$CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,2,[by,null,cy,null],null),null),c)?new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null):$CLJS.F.g(d,dy)?Xx(b):$CLJS.F.g(d,ey)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E($CLJS.Pi.h(b)),b],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.P(null,2,5,$CLJS.Q,
[b,a],null)],null),d],null)};gy=function(a,b){b=kw(fy,b,a);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dg.g($CLJS.N,a),b],null)};
qy=function(a,b,c){c=Xx(c);var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.Fd(d))if($CLJS.F.g(hy,10))var e=[$CLJS.p.h(d),$CLJS.n(iy)?".":null].join("");else{e=$CLJS.p;var f=e.h;if($CLJS.n(iy)){var k=$CLJS.I.g(jy,hy);k=$CLJS.n(k)?k:["#",$CLJS.p.h(hy),"r"].join("")}else k=null;e=[f.call(e,k),$CLJS.p.h(ky(hy,d))].join("")}else e=null;a=$CLJS.n(e)?e:a.h?a.h(d):a.call(null,d);d=a.length;e=d+ly.h(b);e=e>=my.h(b)?e:e+($CLJS.Ud(my.h(b)-e-1,ny.h(b))+1)*ny.h(b);d=$CLJS.O.g($CLJS.p,$CLJS.Ye(e-d,oy.h(b)));
$CLJS.n(cy.h(b))?py.l($CLJS.G([[$CLJS.p.h(d),$CLJS.p.h(a)].join("")])):py.l($CLJS.G([[$CLJS.p.h(a),$CLJS.p.h(d)].join("")]));return c};ry=function(a,b){return $CLJS.ae($CLJS.B(lw(function(c){return 0<c?new $CLJS.P(null,2,5,$CLJS.Q,[aw(c,a),$CLJS.Ud(c,a)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null)},b)))};ky=function(a,b){return 0===b?"0":$CLJS.O.g($CLJS.p,$CLJS.cf.g(function(c){return 10>c?$v(jw("0")+c):$v(jw("a")+(c-10))},ry(a,b)))};
sy=function(a,b){return $CLJS.ae($CLJS.B(lw(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.z($CLJS.ae($CLJS.Ve(a,c))),$CLJS.z($CLJS.We(a,c))],null)},$CLJS.ae(b))))};
wy=function(a,b,c){c=Xx(c);var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.Fd(d)||"number"===typeof d&&!isNaN(d)&&Infinity!==d&&parseFloat(d)!==parseInt(d,10)&&$CLJS.F.g(d,Math.floor(d))){var e=0>d,f=ky(a,e?-d:d);a=$CLJS.n(by.h(b))?function(){var k=$CLJS.cf.g(function(m){return $CLJS.O.g($CLJS.p,m)},sy(ty.h(b),f)),l=$CLJS.Ye($CLJS.E(k),uy.h(b));return $CLJS.O.g($CLJS.p,$CLJS.C($CLJS.Ze.g(l,k)))}():f;a=e?["-",$CLJS.p.h(a)].join(""):$CLJS.n(cy.h(b))?["+",$CLJS.p.h(a)].join(""):a;a=a.length<my.h(b)?
[$CLJS.p.h($CLJS.O.g($CLJS.p,$CLJS.Ye(my.h(b)-a.length,oy.h(b)))),$CLJS.p.h(a)].join(""):a;py.l($CLJS.G([a]))}else qy($CLJS.vy,new $CLJS.h(null,5,[my,my.h(b),ny,1,ly,0,oy,oy.h(b),cy,!0],null),Px(new $CLJS.P(null,1,5,$CLJS.Q,[d],null)));return c};
zy=function(a){var b=$CLJS.Ud(a,100);a=aw(a,100);var c=0<b?[$CLJS.p.h($CLJS.bd(xy,b))," hundred"].join(""):null,d=$CLJS.p,e=d.h;if(0<a)if(20>a)var f=$CLJS.bd(xy,a);else{f=$CLJS.Ud(a,10);var k=aw(a,10);f=[$CLJS.p.h(0<f?$CLJS.bd(yy,f):null),0<f&&0<k?"-":null,$CLJS.p.h(0<k?$CLJS.bd(xy,k):null)].join("")}else f=null;return[c,0<b&&0<a?" ":null,e.call(d,f)].join("")};
By=function(a,b){var c=$CLJS.E(a),d=$CLJS.zf;--c;var e=$CLJS.B(a);for(a=$CLJS.C(a);;){if(null==a)return[$CLJS.p.h($CLJS.O.g($CLJS.p,$CLJS.$e(", ",d))),$CLJS.td(e)||$CLJS.td(d)?null:", ",$CLJS.p.h(e),!$CLJS.td(e)&&0<c+b?[" ",$CLJS.p.h($CLJS.bd(Ay,c+b))].join(""):null].join("");d=$CLJS.td(e)?d:$CLJS.$d.g(d,[$CLJS.p.h(e)," ",$CLJS.p.h($CLJS.bd(Ay,c+b))].join(""));--c;e=$CLJS.B(a);a=$CLJS.C(a)}};
Cy=function(a,b){b=Xx(b);var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);if("number"===typeof c&&0<c&&4E3>c){var d=ry(10,c);c=$CLJS.zf;for(var e=$CLJS.E(d)-1;;)if($CLJS.td(d)){py.l($CLJS.G([$CLJS.O.g($CLJS.p,c)]));break}else{var f=$CLJS.B(d);c=$CLJS.F.g(0,f)?c:$CLJS.$d.g(c,$CLJS.bd($CLJS.bd(a,e),f-1));--e;d=$CLJS.C(d)}}else wy(10,new $CLJS.h(null,5,[my,0,oy," ",uy,",",ty,3,by,!0],null),Px(new $CLJS.P(null,1,5,$CLJS.Q,[c],null)));return b};
Ey=function(a,b){a=Xx(b);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);var c=jw(b);b=c&127;c&=128;var d=$CLJS.I.g(Dy,b);0<c&&py.l($CLJS.G(["Meta-"]));py.l($CLJS.G([$CLJS.n(d)?d:32>b?["Control-",$CLJS.p.h($v(b+64))].join(""):$CLJS.F.g(b,127)?"Control-?":$v(b)]));return a};
Gy=function(a,b){var c=Xx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);a=Fy.h(a);if($CLJS.n($CLJS.F.g?$CLJS.F.g("o",a):$CLJS.F.call(null,"o",a)))Sx(!0,"\\o~3,'0o",$CLJS.G([jw(b)]));else if($CLJS.n($CLJS.F.g?$CLJS.F.g("u",a):$CLJS.F.call(null,"u",a)))Sx(!0,"\\u~4,'0x",$CLJS.G([jw(b)]));else if($CLJS.n($CLJS.F.g?$CLJS.F.g(null,a):$CLJS.F.call(null,null,a)))$CLJS.Zb($CLJS.hw,$CLJS.n($CLJS.F.g?$CLJS.F.g("\b",b):$CLJS.F.call(null,"\b",b))?"\\backspace":$CLJS.n($CLJS.F.g?$CLJS.F.g(" ",b):$CLJS.F.call(null,
" ",b))?"\\space":$CLJS.n($CLJS.F.g?$CLJS.F.g("\t",b):$CLJS.F.call(null,"\t",b))?"\\tab":$CLJS.n($CLJS.F.g?$CLJS.F.g("\n",b):$CLJS.F.call(null,"\n",b))?"\\newline":$CLJS.n($CLJS.F.g?$CLJS.F.g("\f",b):$CLJS.F.call(null,"\f",b))?"\\formfeed":$CLJS.n($CLJS.F.g?$CLJS.F.g("\r",b):$CLJS.F.call(null,"\r",b))?"\\return":$CLJS.n($CLJS.F.g?$CLJS.F.g('"',b):$CLJS.F.call(null,'"',b))?'\\"':$CLJS.n($CLJS.F.g?$CLJS.F.g("\\",b):$CLJS.F.call(null,"\\",b))?"\\\\":["\\",$CLJS.p.h(b)].join(""));else throw Error(["No matching clause: ",
$CLJS.p.h(a)].join(""));return c};Hy=function(a,b){b=Xx(b);a=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);py.l($CLJS.G([a]));return b};Ky=function(a){a=$CLJS.B(a);return $CLJS.F.g(Iy,a)||$CLJS.F.g(Jy,a)};
Oy=function(a,b,c){return $CLJS.id(kw(function(d,e){if(Ky(e))return new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=gy(Ly.h(d),e);var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);var k=mw(f);f=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);f=$CLJS.R.j(f,My,c);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.O.g(Ny.h(d),new $CLJS.P(null,3,5,$CLJS.Q,[f,e,k],null))],null)},b,a))};
Py=function(a){a=$CLJS.p.h(a).toLowerCase();var b=a.indexOf("e"),c=a.indexOf(".");a=0>b?0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a,$CLJS.p.h(a.length-1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,c),a.substring(c+1)].join(""),$CLJS.p.h(c-1)],null):0>c?new $CLJS.P(null,2,5,$CLJS.Q,[a.substring(0,b),a.substring(b+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[[a.substring(0,1),a.substring(2,b)].join(""),a.substring(b+1)],null);b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);a:if(c=$CLJS.E(b),0<c&&$CLJS.F.g($CLJS.bd(b,
$CLJS.E(b)-1),"0"))for(--c;;){if(0>c){b="";break a}if($CLJS.F.g($CLJS.bd(b,c),"0"))--c;else{b=b.substring(0,c+1);break a}}a:{c=b;var d=$CLJS.E(c);if(0<d&&$CLJS.F.g($CLJS.bd(c,0),"0"))for(var e=0;;){if($CLJS.F.g(e,d)||!$CLJS.F.g($CLJS.bd(c,e),"0")){c=c.substring(e);break a}e+=1}}b=$CLJS.E(b)-$CLJS.E(c);a=0<$CLJS.E(a)&&$CLJS.F.g($CLJS.bd(a,0),"+")?a.substring(1):a;return $CLJS.td(c)?new $CLJS.P(null,2,5,$CLJS.Q,["0",0],null):new $CLJS.P(null,2,5,$CLJS.Q,[c,parseInt(a,10)-b],null)};
Qy=function(a,b,c,d){if($CLJS.n($CLJS.n(c)?c:d)){var e=$CLJS.E(a);d=$CLJS.n(d)?2>d?2:d:0;$CLJS.n(c)?c=b+c+1:0<=b?(c=b+1,--d,c=c>d?c:d):c=d+b;var f=$CLJS.F.g(c,0)?new $CLJS.P(null,4,5,$CLJS.Q,[["0",$CLJS.p.h(a)].join(""),b+1,1,e+1],null):new $CLJS.P(null,4,5,$CLJS.Q,[a,b,c,e],null);c=$CLJS.H(f,0,null);e=$CLJS.H(f,1,null);d=$CLJS.H(f,2,null);f=$CLJS.H(f,3,null);if($CLJS.n(d)){if(0>d)return new $CLJS.P(null,3,5,$CLJS.Q,["0",0,!1],null);if(f>d){b=$CLJS.bd(c,d);a=c.substring(0,d);if(jw(b)>=jw("5")){a:for(b=
$CLJS.E(a)-1,c=b|0;;){if(0>c){b=$CLJS.O.j($CLJS.p,"1",$CLJS.Ye(b+1,"0"));break a}if($CLJS.F.g("9",a.charAt(c)))--c;else{b=$CLJS.O.A($CLJS.p,a.substring(0,c),$v(jw(a.charAt(c))+1),$CLJS.Ye(b-c,"0"));break a}}a=$CLJS.E(b)>a.length;c=$CLJS.Q;a&&(d=$CLJS.E(b)-1,b=b.substring(0,d));return new $CLJS.P(null,3,5,c,[b,e,a],null)}return new $CLJS.P(null,3,5,$CLJS.Q,[a,e,!1],null)}}}return new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null)};
Ry=function(a,b,c){var d=0>b?new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h($CLJS.O.g($CLJS.p,$CLJS.Ye(-b-1,"0"))),$CLJS.p.h(a)].join(""),-1],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);a=$CLJS.H(d,0,null);var e=$CLJS.H(d,1,null);d=$CLJS.E(a);c=$CLJS.n(c)?e+c+1:e+1;c=d<c?[$CLJS.p.h(a),$CLJS.p.h($CLJS.O.g($CLJS.p,$CLJS.Ye(c-d,"0")))].join(""):a;0>b?b=[".",$CLJS.p.h(c)].join(""):(b+=1,b=[c.substring(0,b),".",c.substring(b)].join(""));return b};
Sy=function(a,b){return 0>b?[".",$CLJS.p.h(a)].join(""):[a.substring(0,b),".",a.substring(b)].join("")};
Xy=function(a,b){var c=Ty.h(a),d=Uy.h(a);b=Xx(b);var e=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var f=0>e?new $CLJS.P(null,2,5,$CLJS.Q,["-",-e],null):new $CLJS.P(null,2,5,$CLJS.Q,["+",e],null),k=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);f=Py(f);var l=$CLJS.H(f,0,null),m=$CLJS.H(f,1,null)+$CLJS.Vy.h(a);f=function(){var x=cy.h(a);return $CLJS.n(x)?x:0>e}();var t=$CLJS.Sa(d)&&$CLJS.E(l)-1<=m,u=Qy(l,m,d,$CLJS.n(c)?c-($CLJS.n(f)?1:0):null);l=$CLJS.H(u,0,null);m=$CLJS.H(u,1,null);u=$CLJS.H(u,2,null);l=Ry(l,$CLJS.n(u)?
m+1:m,d);d=$CLJS.n($CLJS.n(c)?$CLJS.n(d)?1<=d&&$CLJS.F.g(l.charAt(0),"0")&&$CLJS.F.g(l.charAt(1),".")&&l.length>c-($CLJS.n(f)?1:0):d:c)?l.substring(1):l;m=$CLJS.F.g($CLJS.B(d),".");if($CLJS.n(c)){l=d.length;l=$CLJS.n(f)?l+1:l;m=m&&!(l>=c);t=t&&!(l>=c);var v=m||t?l+1:l;$CLJS.n(function(){var x=v>c;return x?Wy.h(a):x}())?py.l($CLJS.G([$CLJS.O.g($CLJS.p,$CLJS.Ye(c,Wy.h(a)))])):py.l($CLJS.G([[$CLJS.p.h($CLJS.O.g($CLJS.p,$CLJS.Ye(c-v,oy.h(a)))),$CLJS.p.h($CLJS.n(f)?k:null),m?"0":null,d,t?"0":null].join("")]))}else py.l($CLJS.G([[$CLJS.p.h($CLJS.n(f)?
k:null),m?"0":null,d,t?"0":null].join("")]));return b};
$y=function(a,b){b=Xx(b);var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=Py(0>c?-c:c);$CLJS.H(d,0,null);for($CLJS.H(d,1,null);;){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null),k=Ty.h(a),l=Uy.h(a),m=Yy.h(a),t=$CLJS.Vy.h(a),u=function(){var K=Zy.h(a);return $CLJS.n(K)?K:"E"}();d=function(){var K=cy.h(a);return $CLJS.n(K)?K:0>c}();var v=0>=t,x=f-(t-1),A=$CLJS.p.h(Math.abs(x));u=[$CLJS.p.h(u),0>x?"-":"+",$CLJS.p.h($CLJS.n(m)?$CLJS.O.g($CLJS.p,$CLJS.Ye(m-A.length,"0")):null),A].join("");var D=u.length;
x=$CLJS.E(e);e=[$CLJS.p.h($CLJS.O.g($CLJS.p,$CLJS.Ye(-t,"0"))),$CLJS.p.h(e),$CLJS.p.h($CLJS.n(l)?$CLJS.O.g($CLJS.p,$CLJS.Ye(l-(x-1)-(0>t?-t:0),"0")):null)].join("");x=$CLJS.n(k)?k-D:null;e=Qy(e,0,$CLJS.F.g(t,0)?l-1:0<t?l:0>t?l-1:null,$CLJS.n(x)?x-($CLJS.n(d)?1:0):null);x=$CLJS.H(e,0,null);$CLJS.H(e,1,null);A=$CLJS.H(e,2,null);e=Sy(x,t);l=$CLJS.F.g(t,$CLJS.E(x))&&null==l;if($CLJS.Sa(A)){if($CLJS.n(k)){f=e.length+D;f=$CLJS.n(d)?f+1:f;var J=(v=v&&!$CLJS.F.g(f,k))?f+1:f;f=l&&J<k;$CLJS.n(function(){var K=
J>k;K||(K=m,K=$CLJS.n(K)?D-2>m:K);return $CLJS.n(K)?Wy.h(a):K}())?py.l($CLJS.G([$CLJS.O.g($CLJS.p,$CLJS.Ye(k,Wy.h(a)))])):py.l($CLJS.G([[$CLJS.p.h($CLJS.O.g($CLJS.p,$CLJS.Ye(k-J-(f?1:0),oy.h(a)))),$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,f?"0":null,u].join("")]))}else py.l($CLJS.G([[$CLJS.n(d)?0>c?"-":"+":null,v?"0":null,e,l?"0":null,u].join("")]));break}else d=new $CLJS.P(null,2,5,$CLJS.Q,[x,f+1],null)}return b};
az=function(a,b,c){var d=Xx(b),e=$CLJS.H(d,0,null);$CLJS.H(d,1,null);d=Py(0>e?-e:e);var f=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var k=Ty.h(a),l=Uy.h(a),m=Yy.h(a);d=$CLJS.F.g(e,0)?0:d+1;e=$CLJS.n(m)?m+2:4;k=$CLJS.n(k)?k-e:null;$CLJS.n(l)?f=l:(f=$CLJS.E(f),l=7>d?d:7,f=f>l?f:l);d=f-d;return 0<=d&&d<=f?(a=Xy(new $CLJS.h(null,6,[Ty,k,Uy,d,$CLJS.Vy,0,Wy,Wy.h(a),oy,oy.h(a),cy,cy.h(a)],null),b,c),py.l($CLJS.G([$CLJS.O.g($CLJS.p,$CLJS.Ye(e," "))])),a):$y(a,b,c)};
cz=function(a,b){b=Xx(b);var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=Py(Math.abs(c)),e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null),k=Uy.h(a),l=$CLJS.bz.h(a);d=Ty.h(a);var m=function(){var u=cy.h(a);return $CLJS.n(u)?u:0>c}(),t=Qy(e,f,k,null);e=$CLJS.H(t,0,null);f=$CLJS.H(t,1,null);t=$CLJS.H(t,2,null);k=Ry(e,$CLJS.n(t)?f+1:f,k);l=[$CLJS.p.h($CLJS.O.g($CLJS.p,$CLJS.Ye(l-k.indexOf("."),"0"))),k].join("");k=l.length+($CLJS.n(m)?1:0);py.l($CLJS.G([[$CLJS.n(function(){var u=by.h(a);return $CLJS.n(u)?m:
u}())?0>c?"-":"+":null,$CLJS.p.h($CLJS.O.g($CLJS.p,$CLJS.Ye(d-k,oy.h(a)))),$CLJS.n(function(){var u=$CLJS.Sa(by.h(a));return u?m:u}())?0>c?"-":"+":null,l].join("")]));return b};fz=function(a,b){var c=dz.h(a);c=$CLJS.n(c)?new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null):Xx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var d=$CLJS.ez.h(a);b=0>b||b>=$CLJS.E(d)?$CLJS.B($CLJS.Yu.h(a)):$CLJS.bd(d,b);return $CLJS.n(b)?Oy(b,c,My.h(a)):c};
gz=function(a,b){var c=Xx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var d=$CLJS.ez.h(a);b=$CLJS.n(b)?$CLJS.id(d):$CLJS.B(d);return $CLJS.n(b)?Oy(b,c,My.h(a)):c};hz=function(a,b){var c=Xx(b),d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var e=$CLJS.ez.h(a);e=$CLJS.n(d)?$CLJS.B(e):null;return $CLJS.n(d)?$CLJS.n(e)?Oy(e,b,My.h(a)):b:c};
kz=function(a,b){var c=iz.h(a),d=$CLJS.B($CLJS.ez.h(a));d=$CLJS.td(d)?Yx(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);d=Xx(d);var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var f=0;e=Px(e);for(var k=-1;;){if($CLJS.Sa(c)&&$CLJS.F.g(dx.h(e),k)&&1<f)throw Error("%{ construct not consuming any arguments: Infinite loop!");k=$CLJS.td($CLJS.Pi.h(e))&&($CLJS.Sa(by.h(jz.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Oy(b,e,My.h(a));if($CLJS.F.g(Iy,$CLJS.B(k)))return d;
f+=1;var l=dx.h(e);e=k;k=l}};lz=function(a,b){var c=iz.h(a),d=$CLJS.B($CLJS.ez.h(a));d=$CLJS.td(d)?Yx(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);d=Xx(d);var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);for(var f=0;;){var k=$CLJS.td(e)&&($CLJS.Sa(by.h(jz.h(a)))||0<f);if($CLJS.n(k?k:$CLJS.n(c)?f>=c:c))return d;k=Oy(b,Px($CLJS.B(e)),Px($CLJS.C(e)));if($CLJS.F.g(Jy,$CLJS.B(k)))return d;f+=1;e=$CLJS.C(e)}};
mz=function(a,b){var c=iz.h(a),d=$CLJS.B($CLJS.ez.h(a)),e=$CLJS.td(d)?Yx(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.H(e,0,null);d=0;e=$CLJS.H(e,1,null);for(var f=-1;;){if($CLJS.Sa(c)&&$CLJS.F.g(dx.h(e),f)&&1<d)throw Error("%@{ construct not consuming any arguments: Infinite loop!");f=$CLJS.td($CLJS.Pi.h(e))&&($CLJS.Sa(by.h(jz.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=Oy(b,e,My.h(a));if($CLJS.F.g(Iy,$CLJS.B(f)))return $CLJS.id(f);d+=1;var k=dx.h(e);e=f;f=k}};
nz=function(a,b){var c=iz.h(a),d=$CLJS.B($CLJS.ez.h(a)),e=$CLJS.td(d)?Yx(b):new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);b=$CLJS.H(e,0,null);d=0;for(e=$CLJS.H(e,1,null);;){var f=$CLJS.td($CLJS.Pi.h(e))&&($CLJS.Sa(by.h(jz.h(a)))||0<d);if($CLJS.n(f?f:$CLJS.n(c)?d>=c:c))return e;f=$CLJS.Pi.h(e);f=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.B(f),new Vx(Wx.h(e),$CLJS.C(f),dx.h(e)+1,null,null,null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,e],null);e=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);e=Oy(b,Px(e),f);
if($CLJS.F.g(Jy,$CLJS.B(e)))return f;e=f;d+=1}};pz=function(a,b){if($CLJS.n(by.h(jz.h(a)))){var c=$CLJS.ez.h(a),d=$CLJS.E(c),e=1<d?$CLJS.Wj.h(Ly.h($CLJS.B($CLJS.B(c)))):$CLJS.n(by.h(a))?"(":null,f=$CLJS.bd(c,1<d?1:0);c=2<d?$CLJS.Wj.h(Ly.h($CLJS.B($CLJS.bd(c,2)))):$CLJS.n(by.h(a))?")":null;d=Xx(b);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var k=Dx,l=yx;Dx+=1;yx=0;try{sx(e,c),Oy(f,Px(b),My.h(a)),wx()}finally{yx=l,Dx=k}}a=d}else a=oz(a,b);return a};
qz=function(a,b,c){for(var d=$CLJS.zf;;){if($CLJS.td(a))return new $CLJS.P(null,2,5,$CLJS.Q,[d,b],null);var e=$CLJS.B(a);a:{var f=new $CLJS.Fa,k=$CLJS.hw;$CLJS.hw=new $CLJS.qc(f);try{var l=new $CLJS.P(null,2,5,$CLJS.Q,[Oy(e,b,c),$CLJS.p.h(f)],null);break a}finally{$CLJS.hw=k}l=void 0}b=$CLJS.H(l,0,null);e=$CLJS.H(l,1,null);if($CLJS.F.g(Iy,$CLJS.B(b)))return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.id(b)],null);a=$CLJS.C(a);d=$CLJS.$d.g(d,e)}};
oz=function(a,b){var c=function(){var Z=$CLJS.Yu.h(a);return $CLJS.n(Z)?qz(Z,b,My.h(a)):null}(),d=$CLJS.H(c,0,null);d=$CLJS.H(d,0,null);c=$CLJS.H(c,1,null);var e=$CLJS.n(c)?c:b;c=function(){var Z=rz.h(a);return $CLJS.n(Z)?gy(Z,e):null}();var f=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);var k=$CLJS.n(c)?c:e;c=function(){var Z=$CLJS.B(sz.h(f));return $CLJS.n(Z)?Z:0}();var l=function(){var Z=$CLJS.B(tz.h(f));return $CLJS.n(Z)?Z:$CLJS.pw($CLJS.hw,$CLJS.fk)}(),m=$CLJS.ez.h(a);k=qz(m,k,My.h(a));var t=$CLJS.H(k,
0,null);k=$CLJS.H(k,1,null);var u=function(){var Z=$CLJS.E(t)-1+($CLJS.n(by.h(a))?1:0)+($CLJS.n(cy.h(a))?1:0);return 1>Z?1:Z}();m=$CLJS.Pd($CLJS.Ck,$CLJS.cf.g($CLJS.E,t));var v=my.h(a),x=ly.h(a),A=ny.h(a),D=m+u*x;v=D<=v?v:v+A*(1+$CLJS.Ud(D-v-1,A));var J=v-m;m=function(){var Z=$CLJS.Ud(J,u);return x>Z?x:Z}();A=J-m*u;m=$CLJS.O.g($CLJS.p,$CLJS.Ye(m,oy.h(a)));$CLJS.n($CLJS.n(d)?$CLJS.pw(sw.h($CLJS.q($CLJS.q($CLJS.hw))),$CLJS.rw)+c+v>l:d)&&py.l($CLJS.G([d]));c=A;for(var K=t,X=function(){var Z=by.h(a);
return $CLJS.n(Z)?Z:$CLJS.F.g($CLJS.E(K),1)&&$CLJS.Sa(cy.h(a))}();;)if($CLJS.z(K))py.l($CLJS.G([[$CLJS.p.h($CLJS.Sa(X)?$CLJS.B(K):null),$CLJS.p.h($CLJS.n(function(){var Z=X;return $CLJS.n(Z)?Z:(Z=$CLJS.C(K))?Z:cy.h(a)}())?m:null),$CLJS.p.h(0<c?oy.h(a):null)].join("")])),--c,K=d=$CLJS.n(X)?K:$CLJS.C(K),X=!1;else break;return k};uz=function(a,b){this.Aa=a;this.Ch=b;this.C=1074135040;this.I=0};vz=function(a){return new uz(a,$CLJS.N)};wz=function(a,b){this.Aa=a;this.Dh=b;this.C=1074135040;this.I=0};
xz=function(a){return new wz(a,$CLJS.N)};
yz=function(a,b){var c=$CLJS.B(a);return $CLJS.O.g($CLJS.p,$CLJS.B(lw(function(d){if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,null],null);var e=RegExp("\\W\\w","g").exec(d);e=$CLJS.n(e)?e.index+1:e;return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[[d.substring(0,e),$CLJS.bd(d,e).toUpperCase()].join(""),d.substring(e+1)],null):new $CLJS.P(null,2,5,$CLJS.Q,[d,null],null)},$CLJS.n($CLJS.n(b)?$CLJS.n(c)?Vv(c):c:b)?[c.toUpperCase(),a.substring(1)].join(""):a)))};
zz=function(a,b,c){this.Aa=a;this.Ad=b;this.Eh=c;this.C=1074135040;this.I=0};Az=function(a){return new zz(a,$CLJS.Re(!0),$CLJS.N)};Bz=function(a,b,c){this.Aa=a;this.od=b;this.Fh=c;this.C=1074135040;this.I=0};Cz=function(a){return new Bz(a,$CLJS.Re(!1),$CLJS.N)};Dz=function(a,b){var c=$CLJS.n(by.h(a))?$CLJS.Lx:Kx;Nx(c,$CLJS.bz.h(a));return b};Ez=function(a,b){a=$CLJS.n(by.h(a))?$CLJS.n(cy.h(a))?Fx:Hx:$CLJS.n(cy.h(a))?Gx:Ix;Jx(a);return b};
Gz=function(a){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null),d=$CLJS.H(a,2,null);a=new RegExp(Fz.source,"g");var e=a.exec(b);return $CLJS.n(e)?(d=$CLJS.B(e),b=b.substring(a.lastIndex),a=c+a.lastIndex,$CLJS.F.g(",",$CLJS.bd(b,0))?new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b.substring(1),a+1,!0],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[d,c],null),new $CLJS.P(null,3,5,$CLJS.Q,[b,a,!1],null)],null)):$CLJS.n(d)?Ux("Badly formed parameters in format directive",
c):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[b,c],null)],null)};Hz=function(a){var b=$CLJS.H(a,0,null);a=$CLJS.H(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.F.g(b.length,0)?null:$CLJS.F.g(b.length,1)&&$CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,2,["V",null,"v",null],null),null),$CLJS.bd(b,0))?dy:$CLJS.F.g(b.length,1)&&$CLJS.F.g("#",$CLJS.bd(b,0))?ey:$CLJS.F.g(b.length,2)&&$CLJS.F.g("'",$CLJS.bd(b,0))?$CLJS.bd(b,1):parseInt(b,10),a],null)};
Jz=function(a,b){return lw(function(c){var d=$CLJS.H(c,0,null),e=$CLJS.H(c,1,null);c=$CLJS.H(c,2,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null);var f=$CLJS.I.g(Iz,$CLJS.B(d));return $CLJS.n(f)?$CLJS.Hd(c,f)?Ux(['Flag "',$CLJS.p.h($CLJS.B(d)),'" appears more than once in a directive'].join(""),e):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[d.substring(1),e+1,$CLJS.R.j(c,f,new $CLJS.P(null,2,5,$CLJS.Q,[!0,e],null))],
null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,3,5,$CLJS.Q,[d,e,c],null)],null)},new $CLJS.P(null,3,5,$CLJS.Q,[a,b,$CLJS.N],null))};
Nz=function(a,b){var c=Kz.h(a);$CLJS.n(function(){var d=$CLJS.Sa(cy.h(c));return d?cy.h(b):d}())&&Ux(['"@" is an illegal flag for format directive "',$CLJS.p.h(Lz.h(a)),'"'].join(""),$CLJS.bd(cy.h(b),1));$CLJS.n(function(){var d=$CLJS.Sa(by.h(c));return d?by.h(b):d}())&&Ux(['":" is an illegal flag for format directive "',$CLJS.p.h(Lz.h(a)),'"'].join(""),$CLJS.bd(by.h(b),1));$CLJS.n(function(){var d=$CLJS.Sa(Mz.h(c));return d?(d=cy.h(b),$CLJS.n(d)?by.h(b):d):d}())&&Ux(['Cannot combine "@" and ":" flags for format directive "',
$CLJS.p.h(Lz.h(a)),'"'].join(""),function(){var d=$CLJS.bd(by.h(b),1),e=$CLJS.bd(cy.h(b),1);return d<e?d:e}())};
Pz=function(a,b,c,d){Nz(a,c);$CLJS.E(b)>$CLJS.E(Ly.h(a))&&Ux(Sx(null,'Too many parameters for directive "~C": ~D~:* ~[were~;was~:;were~] specified but only ~D~:* ~[are~;is~:;are~] allowed',$CLJS.G([Lz.h(a),$CLJS.E(b),$CLJS.E(Ly.h(a))])),$CLJS.id($CLJS.B(b)));$CLJS.lh($CLJS.cf.j(function(e,f){var k=$CLJS.B(e);return null==k||$CLJS.Hd(Oz,k)||$CLJS.F.g($CLJS.id($CLJS.id(f)),$CLJS.Xa(k))?null:Ux(["Parameter ",$CLJS.fh($CLJS.B(f)),' has bad type in directive "',$CLJS.p.h(Lz.h(a)),'": ',$CLJS.p.h($CLJS.Xa(k))].join(""),
$CLJS.id(e))},b,Ly.h(a)));return $CLJS.$k.l($CLJS.G([$CLJS.dg.g($CLJS.N,$CLJS.ae(function(){return function k(f){return new $CLJS.le(null,function(){for(;;){var l=$CLJS.z(f);if(l){if($CLJS.Ad(l)){var m=$CLJS.jc(l),t=$CLJS.E(m),u=$CLJS.oe(t);a:for(var v=0;;)if(v<t){var x=$CLJS.ld(m,v),A=$CLJS.H(x,0,null);x=$CLJS.H(x,1,null);x=$CLJS.H(x,0,null);u.add(new $CLJS.P(null,2,5,$CLJS.Q,[A,new $CLJS.P(null,2,5,$CLJS.Q,[x,d],null)],null));v+=1}else{m=!0;break a}return m?$CLJS.re($CLJS.te(u),k($CLJS.kc(l))):
$CLJS.re($CLJS.te(u),null)}m=$CLJS.B(l);u=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);m=$CLJS.H(m,0,null);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[u,new $CLJS.P(null,2,5,$CLJS.Q,[m,d],null)],null),k($CLJS.Kc(l)))}return null}},null,null)}(Ly.h(a))}())),$CLJS.ab(function(e,f){return $CLJS.O.j($CLJS.R,e,f)},$CLJS.N,$CLJS.ff(function(e){return $CLJS.B($CLJS.bd(e,1))},$CLJS.cw($CLJS.Wg(Ly.h(a)),b))),c]))};
Sz=function(a,b){b=lw(Gz,new $CLJS.P(null,3,5,$CLJS.Q,[a,b,!1],null));a=$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);b=Jz(b,c);$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=$CLJS.H(b,0,null),e=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);c=$CLJS.B(d);var f=$CLJS.I.g(Qz,c.toUpperCase()),k=$CLJS.n(f)?Pz(f,$CLJS.cf.g(Hz,a),b,e):null;$CLJS.Sa(c)&&Ux("Format string ended in the middle of a directive",e);$CLJS.Sa(f)&&Ux(['Directive "',$CLJS.p.h(c),'" is undefined'].join(""),
e);return new $CLJS.P(null,2,5,$CLJS.Q,[new ay(function(){var l=Rz.h(f);return l.g?l.g(k,e):l.call(null,k,e)}(),f,k,e,null,null,null),function(){var l=d.substring(1),m=e+1;if($CLJS.F.g("\n",Lz.h(f))&&$CLJS.Sa(by.h(k)))a:{var t=new $CLJS.P(null,2,5,$CLJS.Q,[" ","\t"],null);t=$CLJS.ud(t)?$CLJS.eh(t):$CLJS.bh([t]);for(var u=0;;){var v;(v=$CLJS.F.g(u,$CLJS.E(l)))||(v=$CLJS.bd(l,u),v=t.h?t.h(v):t.call(null,v),v=$CLJS.Sa(v));if(v){t=u;break a}u+=1}}else t=0;return new $CLJS.P(null,2,5,$CLJS.Q,[l.substring(t),
m+t],null)}()],null)};Tz=function(a,b){return new ay(function(c,d){py.l($CLJS.G([a]));return d},null,new $CLJS.h(null,1,[$CLJS.Wj,a],null),b,null,null,null)};Xz=function(a,b){var c=Uz(Vz.h(Wz.h(a)),$CLJS.av.h(a),b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[new ay(Ny.h(a),Wz.h(a),$CLJS.$k.l($CLJS.G([Ly.h(a),nw(b,$CLJS.av.h(a))])),$CLJS.av.h(a),null,null,null),c],null)};
$z=function(a,b,c){return lw(function(d){if($CLJS.td(d))return Ux("No closing bracket found.",b);var e=$CLJS.B(d);d=$CLJS.C(d);if($CLJS.n($CLJS.Yz.h(Vz.h(Wz.h(e)))))e=Xz(e,d);else if($CLJS.F.g($CLJS.Yz.h(a),Lz.h(Wz.h(e))))e=new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[Zz,Ly.h(e),null,d],null)],null);else{var f=$CLJS.ev.h(Vz.h(Wz.h(e)));f=$CLJS.n(f)?by.h(Ly.h(e)):f;e=$CLJS.n(f)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Yu,null,Ly.h(e),d],null)],null):
$CLJS.n($CLJS.ev.h(Vz.h(Wz.h(e))))?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ev,null,null,d],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[e,d],null)}return e},c)};
Uz=function(a,b,c){return $CLJS.id(lw(function(d){var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);d=$CLJS.H(d,2,null);var k=$z(a,b,d);d=$CLJS.H(k,0,null);var l=$CLJS.H(k,1,null);k=$CLJS.H(l,0,null);var m=$CLJS.H(l,1,null),t=$CLJS.H(l,2,null);l=$CLJS.H(l,3,null);return $CLJS.F.g(k,Zz)?new $CLJS.P(null,2,5,$CLJS.Q,[null,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aA.l($CLJS.bf,$CLJS.G([e,$CLJS.De([$CLJS.n(f)?$CLJS.Yu:$CLJS.ez,new $CLJS.P(null,1,5,$CLJS.Q,[d],null),jz,m])])),l],null)],null):$CLJS.F.g(k,$CLJS.Yu)?
$CLJS.n($CLJS.Yu.h(e))?Ux('Two else clauses ("~:;") inside bracket construction.',b):$CLJS.Sa($CLJS.Yu.h(a))?Ux('An else clause ("~:;") is in a bracket type that doesn\'t support it.',b):$CLJS.F.g(bA,$CLJS.Yu.h(a))&&$CLJS.z($CLJS.ez.h(e))?Ux('The else clause ("~:;") is only allowed in the first position for this directive.',b):$CLJS.F.g(bA,$CLJS.Yu.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aA.l($CLJS.bf,$CLJS.G([e,new $CLJS.h(null,2,[$CLJS.Yu,new $CLJS.P(null,1,5,
$CLJS.Q,[d],null),rz,t],null)])),!1,l],null)],null):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aA.l($CLJS.bf,$CLJS.G([e,new $CLJS.h(null,1,[$CLJS.ez,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!0,l],null)],null):$CLJS.F.g(k,$CLJS.ev)?$CLJS.n(f)?Ux('A plain clause (with "~;") follows an else clause ("~:;") inside bracket construction.',b):$CLJS.Sa(cA.h(a))?Ux('A separator ("~;") is in a bracket type that doesn\'t support it.',b):new $CLJS.P(null,2,5,$CLJS.Q,[!0,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.aA.l($CLJS.bf,$CLJS.G([e,new $CLJS.h(null,1,[$CLJS.ez,new $CLJS.P(null,1,5,$CLJS.Q,[d],null)],null)])),!1,l],null)],null):null},new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.h(null,1,[$CLJS.ez,$CLJS.zf],null),!1,c],null)))};dA=function(a){return $CLJS.B(lw(function(b){var c=$CLJS.B(b);b=$CLJS.C(b);var d=Vz.h(Wz.h(c));return $CLJS.n($CLJS.Yz.h(d))?Xz(c,b):new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)},a))};
Ox=function(a){var b=Tx;Tx=a;try{return dA($CLJS.B(lw(function(c){var d=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.td(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=d.indexOf("~");return 0>e?new $CLJS.P(null,2,5,$CLJS.Q,[Tz(d,c),new $CLJS.P(null,2,5,$CLJS.Q,["",c+d.length],null)],null):0===e?Sz(d.substring(1),c+1):new $CLJS.P(null,2,5,$CLJS.Q,[Tz(d.substring(0,e),c),new $CLJS.P(null,2,5,$CLJS.Q,[d.substring(e),e+c],null)],null)},new $CLJS.P(null,2,5,$CLJS.Q,[a,0],null))))}finally{Tx=
b}};Rx=function(a,b){kw(function(c,d){if(Ky(d))return new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=gy(Ly.h(c),d);var e=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var f=mw(e);e=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);e=$CLJS.R.j(e,My,d);return new $CLJS.P(null,2,5,$CLJS.Q,[null,$CLJS.O.g(Ny.h(c),new $CLJS.P(null,3,5,$CLJS.Q,[e,d,f],null))],null)},b,a);return null};
fA=function(a){var b=$CLJS.B(a);b=eA.h?eA.h(b):eA.call(null,b);return $CLJS.n($CLJS.n(b)?$CLJS.F.g(2,$CLJS.E(a)):b)?($CLJS.Zb($CLJS.hw,b),$CLJS.Bx($CLJS.id(a)),!0):null};
gA=function(a){if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var b=Dx,c=yx;Dx+=1;yx=0;try{sx("[","]");for(var d=0,e=$CLJS.z(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Bx($CLJS.B(e)),$CLJS.C(e))){$CLJS.Zb($CLJS.hw," ");Jx(Ix);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.Zb($CLJS.hw,"...");break}wx()}finally{yx=c,Dx=b}}return null};
jA=function(a){var b=$CLJS.yd(a)?null:function(){var m=new $CLJS.Dc(function(){return $CLJS.Gh},hA,$CLJS.Hg([$CLJS.pk,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,$CLJS.Si,$CLJS.kj,$CLJS.rk,$CLJS.ti,$CLJS.T,$CLJS.jk],[!0,$CLJS.li,iA,"cljs/core.cljs",15,1,10543,10543,$CLJS.U(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.bj],null)),"Returns [lifted-ns lifted-map] or nil if m can't be lifted.",$CLJS.n($CLJS.Gh)?$CLJS.Gh.H:null]));return m.h?m.h(a):m.call(null,a)}(),c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);var d=$CLJS.n(b)?
b:a,e=$CLJS.n(c)?["#:",$CLJS.p.h(c),"{"].join(""):"{";if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{c=Dx;b=yx;Dx+=1;yx=0;try{sx(e,"}");e=0;for(var f=$CLJS.z(d);;){if($CLJS.Sa($CLJS.Ma)||e<$CLJS.Ma){if(f){if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{d=Dx;var k=yx;Dx+=1;yx=0;try{sx(null,null),$CLJS.Bx($CLJS.B($CLJS.B(f))),$CLJS.Zb($CLJS.hw," "),Jx(Ix),yx=0,$CLJS.Bx($CLJS.B($CLJS.C($CLJS.B(f)))),wx()}finally{yx=k,Dx=d}}if($CLJS.C(f)){$CLJS.Zb($CLJS.hw,", ");Jx(Ix);d=e+1;var l=$CLJS.C(f);e=d;f=l;continue}}}else $CLJS.Zb($CLJS.hw,
"...");break}wx()}finally{yx=b,Dx=c}}return null};kA=function(a){return $CLJS.Zb($CLJS.hw,$CLJS.Lh.l($CLJS.G([a])))};nA=function(a){return a instanceof $CLJS.Qf?lA:(null!=a?a.C&32768||$CLJS.r===a.Hf||(a.C?0:$CLJS.Wa(Wv,a)):$CLJS.Wa(Wv,a))?mA:a instanceof $CLJS.w?$CLJS.Hi:$CLJS.Dd(a)?$CLJS.zj:$CLJS.xd(a)?$CLJS.ek:$CLJS.zd(a)?$CLJS.Hj:$CLJS.vd(a)?$CLJS.Xi:null==a?null:$CLJS.ci};oA=function(a){return $CLJS.zd(a)?new $CLJS.P(null,2,5,$CLJS.Q,["[","]"],null):new $CLJS.P(null,2,5,$CLJS.Q,["(",")"],null)};
qA=function(a){if($CLJS.wd(a)){var b=oA(a),c=$CLJS.H(b,0,null),d=$CLJS.H(b,1,null),e=$CLJS.z(a),f=$CLJS.B(e),k=$CLJS.C(e);if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var l=Dx,m=yx,t=Dx+1;Dx=t;yx=0;try{sx(c,d);(function(){var tb=pA("~w~:i");return function(){function Rb(Gc){var fc=null;if(0<arguments.length){fc=0;for(var Hc=Array(arguments.length-0);fc<Hc.length;)Hc[fc]=arguments[fc+0],++fc;fc=new $CLJS.y(Hc,0,null)}return Vb.call(this,fc)}function Vb(Gc){Gc=Px(Gc);return Rx(tb,Gc)}Rb.v=0;Rb.B=function(Gc){Gc=
$CLJS.z(Gc);return Vb(Gc)};Rb.l=Vb;return Rb}()})()(f);for(var u=k;;)if($CLJS.z(u)){(function(){var tb=pA(" ");return function(Rb,Vb,Gc){return function(){function fc(zb){var Mb=null;if(0<arguments.length){Mb=0;for(var gd=Array(arguments.length-0);Mb<gd.length;)gd[Mb]=arguments[Mb+0],++Mb;Mb=new $CLJS.y(gd,0,null)}return Hc.call(this,Mb)}function Hc(zb){zb=Px(zb);return Rx(Gc,zb)}fc.v=0;fc.B=function(zb){zb=$CLJS.z(zb);return Hc(zb)};fc.l=Hc;return fc}()}(u," ",tb,l,m,t,0,b,c,d,a,e,f,k,f,k)})()();
var v=$CLJS.B(u);if($CLJS.wd(v)){var x=oA(v),A=$CLJS.H(x,0,null),D=$CLJS.H(x,1,null);if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var J=Dx,K=yx,X=Dx+1;Dx=X;yx=0;try{sx(A,D);if($CLJS.F.g($CLJS.E(v),3)&&$CLJS.id(v)instanceof $CLJS.M){var Z=v,fa=$CLJS.H(Z,0,null),ra=$CLJS.H(Z,1,null),xa=$CLJS.H(Z,2,null);(function(){var tb=pA("~w ~w ");return function(Rb,Vb,Gc){return function(){function fc(zb){var Mb=null;if(0<arguments.length){Mb=0;for(var gd=Array(arguments.length-0);Mb<gd.length;)gd[Mb]=arguments[Mb+
0],++Mb;Mb=new $CLJS.y(gd,0,null)}return Hc.call(this,Mb)}function Hc(zb){zb=Px(zb);return Rx(Gc,zb)}fc.v=0;fc.B=function(zb){zb=$CLJS.z(zb);return Hc(zb)};fc.l=Hc;return fc}()}(u,"~w ~w ",tb,Z,fa,ra,xa,J,K,X,0,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)})()(fa,ra);$CLJS.wd(xa)?function(){var tb=$CLJS.zd(xa)?"~\x3c[~;~@{~w~^ ~:_~}~;]~:\x3e":"~\x3c(~;~@{~w~^ ~:_~}~;)~:\x3e",Rb="string"===typeof tb?pA(tb):tb;return function(Vb,Gc,fc){return function(){function Hc(Mb){var gd=null;if(0<arguments.length){gd=0;
for(var Ci=Array(arguments.length-0);gd<Ci.length;)Ci[gd]=arguments[gd+0],++gd;gd=new $CLJS.y(Ci,0,null)}return zb.call(this,gd)}function zb(Mb){Mb=Px(Mb);return Rx(fc,Mb)}Hc.v=0;Hc.B=function(Mb){Mb=$CLJS.z(Mb);return zb(Mb)};Hc.l=zb;return Hc}()}(u,tb,Rb,Z,fa,ra,xa,J,K,X,0,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()(xa):$CLJS.Bx(xa)}else $CLJS.O.g(function(){var tb=pA("~w ~:i~@{~w~^ ~:_~}");return function(Rb,Vb,Gc){return function(){function fc(zb){var Mb=null;if(0<arguments.length){Mb=0;for(var gd=
Array(arguments.length-0);Mb<gd.length;)gd[Mb]=arguments[Mb+0],++Mb;Mb=new $CLJS.y(gd,0,null)}return Hc.call(this,Mb)}function Hc(zb){zb=Px(zb);return Rx(Gc,zb)}fc.v=0;fc.B=function(zb){zb=$CLJS.z(zb);return Hc(zb)};fc.l=Hc;return fc}()}(u,"~w ~:i~@{~w~^ ~:_~}",tb,J,K,X,0,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}(),v);wx()}finally{yx=K,Dx=J}}$CLJS.C(u)&&function(){var tb=pA("~_");return function(Rb,Vb,Gc){return function(){function fc(zb){var Mb=null;if(0<arguments.length){Mb=0;for(var gd=Array(arguments.length-
0);Mb<gd.length;)gd[Mb]=arguments[Mb+0],++Mb;Mb=new $CLJS.y(gd,0,null)}return Hc.call(this,Mb)}function Hc(zb){zb=Px(zb);return Rx(Gc,zb)}fc.v=0;fc.B=function(zb){zb=$CLJS.z(zb);return Hc(zb)};fc.l=Hc;return fc}()}(u,"~_",tb,x,A,D,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()()}else $CLJS.Bx(v),$CLJS.C(u)&&function(){var tb=pA("~:_");return function(Rb,Vb,Gc){return function(){function fc(zb){var Mb=null;if(0<arguments.length){Mb=0;for(var gd=Array(arguments.length-0);Mb<gd.length;)gd[Mb]=arguments[Mb+0],++Mb;
Mb=new $CLJS.y(gd,0,null)}return Hc.call(this,Mb)}function Hc(zb){zb=Px(zb);return Rx(Gc,zb)}fc.v=0;fc.B=function(zb){zb=$CLJS.z(zb);return Hc(zb)};fc.l=Hc;return fc}()}(u,"~:_",tb,v,l,m,t,0,b,c,d,a,e,f,k,f,k)}()();u=$CLJS.C(u)}else break;wx()}finally{yx=m,Dx=l}}}else $CLJS.Bx(a)};
rA=function(a,b){$CLJS.z(a)&&($CLJS.n(b)?function(){var c=pA(" ~_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.y(l,0,null)}return e.call(this,k)}function e(f){f=Px(f);return Rx(c,f)}d.v=0;d.B=function(f){f=$CLJS.z(f);return e(f)};d.l=e;return d}()}()():function(){var c=pA(" ~@_");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=
arguments[k+0],++k;k=new $CLJS.y(l,0,null)}return e.call(this,k)}function e(f){f=Px(f);return Rx(c,f)}d.v=0;d.B=function(f){f=$CLJS.z(f);return e(f)};d.l=e;return d}()}()(),function(){var c=pA("~{~w~^ ~_~}");return function(){function d(f){var k=null;if(0<arguments.length){k=0;for(var l=Array(arguments.length-0);k<l.length;)l[k]=arguments[k+0],++k;k=new $CLJS.y(l,0,null)}return e.call(this,k)}function e(f){f=Px(f);return Rx(c,f)}d.v=0;d.B=function(f){f=$CLJS.z(f);return e(f)};d.l=e;return d}()}()(a))};
sA=function(a){$CLJS.z(a)&&function(){var b=pA(" ~_~{~w~^ ~_~}");return function(){function c(e){var f=null;if(0<arguments.length){f=0;for(var k=Array(arguments.length-0);f<k.length;)k[f]=arguments[f+0],++f;f=new $CLJS.y(k,0,null)}return d.call(this,f)}function d(e){e=Px(e);return Rx(b,e)}c.v=0;c.B=function(e){e=$CLJS.z(e);return d(e)};c.l=d;return c}()}()(a)};
uA=function(a){if($CLJS.C(a)){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d="string"===typeof $CLJS.B(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.B(c),$CLJS.C(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var e=$CLJS.xd($CLJS.B(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.B(d),$CLJS.C(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);d=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var f=
Dx,k=yx;Dx+=1;yx=0;try{sx("(",")"),function(){var l=pA("~w ~1I~@_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=Px(u);return Rx(l,u)}m.v=0;m.B=function(u){u=$CLJS.z(u);return t(u)};m.l=t;return m}()}()(a,b),$CLJS.n(c)&&function(){var l=pA(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-
0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=Px(u);return Rx(l,u)}m.v=0;m.B=function(u){u=$CLJS.z(u);return t(u)};m.l=t;return m}()}()(c),$CLJS.n(d)&&function(){var l=pA(" ~_~w");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=Px(u);return Rx(l,u)}m.v=0;m.B=function(u){u=$CLJS.z(u);return t(u)};
m.l=t;return m}()}()(d),$CLJS.zd($CLJS.B(e))?rA(e,$CLJS.n(c)?c:d):sA(e),wx()}finally{yx=k,Dx=f}}return null}return tA(a)};
vA=function(a){if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var b=Dx,c=yx;Dx+=1;yx=0;try{sx("[","]");for(var d=0;;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if($CLJS.z(a)){if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var e=Dx,f=yx;Dx+=1;yx=0;try{sx(null,null),$CLJS.Bx($CLJS.B(a)),$CLJS.C(a)&&($CLJS.Zb($CLJS.hw," "),Jx(Gx),$CLJS.Bx($CLJS.id(a))),wx()}finally{yx=f,Dx=e}}if($CLJS.C($CLJS.Kc(a))){$CLJS.Zb($CLJS.hw," ");Jx(Ix);e=d+1;var k=$CLJS.C($CLJS.Kc(a));d=e;a=k;continue}}}else $CLJS.Zb($CLJS.hw,"...");
break}wx()}finally{yx=c,Dx=b}}};
wA=function(a){var b=$CLJS.B(a);if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var c=Dx,d=yx;Dx+=1;yx=0;try{sx("(",")"),$CLJS.C(a)&&$CLJS.zd($CLJS.id(a))?(function(){var e=pA("~w ~1I~@_");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.y(t,0,null)}return k.call(this,m)}function k(l){l=Px(l);return Rx(e,l)}f.v=0;f.B=function(l){l=$CLJS.z(l);return k(l)};f.l=k;return f}()}()(b),vA($CLJS.id(a)),function(){var e=
pA(" ~_~{~w~^ ~_~}");return function(){function f(l){var m=null;if(0<arguments.length){m=0;for(var t=Array(arguments.length-0);m<t.length;)t[m]=arguments[m+0],++m;m=new $CLJS.y(t,0,null)}return k.call(this,m)}function k(l){l=Px(l);return Rx(e,l)}f.v=0;f.B=function(l){l=$CLJS.z(l);return k(l)};f.l=k;return f}()}()($CLJS.C($CLJS.Kc(a)))):tA(a),wx()}finally{yx=d,Dx=c}}return null};
tA=function(a){if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var b=Dx,c=yx;Dx+=1;yx=0;try{sx("(",")");Nx(Kx,1);for(var d=0,e=$CLJS.z(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Bx($CLJS.B(e)),$CLJS.C(e))){$CLJS.Zb($CLJS.hw," ");Jx(Ix);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.Zb($CLJS.hw,"...");break}wx()}finally{yx=c,Dx=b}}return null};$CLJS.le.prototype.Oc=$CLJS.va(2,function(){return $CLJS.Sa(this.Nb)});$CLJS.Xe.prototype.Oc=$CLJS.va(1,function(){return!1});
$CLJS.Ih.prototype.Oc=$CLJS.va(0,function(){return $CLJS.Sa(this.f)});$CLJS.hw=null;xA=function xA(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return xA.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};xA.l=function(a){a=$CLJS.z(a);for(var b=$CLJS.bc($CLJS.fg);;)if(a){var c=$CLJS.C(a);if(null==c)throw Error(["No value supplied for key: ",$CLJS.p.h($CLJS.B(a))].join(""));var d=$CLJS.C(c);b=$CLJS.gg.j(b,$CLJS.B(a),$CLJS.B(c));a=d}else return $CLJS.dc(b)};
xA.v=0;xA.B=function(a){return this.l($CLJS.z(a))};$CLJS.aA=function aA(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return aA.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
$CLJS.aA.l=function(a,b){if($CLJS.n($CLJS.Me($CLJS.Sd,b))){var c=function(d,e){var f=$CLJS.yb(e),k=$CLJS.Ab(e);if($CLJS.Hd(d,f)){e=$CLJS.R.j;var l=$CLJS.I.g(d,f);k=a.g?a.g(l,k):a.call(null,l,k);d=e.call($CLJS.R,d,f,k)}else d=$CLJS.R.j(d,f,k);return d};return $CLJS.Pd(function(d,e){return $CLJS.ab(c,$CLJS.n(d)?d:$CLJS.N,$CLJS.z(e))},b)}return null};$CLJS.aA.v=1;$CLJS.aA.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};
$CLJS.vy=function vy(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return vy.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};$CLJS.vy.l=function(a){return $CLJS.Dh(a,$CLJS.R.j($CLJS.Na(),$CLJS.Ha,!1))};$CLJS.vy.v=0;$CLJS.vy.B=function(a){return this.l($CLJS.z(a))};Fw=new $CLJS.M(null,"trailing-white-space","trailing-white-space",1496006996);yA=new $CLJS.M(null,"relative-to","relative-to",-470100051);Vw=new $CLJS.M(null,"intra-block-nl","intra-block-nl",1808826875);
Zy=new $CLJS.M(null,"exponentchar","exponentchar",1986664222);zA=new $CLJS.w(null,"when-let","when-let",-1383043480,null);Gx=new $CLJS.M(null,"miser","miser",-556060186);$CLJS.Lx=new $CLJS.M(null,"current","current",-1088038603);AA=new $CLJS.w(null,"-\x3e","-\x3e",-2139605430,null);BA=new $CLJS.w(null,"..","..",-300507420,null);Iw=new $CLJS.M(null,"end-pos","end-pos",-1643883926);Tw=new $CLJS.M(null,"per-line-prefix","per-line-prefix",846941813);lA=new $CLJS.M(null,"queue","queue",1455835879);
CA=new $CLJS.w(null,"if-let","if-let",1803593690,null);ux=new $CLJS.M(null,"end","end",-268185958);ey=new $CLJS.M(null,"remaining-arg-count","remaining-arg-count",-1216589335);bx=new $CLJS.M(null,"logical-blocks","logical-blocks",-1466339776);DA=new $CLJS.w(null,"defmacro","defmacro",2054157304,null);EA=new $CLJS.w(null,"when-first","when-first",821699168,null);rx=new $CLJS.M(null,"start-block-t","start-block-t",-373430594);FA=new $CLJS.w(null,"binding","binding",-2114503176,null);
Vz=new $CLJS.M(null,"bracket-info","bracket-info",-1600092774);fx=new $CLJS.M(null,"writing","writing",-1486865108);dy=new $CLJS.M(null,"parameter-from-args","parameter-from-args",-758446196);px=new $CLJS.M(null,"logical-block-callback","logical-block-callback",1612691194);dz=new $CLJS.M(null,"selector","selector",762528866);GA=new $CLJS.w(null,"struct","struct",325972931,null);Uw=new $CLJS.M(null,"indent","indent",-148200125);HA=new $CLJS.w(null,"loop","loop",1244978678,null);
tz=new $CLJS.M(null,"max-columns","max-columns",1742323262);Mz=new $CLJS.M(null,"both","both",-393648840);IA=new $CLJS.M(null,"colnum","colnum",2023796854);JA=new $CLJS.w(null,"doseq","doseq",221164135,null);$CLJS.KA=new $CLJS.M(null,"length","length",588987862);Fy=new $CLJS.M(null,"char-format","char-format",-1016499218);LA=new $CLJS.M(null,"prefix","prefix",-265908465);Jy=new $CLJS.M(null,"colon-up-arrow","colon-up-arrow",244853007);tx=new $CLJS.M(null,"suffix","suffix",367373057);
cy=new $CLJS.M(null,"at","at",1476951349);Ew=new $CLJS.M(null,"nl-t","nl-t",-1608382114);Lz=new $CLJS.M(null,"directive","directive",793559132);nx=new $CLJS.M(null,"buffer-level","buffer-level",928864731);Fx=new $CLJS.M(null,"mandatory","mandatory",542802336);lx=new $CLJS.M(null,"pretty-writer","pretty-writer",-1222834267);Lw=new $CLJS.M(null,"done-nl","done-nl",-381024340);MA=new $CLJS.w(null,"condp","condp",1054325175,null);Wx=new $CLJS.M(null,"seq","seq",-1817803783);
NA=new $CLJS.w(null,"defn","defn",-126010802,null);ny=new $CLJS.M(null,"colinc","colinc",-584873385);Iy=new $CLJS.M(null,"up-arrow","up-arrow",1705310333);Zz=new $CLJS.M(null,"right-bracket","right-bracket",951856080);OA=new $CLJS.M(null,"radix","radix",857016463);bA=new $CLJS.M(null,"first","first",-644103046);mx=new $CLJS.M(null,"sections","sections",-886710106);$CLJS.qx=new $CLJS.M(null,"start","start",-355208981);PA=new $CLJS.w("cljs.core","unquote","cljs.core/unquote",1013085760,null);
QA=new $CLJS.w(null,"defn-","defn-",1097765044,null);RA=new $CLJS.w("cljs.core","deref","cljs.core/deref",1901963335,null);Kx=new $CLJS.M(null,"block","block",664686210);SA=new $CLJS.w(null,"when","when",1064114221,null);rz=new $CLJS.M(null,"else-params","else-params",-832171646);$CLJS.TA=new $CLJS.M(null,"count","count",2139924085);$CLJS.Yz=new $CLJS.M(null,"right","right",-452581833);Ix=new $CLJS.M(null,"linear","linear",872268697);UA=new $CLJS.w(null,"when-not","when-not",-1223136340,null);
oy=new $CLJS.M(null,"padchar","padchar",2018584530);my=new $CLJS.M(null,"mincol","mincol",1230695445);VA=new $CLJS.M(null,"not-delivered","not-delivered",1599158697);Nw=new $CLJS.M(null,"miser-width","miser-width",-1310049437);WA=new $CLJS.w(null,"with-local-vars","with-local-vars",837642072,null);iA=new $CLJS.w(null,"lift-ns","lift-ns",602311926,null);cA=new $CLJS.M(null,"allows-separator","allows-separator",-818967742);cx=new $CLJS.M(null,"buffering","buffering",-876713613);
XA=new $CLJS.M(null,"arg1","arg1",951899358);My=new $CLJS.M(null,"base-args","base-args",-1268706822);YA=new $CLJS.M(null,"arg3","arg3",-1486822496);ZA=new $CLJS.M(null,"arg2","arg2",1729550917);ty=new $CLJS.M(null,"commainterval","commainterval",-1980061083);$A=new $CLJS.M(null,"right-margin","right-margin",-810413306);ex=new $CLJS.M(null,"buffer-blob","buffer-blob",-1830112173);aB=new $CLJS.w(null,"with-open","with-open",172119667,null);
hA=new $CLJS.w("cljs.core","lift-ns","cljs.core/lift-ns",463499081,null);bB=new $CLJS.M(null,"lines","lines",-700165781);Mx=new $CLJS.M(null,"indent-t","indent-t",528318969);jz=new $CLJS.M(null,"right-params","right-params",-1790676237);by=new $CLJS.M(null,"colon","colon",-965200945);cB=new $CLJS.w(null,"if-not","if-not",-265415609,null);Ny=new $CLJS.M(null,"func","func",-238706040);$CLJS.dB=new $CLJS.M(null,"last","last",1105735132);mA=new $CLJS.M(null,"deref","deref",-145586795);
eB=new $CLJS.w(null,"dotimes","dotimes",-818708397,null);iz=new $CLJS.M(null,"max-iterations","max-iterations",2021275563);fB=new $CLJS.w(null,"cond","cond",1606708055,null);ly=new $CLJS.M(null,"minpad","minpad",323570901);Qw=new $CLJS.M(null,"logical-block","logical-block",-581022564);gB=new $CLJS.w(null,"struct-map","struct-map",-1387540878,null);vx=new $CLJS.M(null,"end-block-t","end-block-t",1544648735);hB=new $CLJS.M(null,"stream","stream",1534941648);Ly=new $CLJS.M(null,"params","params",710516235);
iB=new $CLJS.M(null,"circle","circle",1903212362);Jw=new $CLJS.M(null,"start-pos","start-pos",668789086);Kz=new $CLJS.M(null,"flags","flags",1775418075);Hx=new $CLJS.M(null,"fill","fill",883462889);kx=new $CLJS.M(null,"buffer-block","buffer-block",-10937307);Rz=new $CLJS.M(null,"generator-fn","generator-fn",811851656);Ow=new $CLJS.M(null,"start-col","start-col",668080143);Ty=new $CLJS.M(null,"w","w",354169001);$CLJS.bz=new $CLJS.M(null,"n","n",562130025);Wz=new $CLJS.M(null,"def","def",-1043430536);
$CLJS.Vy=new $CLJS.M(null,"k","k",-2146297393);Yy=new $CLJS.M(null,"e","e",1381269198);Uy=new $CLJS.M(null,"d","d",1972142424);Wy=new $CLJS.M(null,"overflowchar","overflowchar",-1620088106);sz=new $CLJS.M(null,"min-remaining","min-remaining",962687677);uy=new $CLJS.M(null,"commachar","commachar",652859327);jB=new $CLJS.M(null,"section","section",-300141526);kB=new $CLJS.M(null,"pretty","pretty",-1916372486);lB=new $CLJS.w(null,"let","let",358118826,null);ww=new $CLJS.M(null,"parent","parent",-878878779);
$CLJS.ez=new $CLJS.M(null,"clauses","clauses",1454841241);mB=new $CLJS.w(null,"defonce","defonce",-1681484013,null);sw=new $CLJS.M(null,"base","base",185279322);Dw=new $CLJS.M(null,"type-tag","type-tag",-1873863267);nB=new $CLJS.w(null,"locking","locking",1542862874,null);dx=new $CLJS.M(null,"pos","pos",-864607220);oB=new $CLJS.M(null,"suppress-namespaces","suppress-namespaces",2130686956);$CLJS.rw=new $CLJS.M(null,"cur","cur",1153190599);var py=function py(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return py.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)},Ax,jx,qB,rB,sB,iy,hy,Dx,yx,tB;py.l=function(a){return $CLJS.Zb($CLJS.hw,$CLJS.O.g($CLJS.vy,a))};py.v=0;py.B=function(a){return this.l($CLJS.z(a))};var gw=function gw(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return gw.l(0<c.length?new $CLJS.y(c.slice(0),0,null):null)};
gw.l=function(a){return $CLJS.Zb($CLJS.hw,$CLJS.O.g($CLJS.Lh,a))};gw.v=0;gw.B=function(a){return this.l($CLJS.z(a))};$CLJS.g=uw.prototype;$CLJS.g.P=function(a,b){return new uw(this.Aa,this.uf,this.Rc,b)};$CLJS.g.O=function(){return this.jh};$CLJS.g.Xb=function(){return this.Rc};$CLJS.g.jc=function(){return Xv(this.Aa)};
$CLJS.g.Fc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return a=b.lastIndexOf("\n"),0>a?qw(this,$CLJS.rw,$CLJS.pw(this,$CLJS.rw)+$CLJS.E(b)):(qw(this,$CLJS.rw,$CLJS.E(b)-a-1),qw(this,$CLJS.kj,$CLJS.pw(this,$CLJS.kj)+$CLJS.E($CLJS.ff(function(c){return $CLJS.F.g(c,"\n")},b)))),$CLJS.Zb($CLJS.pw(this,sw),b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return tw(this,b);throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));
};$CLJS.g=vw.prototype;$CLJS.g.pa=function(a,b){return this.ca(null,b,null)};
$CLJS.g.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "parent":return this.parent;case "section":return this.zb;case "start-col":return this.qb;case "indent":return this.pb;case "done-nl":return this.sb;case "intra-block-nl":return this.tb;case "prefix":return this.prefix;case "per-line-prefix":return this.xb;case "suffix":return this.Ab;case "logical-block-callback":return this.ub;default:return $CLJS.I.j(this.G,b,c)}};
$CLJS.g.Cb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.rh(b,function(d){return $CLJS.rh(b,$CLJS.xh,""," ","",c,d)},"#cljs.pprint.logical-block{",", ","}",c,$CLJS.bf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[ww,this.parent],null),new $CLJS.P(null,2,5,$CLJS.Q,[jB,this.zb],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ow,this.qb],null),new $CLJS.P(null,2,5,$CLJS.Q,[Uw,this.pb],null),new $CLJS.P(null,2,5,$CLJS.Q,[Lw,this.sb],null),new $CLJS.P(null,2,5,$CLJS.Q,[Vw,this.tb],null),new $CLJS.P(null,2,5,$CLJS.Q,
[LA,this.prefix],null),new $CLJS.P(null,2,5,$CLJS.Q,[Tw,this.xb],null),new $CLJS.P(null,2,5,$CLJS.Q,[tx,this.Ab],null),new $CLJS.P(null,2,5,$CLJS.Q,[px,this.ub],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Nu(this,10,new $CLJS.P(null,10,5,$CLJS.Q,[ww,jB,Ow,Uw,Lw,Vw,LA,Tw,tx,px],null),$CLJS.n(this.G)?$CLJS.pc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 10+$CLJS.E(this.G)};
$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1977012399^$CLJS.Pc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.parent,b.parent)&&$CLJS.F.g(this.zb,b.zb)&&$CLJS.F.g(this.qb,b.qb)&&$CLJS.F.g(this.pb,b.pb)&&$CLJS.F.g(this.sb,b.sb)&&$CLJS.F.g(this.tb,b.tb)&&$CLJS.F.g(this.prefix,b.prefix)&&$CLJS.F.g(this.xb,b.xb)&&$CLJS.F.g(this.Ab,b.Ab)&&$CLJS.F.g(this.ub,b.ub)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Mb=function(a,b){return $CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,10,[tx,null,Uw,null,ww,null,jB,null,Lw,null,Ow,null,LA,null,Tw,null,px,null,Vw,null],null),null),b)?$CLJS.Ak.g($CLJS.Jb($CLJS.dg.g($CLJS.N,this),this.S),b):new vw(this.parent,this.zb,this.qb,this.pb,this.sb,this.tb,this.prefix,this.xb,this.Ab,this.ub,this.S,$CLJS.Fe($CLJS.Ak.g(this.G,b)),null)};
$CLJS.g.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "parent":case "section":case "start-col":case "indent":case "done-nl":case "intra-block-nl":case "prefix":case "per-line-prefix":case "suffix":case "logical-block-callback":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.na=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(ww,b):$CLJS.fe.call(null,ww,b))?new vw(c,this.zb,this.qb,this.pb,this.sb,this.tb,this.prefix,this.xb,this.Ab,this.ub,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(jB,b):$CLJS.fe.call(null,jB,b))?new vw(this.parent,c,this.qb,this.pb,this.sb,this.tb,this.prefix,this.xb,this.Ab,this.ub,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Ow,b):$CLJS.fe.call(null,Ow,b))?new vw(this.parent,this.zb,c,this.pb,this.sb,this.tb,this.prefix,this.xb,
this.Ab,this.ub,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Uw,b):$CLJS.fe.call(null,Uw,b))?new vw(this.parent,this.zb,this.qb,c,this.sb,this.tb,this.prefix,this.xb,this.Ab,this.ub,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Lw,b):$CLJS.fe.call(null,Lw,b))?new vw(this.parent,this.zb,this.qb,this.pb,c,this.tb,this.prefix,this.xb,this.Ab,this.ub,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Vw,b):$CLJS.fe.call(null,Vw,b))?new vw(this.parent,this.zb,this.qb,this.pb,this.sb,c,this.prefix,
this.xb,this.Ab,this.ub,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(LA,b):$CLJS.fe.call(null,LA,b))?new vw(this.parent,this.zb,this.qb,this.pb,this.sb,this.tb,c,this.xb,this.Ab,this.ub,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Tw,b):$CLJS.fe.call(null,Tw,b))?new vw(this.parent,this.zb,this.qb,this.pb,this.sb,this.tb,this.prefix,c,this.Ab,this.ub,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(tx,b):$CLJS.fe.call(null,tx,b))?new vw(this.parent,this.zb,this.qb,this.pb,this.sb,this.tb,
this.prefix,this.xb,c,this.ub,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(px,b):$CLJS.fe.call(null,px,b))?new vw(this.parent,this.zb,this.qb,this.pb,this.sb,this.tb,this.prefix,this.xb,this.Ab,c,this.S,this.G,null):new vw(this.parent,this.zb,this.qb,this.pb,this.sb,this.tb,this.prefix,this.xb,this.Ab,this.ub,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.z($CLJS.bf.g(new $CLJS.P(null,10,5,$CLJS.Q,[new $CLJS.Xf(ww,this.parent),new $CLJS.Xf(jB,this.zb),new $CLJS.Xf(Ow,this.qb),new $CLJS.Xf(Uw,this.pb),new $CLJS.Xf(Lw,this.sb),new $CLJS.Xf(Vw,this.tb),new $CLJS.Xf(LA,this.prefix),new $CLJS.Xf(Tw,this.xb),new $CLJS.Xf(tx,this.Ab),new $CLJS.Xf(px,this.ub)],null),this.G))};$CLJS.g.P=function(a,b){return new vw(this.parent,this.zb,this.qb,this.pb,this.sb,this.tb,this.prefix,this.xb,this.Ab,this.ub,b,this.G,this.J)};
$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.na(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=yw.prototype;$CLJS.g.pa=function(a,b){return this.ca(null,b,null)};$CLJS.g.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ba;case "data":return this.data;case "trailing-white-space":return this.Wb;case "start-pos":return this.aa;case "end-pos":return this.$;default:return $CLJS.I.j(this.G,b,c)}};
$CLJS.g.Cb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.rh(b,function(d){return $CLJS.rh(b,$CLJS.xh,""," ","",c,d)},"#cljs.pprint.buffer-blob{",", ","}",c,$CLJS.bf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Dw,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.oj,this.data],null),new $CLJS.P(null,2,5,$CLJS.Q,[Fw,this.Wb],null),new $CLJS.P(null,2,5,$CLJS.Q,[Jw,this.aa],null),new $CLJS.P(null,2,5,$CLJS.Q,[Iw,this.$],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Nu(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Dw,$CLJS.oj,Fw,Jw,Iw],null),$CLJS.n(this.G)?$CLJS.pc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1809113693^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.data,b.data)&&$CLJS.F.g(this.Wb,b.Wb)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.$,b.$)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Mb=function(a,b){return $CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,5,[Iw,null,Fw,null,Dw,null,Jw,null,$CLJS.oj,null],null),null),b)?$CLJS.Ak.g($CLJS.Jb($CLJS.dg.g($CLJS.N,this),this.S),b):new yw(this.ba,this.data,this.Wb,this.aa,this.$,this.S,$CLJS.Fe($CLJS.Ak.g(this.G,b)),null)};$CLJS.g.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "data":case "trailing-white-space":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.na=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(Dw,b):$CLJS.fe.call(null,Dw,b))?new yw(c,this.data,this.Wb,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.oj,b):$CLJS.fe.call(null,$CLJS.oj,b))?new yw(this.ba,c,this.Wb,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Fw,b):$CLJS.fe.call(null,Fw,b))?new yw(this.ba,this.data,c,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Jw,b):$CLJS.fe.call(null,Jw,b))?new yw(this.ba,this.data,
this.Wb,c,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Iw,b):$CLJS.fe.call(null,Iw,b))?new yw(this.ba,this.data,this.Wb,this.aa,c,this.S,this.G,null):new yw(this.ba,this.data,this.Wb,this.aa,this.$,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.bf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Xf(Dw,this.ba),new $CLJS.Xf($CLJS.oj,this.data),new $CLJS.Xf(Fw,this.Wb),new $CLJS.Xf(Jw,this.aa),new $CLJS.Xf(Iw,this.$)],null),this.G))};
$CLJS.g.P=function(a,b){return new yw(this.ba,this.data,this.Wb,this.aa,this.$,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.na(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=zw.prototype;$CLJS.g.pa=function(a,b){return this.ca(null,b,null)};
$CLJS.g.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ba;case "type":return this.type;case "logical-block":return this.ea;case "start-pos":return this.aa;case "end-pos":return this.$;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Cb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.rh(b,function(d){return $CLJS.rh(b,$CLJS.xh,""," ","",c,d)},"#cljs.pprint.nl-t{",", ","}",c,$CLJS.bf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Dw,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,this.type],null),new $CLJS.P(null,2,5,$CLJS.Q,[Qw,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Jw,this.aa],null),new $CLJS.P(null,2,5,$CLJS.Q,[Iw,this.$],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Nu(this,5,new $CLJS.P(null,5,5,$CLJS.Q,[Dw,$CLJS.jj,Qw,Jw,Iw],null),$CLJS.n(this.G)?$CLJS.pc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 5+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1640656800^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.type,b.type)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.$,b.$)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Mb=function(a,b){return $CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,5,[Iw,null,$CLJS.jj,null,Qw,null,Dw,null,Jw,null],null),null),b)?$CLJS.Ak.g($CLJS.Jb($CLJS.dg.g($CLJS.N,this),this.S),b):new zw(this.ba,this.type,this.ea,this.aa,this.$,this.S,$CLJS.Fe($CLJS.Ak.g(this.G,b)),null)};$CLJS.g.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "type":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.na=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(Dw,b):$CLJS.fe.call(null,Dw,b))?new zw(c,this.type,this.ea,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.jj,b):$CLJS.fe.call(null,$CLJS.jj,b))?new zw(this.ba,c,this.ea,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Qw,b):$CLJS.fe.call(null,Qw,b))?new zw(this.ba,this.type,c,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Jw,b):$CLJS.fe.call(null,Jw,b))?new zw(this.ba,this.type,
this.ea,c,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Iw,b):$CLJS.fe.call(null,Iw,b))?new zw(this.ba,this.type,this.ea,this.aa,c,this.S,this.G,null):new zw(this.ba,this.type,this.ea,this.aa,this.$,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.bf.g(new $CLJS.P(null,5,5,$CLJS.Q,[new $CLJS.Xf(Dw,this.ba),new $CLJS.Xf($CLJS.jj,this.type),new $CLJS.Xf(Qw,this.ea),new $CLJS.Xf(Jw,this.aa),new $CLJS.Xf(Iw,this.$)],null),this.G))};
$CLJS.g.P=function(a,b){return new zw(this.ba,this.type,this.ea,this.aa,this.$,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.na(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=Aw.prototype;$CLJS.g.pa=function(a,b){return this.ca(null,b,null)};
$CLJS.g.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ba;case "logical-block":return this.ea;case "start-pos":return this.aa;case "end-pos":return this.$;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Cb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.rh(b,function(d){return $CLJS.rh(b,$CLJS.xh,""," ","",c,d)},"#cljs.pprint.start-block-t{",", ","}",c,$CLJS.bf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Dw,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[Qw,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Jw,this.aa],null),new $CLJS.P(null,2,5,$CLJS.Q,[Iw,this.$],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Nu(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Dw,Qw,Jw,Iw],null),$CLJS.n(this.G)?$CLJS.pc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-414877272^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.$,b.$)&&$CLJS.F.g(this.G,b.G)};$CLJS.g.Mb=function(a,b){return $CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,4,[Iw,null,Qw,null,Dw,null,Jw,null],null),null),b)?$CLJS.Ak.g($CLJS.Jb($CLJS.dg.g($CLJS.N,this),this.S),b):new Aw(this.ba,this.ea,this.aa,this.$,this.S,$CLJS.Fe($CLJS.Ak.g(this.G,b)),null)};
$CLJS.g.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.na=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(Dw,b):$CLJS.fe.call(null,Dw,b))?new Aw(c,this.ea,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Qw,b):$CLJS.fe.call(null,Qw,b))?new Aw(this.ba,c,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Jw,b):$CLJS.fe.call(null,Jw,b))?new Aw(this.ba,this.ea,c,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Iw,b):$CLJS.fe.call(null,Iw,b))?new Aw(this.ba,this.ea,this.aa,c,this.S,this.G,null):new Aw(this.ba,
this.ea,this.aa,this.$,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.bf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Xf(Dw,this.ba),new $CLJS.Xf(Qw,this.ea),new $CLJS.Xf(Jw,this.aa),new $CLJS.Xf(Iw,this.$)],null),this.G))};$CLJS.g.P=function(a,b){return new Aw(this.ba,this.ea,this.aa,this.$,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.na(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=Bw.prototype;
$CLJS.g.pa=function(a,b){return this.ca(null,b,null)};$CLJS.g.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ba;case "logical-block":return this.ea;case "start-pos":return this.aa;case "end-pos":return this.$;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Cb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.rh(b,function(d){return $CLJS.rh(b,$CLJS.xh,""," ","",c,d)},"#cljs.pprint.end-block-t{",", ","}",c,$CLJS.bf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Dw,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[Qw,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[Jw,this.aa],null),new $CLJS.P(null,2,5,$CLJS.Q,[Iw,this.$],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Nu(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Dw,Qw,Jw,Iw],null),$CLJS.n(this.G)?$CLJS.pc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=1365867980^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.$,b.$)&&$CLJS.F.g(this.G,b.G)};$CLJS.g.Mb=function(a,b){return $CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,4,[Iw,null,Qw,null,Dw,null,Jw,null],null),null),b)?$CLJS.Ak.g($CLJS.Jb($CLJS.dg.g($CLJS.N,this),this.S),b):new Bw(this.ba,this.ea,this.aa,this.$,this.S,$CLJS.Fe($CLJS.Ak.g(this.G,b)),null)};
$CLJS.g.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.na=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(Dw,b):$CLJS.fe.call(null,Dw,b))?new Bw(c,this.ea,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Qw,b):$CLJS.fe.call(null,Qw,b))?new Bw(this.ba,c,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Jw,b):$CLJS.fe.call(null,Jw,b))?new Bw(this.ba,this.ea,c,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Iw,b):$CLJS.fe.call(null,Iw,b))?new Bw(this.ba,this.ea,this.aa,c,this.S,this.G,null):new Bw(this.ba,
this.ea,this.aa,this.$,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.bf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Xf(Dw,this.ba),new $CLJS.Xf(Qw,this.ea),new $CLJS.Xf(Jw,this.aa),new $CLJS.Xf(Iw,this.$)],null),this.G))};$CLJS.g.P=function(a,b){return new Bw(this.ba,this.ea,this.aa,this.$,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.na(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=Cw.prototype;
$CLJS.g.pa=function(a,b){return this.ca(null,b,null)};$CLJS.g.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":return this.ba;case "logical-block":return this.ea;case "relative-to":return this.Rb;case "offset":return this.offset;case "start-pos":return this.aa;case "end-pos":return this.$;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Cb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.rh(b,function(d){return $CLJS.rh(b,$CLJS.xh,""," ","",c,d)},"#cljs.pprint.indent-t{",", ","}",c,$CLJS.bf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Dw,this.ba],null),new $CLJS.P(null,2,5,$CLJS.Q,[Qw,this.ea],null),new $CLJS.P(null,2,5,$CLJS.Q,[yA,this.Rb],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.av,this.offset],null),new $CLJS.P(null,2,5,$CLJS.Q,[Jw,this.aa],null),new $CLJS.P(null,2,5,$CLJS.Q,[Iw,this.$],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Nu(this,6,new $CLJS.P(null,6,5,$CLJS.Q,[Dw,Qw,yA,$CLJS.av,Jw,Iw],null),$CLJS.n(this.G)?$CLJS.pc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 6+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-1602780238^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.ba,b.ba)&&$CLJS.F.g(this.ea,b.ea)&&$CLJS.F.g(this.Rb,b.Rb)&&$CLJS.F.g(this.offset,b.offset)&&$CLJS.F.g(this.aa,b.aa)&&$CLJS.F.g(this.$,b.$)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Mb=function(a,b){return $CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,6,[$CLJS.av,null,Iw,null,yA,null,Qw,null,Dw,null,Jw,null],null),null),b)?$CLJS.Ak.g($CLJS.Jb($CLJS.dg.g($CLJS.N,this),this.S),b):new Cw(this.ba,this.ea,this.Rb,this.offset,this.aa,this.$,this.S,$CLJS.Fe($CLJS.Ak.g(this.G,b)),null)};
$CLJS.g.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "type-tag":case "logical-block":case "relative-to":case "offset":case "start-pos":case "end-pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.na=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(Dw,b):$CLJS.fe.call(null,Dw,b))?new Cw(c,this.ea,this.Rb,this.offset,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Qw,b):$CLJS.fe.call(null,Qw,b))?new Cw(this.ba,c,this.Rb,this.offset,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(yA,b):$CLJS.fe.call(null,yA,b))?new Cw(this.ba,this.ea,c,this.offset,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.av,b):$CLJS.fe.call(null,$CLJS.av,
b))?new Cw(this.ba,this.ea,this.Rb,c,this.aa,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Jw,b):$CLJS.fe.call(null,Jw,b))?new Cw(this.ba,this.ea,this.Rb,this.offset,c,this.$,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Iw,b):$CLJS.fe.call(null,Iw,b))?new Cw(this.ba,this.ea,this.Rb,this.offset,this.aa,c,this.S,this.G,null):new Cw(this.ba,this.ea,this.Rb,this.offset,this.aa,this.$,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.z($CLJS.bf.g(new $CLJS.P(null,6,5,$CLJS.Q,[new $CLJS.Xf(Dw,this.ba),new $CLJS.Xf(Qw,this.ea),new $CLJS.Xf(yA,this.Rb),new $CLJS.Xf($CLJS.av,this.offset),new $CLJS.Xf(Jw,this.aa),new $CLJS.Xf(Iw,this.$)],null),this.G))};$CLJS.g.P=function(a,b){return new Cw(this.ba,this.ea,this.Rb,this.offset,this.aa,this.$,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.na(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.ab($CLJS.jb,this,b)};
var Gw=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("cljs.pprint","write-token"),function(f,k){return Dw.h(k)},e,a,b,c,d)}();
Gw.m(null,rx,function(a,b){var c=px.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h($CLJS.qx):c.call(null,$CLJS.qx));b=Qw.h(b);c=LA.h(b);$CLJS.n(c)&&$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),c);a=$CLJS.pw(sw.h($CLJS.q($CLJS.q(a))),$CLJS.rw);$CLJS.Se(Ow.h(b),a);return $CLJS.Se(Uw.h(b),a)});Gw.m(null,vx,function(a,b){var c=px.h($CLJS.q($CLJS.q(a)));$CLJS.n(c)&&(c.h?c.h(ux):c.call(null,ux));b=tx.h(Qw.h(b));return $CLJS.n(b)?$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),b):null});
Gw.m(null,Mx,function(a,b){var c=Qw.h(b),d=Uw.h(c),e=$CLJS.av.h(b);b=yA.h(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(Kx,b):$CLJS.F.call(null,Kx,b)))a=$CLJS.q(Ow.h(c));else if($CLJS.n($CLJS.F.g?$CLJS.F.g($CLJS.Lx,b):$CLJS.F.call(null,$CLJS.Lx,b)))a=$CLJS.pw(sw.h($CLJS.q($CLJS.q(a))),$CLJS.rw);else throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));return $CLJS.Se(d,e+a)});Gw.m(null,ex,function(a,b){return $CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),$CLJS.oj.h(b))});
Gw.m(null,Ew,function(a,b){var c=$CLJS.F.g($CLJS.jj.h(b),Fx);c||(c=(c=!$CLJS.F.g($CLJS.jj.h(b),Hx))?$CLJS.q(Lw.h(Qw.h(b))):c);$CLJS.n(c)?Ww.g?Ww.g(a,b):Ww.call(null,a,b):(b=Fw.h($CLJS.q($CLJS.q(a))),$CLJS.n(b)&&$CLJS.Zb(sw.h($CLJS.q($CLJS.q(a))),b));return $CLJS.Nh.A($CLJS.q(a),$CLJS.R,Fw,null)});
var pB=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("cljs.pprint","emit-nl?"),function(f){return $CLJS.jj.h(f)},e,a,b,c,d)}();pB.m(null,Ix,function(a,b,c){a=Qw.h(a);return Mw(b,a,c)});pB.m(null,Gx,function(a,b,c){a=Qw.h(a);return Pw(b,a,c)});
pB.m(null,Hx,function(a,b,c,d){a=Qw.h(a);var e=$CLJS.q(Vw.h(a));return $CLJS.n(e)?e:(d=!Kw(b,d))?d:Pw(b,a,c)});pB.m(null,Fx,function(){return!0});
var Yw=function Yw(a,b){var d=Xw(b);b=$CLJS.H(d,0,null);var e=$CLJS.H(d,1,null);$CLJS.n(b)&&Hw(a,b,!1);if($CLJS.n(e)){d=Rw(e);b=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var f=$CLJS.B(e),k=pB.A(f,a,b,Sw(e));$CLJS.n(k)&&(Ww(a,f),e=$CLJS.C(e));Kw(a,e)?a=e:(e=Yw.g?Yw.g(a,b):Yw.call(null,a,b),$CLJS.F.g(e,b)?(Hw(a,b,!1),a=d):a=$CLJS.dg.g($CLJS.zf,$CLJS.bf.g(e,d)));return a}return null};$CLJS.g=hx.prototype;$CLJS.g.P=function(a,b){return new hx(this.Aa,this.uf,this.Rh,this.Sg,this.Rc,b)};$CLJS.g.O=function(){return this.rh};
$CLJS.g.Xb=function(){return this.Rc};
$CLJS.g.Fc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a))){var c=gx(this,b);b=c.replace(/\s+$/,"");a=c.substring(b.length);var d=$CLJS.xi.h($CLJS.q($CLJS.q(this)));if($CLJS.F.g(d,fx))return ax(this),$CLJS.Zb(sw.h($CLJS.q($CLJS.q(this))),b),$CLJS.Nh.A($CLJS.q(this),$CLJS.R,Fw,a);d=dx.h($CLJS.q($CLJS.q(this)));c=d+$CLJS.E(c);$CLJS.Nh.A($CLJS.q(this),$CLJS.R,dx,c);return $w(this,new yw(ex,b,a,d,c,null,null,null))}if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,
a):$CLJS.F.call(null,Number,a)))return $CLJS.F.g($CLJS.xi.h($CLJS.q($CLJS.q(this))),fx)?(ax(this),b=$CLJS.Zb(sw.h($CLJS.q($CLJS.q(this))),b)):$CLJS.F.g(b,"\n")?b=gx(this,"\n"):(a=dx.h($CLJS.q($CLJS.q(this))),c=a+1,$CLJS.Nh.A($CLJS.q(this),$CLJS.R,dx,c),b=$v(b),b=$w(this,new yw(ex,b,null,a,c,null,null,null))),b;throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};$CLJS.g.jc=function(){this.cf(null);return Xv(sw.h($CLJS.q($CLJS.q(this))))};
$CLJS.g.cf=function(){return $CLJS.F.g($CLJS.xi.h($CLJS.q($CLJS.q(this))),cx)?(Hw(this,$CLJS.Uu.h($CLJS.q($CLJS.q(this))),!0),$CLJS.Nh.A($CLJS.q(this),$CLJS.R,$CLJS.Uu,$CLJS.zf)):ax(this)};$CLJS.zx=!0;Ax=null;$CLJS.ix=72;jx=40;qB=null;rB=null;sB=null;iy=null;hy=10;Dx=0;yx=null;tB=function tB(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return tB.l(arguments[0],1<c.length?new $CLJS.y(c.slice(1),0,null):null)};
tB.l=function(a,b){var c=$CLJS.$k.l($CLJS.G([new $CLJS.h(null,1,[hB,!0],null),$CLJS.O.g(xA,b)]));b=hy;var d=rB,e=$CLJS.Ma,f=$CLJS.ph,k=qB,l=jx,m=Ax,t=$CLJS.zx,u=iy,v=$CLJS.Ia,x=$CLJS.ix,A=sB,D=sw.g(c,hy),J=iB.g(c,rB),K=$CLJS.KA.g(c,$CLJS.Ma),X=$CLJS.gv.g(c,$CLJS.ph),Z=bB.g(c,qB),fa=Nw.g(c,jx),ra=$CLJS.Oi.g(c,Ax),xa=kB.g(c,$CLJS.zx),tb=OA.g(c,iy),Rb=$CLJS.Ha.g(c,$CLJS.Ia),Vb=$A.g(c,$CLJS.ix),Gc=oB.g(c,sB);hy=D;rB=J;$CLJS.Ma=K;$CLJS.ph=X;qB=Z;jx=fa;Ax=ra;$CLJS.zx=xa;iy=tb;$CLJS.Ia=Rb;$CLJS.ix=Vb;sB=
Gc;try{var fc=new $CLJS.Fa,Hc=$CLJS.Hd(c,hB)?hB.h(c):!0,zb=!0===Hc||null==Hc?new $CLJS.qc(fc):Hc;if($CLJS.n($CLJS.zx)){var Mb=$CLJS.Sa($CLJS.xx(zb));c=$CLJS.hw;$CLJS.hw=Mb?$CLJS.ox(zb):zb;try{$CLJS.Bx(a),$CLJS.ow()}finally{$CLJS.hw=c}}else{Mb=$CLJS.hw;$CLJS.hw=zb;try{gw.call(null,a)}finally{$CLJS.hw=Mb}}!0===Hc&&$CLJS.th($CLJS.p.h(fc));return null==Hc?$CLJS.p.h(fc):null}finally{sB=A,$CLJS.ix=x,$CLJS.Ia=v,iy=u,$CLJS.zx=t,Ax=m,jx=l,qB=k,$CLJS.ph=f,$CLJS.Ma=e,rB=d,hy=b}};tB.v=1;
tB.B=function(a){var b=$CLJS.B(a);a=$CLJS.C(a);return this.l(b,a)};var Tx=null;$CLJS.g=Vx.prototype;$CLJS.g.pa=function(a,b){return this.ca(null,b,null)};$CLJS.g.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "seq":return this.mc;case "rest":return this.mb;case "pos":return this.Vb;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Cb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.rh(b,function(d){return $CLJS.rh(b,$CLJS.xh,""," ","",c,d)},"#cljs.pprint.arg-navigator{",", ","}",c,$CLJS.bf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Wx,this.mc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,this.mb],null),new $CLJS.P(null,2,5,$CLJS.Q,[dx,this.Vb],null)],null),this.G))};$CLJS.g.Da=function(){return new $CLJS.Nu(this,3,new $CLJS.P(null,3,5,$CLJS.Q,[Wx,$CLJS.Pi,dx],null),$CLJS.n(this.G)?$CLJS.pc(this.G):$CLJS.He())};
$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 3+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-402038447^$CLJS.Pc(this)};$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.mc,b.mc)&&$CLJS.F.g(this.mb,b.mb)&&$CLJS.F.g(this.Vb,b.Vb)&&$CLJS.F.g(this.G,b.G)};
$CLJS.g.Mb=function(a,b){return $CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,3,[dx,null,Wx,null,$CLJS.Pi,null],null),null),b)?$CLJS.Ak.g($CLJS.Jb($CLJS.dg.g($CLJS.N,this),this.S),b):new Vx(this.mc,this.mb,this.Vb,this.S,$CLJS.Fe($CLJS.Ak.g(this.G,b)),null)};$CLJS.g.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "seq":case "rest":case "pos":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.na=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(Wx,b):$CLJS.fe.call(null,Wx,b))?new Vx(c,this.mb,this.Vb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.Pi,b):$CLJS.fe.call(null,$CLJS.Pi,b))?new Vx(this.mc,c,this.Vb,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(dx,b):$CLJS.fe.call(null,dx,b))?new Vx(this.mc,this.mb,c,this.S,this.G,null):new Vx(this.mc,this.mb,this.Vb,this.S,$CLJS.R.j(this.G,b,c),null)};
$CLJS.g.ga=function(){return $CLJS.z($CLJS.bf.g(new $CLJS.P(null,3,5,$CLJS.Q,[new $CLJS.Xf(Wx,this.mc),new $CLJS.Xf($CLJS.Pi,this.mb),new $CLJS.Xf(dx,this.Vb)],null),this.G))};$CLJS.g.P=function(a,b){return new Vx(this.mc,this.mb,this.Vb,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.na(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.ab($CLJS.jb,this,b)};$CLJS.g=ay.prototype;$CLJS.g.pa=function(a,b){return this.ca(null,b,null)};
$CLJS.g.ca=function(a,b,c){switch(b instanceof $CLJS.M?b.T:null){case "func":return this.$b;case "def":return this.Zb;case "params":return this.fc;case "offset":return this.offset;default:return $CLJS.I.j(this.G,b,c)}};$CLJS.g.Cb=function(a,b,c){return $CLJS.ab(function(d,e){var f=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);return b.j?b.j(d,f,e):b.call(null,d,f,e)},c,this)};
$CLJS.g.da=function(a,b,c){return $CLJS.rh(b,function(d){return $CLJS.rh(b,$CLJS.xh,""," ","",c,d)},"#cljs.pprint.compiled-directive{",", ","}",c,$CLJS.bf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,2,5,$CLJS.Q,[Ny,this.$b],null),new $CLJS.P(null,2,5,$CLJS.Q,[Wz,this.Zb],null),new $CLJS.P(null,2,5,$CLJS.Q,[Ly,this.fc],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.av,this.offset],null)],null),this.G))};
$CLJS.g.Da=function(){return new $CLJS.Nu(this,4,new $CLJS.P(null,4,5,$CLJS.Q,[Ny,Wz,Ly,$CLJS.av],null),$CLJS.n(this.G)?$CLJS.pc(this.G):$CLJS.He())};$CLJS.g.O=function(){return this.S};$CLJS.g.ha=function(){return 4+$CLJS.E(this.G)};$CLJS.g.fa=function(){var a=this.J;return null!=a?a:this.J=a=-829256337^$CLJS.Pc(this)};
$CLJS.g.V=function(a,b){return null!=b&&this.constructor===b.constructor&&$CLJS.F.g(this.$b,b.$b)&&$CLJS.F.g(this.Zb,b.Zb)&&$CLJS.F.g(this.fc,b.fc)&&$CLJS.F.g(this.offset,b.offset)&&$CLJS.F.g(this.G,b.G)};$CLJS.g.Mb=function(a,b){return $CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,4,[$CLJS.av,null,Ny,null,Ly,null,Wz,null],null),null),b)?$CLJS.Ak.g($CLJS.Jb($CLJS.dg.g($CLJS.N,this),this.S),b):new ay(this.$b,this.Zb,this.fc,this.offset,this.S,$CLJS.Fe($CLJS.Ak.g(this.G,b)),null)};
$CLJS.g.kb=function(a,b){switch(b instanceof $CLJS.M?b.T:null){case "func":case "def":case "params":case "offset":return!0;default:return $CLJS.Hd(this.G,b)}};
$CLJS.g.na=function(a,b,c){return $CLJS.n($CLJS.fe.g?$CLJS.fe.g(Ny,b):$CLJS.fe.call(null,Ny,b))?new ay(c,this.Zb,this.fc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Wz,b):$CLJS.fe.call(null,Wz,b))?new ay(this.$b,c,this.fc,this.offset,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g(Ly,b):$CLJS.fe.call(null,Ly,b))?new ay(this.$b,this.Zb,c,this.offset,this.S,this.G,null):$CLJS.n($CLJS.fe.g?$CLJS.fe.g($CLJS.av,b):$CLJS.fe.call(null,$CLJS.av,b))?new ay(this.$b,this.Zb,this.fc,c,this.S,
this.G,null):new ay(this.$b,this.Zb,this.fc,this.offset,this.S,$CLJS.R.j(this.G,b,c),null)};$CLJS.g.ga=function(){return $CLJS.z($CLJS.bf.g(new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.Xf(Ny,this.$b),new $CLJS.Xf(Wz,this.Zb),new $CLJS.Xf(Ly,this.fc),new $CLJS.Xf($CLJS.av,this.offset)],null),this.G))};$CLJS.g.P=function(a,b){return new ay(this.$b,this.Zb,this.fc,this.offset,b,this.G,this.J)};$CLJS.g.ja=function(a,b){return $CLJS.zd(b)?this.na(null,$CLJS.ld(b,0),$CLJS.ld(b,1)):$CLJS.ab($CLJS.jb,this,b)};
var jy=new $CLJS.h(null,3,[2,"#b",8,"#o",16,"#x"],null),xy=new $CLJS.P(null,20,5,$CLJS.Q,"zero one two three four five six seven eight nine ten eleven twelve thirteen fourteen fifteen sixteen seventeen eighteen nineteen".split(" "),null),uB=new $CLJS.P(null,20,5,$CLJS.Q,"zeroth first second third fourth fifth sixth seventh eighth ninth tenth eleventh twelfth thirteenth fourteenth fifteenth sixteenth seventeenth eighteenth nineteenth".split(" "),null),yy=new $CLJS.P(null,10,5,$CLJS.Q,"  twenty thirty forty fifty sixty seventy eighty ninety".split(" "),
null),vB=new $CLJS.P(null,10,5,$CLJS.Q,"  twentieth thirtieth fortieth fiftieth sixtieth seventieth eightieth ninetieth".split(" "),null),Ay=new $CLJS.P(null,22,5,$CLJS.Q," thousand million billion trillion quadrillion quintillion sextillion septillion octillion nonillion decillion undecillion duodecillion tredecillion quattuordecillion quindecillion sexdecillion septendecillion octodecillion novemdecillion vigintillion".split(" "),null),wB=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,
"I II III IIII V VI VII VIII VIIII".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XXXX L LX LXX LXXX LXXXX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CCCC D DC DCC DCCC DCCCC".split(" "),null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),xB=new $CLJS.P(null,4,5,$CLJS.Q,[new $CLJS.P(null,9,5,$CLJS.Q,"I II III IV V VI VII VIII IX".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"X XX XXX XL L LX LXX LXXX XC".split(" "),null),new $CLJS.P(null,9,5,$CLJS.Q,"C CC CCC CD D DC DCC DCCC CM".split(" "),
null),new $CLJS.P(null,3,5,$CLJS.Q,["M","MM","MMM"],null)],null),Dy=new $CLJS.h(null,5,[8,"Backspace",9,"Tab",10,"Newline",13,"Return",32,"Space"],null);uz.prototype.P=function(a,b){return new uz(this.Aa,b)};uz.prototype.O=function(){return this.Ch};uz.prototype.jc=function(){return Xv(this.Aa)};
uz.prototype.Fc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Zb(this.Aa,b.toLowerCase());if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.Zb(this.Aa,$v(b).toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};wz.prototype.P=function(a,b){return new wz(this.Aa,b)};wz.prototype.O=function(){return this.Dh};wz.prototype.jc=function(){return Xv(this.Aa)};
wz.prototype.Fc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Zb(this.Aa,b.toUpperCase());if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return $CLJS.Zb(this.Aa,$v(b).toUpperCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};zz.prototype.P=function(a,b){return new zz(this.Aa,this.Ad,b)};zz.prototype.O=function(){return this.Eh};zz.prototype.jc=function(){return Xv(this.Aa)};
zz.prototype.Fc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return $CLJS.Zb(this.Aa,yz(b.toLowerCase(),$CLJS.q(this.Ad))),0<b.length?$CLJS.Se(this.Ad,$CLJS.Ba($CLJS.bd(b,$CLJS.E(b)-1))):null;if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return b=$v(b),a=$CLJS.n($CLJS.q(this.Ad))?b.toUpperCase():b,$CLJS.Zb(this.Aa,a),$CLJS.Se(this.Ad,$CLJS.Ba(b));throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
Bz.prototype.P=function(a,b){return new Bz(this.Aa,this.od,b)};Bz.prototype.O=function(){return this.Fh};Bz.prototype.jc=function(){return Xv(this.Aa)};
Bz.prototype.Fc=function(a,b){a=$CLJS.Xa(b);if($CLJS.n($CLJS.F.g?$CLJS.F.g(String,a):$CLJS.F.call(null,String,a)))return b=b.toLowerCase(),$CLJS.Sa($CLJS.q(this.od))?(a=RegExp("\\S","g").exec(b),a=$CLJS.n(a)?a.index:a,$CLJS.n(a)?($CLJS.Zb(this.Aa,[b.substring(0,a),$CLJS.bd(b,a).toUpperCase(),b.substring(a+1).toLowerCase()].join("")),$CLJS.Se(this.od,!0)):$CLJS.Zb(this.Aa,b)):$CLJS.Zb(this.Aa,b.toLowerCase());if($CLJS.n($CLJS.F.g?$CLJS.F.g(Number,a):$CLJS.F.call(null,Number,a)))return b=$v(b),a=$CLJS.Sa($CLJS.q(this.od)),
$CLJS.n(a?Vv(b):a)?($CLJS.Se(this.od,!0),$CLJS.Zb(this.Aa,b.toUpperCase())):$CLJS.Zb(this.Aa,b.toLowerCase());throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));};
var Qz=$CLJS.Hg("ASDBOXRPCFEG$%\x26|~\nT*?()[;]{}\x3c\x3e^W_I".split(""),[new $CLJS.h(null,5,[Lz,"A",Ly,new $CLJS.h(null,4,[my,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),ny,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),ly,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),oy,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,Mz,null],null),null),Vz,$CLJS.N,Rz,function(){return function(a,b){return qy($CLJS.vy,a,b)}}],null),new $CLJS.h(null,
5,[Lz,"S",Ly,new $CLJS.h(null,4,[my,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),ny,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),ly,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),oy,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,Mz,null],null),null),Vz,$CLJS.N,Rz,function(){return function(a,b){return qy($CLJS.Lh,a,b)}}],null),new $CLJS.h(null,5,[Lz,"D",Ly,new $CLJS.h(null,4,[my,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),oy,new $CLJS.P(null,
2,5,$CLJS.Q,[" ",String],null),uy,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),ty,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,Mz,null],null),null),Vz,$CLJS.N,Rz,function(){return function(a,b){return wy(10,a,b)}}],null),new $CLJS.h(null,5,[Lz,"B",Ly,new $CLJS.h(null,4,[my,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),oy,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),uy,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),ty,new $CLJS.P(null,
2,5,$CLJS.Q,[3,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,Mz,null],null),null),Vz,$CLJS.N,Rz,function(){return function(a,b){return wy(2,a,b)}}],null),new $CLJS.h(null,5,[Lz,"O",Ly,new $CLJS.h(null,4,[my,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),oy,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),uy,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),ty,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,Mz,
null],null),null),Vz,$CLJS.N,Rz,function(){return function(a,b){return wy(8,a,b)}}],null),new $CLJS.h(null,5,[Lz,"X",Ly,new $CLJS.h(null,4,[my,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),oy,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),uy,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),ty,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,Mz,null],null),null),Vz,$CLJS.N,Rz,function(){return function(a,b){return wy(16,a,b)}}],null),new $CLJS.h(null,
5,[Lz,"R",Ly,new $CLJS.h(null,5,[sw,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),my,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),oy,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),uy,new $CLJS.P(null,2,5,$CLJS.Q,[",",String],null),ty,new $CLJS.P(null,2,5,$CLJS.Q,[3,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,Mz,null],null),null),Vz,$CLJS.N,Rz,function(a){return $CLJS.n($CLJS.B(sw.h(a)))?function(b,c){return wy(sw.h(b),b,c)}:$CLJS.n(function(){var b=cy.h(a);return $CLJS.n(b)?
by.h(a):b}())?function(b,c){return Cy(wB,c)}:$CLJS.n(cy.h(a))?function(b,c){return Cy(xB,c)}:$CLJS.n(by.h(a))?function(b,c){b=Xx(c);c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);if($CLJS.F.g(0,c))py.l($CLJS.G(["zeroth"]));else{var d=ry(1E3,0>c?-c:c);if($CLJS.E(d)<=$CLJS.E(Ay)){var e=$CLJS.cf.g(zy,$CLJS.fw(1,d));e=By(e,1);var f=$CLJS.jd(d);d=$CLJS.Ud(f,100);f=aw(f,100);var k=0<d?[$CLJS.p.h($CLJS.bd(xy,d))," hundred"].join(""):null,l=$CLJS.p,m=l.h;if(0<f)if(20>f)var t=$CLJS.bd(uB,f);else{t=$CLJS.Ud(f,10);
var u=aw(f,10);t=0<t&&!(0<u)?$CLJS.bd(vB,t):[$CLJS.p.h(0<t?$CLJS.bd(yy,t):null),0<t&&0<u?"-":null,$CLJS.p.h(0<u?$CLJS.bd(uB,u):null)].join("")}else t=0<d?"th":null;d=[k,0<d&&0<f?" ":null,m.call(l,t)].join("");py.l($CLJS.G([[0>c?"minus ":null,$CLJS.td(e)||$CLJS.td(d)?$CLJS.td(e)?d:[e,"th"].join(""):[e,", ",d].join("")].join("")]))}else wy(10,new $CLJS.h(null,5,[my,0,oy," ",uy,",",ty,3,by,!0],null),Px(new $CLJS.P(null,1,5,$CLJS.Q,[c],null))),e=aw(c,100),c=11<e||19>e,e=aw(e,10),py.l($CLJS.G([1===e&&
c?"st":2===e&&c?"nd":3===e&&c?"rd":"th"]))}return b}:function(b,c){c=Xx(c);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);if($CLJS.F.g(0,b))py.l($CLJS.G(["zero"]));else{var d=ry(1E3,0>b?-b:b);$CLJS.E(d)<=$CLJS.E(Ay)?(d=$CLJS.cf.g(zy,d),d=By(d,0),py.l($CLJS.G([[0>b?"minus ":null,d].join("")]))):wy(10,new $CLJS.h(null,5,[my,0,oy," ",uy,",",ty,3,by,!0],null),Px(new $CLJS.P(null,1,5,$CLJS.Q,[b],null)))}return c}}],null),new $CLJS.h(null,5,[Lz,"P",Ly,$CLJS.N,Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,
null,Mz,null],null),null),Vz,$CLJS.N,Rz,function(){return function(a,b){b=$CLJS.n(by.h(a))?Zx(b,-1):b;a=$CLJS.n(cy.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,["y","ies"],null):new $CLJS.P(null,2,5,$CLJS.Q,["","s"],null);var c=Xx(b);b=$CLJS.H(c,0,null);c=$CLJS.H(c,1,null);py.l($CLJS.G([$CLJS.F.g(b,1)?$CLJS.B(a):$CLJS.id(a)]));return c}}],null),new $CLJS.h(null,5,[Lz,"C",Ly,new $CLJS.h(null,1,[Fy,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,
Mz,null],null),null),Vz,$CLJS.N,Rz,function(a){return $CLJS.n(by.h(a))?Ey:$CLJS.n(cy.h(a))?Gy:Hy}],null),new $CLJS.h(null,5,[Lz,"F",Ly,new $CLJS.h(null,5,[Ty,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Uy,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.Vy,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),Wy,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),oy,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,1,[cy,null],null),null),Vz,$CLJS.N,Rz,function(){return Xy}],
null),new $CLJS.h(null,5,[Lz,"E",Ly,new $CLJS.h(null,7,[Ty,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Uy,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Yy,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.Vy,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Wy,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),oy,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Zy,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,1,[cy,null],null),null),Vz,$CLJS.N,
Rz,function(){return $y}],null),new $CLJS.h(null,5,[Lz,"G",Ly,new $CLJS.h(null,7,[Ty,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Uy,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),Yy,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),$CLJS.Vy,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Wy,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null),oy,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null),Zy,new $CLJS.P(null,2,5,$CLJS.Q,[null,String],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,1,[cy,null],
null),null),Vz,$CLJS.N,Rz,function(){return az}],null),new $CLJS.h(null,5,[Lz,"$",Ly,new $CLJS.h(null,4,[Uy,new $CLJS.P(null,2,5,$CLJS.Q,[2,Number],null),$CLJS.bz,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),Ty,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),oy,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,Mz,null],null),null),Vz,$CLJS.N,Rz,function(){return cz}],null),new $CLJS.h(null,5,[Lz,"%",Ly,new $CLJS.h(null,1,[$CLJS.TA,new $CLJS.P(null,
2,5,$CLJS.Q,[1,Number],null)],null),Kz,$CLJS.ah,Vz,$CLJS.N,Rz,function(){return function(a,b){a=$CLJS.TA.h(a);for(var c=0;;)if(c<a)iw(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[Lz,"\x26",Ly,new $CLJS.h(null,1,[$CLJS.TA,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,1,[kB,null],null),null),Vz,$CLJS.N,Rz,function(){return function(a,b){a=$CLJS.TA.h(a);0<a&&((null!=$CLJS.hw?$CLJS.hw.C&32768||$CLJS.r===$CLJS.hw.Hf||($CLJS.hw.C?0:$CLJS.Wa(Wv,$CLJS.hw)):
$CLJS.Wa(Wv,$CLJS.hw))?$CLJS.F.g(0,$CLJS.pw(sw.h($CLJS.q($CLJS.q($CLJS.hw))),$CLJS.rw))||iw():iw());--a;for(var c=0;;)if(c<a)iw(),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[Lz,"|",Ly,new $CLJS.h(null,1,[$CLJS.TA,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),Kz,$CLJS.ah,Vz,$CLJS.N,Rz,function(){return function(a,b){a=$CLJS.TA.h(a);for(var c=0;;)if(c<a)py.l($CLJS.G(["\f"])),c+=1;else break;return b}}],null),new $CLJS.h(null,5,[Lz,"~",Ly,new $CLJS.h(null,1,[$CLJS.bz,new $CLJS.P(null,
2,5,$CLJS.Q,[1,Number],null)],null),Kz,$CLJS.ah,Vz,$CLJS.N,Rz,function(){return function(a,b){a=$CLJS.bz.h(a);py.l($CLJS.G([$CLJS.O.g($CLJS.p,$CLJS.Ye(a,"~"))]));return b}}],null),new $CLJS.h(null,5,[Lz,"\n",Ly,$CLJS.N,Kz,new $CLJS.$g(null,new $CLJS.h(null,2,[by,null,cy,null],null),null),Vz,$CLJS.N,Rz,function(){return function(a,b){$CLJS.n(cy.h(a))&&iw();return b}}],null),new $CLJS.h(null,5,[Lz,"T",Ly,new $CLJS.h(null,2,[IA,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),ny,new $CLJS.P(null,2,5,$CLJS.Q,
[1,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,2,[cy,null,kB,null],null),null),Vz,$CLJS.N,Rz,function(a){return $CLJS.n(cy.h(a))?function(b,c){var d=IA.h(b);b=ny.h(b);var e=d+$CLJS.pw(sw.h($CLJS.q($CLJS.q($CLJS.hw))),$CLJS.rw);e=0<b?aw(e,b):0;d+=$CLJS.F.g(0,e)?0:b-e;py.l($CLJS.G([$CLJS.O.g($CLJS.p,$CLJS.Ye(d," "))]));return c}:function(b,c){var d=IA.h(b);b=ny.h(b);var e=$CLJS.pw(sw.h($CLJS.q($CLJS.q($CLJS.hw))),$CLJS.rw);d=e<d?d-e:$CLJS.F.g(b,0)?0:b-aw(e-d,b);py.l($CLJS.G([$CLJS.O.g($CLJS.p,
$CLJS.Ye(d," "))]));return c}}],null),new $CLJS.h(null,5,[Lz,"*",Ly,new $CLJS.h(null,1,[$CLJS.bz,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,2,[by,null,cy,null],null),null),Vz,$CLJS.N,Rz,function(){return function(a,b){var c=$CLJS.bz.h(a);return $CLJS.n(cy.h(a))?$x(b,c):Zx(b,$CLJS.n(by.h(a))?-c:c)}}],null),new $CLJS.h(null,5,[Lz,"?",Ly,$CLJS.N,Kz,new $CLJS.$g(null,new $CLJS.h(null,1,[cy,null],null),null),Vz,$CLJS.N,Rz,function(a){return $CLJS.n(cy.h(a))?
function(b,c){var d=Yx(c);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return Oy(c,d,My.h(b))}:function(b,c){var d=Yx(c);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);var e=Xx(d);d=$CLJS.H(e,0,null);e=$CLJS.H(e,1,null);d=Px(d);Oy(c,d,My.h(b));return e}}],null),new $CLJS.h(null,5,[Lz,"(",Ly,$CLJS.N,Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,Mz,null],null),null),Vz,new $CLJS.h(null,3,[$CLJS.Yz,")",cA,null,$CLJS.Yu,null],null),Rz,function(a){var b=$CLJS.n(function(){var c=cy.h(a);return $CLJS.n(c)?
by.h(a):c}())?xz:$CLJS.n(by.h(a))?Az:$CLJS.n(cy.h(a))?Cz:vz;return function(c,d){a:{var e=$CLJS.B($CLJS.ez.h(c)),f=$CLJS.hw;$CLJS.hw=b.h?b.h($CLJS.hw):b.call(null,$CLJS.hw);try{var k=Oy(e,d,My.h(c));break a}finally{$CLJS.hw=f}k=void 0}return k}}],null),new $CLJS.h(null,5,[Lz,")",Ly,$CLJS.N,Kz,$CLJS.ah,Vz,$CLJS.N,Rz,function(){return null}],null),new $CLJS.h(null,5,[Lz,"[",Ly,new $CLJS.h(null,1,[dz,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,2,[by,
null,cy,null],null),null),Vz,new $CLJS.h(null,3,[$CLJS.Yz,"]",cA,!0,$CLJS.Yu,$CLJS.dB],null),Rz,function(a){return $CLJS.n(by.h(a))?gz:$CLJS.n(cy.h(a))?hz:fz}],null),new $CLJS.h(null,5,[Lz,";",Ly,new $CLJS.h(null,2,[sz,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),tz,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,1,[by,null],null),null),Vz,new $CLJS.h(null,1,[$CLJS.ev,!0],null),Rz,function(){return null}],null),new $CLJS.h(null,5,[Lz,"]",Ly,$CLJS.N,
Kz,$CLJS.ah,Vz,$CLJS.N,Rz,function(){return null}],null),new $CLJS.h(null,5,[Lz,"{",Ly,new $CLJS.h(null,1,[iz,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,Mz,null],null),null),Vz,new $CLJS.h(null,2,[$CLJS.Yz,"}",cA,!1],null),Rz,function(a){var b=cy.h(a);b=$CLJS.n(b)?by.h(a):b;return $CLJS.n(b)?nz:$CLJS.n(by.h(a))?lz:$CLJS.n(cy.h(a))?mz:kz}],null),new $CLJS.h(null,5,[Lz,"}",Ly,$CLJS.N,Kz,new $CLJS.$g(null,new $CLJS.h(null,1,[by,null],
null),null),Vz,$CLJS.N,Rz,function(){return null}],null),new $CLJS.h(null,5,[Lz,"\x3c",Ly,new $CLJS.h(null,4,[my,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),ny,new $CLJS.P(null,2,5,$CLJS.Q,[1,Number],null),ly,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null),oy,new $CLJS.P(null,2,5,$CLJS.Q,[" ",String],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,4,[by,null,cy,null,Mz,null,kB,null],null),null),Vz,new $CLJS.h(null,3,[$CLJS.Yz,"\x3e",cA,!0,$CLJS.Yu,bA],null),Rz,function(){return pz}],null),new $CLJS.h(null,
5,[Lz,"\x3e",Ly,$CLJS.N,Kz,new $CLJS.$g(null,new $CLJS.h(null,1,[by,null],null),null),Vz,$CLJS.N,Rz,function(){return null}],null),new $CLJS.h(null,5,[Lz,"^",Ly,new $CLJS.h(null,3,[XA,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),ZA,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null),YA,new $CLJS.P(null,2,5,$CLJS.Q,[null,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,1,[by,null],null),null),Vz,$CLJS.N,Rz,function(){return function(a,b){var c=XA.h(a),d=ZA.h(a),e=YA.h(a),f=$CLJS.n(by.h(a))?
Jy:Iy;return $CLJS.n($CLJS.n(c)?$CLJS.n(d)?e:d:c)?c<=d&&d<=e?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n($CLJS.n(c)?d:c)?$CLJS.F.g(c,d)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:$CLJS.n(c)?$CLJS.F.g(c,0)?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b:($CLJS.n(by.h(a))?$CLJS.td($CLJS.Pi.h(My.h(a))):$CLJS.td($CLJS.Pi.h(b)))?new $CLJS.P(null,2,5,$CLJS.Q,[f,b],null):b}}],null),new $CLJS.h(null,5,[Lz,"W",Ly,$CLJS.N,Kz,new $CLJS.$g(null,new $CLJS.h(null,4,[by,null,cy,null,Mz,null,kB,null],null),null),
Vz,$CLJS.N,Rz,function(a){if($CLJS.n(function(){var c=cy.h(a);return $CLJS.n(c)?c:by.h(a)}())){var b=$CLJS.bf.g($CLJS.n(cy.h(a))?new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.gv,null,$CLJS.KA,null],null):$CLJS.zf,$CLJS.n(by.h(a))?new $CLJS.P(null,2,5,$CLJS.Q,[kB,!0],null):$CLJS.zf);return function(c,d){d=Xx(d);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.n($CLJS.O.j(tB,c,b))?new $CLJS.P(null,2,5,$CLJS.Q,[Iy,d],null):d}}return function(c,d){d=Xx(d);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);return $CLJS.n($CLJS.Bx(c))?
new $CLJS.P(null,2,5,$CLJS.Q,[Iy,d],null):d}}],null),new $CLJS.h(null,5,[Lz,"_",Ly,$CLJS.N,Kz,new $CLJS.$g(null,new $CLJS.h(null,3,[by,null,cy,null,Mz,null],null),null),Vz,$CLJS.N,Rz,function(){return Ez}],null),new $CLJS.h(null,5,[Lz,"I",Ly,new $CLJS.h(null,1,[$CLJS.bz,new $CLJS.P(null,2,5,$CLJS.Q,[0,Number],null)],null),Kz,new $CLJS.$g(null,new $CLJS.h(null,1,[by,null],null),null),Vz,$CLJS.N,Rz,function(){return Dz}],null)]),Fz=/^([vV]|#|('.)|([+-]?\d+)|(?=,))/,Oz=new $CLJS.$g(null,new $CLJS.h(null,
2,[ey,null,dy,null],null),null),Iz=new $CLJS.h(null,2,[":",by,"@",cy],null),Qx=function Qx(a){for(;;){if($CLJS.td(a))return!1;var c=kB.h(Kz.h(Wz.h($CLJS.B(a))));$CLJS.n(c)||(c=$CLJS.Me(Qx,$CLJS.B($CLJS.ez.h(Ly.h($CLJS.B(a))))),c=$CLJS.n(c)?c:$CLJS.Me(Qx,$CLJS.B($CLJS.Yu.h(Ly.h($CLJS.B(a))))));if($CLJS.n(c))return!0;a=$CLJS.C(a)}},pA=$CLJS.Oh(Ox),eA=new $CLJS.h(null,6,[$CLJS.Nj,"'",$CLJS.Rj,"#'",$CLJS.dv,"@",$CLJS.fv,"~",RA,"@",PA,"~"],null);
(function(){var a=pA("~\x3c[~;~@{~w~^, ~:_~}~;]~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=Px(d);return Rx(a,d)}b.v=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()})();
var yB=function(){var a=pA("~\x3c#{~;~@{~w~^ ~:_~}~;}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=Px(d);return Rx(a,d)}b.v=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),zB=new $CLJS.h(null,2,["core$future_call","Future","core$promise","Promise"],null),AB=function(){var a=pA("~\x3c\x3c-(~;~@{~w~^ ~_~}~;)-\x3c~:\x3e");
return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=Px(d);return Rx(a,d)}b.v=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),BB,CB=$CLJS.Re($CLJS.N),DB=$CLJS.Re($CLJS.N),EB=$CLJS.Re($CLJS.N),FB=$CLJS.Re($CLJS.N),GB=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));
BB=new $CLJS.di($CLJS.Eh.g("cljs.pprint","simple-dispatch"),nA,GB,CB,DB,EB,FB);bw(BB,$CLJS.zj,function(a){if($CLJS.Sa(fA(a)))if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var b=Dx,c=yx;Dx+=1;yx=0;try{sx("(",")");for(var d=0,e=$CLJS.z(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e&&($CLJS.Bx($CLJS.B(e)),$CLJS.C(e))){$CLJS.Zb($CLJS.hw," ");Jx(Ix);a=d+1;var f=$CLJS.C(e);d=a;e=f;continue}}else $CLJS.Zb($CLJS.hw,"...");break}wx()}finally{yx=c,Dx=b}}return null});bw(BB,$CLJS.Hj,gA);bw(BB,$CLJS.ek,jA);
bw(BB,$CLJS.Xi,yB);bw(BB,null,function(){return $CLJS.Zb($CLJS.hw,$CLJS.Lh.l($CLJS.G([null])))});bw(BB,$CLJS.ci,kA);Ax=BB;
var HB=function(){var a=pA("~:\x3c~w~^ ~@_~w~^ ~_~@{~w~^ ~_~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=Px(d);return Rx(a,d)}b.v=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),IB=function(){var a=pA("~:\x3c~1I~w~^ ~@_~w~@{ ~_~w~}~:\x3e");return function(){function b(d){var e=null;if(0<arguments.length){e=0;for(var f=
Array(arguments.length-0);e<f.length;)f[e]=arguments[e+0],++e;e=new $CLJS.y(f,0,null)}return c.call(this,e)}function c(d){d=Px(d);return Rx(a,d)}b.v=0;b.B=function(d){d=$CLJS.z(d);return c(d)};b.l=c;return b}()}(),JB=$CLJS.N,KB=function(a){return $CLJS.dg.g($CLJS.N,$CLJS.df($CLJS.Sd,$CLJS.G([function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.ld(f,m);t=new $CLJS.P(null,
2,5,$CLJS.Q,[t,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Eh.h($CLJS.fh($CLJS.B(t))),$CLJS.id(t)],null)],null);l.add(t);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.kc(e))):$CLJS.re($CLJS.te(l),null)}l=$CLJS.B(e);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[l,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Eh.h($CLJS.fh($CLJS.B(l))),$CLJS.id(l)],null)],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}()])))}(function(a){return $CLJS.dg.g($CLJS.N,$CLJS.cf.g(function(b){var c=$CLJS.H(b,0,null),d=$CLJS.H(b,
1,null);var e=$CLJS.ge(c);e=$CLJS.n(e)?e:$CLJS.Hd(new $CLJS.$g(null,new $CLJS.h(null,24,[$CLJS.Yi,"null",$CLJS.nj,"null",$CLJS.lk,"null",$CLJS.nk,"null",$CLJS.ij,"null",$CLJS.Jj,"null",$CLJS.Fj,"null",$CLJS.Kj,"null",$CLJS.qi,"null",$CLJS.Uj,"null",$CLJS.yj,"null",$CLJS.qj,"null",$CLJS.bk,"null",$CLJS.mk,"null",$CLJS.Ri,"null",$CLJS.Ni,"null",$CLJS.gj,"null",$CLJS.sj,"null",$CLJS.Zi,"null",$CLJS.Rj,"null",$CLJS.Nj,"null",$CLJS.gk,"null",$CLJS.Ji,"null",$CLJS.dk,"null"],null),null),c);return $CLJS.Sa(e)?
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Eh.g("clojure.core",$CLJS.fh(c)),d],null):b},a))}($CLJS.Hg([$CLJS.Zi,$CLJS.Ni,EA,$CLJS.Uj,MA,BA,DA,NA,HA,GA,JA,cB,UA,$CLJS.dk,SA,aB,WA,mB,zA,$CLJS.qj,eB,fB,lB,$CLJS.Oj,QA,nB,AA,CA,FA,gB],[HB,function(a){var b=$CLJS.id(a),c=$CLJS.B($CLJS.Kc($CLJS.Kc(a)));if($CLJS.zd(b)){a=JB;JB=$CLJS.F.g(1,$CLJS.E(b))?$CLJS.De([$CLJS.B(b),"%"]):$CLJS.dg.g($CLJS.N,$CLJS.cf.j(function(d,e){return new $CLJS.P(null,2,5,$CLJS.Q,[d,["%",$CLJS.p.h(e)].join("")],null)},b,$CLJS.dw(1,$CLJS.E(b)+
1)));try{return function(){var d=pA("~\x3c#(~;~@{~w~^ ~_~}~;)~:\x3e");return function(){function e(k){var l=null;if(0<arguments.length){l=0;for(var m=Array(arguments.length-0);l<m.length;)m[l]=arguments[l+0],++l;l=new $CLJS.y(m,0,null)}return f.call(this,l)}function f(k){k=Px(k);return Rx(d,k)}e.v=0;e.B=function(k){k=$CLJS.z(k);return f(k)};e.l=f;return e}()}()(c)}finally{JB=a}}else return tA(a)},wA,IB,function(a){if(3<$CLJS.E(a)){if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var b=Dx,c=yx;Dx+=1;yx=
0;try{sx("(",")");Nx(Kx,1);$CLJS.O.g(function(){var l=pA("~w ~@_~w ~@_~w ~_");return function(){function m(u){var v=null;if(0<arguments.length){v=0;for(var x=Array(arguments.length-0);v<x.length;)x[v]=arguments[v+0],++v;v=new $CLJS.y(x,0,null)}return t.call(this,v)}function t(u){u=Px(u);return Rx(l,u)}m.v=0;m.B=function(u){u=$CLJS.z(u);return t(u)};m.l=t;return m}()}(),a);for(var d=0,e=$CLJS.z($CLJS.We(3,a));;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{a=
Dx;var f=yx;Dx+=1;yx=0;try{sx(null,null),$CLJS.Bx($CLJS.B(e)),$CLJS.C(e)&&($CLJS.Zb($CLJS.hw," "),Jx(Gx),$CLJS.Bx($CLJS.id(e))),wx()}finally{yx=f,Dx=a}}if($CLJS.C($CLJS.Kc(e))){$CLJS.Zb($CLJS.hw," ");Jx(Ix);a=d+1;var k=$CLJS.C($CLJS.Kc(e));d=a;e=k;continue}}}else $CLJS.Zb($CLJS.hw,"...");break}wx()}finally{yx=c,Dx=b}}return null}return tA(a)},HB,uA,uA,wA,HB,wA,IB,IB,HB,IB,wA,wA,HB,wA,function(a){if($CLJS.C(a)){var b=$CLJS.z(a);a=$CLJS.B(b);var c=$CLJS.C(b);b=$CLJS.B(c);c=$CLJS.C(c);var d="string"===
typeof $CLJS.B(c)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.B(c),$CLJS.C(c)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,c],null);c=$CLJS.H(d,0,null);d=$CLJS.H(d,1,null);d=$CLJS.xd($CLJS.B(d))?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.B(d),$CLJS.C(d)],null):new $CLJS.P(null,2,5,$CLJS.Q,[null,d],null);var e=$CLJS.H(d,0,null),f=$CLJS.H(d,1,null);if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{d=Dx;var k=yx;Dx+=1;yx=0;try{sx("(",")");(function(){var m=pA("~w ~1I~@_~w");return function(){function t(v){var x=null;if(0<
arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.y(A,0,null)}return u.call(this,x)}function u(v){v=Px(v);return Rx(m,v)}t.v=0;t.B=function(v){v=$CLJS.z(v);return u(v)};t.l=u;return t}()})()(a,b);$CLJS.n($CLJS.n(c)?c:$CLJS.n(e)?e:$CLJS.z(f))&&function(){var m=pA("~@:_");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.y(A,0,null)}return u.call(this,
x)}function u(v){v=Px(v);return Rx(m,v)}t.v=0;t.B=function(v){v=$CLJS.z(v);return u(v)};t.l=u;return t}()}()();$CLJS.n(c)&&Sx(!0,'"~a"~:[~;~:@_~]',$CLJS.G([c,$CLJS.n(e)?e:$CLJS.z(f)]));$CLJS.n(e)&&function(){var m=pA("~w~:[~;~:@_~]");return function(){function t(v){var x=null;if(0<arguments.length){x=0;for(var A=Array(arguments.length-0);x<A.length;)A[x]=arguments[x+0],++x;x=new $CLJS.y(A,0,null)}return u.call(this,x)}function u(v){v=Px(v);return Rx(m,v)}t.v=0;t.B=function(v){v=$CLJS.z(v);return u(v)};
t.l=u;return t}()}()(e,$CLJS.z(f));for(a=f;;){qA($CLJS.B(a));var l=$CLJS.C(a);if(l)b=l,Jx(Ix),a=b;else break}wx()}finally{yx=k,Dx=d}}return null}return $CLJS.Bx(a)},wA,function(a){if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{var b=Dx,c=yx;Dx+=1;yx=0;try{sx("(",")");Nx(Kx,1);$CLJS.Bx($CLJS.B(a));if($CLJS.C(a)){$CLJS.Zb($CLJS.hw," ");Jx(Ix);for(var d=0,e=$CLJS.C(a);;){if($CLJS.Sa($CLJS.Ma)||d<$CLJS.Ma){if(e){if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{a=Dx;var f=yx;Dx+=1;yx=0;try{sx(null,null),$CLJS.Bx($CLJS.B(e)),
$CLJS.C(e)&&($CLJS.Zb($CLJS.hw," "),Jx(Gx),$CLJS.Bx($CLJS.id(e))),wx()}finally{yx=f,Dx=a}}if($CLJS.C($CLJS.Kc(e))){$CLJS.Zb($CLJS.hw," ");Jx(Ix);a=d+1;var k=$CLJS.C($CLJS.Kc(e));d=a;e=k;continue}}}else $CLJS.Zb($CLJS.hw,"...");break}}wx()}finally{yx=c,Dx=b}}return null},wA,uA,uA,HB,HB,wA,wA,HB]))),LB,MB=$CLJS.Re($CLJS.N),NB=$CLJS.Re($CLJS.N),aaa=$CLJS.Re($CLJS.N),baa=$CLJS.Re($CLJS.N),caa=$CLJS.I.j($CLJS.N,$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));
LB=new $CLJS.di($CLJS.Eh.g("cljs.pprint","code-dispatch"),nA,caa,MB,NB,aaa,baa);bw(LB,$CLJS.zj,function(a){if($CLJS.Sa(fA(a))){var b=KB.call(null,$CLJS.B(a));return $CLJS.n(b)?b.h?b.h(a):b.call(null,a):tA(a)}return null});bw(LB,$CLJS.Hi,function(a){var b=a.h?a.h(JB):a.call(null,JB);return $CLJS.n(b)?py.l($CLJS.G([b])):$CLJS.n(sB)?py.l($CLJS.G([$CLJS.fh(a)])):gw.call(null,a)});bw(LB,$CLJS.Hj,gA);bw(LB,$CLJS.ek,jA);bw(LB,$CLJS.Xi,yB);bw(LB,lA,AB);
bw(LB,mA,function(a){var b=$CLJS.p,c=b.h,d=$CLJS.Xa(a).name;var e=$CLJS.nh(/^[^$]+\$[^$]+/,d);e=$CLJS.n(e)?zB.h?zB.h(e):zB.call(null,e):null;b=["#\x3c",c.call(b,$CLJS.n(e)?e:d),"@",$CLJS.p.h($CLJS.za(a)),": "].join("");if($CLJS.n(Ex()))$CLJS.Zb($CLJS.hw,"#");else{c=Dx;d=yx;Dx+=1;yx=0;try{sx(b,"\x3e");Nx(Kx,-(b.length-2));Jx(Ix);var f=null!=a?a.I&1||$CLJS.r===a.Oi?!0:a.I?!1:$CLJS.Wa(Yv,a):$CLJS.Wa(Yv,a);var k=f?!Zv(a):f;$CLJS.Bx(k?VA:$CLJS.q(a));wx()}finally{yx=d,Dx=c}}return null});bw(LB,null,gw);
bw(LB,$CLJS.ci,kA);Ax=BB;