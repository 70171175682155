var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var qH,vH,vba,wba,xba,yba,zba,Aba;
$CLJS.oH=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.Ku([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.pH=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.SG(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)};qH=function(a){return $CLJS.zd(a)&&$CLJS.B(a)instanceof $CLJS.M};
$CLJS.sH=function(a){var b=function(){var c=qH(a);return c?(c=$CLJS.xd($CLJS.id(a)))?(c=$CLJS.Fi.h($CLJS.id(a)),$CLJS.n(c)?c:$CLJS.gE.h($CLJS.id(a))):c:c}();return $CLJS.n(b)?b:$CLJS.rH.h(a)};
vH=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,2,[$CLJS.Du,"valid MBQL clause",$CLJS.Fu,function(c){c=$CLJS.Ee(c);c=$CLJS.I.g(c,$CLJS.Aj);return["invalid MBQL clause: ",$CLJS.Lh.l($CLJS.G([c]))].join("")}],null),$CLJS.Ne(qH)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.tH],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,b],null),function(c){c=$CLJS.sH(c);
return uH(c,a)}],null)],null)};$CLJS.wH=new $CLJS.M(null,"right-join","right-join",-56349359);vba=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);wba=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);xba=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.xH=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.yH=new $CLJS.M(null,"expr","expr",745722291);$CLJS.zH=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.AH=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.BH=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.CH=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.DH=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.EH=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.FH=new $CLJS.M("lib","expression-name","lib/expression-name",-1799326590);$CLJS.GH=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);yba=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);
$CLJS.HH=new $CLJS.M(null,"left-join","left-join",-672831855);$CLJS.IH=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.JH=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.KH=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);
zba=new $CLJS.w("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);Aba=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.LH=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.tH=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.MH=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.NH=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.OH=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.PH=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.rH=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.SE(f);return $CLJS.F.g(k,$CLJS.fE)?$CLJS.Xa(f):k},e,a,b,c,d)}();
$CLJS.W(wba,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.EH],null),$CLJS.hH],null));$CLJS.rH.m(null,$CLJS.ci,function(a){throw $CLJS.hi($CLJS.SG("{0}: Don''t know how to determine the type of {1}",$CLJS.G([zba,$CLJS.Lh.l($CLJS.G([a]))])),new $CLJS.h(null,1,[$CLJS.yH,a],null));});$CLJS.rH.m(null,$CLJS.JH,function(a){$CLJS.H(a,0,null);$CLJS.H(a,1,null);a=$CLJS.H(a,2,null);return $CLJS.sH(a)});
var uH=function uH(a,b){return $CLJS.vd(a)?$CLJS.Me(function(d){return uH.g?uH.g(d,b):uH.call(null,d,b)},a):$CLJS.vd(b)?$CLJS.Me(function(d){return uH.g?uH.g(a,d):uH.call(null,a,d)},b):$CLJS.F.g(a,$CLJS.EH)?!0:$CLJS.fF(a,b)};$CLJS.W($CLJS.CH,vH($CLJS.Ij,"expression returning a boolean"));$CLJS.W($CLJS.MH,vH($CLJS.ak,"expression returning a string"));$CLJS.W($CLJS.PH,vH($CLJS.tj,"expression returning an integer"));$CLJS.W(xba,vH($CLJS.zG,"expression returning a non-integer real number"));
$CLJS.W($CLJS.OH,vH($CLJS.Mj,"expression returning a number"));$CLJS.W(yba,vH($CLJS.GF,"expression returning a date"));$CLJS.W(Aba,vH($CLJS.CG,"expression returning a time"));$CLJS.W(vba,vH($CLJS.dG,"expression returning a date time"));$CLJS.W($CLJS.AH,vH($CLJS.Tj,"expression returning a date, time, or date time"));$CLJS.QH=new $CLJS.$g(null,new $CLJS.h(null,3,[$CLJS.Mj,null,$CLJS.ak,null,$CLJS.Tj,null],null),null);$CLJS.W($CLJS.KH,vH($CLJS.QH,"an expression that can be compared with :\x3e or :\x3c"));
$CLJS.W($CLJS.IH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,vH(new $CLJS.$g(null,new $CLJS.h(null,4,[$CLJS.Mj,null,$CLJS.ak,null,$CLJS.Tj,null,$CLJS.Ij,null],null),null),"an expression that can appear in :\x3d or :!\x3d")],null));$CLJS.W($CLJS.DH,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,vH($CLJS.xj,"any type of expression")],null));
$CLJS.W($CLJS.zH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.DH],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.at,$CLJS.Gt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FH,$CLJS.Wj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,$CLJS.Gt],null)],null)],null)],null));