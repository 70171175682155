var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.js");
'use strict';$CLJS.w3=function(a,b,c){return null==c||$CLJS.Hd(a,b)?a:$CLJS.R.j(a,b,c)};$CLJS.x3=function(a,b){if($CLJS.z(b)){if($CLJS.F.g($CLJS.E(b),1))return $CLJS.B(b);a=[" ",(0,$CLJS.ma)(a)," "].join("");return $CLJS.F.g($CLJS.E(b),2)?[$CLJS.p.h($CLJS.B(b)),a,$CLJS.p.h($CLJS.id(b))].join(""):[$CLJS.Pu(", ",$CLJS.Mu(b)),",",a,$CLJS.p.h($CLJS.jd(b))].join("")}return null};$CLJS.z3=function(a,b){return $CLJS.y3.g(a,b instanceof $CLJS.M?b:$CLJS.ri.h(b))};
$CLJS.A3=new $CLJS.M("lib","source-uuid","lib/source-uuid",1828897581);var Xja,Yja,Zja,$ja,aka,D3;
$CLJS.B3=function(){function a(e,f){if($CLJS.Sa(f))return"";e=Math.abs(e);var k=$CLJS.yh.h(f);switch(k instanceof $CLJS.M?k.T:null){case "default":return $CLJS.BX("Default period","Default periods",e);case "millisecond":return $CLJS.BX("Millisecond","Milliseconds",e);case "second":return $CLJS.BX("Second","Seconds",e);case "minute":return $CLJS.BX("Minute","Minutes",e);case "hour":return $CLJS.BX("Hour","Hours",e);case "day":return $CLJS.BX("Day","Days",e);case "week":return $CLJS.BX("Week","Weeks",
e);case "month":return $CLJS.BX("Month","Months",e);case "quarter":return $CLJS.BX("Quarter","Quarters",e);case "year":return $CLJS.BX("Year","Years",e);case "minute-of-hour":return $CLJS.BX("Minute of hour","Minutes of hour",e);case "hour-of-day":return $CLJS.BX("Hour of day","Hours of day",e);case "day-of-week":return $CLJS.BX("Day of week","Days of week",e);case "day-of-month":return $CLJS.BX("Day of month","Days of month",e);case "day-of-year":return $CLJS.BX("Day of year","Days of year",e);case "week-of-year":return $CLJS.BX("Week of year",
"Weeks of year",e);case "month-of-year":return $CLJS.BX("Month of year","Months of year",e);case "quarter-of-year":return $CLJS.BX("Quarter of year","Quarters of year",e);default:return f=$CLJS.tv($CLJS.fh(f),/-/),e=$CLJS.z(f),f=$CLJS.B(e),e=$CLJS.C(e),$CLJS.Pu(" ",$CLJS.ce($CLJS.AD(f),e))}}function b(e){return d.g?d.g(1,e):d.call(null,1,e)}function c(){return d.g?d.g(1,null):d.call(null,1,null)}var d=null;d=function(e,f){switch(arguments.length){case 0:return c.call(this);case 1:return b.call(this,
e);case 2:return a.call(this,e,f)}throw Error("Invalid arity: "+arguments.length);};d.o=c;d.h=b;d.g=a;return d}();$CLJS.y3=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.temporal-bucket","with-temporal-bucket-method"),function(f){return $CLJS.SE(f)},e,a,b,c,d)}();Xja=$CLJS.Re($CLJS.N);Yja=$CLJS.Re($CLJS.N);
Zja=$CLJS.Re($CLJS.N);$ja=$CLJS.Re($CLJS.N);aka=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));$CLJS.C3=new $CLJS.di($CLJS.Eh.g("metabase.lib.temporal-bucket","temporal-bucket-method"),$CLJS.SE,aka,Xja,Yja,Zja,$ja);$CLJS.C3.m(null,$CLJS.ci,function(){return null});D3=new $CLJS.$g(null,new $CLJS.h(null,4,[$CLJS.wJ,null,$CLJS.zJ,null,$CLJS.AJ,null,$CLJS.xJ,null],null),null);
$CLJS.bka=$CLJS.dg.j($CLJS.zf,$CLJS.Ik.g($CLJS.Jk.h(D3),$CLJS.cf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.FJ,$CLJS.ri,a],null);return $CLJS.F.g(a,$CLJS.Fv)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.RJ);$CLJS.cka=$CLJS.Kk.g(function(a){var b=new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.FJ,$CLJS.ri,a],null);return $CLJS.F.g(a,$CLJS.Gv)?$CLJS.R.j(b,$CLJS.ci,!0):b},$CLJS.MJ);
$CLJS.dka=$CLJS.dg.j($CLJS.zf,$CLJS.Ik.g($CLJS.Jk.h(D3),$CLJS.cf.h(function(a){var b=new $CLJS.h(null,2,[$CLJS.Bj,$CLJS.FJ,$CLJS.ri,a],null);return $CLJS.F.g(a,$CLJS.Gv)?$CLJS.R.j(b,$CLJS.ci,!0):b})),$CLJS.SJ);$CLJS.p2.m(null,$CLJS.FJ,function(a,b,c){a=$CLJS.Ee(c);a=$CLJS.I.g(a,$CLJS.ri);return $CLJS.B3.h(a)});$CLJS.w2.m(null,$CLJS.FJ,function(a,b,c){return $CLJS.$k.l($CLJS.G([new $CLJS.h(null,1,[$CLJS.WG,$CLJS.V1.j(a,b,c)],null),$CLJS.Yl(c,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ci,$CLJS.c2],null))]))});
$CLJS.E3=function(){var a=$CLJS.Re($CLJS.N),b=$CLJS.Re($CLJS.N),c=$CLJS.Re($CLJS.N),d=$CLJS.Re($CLJS.N),e=$CLJS.I.j(new $CLJS.h(null,1,[$CLJS.ej,$CLJS.UE],null),$CLJS.ej,$CLJS.Uh.o?$CLJS.Uh.o():$CLJS.Uh.call(null));return new $CLJS.di($CLJS.Eh.g("metabase.lib.temporal-bucket","available-temporal-buckets-method"),function(f,k,l){return $CLJS.SE(l)},e,a,b,c,d)}();$CLJS.E3.m(null,$CLJS.ci,function(){return $CLJS.ah});
$CLJS.F3=function(){function a(d,e,f){return $CLJS.E3.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();