var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.schema.order_by.js");require("./metabase.util.malli.registry.js");
'use strict';var y6,z6,D6,F6,nma,H6,I6,K6,M6,O6,oma,U6,V6,$6,a7,b7,d7,g7,h7,i7,k7;$CLJS.x6=new $CLJS.M(null,"many-pks?","many-pks?",-459675021);y6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.common","metabase.lib.schema.drill-thru/drill-thru.common",1573044895);z6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.pk","metabase.lib.schema.drill-thru/drill-thru.pk",339574481);$CLJS.A6=new $CLJS.M("drill-thru","quick-filter","drill-thru/quick-filter",-1465756422);
$CLJS.B6=new $CLJS.M(null,"row","row",-570139521);$CLJS.C6=new $CLJS.M(null,"object-id","object-id",-754527291);D6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.distribution","metabase.lib.schema.drill-thru/drill-thru.distribution",937770403);$CLJS.E6=new $CLJS.M(null,"pivots","pivots",90109371);F6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.pivot","metabase.lib.schema.drill-thru/drill-thru.pivot",-1408664484);
nma=new $CLJS.M("metabase.lib.schema.drill-thru","context","metabase.lib.schema.drill-thru/context",122725764);$CLJS.G6=new $CLJS.M(null,"location","location",1815599388);H6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.column-filter","metabase.lib.schema.drill-thru/drill-thru.column-filter",603928893);I6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.summarize-column-by-time","metabase.lib.schema.drill-thru/drill-thru.summarize-column-by-time",1590982743);
$CLJS.J6=new $CLJS.M(null,"initial-op","initial-op",918033121);K6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.summarize-column","metabase.lib.schema.drill-thru/drill-thru.summarize-column",1770935578);$CLJS.L6=new $CLJS.M("drill-thru","column-filter","drill-thru/column-filter",1535293733);M6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.automatic-insights","metabase.lib.schema.drill-thru/drill-thru.automatic-insights",671448614);
$CLJS.N6=new $CLJS.M("drill-thru","fk-filter","drill-thru/fk-filter",1598101197);O6=new $CLJS.M("drill-thru","automatic-insights","drill-thru/automatic-insights",10252211);oma=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru","metabase.lib.schema.drill-thru/drill-thru",-124537581);$CLJS.P6=new $CLJS.M("drill-thru","sort","drill-thru/sort",511059541);$CLJS.Q6=new $CLJS.M("drill-thru","summarize-column","drill-thru/summarize-column",-156285576);
$CLJS.R6=new $CLJS.M(null,"sort-directions","sort-directions",300459345);$CLJS.S6=new $CLJS.M("drill-thru","pk","drill-thru/pk",1721977789);$CLJS.T6=new $CLJS.M(null,"aggregations","aggregations",-1081114338);U6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.fk-details","metabase.lib.schema.drill-thru/drill-thru.fk-details",233147406);V6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.quick-filter","metabase.lib.schema.drill-thru/drill-thru.quick-filter",-1703184103);
$CLJS.W6=new $CLJS.M(null,"row-count","row-count",1060167988);$CLJS.X6=new $CLJS.M("drill-thru","fk-details","drill-thru/fk-details",-687193600);$CLJS.Y6=new $CLJS.M("drill-thru","distribution","drill-thru/distribution",1480876450);$CLJS.Z6=new $CLJS.M("drill-thru","underlying-records","drill-thru/underlying-records",-1420917644);$6=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.fk-filter","metabase.lib.schema.drill-thru/drill-thru.fk-filter",-1219793148);
a7=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.zoom","metabase.lib.schema.drill-thru/drill-thru.zoom",-262809778);b7=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.keyed","metabase.lib.schema.drill-thru/drill-thru.keyed",-1354821974);$CLJS.c7=new $CLJS.M("drill-thru","summarize-column-by-time","drill-thru/summarize-column-by-time",-765720821);
d7=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.zoom-in.timeseries","metabase.lib.schema.drill-thru/drill-thru.zoom-in.timeseries",-890057316);$CLJS.e7=new $CLJS.M("drill-thru","pivot","drill-thru/pivot",-1038336574);$CLJS.f7=new $CLJS.M("metabase.lib.drill-thru","drill-thru","metabase.lib.drill-thru/drill-thru",-67424984);g7=new $CLJS.M("drill-thru","zoom-in.timeseries","drill-thru/zoom-in.timeseries",-168450975);
h7=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.underlying-records","metabase.lib.schema.drill-thru/drill-thru.underlying-records",1571614324);i7=new $CLJS.M("metabase.lib.schema.drill-thru","drill-thru.sort","metabase.lib.schema.drill-thru/drill-thru.sort",-920673199);$CLJS.j7=new $CLJS.M("drill-thru","zoom","drill-thru/zoom",-1212878631);k7=new $CLJS.M("metabase.lib.schema.drill-thru","pivot-types","metabase.lib.schema.drill-thru/pivot-types",1174694312);$CLJS.W(k7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kt,$CLJS.gT,$CLJS.G6,$CLJS.hC],null));$CLJS.W(y6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,$CLJS.ee],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.f7],null)],null)],null));
$CLJS.W(b7,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.C6,$CLJS.Gt],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.x6,$CLJS.Ft],null)],null)],null));$CLJS.W(z6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,b7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.S6],null)],null)],null)],null));
$CLJS.W(U6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,b7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.X6],null)],null)],null)],null));$CLJS.W(a7,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,b7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.j7],null)],null)],null)],null));
$CLJS.W(V6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.A6],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E0,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,$CLJS.Va],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gP,$CLJS.CH],null)],null)],null)],null)],null)],null));
$CLJS.W($6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.N6],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.gP,$CLJS.CH],null)],null)],null));
$CLJS.W(D6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.Y6],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,$CLJS.X0],null)],null)],null));
$CLJS.W(F6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.e7],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.E6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tt,k7,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,$CLJS.X0],null)],null)],null)],null)],null));
$CLJS.W(i7,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.P6],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.R6,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,$CLJS.AN],null)],null)],null)],null));
$CLJS.W(K6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.Q6],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,$CLJS.X0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.T6,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kt,$CLJS.EI,$CLJS.qI,$CLJS.XI],null)],null)],null)],null)],null));
$CLJS.W(I6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.c7],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,$CLJS.X0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GQ,$CLJS.X0],null)],null)],null));
$CLJS.W(H6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.L6],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,$CLJS.X0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.J6,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.lM],null)],null)],null)],null));
$CLJS.W(h7,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.Z6],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W6,$CLJS.tl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.M0,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Va],null)],null)],null)],null));
$CLJS.W(M6,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,O6],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Bj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.f7],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,$CLJS.X0],null)],null)],null));
$CLJS.W(d7,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,y6,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,g7],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W6,$CLJS.tl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.M0,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Va],null)],null)],null)],null));
$CLJS.W(oma,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,y6,new $CLJS.P(null,16,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Oi,$CLJS.jj,$CLJS.Fu,function(a){a=$CLJS.Ee(a);a=$CLJS.I.g(a,$CLJS.Aj);return["Invalid drill thru (unknown :type): ",$CLJS.Lh.l($CLJS.G([a]))].join("")}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S6,z6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.X6,U6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.j7,a7],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.A6,V6],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.N6,$6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Y6,D6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.e7,F6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.P6,i7],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Q6,K6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.c7,I6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.L6,H6],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Z6,h7],null),new $CLJS.P(null,2,5,$CLJS.Q,[O6,M6],null),new $CLJS.P(null,2,5,$CLJS.Q,[g7,d7],null)],null)],null));
$CLJS.W(nma,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Si,$CLJS.X0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Gt],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.B6,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nJ,$CLJS.Va],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,$CLJS.Gt],null)],null)],null)],null)],null));