var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./metabase.mbql.schema.macros.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.malli.registry.js");
'use strict';var MN,NN,ON,Nda,Oda,SN,Pda,VN,WN,XN,YN,Qda,ZN,$N,bO,cO,PN,dO,Rda,eO,Sda,gO,hO,iO,Tda,jO,kO,Uda,mO,Vda,nO,oO,Wda,qO,Xda,Yda,Zda,sO,tO,uO,vO,wO,xO,yO,zO,$da,AO,BO,CO,EO,GO,aea,HO,IO,JO,KO,bea,LO,MO,NO,OO,cea,PO,QO,RO,SO,dea,TO,eea,UO,VO,WO,fea,XO,YO,ZO,$O,bP,cP,gea,dP,eP,hea,fP,hP,iP,jP,kP,iea,lP,jea,mP,nP,pP,kea,qP,rP,sP,lea,vP,wP,mea,nea,xP,zP,AP,BP,oea,EP,FP,pea,qea,rea,GP,HP,IP,JP,KP,LP,sea,MP,tea,NP,OP,QP,SP,TP,UP,VP,uea,XP,vea,wea,YP,ZP,$P,aQ,bQ,xea,cQ,yea,zea,dQ,eQ,fQ,gQ,hQ,Aea,
Bea,Cea,iQ,Dea,jQ,Eea,lQ,mQ,Fea,oQ,pQ,qQ,rQ,Gea,uQ,Hea,Iea,vQ,wQ,xQ,Jea,yQ,zQ,AQ,BQ,Kea,DQ,EQ,FQ,HQ,IQ,Lea,JQ,KQ,Mea,LQ,MQ,NQ,OQ,PQ,QQ,SQ,Nea,TQ,Oea,UQ,VQ,WQ,Pea,Qea,Rea,YQ,ZQ,$Q,Sea,aR,bR,cR,dR,Tea,Uea,fR,Vea,Wea,gR,hR,iR,jR,kR,Xea,lR,mR,nR,oR,Yea,pR,qR,rR,sR,uR,Zea,vR,wR,$ea,xR,yR,zR,afa,AR,bfa,CR,cfa,DR,ER,FR,GR,HR,IR,JR,dfa,efa,ffa,KR,gfa,LR,MR,hfa,ifa,NR,OR,PR,jfa,QR,kfa,RR,lfa,mfa,SR,nfa,ofa,pfa,VR,WR,qfa,YR,ZR,aS,rfa,bS,sfa,cS,dS,eS,tfa,ufa,fS,gS,hS,iS,vfa,kS,lS,wfa,mS,nS,oS,pS,qS,rS,sS,tS,
xfa,uS,vS,yfa,wS,zfa,xS,yS,zS,Afa,AS,BS,Bfa,Cfa,CS,DS,ES,Dfa,Efa,FS,Ffa,Gfa,Hfa,IS,Ifa,LS,Jfa,Kfa,MS,NS,Lfa,Mfa,Nfa,OS,PS,QS,RS,SS,TS,US,Ofa,VS,WS,Pfa,XS,Qfa,Rfa,Sfa,YS,ZS,$S,Tfa,aT,bT,Ufa,cT,Vfa,dT,Wfa,Xfa,fT,hT,iT,jT,kT,Yfa,lT,mT,Zfa,nT,oT,pT,qT,rT,sT,tT,uT,$fa,vT,wT,yT,zT,AT,BT,CT,DT,ET,FT,GT,HT,aga,IT,JT,KT,LT,MT,bga,NT,OT,PT,QT,cga,ST,dga,ega,UT,fga,VT,gga,WT,hga,iga,XT,jga,YT,$T,aU,kga,eU,fU,lga,mga,gU,hU,iU,jU,kU,lU,mU,oU,pU,nga;
$CLJS.KN=function(a,b){return function(){function c(l,m,t){return $CLJS.Ed(function(){var u=a.h?a.h(l):a.call(null,l);return $CLJS.n(u)?(u=a.h?a.h(m):a.call(null,m),$CLJS.n(u)?(u=a.h?a.h(t):a.call(null,t),$CLJS.n(u)?(u=b.h?b.h(l):b.call(null,l),$CLJS.n(u)?(u=b.h?b.h(m):b.call(null,m),$CLJS.n(u)?b.h?b.h(t):b.call(null,t):u):u):u):u):u}())}function d(l,m){return $CLJS.Ed(function(){var t=a.h?a.h(l):a.call(null,l);return $CLJS.n(t)?(t=a.h?a.h(m):a.call(null,m),$CLJS.n(t)?(t=b.h?b.h(l):b.call(null,l),
$CLJS.n(t)?b.h?b.h(m):b.call(null,m):t):t):t}())}function e(l){var m=a.h?a.h(l):a.call(null,l);l=$CLJS.n(m)?b.h?b.h(l):b.call(null,l):m;return $CLJS.Ed(l)}var f=null,k=function(){function l(t,u,v,x){var A=null;if(3<arguments.length){A=0;for(var D=Array(arguments.length-3);A<D.length;)D[A]=arguments[A+3],++A;A=new $CLJS.y(D,0,null)}return m.call(this,t,u,v,A)}function m(t,u,v,x){return $CLJS.Ed(function(){var A=f.j(t,u,v);return $CLJS.n(A)?$CLJS.Le(function(D){var J=a.h?a.h(D):a.call(null,D);return $CLJS.n(J)?
b.h?b.h(D):b.call(null,D):J},x):A}())}l.v=3;l.B=function(t){var u=$CLJS.B(t);t=$CLJS.C(t);var v=$CLJS.B(t);t=$CLJS.C(t);var x=$CLJS.B(t);t=$CLJS.Kc(t);return m(u,v,x,t)};l.l=m;return l}();f=function(l,m,t,u){switch(arguments.length){case 0:return!0;case 1:return e.call(this,l);case 2:return d.call(this,l,m);case 3:return c.call(this,l,m,t);default:var v=null;if(3<arguments.length){v=0;for(var x=Array(arguments.length-3);v<x.length;)x[v]=arguments[v+3],++v;v=new $CLJS.y(x,0,null)}return k.l(l,m,t,
v)}throw Error("Invalid arity: "+arguments.length);};f.v=3;f.B=k.B;f.o=function(){return!0};f.h=e;f.g=d;f.j=c;f.l=k.l;return f}()};
$CLJS.LN=function(a){var b=$CLJS.qo.g(a,null),c=$CLJS.SH(b,$CLJS.Ot,function(d){return $CLJS.pn(d,$CLJS.zf)});return function(){function d(k,l,m){return(l=$CLJS.z(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.il,b,$CLJS.Aj,k,$CLJS.Vt,l],null):null}function e(k){return f.j(k,$CLJS.zf,$CLJS.zf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
MN=function(a,b){return $CLJS.xd(a)?$CLJS.I.g(a,b):a};NN=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.sn($CLJS.qo.g($CLJS.il.h(a),null));if($CLJS.n(b)){var e=MN($CLJS.Fu.h(b),c);$CLJS.n(e)?(e=$CLJS.zq(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:MN($CLJS.Du.h(b),c)}return null};ON=function(a,b){return $CLJS.vd(a)||$CLJS.Cl(a)?$CLJS.I.g(a,b):$CLJS.wd(a)?$CLJS.I.g($CLJS.Bf(a),b):null};
Nda=function(a,b){a=$CLJS.Ee(a);var c=$CLJS.I.g(a,$CLJS.il),d=$CLJS.I.g(a,$CLJS.jj);b=$CLJS.Ee(b);var e=$CLJS.I.j(b,$CLJS.Vt,$CLJS.Jaa),f=$CLJS.I.j(b,$CLJS.Eaa,!0),k=$CLJS.I.g(b,$CLJS.RE),l=$CLJS.I.j(b,$CLJS.PE,$CLJS.NE),m=NN(a,$CLJS.Lq.h(c),k,b);if($CLJS.n(m))return m;m=NN(a,$CLJS.ju.h(c),k,b);if($CLJS.n(m))return m;m=NN(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=NN(a,function(){var t=$CLJS.Xn.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=NN(a,$CLJS.Lq.h(c),
l,b);if($CLJS.n(m))return m;m=NN(a,$CLJS.ju.h(c),l,b);if($CLJS.n(m))return m;d=NN(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=NN(a,function(){var t=$CLJS.Xn.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?NN(a,e.h?e.h($CLJS.LE):e.call(null,$CLJS.LE),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?NN(a,e.h?e.h($CLJS.LE):e.call(null,$CLJS.LE),l,b):f};
Oda=function(a,b,c){a=$CLJS.Q;var d=$CLJS.dg.g;var e=$CLJS.fl.h(b);var f=$CLJS.Ee(b);var k=$CLJS.I.g(f,$CLJS.il),l=$CLJS.Ee(c);f=$CLJS.I.g(l,$CLJS.RE);l=$CLJS.I.j(l,$CLJS.PE,$CLJS.NE);k=$CLJS.Lq.h(k);f=MN($CLJS.OE.h(k),f);f=$CLJS.n(f)?f:MN($CLJS.OE.h(k),l);d=d.call($CLJS.dg,e,f);return new $CLJS.P(null,2,5,a,[d,Nda(b,c)],null)};
$CLJS.QN=function(a){var b=$CLJS.Ee(a),c=$CLJS.I.g(b,$CLJS.Aj);a=$CLJS.I.g(b,$CLJS.Vt);var d=$CLJS.Ee(null),e=$CLJS.I.j(d,$CLJS.Faa,$CLJS.Qi),f=$CLJS.I.j(d,$CLJS.Haa,Oda);return $CLJS.n(a)?$CLJS.ab(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.H(m,0,null);m=$CLJS.H(m,1,null);l=$CLJS.R.j(l,$CLJS.Qi,m);l=e.h?e.h(l):e.call(null,l);return PN(k,c,t,l)},null,a):null};
SN=function(a,b,c){var d=$CLJS.ED($CLJS.q($CLJS.NG),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.Nh.A($CLJS.NG,$CLJS.RN,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.TN=function(a){return SN($CLJS.qk,a,function(){return $CLJS.TH.h(a)})};Pda=function(){var a=UN;return SN($CLJS.Ot,a,function(){var b=$CLJS.TH.h(a),c=$CLJS.LN(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
VN=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.il,$CLJS.ke(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,a],null):a],null)};WN=function(a){if($CLJS.zd(a)){var b=$CLJS.H(a,0,null),c=$CLJS.H(a,1,null);switch(b instanceof $CLJS.M?b.T:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ts,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,VN(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ss,VN(c)],null);default:return VN(a)}}else return VN(a)};
XN=function(a,b){var c=$CLJS.zd(b);return c?(c=$CLJS.B(b)instanceof $CLJS.M)?$CLJS.ud(a)?(b=$CLJS.B(b),a=$CLJS.eh(a),a.h?a.h(b):a.call(null,b)):$CLJS.F.g(a,$CLJS.B(b)):c:c};
YN=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Pe(XN,a)],null),$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dt,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,a],null)],null)],null),function(){return function e(d){return new $CLJS.le(null,function(){for(;;){var f=$CLJS.z(d);if(f){if($CLJS.Ad(f)){var k=$CLJS.jc(f),l=$CLJS.E(k),m=$CLJS.oe(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.ld(k,t),v=$CLJS.H(u,0,null);u=$CLJS.H(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,WN(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.re($CLJS.te(m),e($CLJS.kc(f))):$CLJS.re($CLJS.te(m),null)}k=$CLJS.B(f);m=$CLJS.H(k,0,null);k=$CLJS.H(k,1,null);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[m,WN(k)],null),e($CLJS.Kc(f)))}return null}},null,null)}($CLJS.mu(2,2,b))}())],null)};Qda=function(a){return $CLJS.zd(a)&&$CLJS.B(a)instanceof $CLJS.M?$CLJS.B(a):null};
ZN=function(a){return $CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Oi,Qda,$CLJS.Du,["valid instance of one of these MBQL clauses: ",$CLJS.Pu(", ",$CLJS.cf.g($CLJS.B,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.le(null,function(){for(;;){var e=$CLJS.z(c);if(e){if($CLJS.Ad(e)){var f=$CLJS.jc(e),k=$CLJS.E(f),l=$CLJS.oe(k);a:for(var m=0;;)if(m<k){var t=$CLJS.ld(f,m),u=$CLJS.H(t,0,null);t=$CLJS.H(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.ke(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.re($CLJS.te(l),d($CLJS.kc(e))):$CLJS.re($CLJS.te(l),null)}f=$CLJS.B(e);l=$CLJS.H(f,0,null);f=$CLJS.H(f,1,null);return $CLJS.ce(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.ke(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,f],null):f],null),d($CLJS.Kc(e)))}return null}},null,null)}(a)}())};
$N=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.JN],null)],null)};$CLJS.aO={};bO={};cO={};$CLJS.RN=function RN(a,b,c){var e=$CLJS.z(b);b=$CLJS.B(e);var f=$CLJS.C(e);if(f){e=$CLJS.R.j;var k=$CLJS.I.g(a,b);c=RN.j?RN.j(k,f,c):RN.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
PN=function PN(a,b,c,d){var f=$CLJS.z(c);c=$CLJS.B(f);var k=$CLJS.C(f),l=ON(b,c);f=$CLJS.n(a)?a:$CLJS.wd(b)?$CLJS.zf:$CLJS.yd(b)?$CLJS.N:$CLJS.kd(b);return $CLJS.n($CLJS.n(c)?$CLJS.ME.h($CLJS.qd(f)):c)?a:$CLJS.n(c)?(b=ON(f,c),d=PN.A?PN.A(b,l,k,d):PN.call(null,b,l,k,d),$CLJS.Hl(c)&&$CLJS.wd(f)&&c>$CLJS.E(f)&&(b=$CLJS.Ye(c-$CLJS.E(f),null),b=$CLJS.bf.g(f,b),f=null==f||$CLJS.Dd(f)?b:$CLJS.dg.g($CLJS.kd(f),b)),null==f||$CLJS.Cl(f)?$CLJS.R.j(f,c,d):$CLJS.vd(f)?$CLJS.$d.g(f,d):$CLJS.O.g($CLJS.U,$CLJS.R.j($CLJS.Bf(f),
c,d))):$CLJS.xd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.Gaa],null),PN.A?PN.A(f,b,c,d):PN.call(null,f,b,c,d)):$CLJS.n($CLJS.ME.h($CLJS.qd(f)))?$CLJS.$d.g(f,d):$CLJS.zd($CLJS.Fe(f))?f:$CLJS.pd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.ME,!0],null))};dO=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);Rda=new $CLJS.w("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
eO=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);$CLJS.fO=new $CLJS.M(null,"dimension","dimension",543254198);Sda=new $CLJS.w("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);gO=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);hO=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);
iO=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);Tda=new $CLJS.w("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);jO=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);kO=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);Uda=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);
$CLJS.lO=new $CLJS.M(null,"query-hash","query-hash",-1524484965);mO=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);Vda=new $CLJS.M(null,"from","from",1815293044);nO=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);oO=new $CLJS.w(null,"does-not-contain","does-not-contain",1365584674,null);Wda=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);
$CLJS.pO=new $CLJS.M(null,"snippet-name","snippet-name",819240328);qO=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);Xda=new $CLJS.w("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);Yda=new $CLJS.w("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.rO=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);
Zda=new $CLJS.w("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);sO=new $CLJS.M("location","zip_code","location/zip_code",1641155222);tO=new $CLJS.w(null,"ExpressionArg","ExpressionArg",1060743736,null);uO=new $CLJS.w(null,"get-hour","get-hour",-2031721710,null);vO=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);wO=new $CLJS.w(null,"ag:var","ag:var",-1804440286,null);
xO=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);yO=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);zO=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);$da=new $CLJS.M(null,"lon-max","lon-max",1590224717);AO=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);
BO=new $CLJS.w(null,"stddev","stddev",775056588,null);CO=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);$CLJS.DO=new $CLJS.M(null,"snippet","snippet",953581994);EO=new $CLJS.w("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);$CLJS.FO=new $CLJS.M(null,"query","query",-1288509510);GO=new $CLJS.M(null,"string-expression","string-expression",-1395337766);aea=new $CLJS.M(null,"lat-field","lat-field",-830652957);
HO=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);IO=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);JO=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);KO=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);bea=new $CLJS.w("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);
LO=new $CLJS.M("location","country","location/country",1666636202);MO=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);NO=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);OO=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);cea=new $CLJS.w("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);PO=new $CLJS.M(null,"unary","unary",-989314568);
QO=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);RO=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);SO=new $CLJS.w(null,"ceil","ceil",-184398425,null);dea=new $CLJS.M(null,"lon-min","lon-min",-787291357);TO=new $CLJS.w(null,"NonBlankString","NonBlankString",-719244809,null);eea=new $CLJS.M(null,"match","match",1220059550);UO=new $CLJS.w(null,"count-where","count-where",2025939247,null);
VO=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);WO=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);fea=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);XO=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);YO=new $CLJS.w(null,"sum","sum",1777518341,null);ZO=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);
$O=new $CLJS.w("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);$CLJS.aP=new $CLJS.M("date","range","date/range",1647265776);bP=new $CLJS.w(null,"between","between",-1523336493,null);cP=new $CLJS.M(null,"clause-form","clause-form",1820463737);gea=new $CLJS.w("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);dP=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);eP=new $CLJS.w(null,"field","field",338095027,null);
hea=new $CLJS.M(null,"segment-id","segment-id",1810133590);fP=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);$CLJS.gP=new $CLJS.M(null,"filter","filter",-948537934);hP=new $CLJS.w(null,"not-null","not-null",313812992,null);iP=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);jP=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);
kP=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);iea=new $CLJS.M(null,"template-tag","template-tag",310841038);lP=new $CLJS.M(null,"invalid","invalid",412869516);jea=new $CLJS.w("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);mP=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);nP=new $CLJS.w(null,"regex-match-first","regex-match-first",983231809,null);
$CLJS.oP=new $CLJS.M(null,"context","context",-830191113);pP=new $CLJS.w(null,"get-year","get-year",704520253,null);kea=new $CLJS.M(null,"format-rows?","format-rows?",992129486);qP=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);rP=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);sP=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);
$CLJS.tP=new $CLJS.M(null,"parameters","parameters",-1229919748);lea=new $CLJS.M(null,"json-download","json-download",-971130133);$CLJS.uP=new $CLJS.M(null,"card","card",-1430355152);vP=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);wP=new $CLJS.w(null,"!\x3d","!\x3d",-201205829,null);mea=new $CLJS.w("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);nea=new $CLJS.w("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);
xP=new $CLJS.w(null,"expression","expression",1842843403,null);$CLJS.yP=new $CLJS.M("date","month-year","date/month-year",1948031290);zP=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);AP=new $CLJS.w(null,"ends-with","ends-with",1183740516,null);BP=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.CP=new $CLJS.M(null,"joins","joins",1033962699);oea=new $CLJS.M(null,"slug","slug",2029314850);
$CLJS.DP=new $CLJS.M(null,"source-field","source-field",933829534);EP=new $CLJS.w(null,"Field","Field",430385967,null);FP=new $CLJS.w(null,"convert-timezone","convert-timezone",1515728526,null);pea=new $CLJS.w("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);qea=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);rea=new $CLJS.M(null,"items","items",1031954938);
GP=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);HP=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);IP=new $CLJS.M(null,"more","more",-2058821800);JP=new $CLJS.M(null,"first-clause","first-clause",-20953491);KP=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);LP=new $CLJS.w(null,"OrderComparable","OrderComparable",772072595,null);
sea=new $CLJS.w("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);MP=new $CLJS.w(null,"contains","contains",-1977535957,null);tea=new $CLJS.w("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);NP=new $CLJS.w("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);OP=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.PP=new $CLJS.M(null,"widget-type","widget-type",1836256899);
QP=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.RP=new $CLJS.M(null,"fingerprint","fingerprint",598613022);SP=new $CLJS.w(null,"is-null","is-null",-356519403,null);TP=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);UP=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);VP=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);uea=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);
$CLJS.WP=new $CLJS.M(null,"required","required",1807647006);XP=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);vea=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);wea=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);YP=new $CLJS.w(null,"CaseClauses","CaseClauses",-1749071354,null);ZP=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);$P=new $CLJS.M("string","contains","string/contains",1602423827);
aQ=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);bQ=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);xea=new $CLJS.w("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);cQ=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);yea=new $CLJS.w(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);
zea=new $CLJS.w("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);dQ=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);eQ=new $CLJS.w("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);fQ=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);gQ=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);
hQ=new $CLJS.w(null,"share","share",1051097594,null);Aea=new $CLJS.w("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);Bea=new $CLJS.w("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);Cea=new $CLJS.w("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);iQ=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);Dea=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
jQ=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);Eea=new $CLJS.w("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.kQ=new $CLJS.M(null,"collection","collection",-683361892);lQ=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);mQ=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.nQ=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);Fea=new $CLJS.w("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);oQ=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);pQ=new $CLJS.w(null,"metric","metric",2049329604,null);qQ=new $CLJS.w(null,"concat","concat",-467652465,null);rQ=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.sQ=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
Gea=new $CLJS.w(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.tQ=new $CLJS.M("date","relative","date/relative",25987732);uQ=new $CLJS.M("location","city","location/city",-1746973325);Hea=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);Iea=new $CLJS.w("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);vQ=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);wQ=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
xQ=new $CLJS.M("number","between","number/between",97700581);Jea=new $CLJS.w("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);yQ=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);zQ=new $CLJS.w(null,"sqrt","sqrt",370479598,null);AQ=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);BQ=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.CQ=new $CLJS.M(null,"semantic_type","semantic_type",272485089);Kea=new $CLJS.M(null,"metric-id","metric-id",-686486942);DQ=new $CLJS.w(null,"*","*",345799209,null);EQ=new $CLJS.w(null,"+","+",-740910886,null);FQ=new $CLJS.w(null,"-","-",-471816912,null);$CLJS.GQ=new $CLJS.M(null,"breakout","breakout",-732419050);HQ=new $CLJS.w(null,"template-tag","template-tag",1951372565,null);IQ=new $CLJS.M(null,"allowed-for","allowed-for",122724334);Lea=new $CLJS.M(null,"question","question",-1411720117);
JQ=new $CLJS.w(null,"asc","asc",1997386096,null);KQ=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);Mea=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);LQ=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);MQ=new $CLJS.w(null,"\x3c\x3d","\x3c\x3d",1244895369,null);NQ=new $CLJS.w(null,"\x3c","\x3c",993667236,null);OQ=new $CLJS.w(null,"\x3e","\x3e",1085014381,null);
PQ=new $CLJS.w(null,"\x3d","\x3d",-1501502141,null);QQ=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.RQ=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);SQ=new $CLJS.M("string","ends-with","string/ends-with",302681156);Nea=new $CLJS.w("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);TQ=new $CLJS.w(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);
Oea=new $CLJS.w(null,"MetricID","MetricID",-2128635641,null);UQ=new $CLJS.w(null,"and","and",668631710,null);VQ=new $CLJS.w(null,"\x3e\x3d","\x3e\x3d",1016916022,null);WQ=new $CLJS.w(null,"round","round",-645002441,null);Pea=new $CLJS.M(null,"to","to",192099007);$CLJS.XQ=new $CLJS.M("date","single","date/single",1554682003);Qea=new $CLJS.M(null,"action-id","action-id",-1727958578);Rea=new $CLJS.w("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);
YQ=new $CLJS.w(null,"exp","exp",1378825265,null);ZQ=new $CLJS.w(null,"Filter","Filter",-424893332,null);$Q=new $CLJS.w(null,"cum-count","cum-count",-323900016,null);Sea=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);aR=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);bR=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);cR=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
dR=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.eR=new $CLJS.M(null,"source-table","source-table",-225307692);Tea=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);Uea=new $CLJS.w("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);fR=new $CLJS.w(null,"floor","floor",-772394748,null);Vea=new $CLJS.M(null,"middleware","middleware",1462115504);
Wea=new $CLJS.w("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);gR=new $CLJS.M(null,"requires-features","requires-features",-101116256);hR=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);iR=new $CLJS.M(null,"clause-name","clause-name",-996419059);jR=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);kR=new $CLJS.w(null,"now","now",-9994004,null);
Xea=new $CLJS.w("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);lR=new $CLJS.w(null,"not","not",1044554643,null);mR=new $CLJS.w(null,"avg","avg",1837937727,null);nR=new $CLJS.w(null,"sum-where","sum-where",-519087341,null);oR=new $CLJS.M(null,"max-results","max-results",-32858165);Yea=new $CLJS.w("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);pR=new $CLJS.w(null,"case","case",-1510733573,null);
qR=new $CLJS.w(null,"distinct","distinct",-148347594,null);rR=new $CLJS.w(null,"get-second","get-second",-425414791,null);sR=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.tR=new $CLJS.M(null,"join-alias","join-alias",1454206794);uR=new $CLJS.w(null,"is-empty","is-empty",600228619,null);Zea=new $CLJS.w("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
vR=new $CLJS.w(null,"relative-datetime","relative-datetime",-1369266491,null);wR=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);$ea=new $CLJS.M(null,"original","original",-445386197);xR=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);yR=new $CLJS.w(null,"abs","abs",1394505050,null);zR=new $CLJS.w(null,"datetime-diff","datetime-diff",-1521323614,null);afa=new $CLJS.w("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
AR=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);bfa=new $CLJS.w(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.BR=new $CLJS.M(null,"date","date",-1463434462);CR=new $CLJS.M(null,"second-clause","second-clause",-461435645);cfa=new $CLJS.w("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);DR=new $CLJS.w(null,"rtrim","rtrim",979195078,null);
ER=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);FR=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);GR=new $CLJS.w("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);HR=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);IR=new $CLJS.w(null,"CaseOptions","CaseOptions",1989286806,null);JR=new $CLJS.w(null,"or","or",1876275696,null);
dfa=new $CLJS.w("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);efa=new $CLJS.M(null,"constraints","constraints",422775616);ffa=new $CLJS.w("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);KR=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);gfa=new $CLJS.M(null,"csv-download","csv-download",2141432084);LR=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
MR=new $CLJS.w(null,"datetime-add","datetime-add",1850134938,null);hfa=new $CLJS.w("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);ifa=new $CLJS.w("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);NR=new $CLJS.w("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);OR=new $CLJS.w("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
PR=new $CLJS.w("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);jfa=new $CLJS.w("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);QR=new $CLJS.w(null,"get-quarter","get-quarter",-1326657176,null);kfa=new $CLJS.w("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);RR=new $CLJS.w("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);lfa=new $CLJS.w("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
mfa=new $CLJS.w("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);SR=new $CLJS.w(null,"temporal-extract","temporal-extract",-872749364,null);nfa=new $CLJS.w("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);ofa=new $CLJS.w("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);pfa=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.TR=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.UR=new $CLJS.M(null,"database","database",1849087575);VR=new $CLJS.w(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);WR=new $CLJS.w(null,"get-day-of-week","get-day-of-week",-644295017,null);qfa=new $CLJS.w("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);YR=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
ZR=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.$R=new $CLJS.M(null,"expressions","expressions",255689909);aS=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);rfa=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);bS=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
sfa=new $CLJS.w("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);cS=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);dS=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);eS=new $CLJS.w(null,"absolute-datetime","absolute-datetime",1080191062,null);tfa=new $CLJS.w(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);ufa=new $CLJS.M(null,"action","action",-811238024);
fS=new $CLJS.w(null,"get-day","get-day",1768100384,null);gS=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);hS=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);iS=new $CLJS.w(null,"not-empty","not-empty",2029453590,null);$CLJS.jS=new $CLJS.M(null,"native","native",-613060878);vfa=new $CLJS.w("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);kS=new $CLJS.M(null,"page","page",849072397);
lS=new $CLJS.w(null,"length","length",-2065447907,null);wfa=new $CLJS.M(null,"dashboard","dashboard",-631747508);mS=new $CLJS.w(null,"get-week","get-week",752472178,null);nS=new $CLJS.w(null,"get-month","get-month",1271156796,null);oS=new $CLJS.w(null,"dimension","dimension",-2111181571,null);pS=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);qS=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
rS=new $CLJS.w(null,"StringFilterOptions","StringFilterOptions",-847398229,null);sS=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);tS=new $CLJS.w("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);xfa=new $CLJS.w("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);uS=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);vS=new $CLJS.w(null,"substring","substring",-1513569493,null);
yfa=new $CLJS.w("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);wS=new $CLJS.M(null,"internal","internal",-854870097);zfa=new $CLJS.w("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);xS=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);yS=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
zS=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);Afa=new $CLJS.w("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);AS=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);BS=new $CLJS.w(null,"ltrim","ltrim",-1000166486,null);Bfa=new $CLJS.w("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
Cfa=new $CLJS.w("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);CS=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);DS=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);ES=new $CLJS.w("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
Dfa=new $CLJS.w("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);Efa=new $CLJS.w("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);FS=new $CLJS.w(null,"desc","desc",-560950005,null);$CLJS.GS=new $CLJS.M(null,"template-tags","template-tags",1853115685);Ffa=new $CLJS.M(null,"public-question","public-question",629369976);$CLJS.HS=new $CLJS.M(null,"fk-field-id","fk-field-id",1124127357);
Gfa=new $CLJS.w("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);Hfa=new $CLJS.M(null,"binary","binary",-1802232288);IS=new $CLJS.w(null,"time-interval","time-interval",-1949813754,null);Ifa=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.JS=new $CLJS.M(null,"source-query","source-query",198004422);$CLJS.KS=new $CLJS.M(null,"settings","settings",1556144875);
LS=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);Jfa=new $CLJS.w(null,"SegmentID","SegmentID",-1773652704,null);Kfa=new $CLJS.w("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);MS=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);NS=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);
Lfa=new $CLJS.M(null,"executed-by","executed-by",-739811161);Mfa=new $CLJS.w("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);Nfa=new $CLJS.w("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);OS=new $CLJS.M(null,"amount","amount",364489504);PS=new $CLJS.w(null,"percentile","percentile",1039342775,null);QS=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);
RS=new $CLJS.w(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);SS=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);TS=new $CLJS.w(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);US=new $CLJS.w(null,"trim","trim",-1880116002,null);Ofa=new $CLJS.w(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);VS=new $CLJS.w(null,"coalesce","coalesce",-1999813740,null);
WS=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);Pfa=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);XS=new $CLJS.M("string","\x3d","string/\x3d",983744235);Qfa=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);Rfa=new $CLJS.w("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);
Sfa=new $CLJS.w("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);YS=new $CLJS.w(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);ZS=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);$S=new $CLJS.w(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);Tfa=new $CLJS.M(null,"lat-min","lat-min",1630784161);aT=new $CLJS.w(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);
bT=new $CLJS.w(null,"inside","inside",-681932758,null);Ufa=new $CLJS.w("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);cT=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);Vfa=new $CLJS.w("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);dT=new $CLJS.w(null,"starts-with","starts-with",2007034536,null);
Wfa=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);Xfa=new $CLJS.w("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.eT=new $CLJS.M(null,"card-id","card-id",-1770060179);fT=new $CLJS.M(null,"variadic","variadic",882626057);$CLJS.gT=new $CLJS.M(null,"category","category",-593092832);hT=new $CLJS.w(null,"upper","upper",1886775433,null);iT=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);
jT=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);kT=new $CLJS.w(null,"optional","optional",-600484260,null);Yfa=new $CLJS.w("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);lT=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);mT=new $CLJS.M(null,"sugar","sugar",-73788488);Zfa=new $CLJS.M(null,"lat-max","lat-max",841568226);nT=new $CLJS.w(null,"power","power",702679448,null);
oT=new $CLJS.w("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);pT=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);qT=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);rT=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);sT=new $CLJS.w(null,"median","median",-2084869638,null);
tT=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);uT=new $CLJS.w(null,"cum-sum","cum-sum",-589533420,null);$fa=new $CLJS.w("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);vT=new $CLJS.M(null,"y","y",-1757859776);wT=new $CLJS.w(null,"aggregation","aggregation",-1056959073,null);$CLJS.xT=new $CLJS.M(null,"binning","binning",1709835866);yT=new $CLJS.w(null,"TimezoneId","TimezoneId",-1406242397,null);
zT=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);AT=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);BT=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);CT=new $CLJS.w(null,"Reference","Reference",2024574086,null);DT=new $CLJS.M(null,"b","b",1482224470);ET=new $CLJS.M(null,"a","a",-2123407586);FT=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);
GT=new $CLJS.w(null,"replace","replace",853943757,null);HT=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);aga=new $CLJS.w("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);IT=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);JT=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);KT=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);
LT=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);MT=new $CLJS.w(null,"segment","segment",675610331,null);bga=new $CLJS.w("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);NT=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);OT=new $CLJS.w("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);PT=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);
QT=new $CLJS.M("string","starts-with","string/starts-with",1266861170);cga=new $CLJS.w(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.RT=new $CLJS.M(null,"order-by","order-by",1527318070);ST=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);dga=new $CLJS.w("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);ega=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);
$CLJS.TT=new $CLJS.M(null,"condition","condition",1668437652);UT=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);fga=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);VT=new $CLJS.w("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);gga=new $CLJS.M(null,"card-name","card-name",-2035606807);WT=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);
hga=new $CLJS.w("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);iga=new $CLJS.w("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);XT=new $CLJS.w(null,"log","log",45015523,null);jga=new $CLJS.w("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);YT=new $CLJS.w(null,"time","time",-1268547887,null);$CLJS.ZT=new $CLJS.M(null,"database_type","database_type",-54700895);
$T=new $CLJS.w(null,"EqualityComparable","EqualityComparable",-2076258858,null);aU=new $CLJS.w(null,"get-minute","get-minute",-1186349426,null);$CLJS.bU=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.cU=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.dU=new $CLJS.M("date","all-options","date/all-options",549325958);kga=new $CLJS.w("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);
eU=new $CLJS.M("location","state","location/state",-114378652);fU=new $CLJS.w(null,"datetime-subtract","datetime-subtract",1915839224,null);lga=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);mga=new $CLJS.M(null,"lon-field","lon-field",517872067);gU=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);hU=new $CLJS.w(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);
iU=new $CLJS.M(null,"numeric","numeric",-1495594714);jU=new $CLJS.w(null,"variable","variable",1359185035,null);kU=new $CLJS.w(null,"lower","lower",-1534114948,null);lU=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);mU=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.nU=new $CLJS.M(null,"limit","limit",-1355822363);oU=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);
pU=new $CLJS.w(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);nga=new $CLJS.M(null,"pulse","pulse",-244494476);var qU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$G],null),rU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hH],null),sU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XG],null),tU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.TG],null),uU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.dH],null),vU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.oN],null),oga=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.kN],null),pga=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iN],null),wU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,
$CLJS.VK],null),xU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZK],null),yU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.RK],null),zU=new $CLJS.$g(null,new $CLJS.h(null,12,[$CLJS.oi,null,$CLJS.Gv,null,$CLJS.lj,null,$CLJS.ci,null,$CLJS.Gi,null,$CLJS.uj,null,$CLJS.hk,null,$CLJS.Dj,null,$CLJS.ji,null,$CLJS.Cj,null,$CLJS.pi,null,$CLJS.zi,null],null),null),AU=new $CLJS.$g(null,new $CLJS.h(null,7,[$CLJS.Fv,null,$CLJS.wJ,null,$CLJS.ci,null,$CLJS.vi,null,$CLJS.zJ,null,$CLJS.fj,null,$CLJS.Ev,null],null),null),
BU=$CLJS.Hv.g(zU,AU),qga=$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"date bucketing unit"],null)],null),zU),rga=$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"time bucketing unit"],null)],null),AU),CU=$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"datetime bucketing unit"],null)],null),BU),DU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.GL],null),sga=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,
1,[$CLJS.Du,"temporal extract unit"],null),$CLJS.AJ,$CLJS.zi,$CLJS.hk,$CLJS.pL,$CLJS.ML,$CLJS.NL,$CLJS.Dj,$CLJS.Gi,$CLJS.vi,$CLJS.fj,$CLJS.xJ],null),tga=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"datetime-diff unit"],null),$CLJS.wJ,$CLJS.Ev,$CLJS.Fv,$CLJS.Gv,$CLJS.lj,$CLJS.uj,$CLJS.oi,$CLJS.ji],null),EU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"temporal-extract week extraction mode"],null),$CLJS.jL,$CLJS.sL,$CLJS.xL],null),FU=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"relative-datetime unit"],null),$CLJS.ci,$CLJS.Ev,$CLJS.Fv,$CLJS.Gv,$CLJS.lj,$CLJS.uj,$CLJS.oi,$CLJS.ji],null),MU,vga,UU,VU,WU,XU,YU,ZU,$U,LW,xga,MW,yga,zga,NW,Aga,Bga,Cga;$CLJS.W(RO,YN($CLJS.tL,$CLJS.G(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.Lx],null),$CLJS.Bt],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,FU],null)])));var GU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,RO],null);
$CLJS.W(xR,YN($CLJS.rL,$CLJS.G(["n",$CLJS.Bt,"unit",FU])));var uga=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xR],null);
$CLJS.W(mP,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Du,"valid :absolute-datetime clause",$CLJS.Oi,function(a){if($CLJS.Sa(XN($CLJS.nL,a)))a=lP;else{a=$CLJS.id(a);var b=$CLJS.TN(wU);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.BR:$CLJS.uL}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[lP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"not an :absolute-datetime clause"],null),$CLJS.Oe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BR,YN($CLJS.nL,
$CLJS.G(["date",wU,"unit",qga]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uL,YN($CLJS.nL,$CLJS.G(["datetime",xU,"unit",CU]))],null)],null));var HU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mP],null);$CLJS.W(BP,YN($CLJS.hC,$CLJS.G(["time",yU,"unit",rga])));var IU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BP],null),JU=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ot,new $CLJS.h(null,1,[$CLJS.Du,"date or datetime literal"],null),HU,xU,wU],null);
$CLJS.W(jO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ot,new $CLJS.h(null,1,[$CLJS.Du,"time literal"],null),IU,yU],null));$CLJS.W(gU,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ot,new $CLJS.h(null,1,[$CLJS.Du,"temporal literal"],null),JU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jO],null)],null));var KU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gU],null);
$CLJS.W(Pfa,ZN($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof HU?new $CLJS.Dc(function(){return HU},$CLJS.pd(pea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,wS,$CLJS.kj,$CLJS.rk,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),eS,"metabase/mbql/schema.cljc",69,$CLJS.nL,1,!0,168,168,$CLJS.Jc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(HU)?HU.H:null])):null));return $CLJS.n(a)?a:eS}(),HU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof GU?new $CLJS.Dc(function(){return GU},$CLJS.pd(kga,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,
$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vR,"metabase/mbql/schema.cljc",29,$CLJS.tL,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.tL,$CLJS.bz,$CLJS.U($CLJS.Nj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.Lx],null),$CLJS.Bt],null)),$CLJS.ri,$CLJS.U($CLJS.Nj,$CLJS.U(kT,TQ))],null),$CLJS.Jc,"Schema for a valid relative-datetime clause.",
$CLJS.n(GU)?GU.H:null])):null));return $CLJS.n(a)?a:vR}(),GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof IU?new $CLJS.Dc(function(){return IU},$CLJS.pd(mfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,wS,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YT,"metabase/mbql/schema.cljc",27,$CLJS.hC,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hC,$CLJS.hC,$CLJS.U($CLJS.Nj,Ofa),$CLJS.ri,$CLJS.U($CLJS.Nj,Gea)],null),$CLJS.Jc,"Schema for a valid time clause.",$CLJS.n(IU)?IU.H:null])):null));return $CLJS.n(a)?a:YT}(),IU],null)])));
$CLJS.W(WT,YN($CLJS.Aj,$CLJS.G(["value",$CLJS.Gt,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZT,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,qU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TF,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,rU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CQ,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.nt,sU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ri,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,CU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,qU],null)],null)],null)],null)])));var LU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,WT],null);$CLJS.W(BQ,YN($CLJS.dE,$CLJS.G(["expression-name",qU,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.ek],null)])));
MU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BQ],null);
vga=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.Ee(b);b=$CLJS.I.g(c,$CLJS.jH);c=$CLJS.I.g(c,$CLJS.UG);return $CLJS.F.g(b,$CLJS.UG)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.Ee(b);b=$CLJS.I.g(c,$CLJS.jH);c=$CLJS.I.g(c,$CLJS.iH);return $CLJS.F.g(b,$CLJS.iH)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Du,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jH,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"binning strategy"],null),$CLJS.UG,$CLJS.iH,$CLJS.ci],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UG,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),tU],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.iH,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,$CLJS.tl,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"bin width must be \x3e\x3d 0."],null),$CLJS.Ne($CLJS.Pl)],null)],null)],null)],null)));$CLJS.NU=function NU(a){switch(arguments.length){case 1:return NU.h(arguments[0]);case 2:return NU.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.NU.h=function(a){var b=$CLJS.Ee(a);a=$CLJS.I.g(b,$CLJS.gE);b=$CLJS.I.g(b,$CLJS.QI);return $CLJS.NU.g(a,b)};$CLJS.NU.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.fF(a,$CLJS.GF)?zU:$CLJS.fF(a,$CLJS.CG)?AU:$CLJS.fF(a,$CLJS.dG)?BU:null:null;return $CLJS.n(a)?$CLJS.Hd(a,b):!0};$CLJS.NU.v=2;
$CLJS.W(lT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Du,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gE,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,rU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DP,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,vU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QI,new $CLJS.h(null,
1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,CU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tR,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,qU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xT,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,vga],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.NU],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Ne($CLJS.jH)],null)],null));
$CLJS.W(NT,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.H(b,0,null);var c=$CLJS.H(b,1,null);b=$CLJS.H(b,2,null);b=$CLJS.Ee(b);b=$CLJS.I.g(b,$CLJS.gE);return"string"===typeof c?b:!0}],null)],null)}(YN($CLJS.KI,$CLJS.G(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,vU,qU],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lT],null)],null)]))));var OU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,NT],null);
$CLJS.W(KR,ZN($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof MU?new $CLJS.Dc(function(){return MU},$CLJS.pd(NP,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xP,"metabase/mbql/schema.cljc",60,$CLJS.dE,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dE,$CLJS.cU,$CLJS.U($CLJS.Nj,TO),$CLJS.cj,$CLJS.U($CLJS.Nj,$CLJS.U(kT,$CLJS.ek))],null),$CLJS.Jc,"Schema for a valid expression clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:xP}(),MU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof OU?new $CLJS.Dc(function(){return OU},
$CLJS.pd(eQ,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Qr,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,$CLJS.ti,$CLJS.T,$CLJS.jk],["0.39.0",$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",51,$CLJS.KI,1,382,382,$CLJS.Jc,"Schema for a `:field` clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:eP}(),OU],null)])));
$CLJS.PU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KR],null);$CLJS.W(dO,YN($CLJS.zI,$CLJS.G(["aggregation-clause-index",$CLJS.Bt,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.ek],null)])));var QU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dO],null);
$CLJS.W(NO,ZN($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof QU?new $CLJS.Dc(function(){return QU},$CLJS.pd(Mfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wT,"metabase/mbql/schema.cljc",23,$CLJS.zI,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zI,lga,$CLJS.U($CLJS.Nj,$CLJS.Bt),$CLJS.cj,$CLJS.U($CLJS.Nj,$CLJS.U(kT,$CLJS.ek))],null),$CLJS.Jc,"Schema for a valid aggregation clause.",$CLJS.n(QU)?QU.H:null])):null));return $CLJS.n(a)?a:wT}(),QU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof MU?new $CLJS.Dc(function(){return MU},
$CLJS.pd(NP,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xP,"metabase/mbql/schema.cljc",60,$CLJS.dE,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dE,$CLJS.cU,$CLJS.U($CLJS.Nj,TO),$CLJS.cj,
$CLJS.U($CLJS.Nj,$CLJS.U(kT,$CLJS.ek))],null),$CLJS.Jc,"Schema for a valid expression clause.",$CLJS.n(MU)?MU.H:null])):null));return $CLJS.n(a)?a:xP}(),MU],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof OU?new $CLJS.Dc(function(){return OU},$CLJS.pd(eQ,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Qr,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,$CLJS.ti,
$CLJS.T,$CLJS.jk],["0.39.0",$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",51,$CLJS.KI,1,382,382,$CLJS.Jc,"Schema for a `:field` clause.",$CLJS.n(OU)?OU.H:null])):null));return $CLJS.n(a)?a:eP}(),OU],null)])));
var RU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,NO],null),SU=new $CLJS.$g(null,new $CLJS.h(null,11,[$CLJS.Tu,null,$CLJS.cJ,null,$CLJS.gI,null,$CLJS.hJ,null,$CLJS.lI,null,$CLJS.NI,null,$CLJS.uI,null,$CLJS.hv,null,$CLJS.aJ,null,$CLJS.MI,null,$CLJS.iJ,null],null),null),TU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KO],null);
$CLJS.W(mO,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,1,[$CLJS.Oi,function(a){return"string"===typeof a?$CLJS.Wj:$CLJS.n(XN(SU,a))?GO:$CLJS.n(XN($CLJS.Aj,a))?$CLJS.Aj:$CLJS.Yu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wj,$CLJS.Wj],null),new $CLJS.P(null,2,5,$CLJS.Q,[GO,TU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yu,$CLJS.PU],null)],null));UU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mO],null);
VU=new $CLJS.$g(null,new $CLJS.h(null,26,[$CLJS.iI,null,$CLJS.LL,null,$CLJS.Ss,null,$CLJS.HL,null,$CLJS.CL,null,$CLJS.yI,null,$CLJS.tu,null,$CLJS.fI,null,$CLJS.IL,null,$CLJS.zL,null,$CLJS.NI,null,$CLJS.eJ,null,$CLJS.DL,null,$CLJS.PI,null,$CLJS.jI,null,$CLJS.lL,null,$CLJS.Rs,null,$CLJS.mI,null,$CLJS.uI,null,$CLJS.fL,null,$CLJS.KA,null,$CLJS.mL,null,$CLJS.yL,null,$CLJS.qL,null,$CLJS.gJ,null,$CLJS.II,null],null),null);
WU=new $CLJS.$g(null,new $CLJS.h(null,9,[$CLJS.Ns,null,$CLJS.Hs,null,$CLJS.ot,null,$CLJS.qt,null,$CLJS.Js,null,$CLJS.bJ,null,$CLJS.vt,null,$CLJS.Os,null,$CLJS.Ls,null],null),null);XU=new $CLJS.$g(null,new $CLJS.h(null,17,[$CLJS.Rn,null,$CLJS.$I,null,$CLJS.TI,null,$CLJS.LI,null,$CLJS.dJ,null,$CLJS.fJ,null,$CLJS.qI,null,$CLJS.wI,null,$CLJS.rO,null,$CLJS.rI,null,$CLJS.YI,null,$CLJS.RI,null,$CLJS.fk,null,$CLJS.TA,null,$CLJS.EI,null,$CLJS.FI,null,$CLJS.XI,null],null),null);
YU=new $CLJS.$g(null,new $CLJS.h(null,5,[$CLJS.vL,null,$CLJS.hL,null,$CLJS.JL,null,$CLJS.Rs,null,$CLJS.BL,null],null),null);ZU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,cT],null);$U=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zP],null);$CLJS.aV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,CS],null);$CLJS.bV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jT],null);
$CLJS.W(qT,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Du,"numeric expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.vv:$CLJS.n(XN(VU,a))?dR:$CLJS.n(XN(XU,a))?$CLJS.zI:$CLJS.n(XN($CLJS.Aj,a))?$CLJS.Aj:$CLJS.KI}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vv,$CLJS.tl],null),new $CLJS.P(null,2,5,$CLJS.Q,[dR,ZU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zI,$CLJS.bV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KI,
$CLJS.PU],null)],null));var cV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,qT],null);
$CLJS.W(xS,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Du,"datetime expression argument",$CLJS.Oi,function(a){return $CLJS.n(XN(XU,a))?$CLJS.zI:$CLJS.n(XN($CLJS.Aj,a))?$CLJS.Aj:$CLJS.n(XN(YU,a))?GP:$CLJS.Yu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zI,$CLJS.bV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[GP,$CLJS.aV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yu,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,JU,$CLJS.PU],null)],null)],null));
var dV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xS],null);
$CLJS.W(aS,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Du,"expression argument",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.vv:$CLJS.Cd(a)?$CLJS.Ft:$CLJS.n(XN(WU,a))?pS:$CLJS.n(XN(VU,a))?dR:$CLJS.n(XN(YU,a))?GP:"string"===typeof a?$CLJS.Wj:$CLJS.n(XN(SU,a))?GO:$CLJS.n(XN($CLJS.Aj,a))?$CLJS.Aj:$CLJS.Yu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vv,$CLJS.tl],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,$CLJS.Ft],null),new $CLJS.P(null,2,5,$CLJS.Q,[pS,$U],null),new $CLJS.P(null,
2,5,$CLJS.Q,[dR,ZU],null),new $CLJS.P(null,2,5,$CLJS.Q,[GP,$CLJS.aV],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wj,$CLJS.Wj],null),new $CLJS.P(null,2,5,$CLJS.Q,[GO,TU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yu,$CLJS.PU],null)],null));var eV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,aS],null);$CLJS.W(ZR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ot,new $CLJS.h(null,1,[$CLJS.Du,"numeric expression arg or interval"],null),uga,cV],null));
var fV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZR],null);$CLJS.W(xO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Du,"int greater than zero or numeric expression",$CLJS.Oi,function(a){return"number"===typeof a?$CLJS.vv:$CLJS.Yu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vv,tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yu,ZU],null)],null));var wga=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,xO],null);
$CLJS.W(HP,YN($CLJS.NI,$CLJS.G(["a",eV,"b",eV,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,eV],null)])));var gV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HP],null);$CLJS.W(iO,YN($CLJS.gI,$CLJS.G(["s",UU,"start",wga,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,cV],null)])));var hV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iO],null);$CLJS.W(qS,YN($CLJS.KA,$CLJS.G(["s",UU])));var iV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,qS],null);$CLJS.W(MO,YN($CLJS.aJ,$CLJS.G(["s",UU])));
var jV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,MO],null);$CLJS.W(QO,YN($CLJS.iJ,$CLJS.G(["s",UU])));var kV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,QO],null);$CLJS.W(jP,YN($CLJS.cJ,$CLJS.G(["s",UU])));var lV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jP],null);$CLJS.W(VP,YN($CLJS.Tu,$CLJS.G(["s",UU])));var mV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,VP],null);$CLJS.W(CO,YN($CLJS.hv,$CLJS.G(["s",UU])));var nV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,CO],null);
$CLJS.W(pT,YN($CLJS.hJ,$CLJS.G(["s",UU,"match",$CLJS.Wj,"replacement",$CLJS.Wj])));var oV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,pT],null);$CLJS.W(lU,YN($CLJS.lI,$CLJS.G(["a",UU,"b",UU,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,UU],null)])));var pV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lU],null);$CLJS.W(LQ,YN($CLJS.MI,$CLJS.G(["s",UU,"pattern",$CLJS.Wj])));var qV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,LQ],null);
$CLJS.W(LT,YN($CLJS.Rs,$CLJS.G(["x",fV,"y",fV,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,fV],null)])));var rV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,LT],null);$CLJS.W(JT,YN($CLJS.tu,$CLJS.G(["x",cV,"y",fV,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,fV],null)])));var sV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JT],null);$CLJS.W(IT,YN($CLJS.eJ,$CLJS.G(["x",cV,"y",cV,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,cV],null)])));var tV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,IT],null);
$CLJS.W(KT,YN($CLJS.Ss,$CLJS.G(["x",cV,"y",cV,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,cV],null)])));var uV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KT],null);$CLJS.W(YR,YN($CLJS.II,$CLJS.G(["x",cV])));var vV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,YR],null);$CLJS.W(SS,YN($CLJS.iI,$CLJS.G(["x",cV])));var wV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,SS],null);$CLJS.W(oU,YN($CLJS.PI,$CLJS.G(["x",cV])));var xV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,oU],null);$CLJS.W(bR,YN($CLJS.mI,$CLJS.G(["x",cV])));
var yV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bR],null);$CLJS.W(hR,YN($CLJS.jI,$CLJS.G(["x",cV,"y",cV])));var zV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hR],null);$CLJS.W(ER,YN($CLJS.yI,$CLJS.G(["x",cV])));var AV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ER],null);$CLJS.W(yO,YN($CLJS.fI,$CLJS.G(["x",cV])));var BV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,yO],null);$CLJS.W(dS,YN($CLJS.gJ,$CLJS.G(["x",cV])));var CV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dS],null);
$CLJS.W(ZO,YN($CLJS.qL,$CLJS.G(["datetime-x",dV,"datetime-y",dV,"unit",tga])));var DV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,ZO],null);$CLJS.W(rP,YN($CLJS.HL,$CLJS.G(["datetime",dV,"unit",sga,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,EU],null)])));var EV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,rP],null);$CLJS.W(jR,YN($CLJS.mL,$CLJS.G(["date",dV])));var FV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jR],null);$CLJS.W(LR,YN($CLJS.LL,$CLJS.G(["date",dV])));
var GV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,LR],null);$CLJS.W(kO,YN($CLJS.fL,$CLJS.G(["date",dV])));var HV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kO],null);$CLJS.W(mU,YN($CLJS.zL,$CLJS.G(["date",dV,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,EU],null)])));var IV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mU],null);$CLJS.W(vQ,YN($CLJS.yL,$CLJS.G(["date",dV])));var JV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vQ],null);$CLJS.W(FR,YN($CLJS.DL,$CLJS.G(["date",dV])));
var KV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,FR],null);$CLJS.W(VO,YN($CLJS.IL,$CLJS.G(["datetime",dV])));var LV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,VO],null);$CLJS.W(AQ,YN($CLJS.CL,$CLJS.G(["datetime",dV])));var MV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AQ],null);$CLJS.W(OO,YN($CLJS.lL,$CLJS.G(["datetime",dV])));var NV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,OO],null);$CLJS.W(fQ,YN($CLJS.hL,$CLJS.G(["datetime",dV,"to",DU,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,DU],null)])));
var OV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fQ],null),PV=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"datetime arithmetic unit"],null),$CLJS.zJ,$CLJS.wJ,$CLJS.Ev,$CLJS.Fv,$CLJS.Gv,$CLJS.lj,$CLJS.uj,$CLJS.oi,$CLJS.ji],null);$CLJS.W(hO,YN($CLJS.BL,$CLJS.G(["datetime",dV,"amount",cV,"unit",PV])));var QV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hO],null);
$CLJS.W(HO,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return YN(arguments[0],1<b.length?new $CLJS.y(b.slice(1),0,null):null)}($CLJS.vL));var RV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HO],null);$CLJS.W(gO,YN($CLJS.JL,$CLJS.G(["datetime",dV,"amount",cV,"unit",PV])));var SV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gO],null);
$CLJS.W(CS,ZN($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof rV?new $CLJS.Dc(function(){return rV},$CLJS.pd(RR,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EQ,"metabase/mbql/schema.cljc",51,$CLJS.Rs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Rs,$CLJS.gH,$CLJS.U($CLJS.Nj,VR),vT,$CLJS.U($CLJS.Nj,VR),IP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,VR))],null),$CLJS.Jc,"Schema for a valid + clause.",$CLJS.n(rV)?rV.H:null])):null));return $CLJS.n(a)?a:EQ}(),rV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof QV?new $CLJS.Dc(function(){return QV},
$CLJS.pd(Xea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[ZP,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MR,"metabase/mbql/schema.cljc",67,$CLJS.BL,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.BL,$CLJS.uL,$CLJS.U($CLJS.Nj,hU),OS,$CLJS.U($CLJS.Nj,
TS),$CLJS.ri,$CLJS.U($CLJS.Nj,aT)],null),$CLJS.Jc,"Schema for a valid datetime-add clause.",$CLJS.n(QV)?QV.H:null])):null));return $CLJS.n(a)?a:MR}(),QV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof SV?new $CLJS.Dc(function(){return SV},$CLJS.pd(Rda,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,
$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[ZP,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fU,"metabase/mbql/schema.cljc",72,$CLJS.JL,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JL,$CLJS.uL,$CLJS.U($CLJS.Nj,hU),OS,$CLJS.U($CLJS.Nj,TS),$CLJS.ri,$CLJS.U($CLJS.Nj,aT)],null),$CLJS.Jc,"Schema for a valid datetime-subtract clause.",$CLJS.n(SV)?SV.H:null])):null));
return $CLJS.n(a)?a:fU}(),SV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof OV?new $CLJS.Dc(function(){return OV},$CLJS.pd(Vfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hL,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FP,"metabase/mbql/schema.cljc",71,$CLJS.hL,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hL,$CLJS.uL,$CLJS.U($CLJS.Nj,hU),Pea,$CLJS.U($CLJS.Nj,yT),Vda,$CLJS.U($CLJS.Nj,$CLJS.U(kT,yT))],null),$CLJS.Jc,"Schema for a valid convert-timezone clause.",$CLJS.n(OV)?OV.H:null])):null));return $CLJS.n(a)?a:FP}(),OV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&
"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof RV?new $CLJS.Dc(function(){return RV},$CLJS.pd(xfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.vL,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kR,"metabase/mbql/schema.cljc",
45,$CLJS.vL,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.vL],null),$CLJS.Jc,"Schema for a valid now clause.",$CLJS.n(RV)?RV.H:null])):null));return $CLJS.n(a)?a:kR}(),RV],null)])));$CLJS.TV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HR],null);$CLJS.W(nO,YN($CLJS.vt,$CLJS.G(["first-clause",$CLJS.TV,"second-clause",$CLJS.TV,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.TV],null)])));var UV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,nO],null);
$CLJS.W(bQ,YN($CLJS.ot,$CLJS.G(["first-clause",$CLJS.TV,"second-clause",$CLJS.TV,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,$CLJS.TV],null)])));var VV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bQ],null);$CLJS.W(JO,YN($CLJS.qt,$CLJS.G(["clause",$CLJS.TV])));
var WV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,JO],null),XV=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,3,[$CLJS.Du,":field or :expression reference or :relative-datetime",$CLJS.Fu,$CLJS.Oe(":field or :expression reference or :relative-datetime"),$CLJS.Oi,function(a){return $CLJS.n(XN($CLJS.tL,a))?$CLJS.tL:$CLJS.Yu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tL,GU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yu,$CLJS.PU],null)],null);
$CLJS.W(zS,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nt,new $CLJS.h(null,1,[$CLJS.Du,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ot,$CLJS.Ft,$CLJS.tl,$CLJS.Wj,KU,XV,eV,LU],null)],null));var YV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zS],null);
$CLJS.W(QQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Du,"order comparable",$CLJS.Oi,function(a){return $CLJS.n(XN($CLJS.Aj,a))?$CLJS.Aj:$CLJS.Yu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,LU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yu,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ot,$CLJS.tl,$CLJS.Wj,KU,eV,XV],null)],null)],null));var ZV=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,QQ],null);
$CLJS.W(BT,YN($CLJS.Os,$CLJS.G(["field",YV,"value-or-field",YV,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,YV],null)])));var $V=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,BT],null);$CLJS.W(FT,YN($CLJS.bJ,$CLJS.G(["field",YV,"value-or-field",YV,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pi,YV],null)])));var aW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,FT],null);$CLJS.W(AT,YN($CLJS.Ls,$CLJS.G(["field",ZV,"value-or-field",ZV])));
var bW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AT],null);$CLJS.W(zT,YN($CLJS.Hs,$CLJS.G(["field",ZV,"value-or-field",ZV])));var cW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zT],null);$CLJS.W(kP,YN($CLJS.Ns,$CLJS.G(["field",ZV,"value-or-field",ZV])));var dW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,kP],null);$CLJS.W(qP,YN($CLJS.Js,$CLJS.G(["field",ZV,"value-or-field",ZV])));var eW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,qP],null);$CLJS.W(hS,YN($CLJS.oI,$CLJS.G(["field",ZV,"min",ZV,"max",ZV])));
var fW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,hS],null);$CLJS.W(LS,YN($CLJS.tI,$CLJS.G(["lat-field",ZV,"lon-field",ZV,"lat-max",ZV,"lon-min",ZV,"lat-min",ZV,"lon-max",ZV])));var gW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,LS],null);$CLJS.W(UP,YN($CLJS.nI,$CLJS.G(["field",$CLJS.PU])));var hW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,UP],null);$CLJS.W(KQ,YN($CLJS.xI,$CLJS.G(["field",$CLJS.PU])));var iW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KQ],null);$CLJS.W(lQ,YN($CLJS.pI,$CLJS.G(["field",$CLJS.PU])));
var jW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,lQ],null);$CLJS.W(qO,YN($CLJS.vI,$CLJS.G(["field",$CLJS.PU])));var kW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,qO],null),lW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SI,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null)],null);$CLJS.W(yQ,YN($CLJS.BI,$CLJS.G(["field",UU,"string-or-field",UU,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,lW],null)])));var mW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,yQ],null);
$CLJS.W(sR,YN($CLJS.VI,$CLJS.G(["field",UU,"string-or-field",UU,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,lW],null)])));var nW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,sR],null);$CLJS.W(jQ,YN($CLJS.JI,$CLJS.G(["field",UU,"string-or-field",UU,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,lW],null)])));var oW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,jQ],null);$CLJS.W(bS,YN($CLJS.CI,$CLJS.G(["field",UU,"string-or-field",UU,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,lW],null)])));
var pW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,bS],null);$CLJS.W(zO,YN($CLJS.WI,$CLJS.G(["field",$CLJS.PU,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.Bt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kt,$CLJS.Lx,$CLJS.dB,$CLJS.sI],null)],null),"unit",FU,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZI,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null)],null)],null)])));var qW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,zO],null);
$CLJS.W(sS,YN($CLJS.kI,$CLJS.G(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.hN],null),qU],null)])));var rW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,sS],null);
$CLJS.W(zP,ZN($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof UV?new $CLJS.Dc(function(){return UV},$CLJS.pd(oT,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UQ,"metabase/mbql/schema.cljc",15,$CLJS.vt,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.vt,JP,$CLJS.U($CLJS.Nj,ZQ),CR,$CLJS.U($CLJS.Nj,ZQ),TP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,ZQ))],null),$CLJS.Jc,"Schema for a valid and clause.",$CLJS.n(UV)?UV.H:null])):null));return $CLJS.n(a)?a:UQ}(),UV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof VV?new $CLJS.Dc(function(){return VV},
$CLJS.pd(GR,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JR,"metabase/mbql/schema.cljc",14,$CLJS.ot,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ot,JP,$CLJS.U($CLJS.Nj,ZQ),CR,$CLJS.U($CLJS.Nj,ZQ),TP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,ZQ))],null),$CLJS.Jc,
"Schema for a valid or clause.",$CLJS.n(VV)?VV.H:null])):null));return $CLJS.n(a)?a:JR}(),VV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof WV?new $CLJS.Dc(function(){return WV},$CLJS.pd(tS,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),lR,"metabase/mbql/schema.cljc",15,$CLJS.qt,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,$CLJS.bU,$CLJS.U($CLJS.Nj,ZQ)],null),$CLJS.Jc,"Schema for a valid not clause.",$CLJS.n(WV)?WV.H:null])):null));return $CLJS.n(a)?a:lR}(),WV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof bW?new $CLJS.Dc(function(){return bW},$CLJS.pd(PR,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,
$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NQ,"metabase/mbql/schema.cljc",13,$CLJS.Ls,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ls,$CLJS.KI,$CLJS.U($CLJS.Nj,LP),ZS,$CLJS.U($CLJS.Nj,LP)],null),$CLJS.Jc,"Schema for a valid \x3c clause.",$CLJS.n(bW)?bW.H:null])):null));return $CLJS.n(a)?a:NQ}(),bW],null),
new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof dW?new $CLJS.Dc(function(){return dW},$CLJS.pd(VT,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MQ,"metabase/mbql/schema.cljc",14,$CLJS.Ns,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ns,$CLJS.KI,$CLJS.U($CLJS.Nj,LP),ZS,$CLJS.U($CLJS.Nj,LP)],null),$CLJS.Jc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(dW)?dW.H:null])):null));return $CLJS.n(a)?a:MQ}(),dW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof cW?new $CLJS.Dc(function(){return cW},$CLJS.pd(NR,new $CLJS.h(null,
1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),OQ,"metabase/mbql/schema.cljc",13,$CLJS.Hs,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hs,$CLJS.KI,$CLJS.U($CLJS.Nj,LP),ZS,$CLJS.U($CLJS.Nj,LP)],null),$CLJS.Jc,"Schema for a valid \x3e clause.",$CLJS.n(cW)?cW.H:null])):null));
return $CLJS.n(a)?a:OQ}(),cW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof eW?new $CLJS.Dc(function(){return eW},$CLJS.pd(OT,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VQ,"metabase/mbql/schema.cljc",14,$CLJS.Js,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Js,$CLJS.KI,$CLJS.U($CLJS.Nj,LP),ZS,$CLJS.U($CLJS.Nj,LP)],null),$CLJS.Jc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(eW)?eW.H:null])):null));return $CLJS.n(a)?a:VQ}(),eW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof $V?new $CLJS.Dc(function(){return $V},$CLJS.pd(OR,new $CLJS.h(null,
1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",13,$CLJS.Os,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Os,$CLJS.KI,$CLJS.U($CLJS.Nj,$T),ZS,$CLJS.U($CLJS.Nj,$T),AS,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,$T))],null),$CLJS.Jc,"Schema for a valid \x3d clause.",
$CLJS.n($V)?$V.H:null])):null));return $CLJS.n(a)?a:PQ}(),$V],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof aW?new $CLJS.Dc(function(){return aW},$CLJS.pd($O,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wP,"metabase/mbql/schema.cljc",14,$CLJS.bJ,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bJ,$CLJS.KI,$CLJS.U($CLJS.Nj,$T),ZS,$CLJS.U($CLJS.Nj,$T),AS,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,$T))],null),$CLJS.Jc,"Schema for a valid !\x3d clause.",$CLJS.n(aW)?aW.H:null])):null));return $CLJS.n(a)?a:wP}(),aW],null)])));
$CLJS.W(HR,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Du,"valid filter expression",$CLJS.Oi,function(a){return $CLJS.n(XN(YU,a))?$CLJS.uL:$CLJS.n(XN(VU,a))?iU:$CLJS.n(XN(SU,a))?$CLJS.Wj:$CLJS.n(XN(WU,a))?$CLJS.Ft:$CLJS.Yu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uL,$CLJS.aV],null),new $CLJS.P(null,2,5,$CLJS.Q,[iU,ZU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wj,TU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,$U],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yu,ZN($CLJS.G([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof UV?new $CLJS.Dc(function(){return UV},$CLJS.pd(oT,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UQ,"metabase/mbql/schema.cljc",
15,$CLJS.vt,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.vt,JP,$CLJS.U($CLJS.Nj,ZQ),CR,$CLJS.U($CLJS.Nj,ZQ),TP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,ZQ))],null),$CLJS.Jc,"Schema for a valid and clause.",$CLJS.n(UV)?UV.H:null])):null));return $CLJS.n(a)?a:UQ}(),UV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof VV?new $CLJS.Dc(function(){return VV},$CLJS.pd(GR,new $CLJS.h(null,1,[$CLJS.Yj,
!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JR,"metabase/mbql/schema.cljc",14,$CLJS.ot,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.ot,JP,$CLJS.U($CLJS.Nj,ZQ),CR,$CLJS.U($CLJS.Nj,ZQ),TP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,ZQ))],null),$CLJS.Jc,"Schema for a valid or clause.",$CLJS.n(VV)?
VV.H:null])):null));return $CLJS.n(a)?a:JR}(),VV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof WV?new $CLJS.Dc(function(){return WV},$CLJS.pd(tS,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),lR,"metabase/mbql/schema.cljc",15,$CLJS.qt,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qt,$CLJS.bU,$CLJS.U($CLJS.Nj,ZQ)],null),$CLJS.Jc,"Schema for a valid not clause.",$CLJS.n(WV)?WV.H:null])):null));return $CLJS.n(a)?a:lR}(),WV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof $V?new $CLJS.Dc(function(){return $V},$CLJS.pd(OR,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,
$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PQ,"metabase/mbql/schema.cljc",13,$CLJS.Os,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Os,$CLJS.KI,$CLJS.U($CLJS.Nj,$T),ZS,$CLJS.U($CLJS.Nj,$T),AS,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,$T))],null),$CLJS.Jc,"Schema for a valid \x3d clause.",$CLJS.n($V)?$V.H:null])):null));
return $CLJS.n(a)?a:PQ}(),$V],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof aW?new $CLJS.Dc(function(){return aW},$CLJS.pd($O,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wP,"metabase/mbql/schema.cljc",14,$CLJS.bJ,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bJ,$CLJS.KI,$CLJS.U($CLJS.Nj,$T),ZS,$CLJS.U($CLJS.Nj,$T),AS,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,$T))],null),$CLJS.Jc,"Schema for a valid !\x3d clause.",$CLJS.n(aW)?aW.H:null])):null));return $CLJS.n(a)?a:wP}(),aW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof bW?new $CLJS.Dc(function(){return bW},
$CLJS.pd(PR,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NQ,"metabase/mbql/schema.cljc",13,$CLJS.Ls,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ls,$CLJS.KI,$CLJS.U($CLJS.Nj,LP),ZS,$CLJS.U($CLJS.Nj,LP)],null),$CLJS.Jc,"Schema for a valid \x3c clause.",
$CLJS.n(bW)?bW.H:null])):null));return $CLJS.n(a)?a:NQ}(),bW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof cW?new $CLJS.Dc(function(){return cW},$CLJS.pd(NR,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),OQ,"metabase/mbql/schema.cljc",13,$CLJS.Hs,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Hs,$CLJS.KI,$CLJS.U($CLJS.Nj,LP),ZS,$CLJS.U($CLJS.Nj,LP)],null),$CLJS.Jc,"Schema for a valid \x3e clause.",$CLJS.n(cW)?cW.H:null])):null));return $CLJS.n(a)?a:OQ}(),cW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof dW?new $CLJS.Dc(function(){return dW},$CLJS.pd(VT,new $CLJS.h(null,
1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MQ,"metabase/mbql/schema.cljc",14,$CLJS.Ns,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Ns,$CLJS.KI,$CLJS.U($CLJS.Nj,LP),ZS,$CLJS.U($CLJS.Nj,LP)],null),$CLJS.Jc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(dW)?dW.H:null])):
null));return $CLJS.n(a)?a:MQ}(),dW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof eW?new $CLJS.Dc(function(){return eW},$CLJS.pd(OT,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VQ,"metabase/mbql/schema.cljc",14,$CLJS.Js,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Js,$CLJS.KI,$CLJS.U($CLJS.Nj,LP),ZS,$CLJS.U($CLJS.Nj,LP)],null),$CLJS.Jc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(eW)?eW.H:null])):null));return $CLJS.n(a)?a:VQ}(),eW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof fW?new $CLJS.Dc(function(){return fW},$CLJS.pd(ofa,new $CLJS.h(null,
1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bP,"metabase/mbql/schema.cljc",19,$CLJS.oI,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.oI,$CLJS.KI,$CLJS.U($CLJS.Nj,LP),$CLJS.Rn,$CLJS.U($CLJS.Nj,LP),$CLJS.fk,$CLJS.U($CLJS.Nj,LP)],null),$CLJS.Jc,"Schema for a valid between clause.",
$CLJS.n(fW)?fW.H:null])):null));return $CLJS.n(a)?a:bP}(),fW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof mW?new $CLJS.Dc(function(){return mW},$CLJS.pd(qfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dT,"metabase/mbql/schema.cljc",23,$CLJS.BI,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.BI,$CLJS.KI,$CLJS.U($CLJS.Nj,YS),vP,$CLJS.U($CLJS.Nj,YS),$CLJS.cj,$CLJS.U($CLJS.Nj,$CLJS.U(kT,rS))],null),$CLJS.Jc,"Schema for a valid starts-with clause.",$CLJS.n(mW)?mW.H:null])):null));return $CLJS.n(a)?a:dT}(),mW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof nW?new $CLJS.Dc(function(){return nW},
$CLJS.pd(sea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",21,$CLJS.VI,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.VI,$CLJS.KI,$CLJS.U($CLJS.Nj,YS),vP,$CLJS.U($CLJS.Nj,YS),$CLJS.cj,$CLJS.U($CLJS.Nj,$CLJS.U(kT,rS))],null),
$CLJS.Jc,"Schema for a valid ends-with clause.",$CLJS.n(nW)?nW.H:null])):null));return $CLJS.n(a)?a:AP}(),nW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof oW?new $CLJS.Dc(function(){return oW},$CLJS.pd(Eea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",20,$CLJS.JI,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JI,$CLJS.KI,$CLJS.U($CLJS.Nj,YS),vP,$CLJS.U($CLJS.Nj,YS),$CLJS.cj,$CLJS.U($CLJS.Nj,$CLJS.U(kT,rS))],null),$CLJS.Jc,"Schema for a valid contains clause.",$CLJS.n(oW)?oW.H:null])):null));return $CLJS.n(a)?a:MP}(),oW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==
typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof pW?new $CLJS.Dc(function(){return pW},$CLJS.pd(iga,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oO,"metabase/mbql/schema.cljc",36,$CLJS.CI,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.CI,$CLJS.KI,$CLJS.U($CLJS.Nj,YS),vP,$CLJS.U($CLJS.Nj,YS),$CLJS.cj,$CLJS.U($CLJS.Nj,$CLJS.U(kT,rS))],null),$CLJS.Jc,"Schema for a valid does-not-contain clause.",$CLJS.n(pW)?pW.H:null])):null));return $CLJS.n(a)?a:oO}(),pW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof gW?new $CLJS.Dc(function(){return gW},$CLJS.pd(Sfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),
$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bT,"metabase/mbql/schema.cljc",26,$CLJS.tI,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.tI,aea,$CLJS.U($CLJS.Nj,LP),mga,$CLJS.U($CLJS.Nj,LP),Zfa,$CLJS.U($CLJS.Nj,LP),dea,$CLJS.U($CLJS.Nj,LP),Tfa,$CLJS.U($CLJS.Nj,LP),$da,$CLJS.U($CLJS.Nj,
LP)],null),$CLJS.Jc,"Schema for a valid inside clause.",$CLJS.n(gW)?gW.H:null])):null));return $CLJS.n(a)?a:bT}(),gW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof jW?new $CLJS.Dc(function(){return jW},$CLJS.pd(Fea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uR,"metabase/mbql/schema.cljc",28,$CLJS.pI,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pI,$CLJS.KI,$CLJS.U($CLJS.Nj,EP)],null),$CLJS.Jc,"Schema for a valid is-empty clause.",$CLJS.n(jW)?jW.H:null])):null));return $CLJS.n(a)?a:uR}(),jW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&
"undefined"!==typeof kW?new $CLJS.Dc(function(){return kW},$CLJS.pd(Dfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iS,"metabase/mbql/schema.cljc",29,$CLJS.vI,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vI,$CLJS.KI,$CLJS.U($CLJS.Nj,EP)],null),
$CLJS.Jc,"Schema for a valid not-empty clause.",$CLJS.n(kW)?kW.H:null])):null));return $CLJS.n(a)?a:iS}(),kW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof hW?new $CLJS.Dc(function(){return hW},$CLJS.pd(dga,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",27,$CLJS.nI,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nI,$CLJS.KI,$CLJS.U($CLJS.Nj,EP)],null),$CLJS.Jc,"Schema for a valid is-null clause.",$CLJS.n(hW)?hW.H:null])):null));return $CLJS.n(a)?a:SP}(),hW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&
"undefined"!==typeof iW?new $CLJS.Dc(function(){return iW},$CLJS.pd(Rea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",28,$CLJS.xI,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xI,$CLJS.KI,$CLJS.U($CLJS.Nj,EP)],null),
$CLJS.Jc,"Schema for a valid not-null clause.",$CLJS.n(iW)?iW.H:null])):null));return $CLJS.n(a)?a:hP}(),iW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof qW?new $CLJS.Dc(function(){return qW},$CLJS.pd(Sda,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),IS,"metabase/mbql/schema.cljc",33,$CLJS.WI,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.WI,$CLJS.KI,$CLJS.U($CLJS.Nj,EP),$CLJS.bz,$CLJS.U($CLJS.Nj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.Bt,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kt,$CLJS.Lx,$CLJS.dB,$CLJS.sI],null)],null)),$CLJS.ri,$CLJS.U($CLJS.Nj,TQ),$CLJS.cj,$CLJS.U($CLJS.Nj,$CLJS.U(kT,tfa))],null),$CLJS.Jc,"Schema for a valid time-interval clause.",
$CLJS.n(qW)?qW.H:null])):null));return $CLJS.n(a)?a:IS}(),qW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof rW?new $CLJS.Dc(function(){return rW},$CLJS.pd(Zea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),MT,"metabase/mbql/schema.cljc",27,$CLJS.kI,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kI,hea,$CLJS.U($CLJS.Nj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,Jfa,TO],null))],null),$CLJS.Jc,"Schema for a valid segment clause.",$CLJS.n(rW)?rW.H:null])):null));return $CLJS.n(a)?a:MT}(),rW],null)]))],null)],null));
$CLJS.W(uS,YN($CLJS.uI,$CLJS.G(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.rt,new $CLJS.h(null,1,[$CLJS.Du,":case subclause"],null),$CLJS.TV,eV],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Du,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),eV],null)],null)],null)])));
var sW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,uS],null);
$CLJS.W(cT,ZN($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof rV?new $CLJS.Dc(function(){return rV},$CLJS.pd(RR,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EQ,"metabase/mbql/schema.cljc",51,$CLJS.Rs,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Rs,$CLJS.gH,$CLJS.U($CLJS.Nj,VR),vT,$CLJS.U($CLJS.Nj,VR),IP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,VR))],null),$CLJS.Jc,"Schema for a valid + clause.",$CLJS.n(rV)?rV.H:null])):null));return $CLJS.n(a)?a:EQ}(),rV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof sV?new $CLJS.Dc(function(){return sV},
$CLJS.pd(kfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FQ,"metabase/mbql/schema.cljc",51,$CLJS.tu,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.tu,$CLJS.gH,$CLJS.U($CLJS.Nj,TS),
vT,$CLJS.U($CLJS.Nj,VR),IP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,VR))],null),$CLJS.Jc,"Schema for a valid - clause.",$CLJS.n(sV)?sV.H:null])):null));return $CLJS.n(a)?a:FQ}(),sV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO?new $CLJS.Dc(function(){return tV},$CLJS.pd(jfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,iR,$CLJS.Si,$CLJS.kj,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,
new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Zu,"metabase/mbql/schema.cljc",$CLJS.eJ,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.eJ,$CLJS.gH,$CLJS.U($CLJS.Nj,TS),vT,$CLJS.U($CLJS.Nj,TS),IP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,TS))],null),$CLJS.Jc,"Schema for a valid / clause.",$CLJS.n(tV)?tV.H:null])):null));return $CLJS.n(a)?a:$CLJS.Zu}(),tV],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof uV?new $CLJS.Dc(function(){return uV},$CLJS.pd(lfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),DQ,"metabase/mbql/schema.cljc",51,$CLJS.Ss,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Ss,$CLJS.gH,$CLJS.U($CLJS.Nj,TS),vT,$CLJS.U($CLJS.Nj,TS),IP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,TS))],null),$CLJS.Jc,"Schema for a valid * clause.",$CLJS.n(uV)?uV.H:null])):null));return $CLJS.n(a)?a:DQ}(),uV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof gV?new $CLJS.Dc(function(){return gV},
$CLJS.pd(ES,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VS,"metabase/mbql/schema.cljc",58,$CLJS.NI,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.NI,ET,$CLJS.U($CLJS.Nj,tO),DT,$CLJS.U($CLJS.Nj,
tO),IP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,tO))],null),$CLJS.Jc,"Schema for a valid coalesce clause.",$CLJS.n(gV)?gV.H:null])):null));return $CLJS.n(a)?a:VS}(),gV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof iV?new $CLJS.Dc(function(){return iV},$CLJS.pd(Cfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,
$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lS,"metabase/mbql/schema.cljc",56,$CLJS.KA,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KA,$CLJS.OB,$CLJS.U($CLJS.Nj,YS)],null),$CLJS.Jc,"Schema for a valid length clause.",$CLJS.n(iV)?iV.H:null])):null));return $CLJS.n(a)?a:lS}(),iV],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof vV?new $CLJS.Dc(function(){return vV},$CLJS.pd(tea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fR,"metabase/mbql/schema.cljc",55,$CLJS.II,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.II,$CLJS.gH,$CLJS.U($CLJS.Nj,TS)],null),$CLJS.Jc,"Schema for a valid floor clause.",$CLJS.n(vV)?vV.H:null])):null));return $CLJS.n(a)?a:fR}(),vV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof wV?new $CLJS.Dc(function(){return wV},$CLJS.pd(nea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),
$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",54,$CLJS.iI,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iI,$CLJS.gH,$CLJS.U($CLJS.Nj,TS)],null),$CLJS.Jc,"Schema for a valid ceil clause.",$CLJS.n(wV)?
wV.H:null])):null));return $CLJS.n(a)?a:SO}(),wV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof xV?new $CLJS.Dc(function(){return xV},$CLJS.pd(Bea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WQ,"metabase/mbql/schema.cljc",55,$CLJS.PI,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PI,$CLJS.gH,$CLJS.U($CLJS.Nj,TS)],null),$CLJS.Jc,"Schema for a valid round clause.",$CLJS.n(xV)?xV.H:null])):null));return $CLJS.n(a)?a:WQ}(),xV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&
"undefined"!==typeof yV?new $CLJS.Dc(function(){return yV},$CLJS.pd(jga,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yR,"metabase/mbql/schema.cljc",53,$CLJS.mI,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.mI,$CLJS.gH,$CLJS.U($CLJS.Nj,TS)],null),$CLJS.Jc,"Schema for a valid abs clause.",$CLJS.n(yV)?yV.H:null])):null));return $CLJS.n(a)?a:yR}(),yV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof zV?new $CLJS.Dc(function(){return zV},$CLJS.pd(Xfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,
cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[ST,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nT,"metabase/mbql/schema.cljc",69,$CLJS.jI,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.jI,$CLJS.gH,$CLJS.U($CLJS.Nj,TS),vT,$CLJS.U($CLJS.Nj,TS)],null),$CLJS.Jc,"Schema for a valid power clause.",$CLJS.n(zV)?zV.H:null])):null));return $CLJS.n(a)?a:nT}(),zV],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof AV?new $CLJS.Dc(function(){return AV},$CLJS.pd(Uea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[ST,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zQ,"metabase/mbql/schema.cljc",68,$CLJS.yI,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yI,$CLJS.gH,$CLJS.U($CLJS.Nj,TS)],null),$CLJS.Jc,"Schema for a valid sqrt clause.",$CLJS.n(AV)?AV.H:null])):null));return $CLJS.n(a)?a:zQ}(),AV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof BV?new $CLJS.Dc(function(){return BV},$CLJS.pd(Yfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),
$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[ST,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YQ,"metabase/mbql/schema.cljc",67,$CLJS.fI,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fI,$CLJS.gH,$CLJS.U($CLJS.Nj,TS)],null),$CLJS.Jc,"Schema for a valid exp clause.",$CLJS.n(BV)?
BV.H:null])):null));return $CLJS.n(a)?a:YQ}(),BV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof CV?new $CLJS.Dc(function(){return CV},$CLJS.pd(gea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[ST,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XT,"metabase/mbql/schema.cljc",67,$CLJS.gJ,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gJ,$CLJS.gH,$CLJS.U($CLJS.Nj,TS)],null),$CLJS.Jc,"Schema for a valid log clause.",$CLJS.n(CV)?CV.H:null])):null));return $CLJS.n(a)?a:XT}(),CV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&
"undefined"!==typeof sW?new $CLJS.Dc(function(){return sW},$CLJS.pd(EO,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pR,"metabase/mbql/schema.cljc",61,$CLJS.uI,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.uI,$CLJS.ez,$CLJS.U($CLJS.Nj,YP),$CLJS.cj,$CLJS.U($CLJS.Nj,$CLJS.U(kT,IR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(sW)?sW.H:null])):null));return $CLJS.n(a)?a:pR}(),sW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof DV?new $CLJS.Dc(function(){return DV},$CLJS.pd(jea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,
$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.qL,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zR,"metabase/mbql/schema.cljc",65,$CLJS.qL,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.qL,vea,$CLJS.U($CLJS.Nj,hU),wea,$CLJS.U($CLJS.Nj,hU),$CLJS.ri,$CLJS.U($CLJS.Nj,bfa)],null),$CLJS.Jc,"Schema for a valid datetime-diff clause.",
$CLJS.n(DV)?DV.H:null])):null));return $CLJS.n(a)?a:zR}(),DV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof EV?new $CLJS.Dc(function(){return EV},$CLJS.pd(Gfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.HL,"null"],null),null),$CLJS.pd(RS,
new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SR,"metabase/mbql/schema.cljc",71,$CLJS.HL,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.HL,$CLJS.uL,$CLJS.U($CLJS.Nj,hU),$CLJS.ri,$CLJS.U($CLJS.Nj,cga),$CLJS.xi,$CLJS.U($CLJS.Nj,$CLJS.U(kT,pU))],null),$CLJS.Jc,"Schema for a valid temporal-extract clause.",$CLJS.n(EV)?EV.H:null])):null));return $CLJS.n(a)?a:SR}(),EV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof FV?new $CLJS.Dc(function(){return FV},$CLJS.pd(Nea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.HL,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),pP,"metabase/mbql/schema.cljc",71,$CLJS.mL,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mL,$CLJS.BR,$CLJS.U($CLJS.Nj,hU)],null),$CLJS.Jc,"Schema for a valid get-year clause.",$CLJS.n(FV)?FV.H:null])):null));return $CLJS.n(a)?a:pP}(),FV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof GV?new $CLJS.Dc(function(){return GV},$CLJS.pd(Cea,new $CLJS.h(null,1,[$CLJS.Yj,!0],
null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.HL,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QR,"metabase/mbql/schema.cljc",74,$CLJS.LL,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LL,$CLJS.BR,$CLJS.U($CLJS.Nj,hU)],null),$CLJS.Jc,"Schema for a valid get-quarter clause.",
$CLJS.n(GV)?GV.H:null])):null));return $CLJS.n(a)?a:QR}(),GV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof HV?new $CLJS.Dc(function(){return HV},$CLJS.pd(ifa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.HL,"null"],null),null),
$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nS,"metabase/mbql/schema.cljc",72,$CLJS.fL,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fL,$CLJS.BR,$CLJS.U($CLJS.Nj,hU)],null),$CLJS.Jc,"Schema for a valid get-month clause.",$CLJS.n(HV)?HV.H:null])):null));return $CLJS.n(a)?a:nS}(),HV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==
typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof IV?new $CLJS.Dc(function(){return IV},$CLJS.pd(bga,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.HL,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mS,"metabase/mbql/schema.cljc",
71,$CLJS.zL,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.zL,$CLJS.BR,$CLJS.U($CLJS.Nj,hU),$CLJS.xi,$CLJS.U($CLJS.Nj,$CLJS.U(kT,pU))],null),$CLJS.Jc,"Schema for a valid get-week clause.",$CLJS.n(IV)?IV.H:null])):null));return $CLJS.n(a)?a:mS}(),IV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof JV?new $CLJS.Dc(function(){return JV},$CLJS.pd(Yda,new $CLJS.h(null,1,[$CLJS.Yj,
!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.HL,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fS,"metabase/mbql/schema.cljc",70,$CLJS.yL,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yL,$CLJS.BR,$CLJS.U($CLJS.Nj,hU)],null),$CLJS.Jc,"Schema for a valid get-day clause.",
$CLJS.n(JV)?JV.H:null])):null));return $CLJS.n(a)?a:fS}(),JV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof KV?new $CLJS.Dc(function(){return KV},$CLJS.pd(cfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.HL,"null"],null),null),
$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WR,"metabase/mbql/schema.cljc",78,$CLJS.DL,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DL,$CLJS.BR,$CLJS.U($CLJS.Nj,hU)],null),$CLJS.Jc,"Schema for a valid get-day-of-week clause.",$CLJS.n(KV)?KV.H:null])):null));return $CLJS.n(a)?a:WR}(),KV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==
typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof LV?new $CLJS.Dc(function(){return LV},$CLJS.pd(afa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.HL,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uO,"metabase/mbql/schema.cljc",
71,$CLJS.IL,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IL,$CLJS.uL,$CLJS.U($CLJS.Nj,hU)],null),$CLJS.Jc,"Schema for a valid get-hour clause.",$CLJS.n(LV)?LV.H:null])):null));return $CLJS.n(a)?a:uO}(),LV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof MV?new $CLJS.Dc(function(){return MV},$CLJS.pd(Kfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,
$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.HL,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),aU,"metabase/mbql/schema.cljc",73,$CLJS.CL,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CL,$CLJS.uL,$CLJS.U($CLJS.Nj,hU)],null),$CLJS.Jc,"Schema for a valid get-minute clause.",$CLJS.n(MV)?MV.H:null])):null));
return $CLJS.n(a)?a:aU}(),MV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof NV?new $CLJS.Dc(function(){return NV},$CLJS.pd(Yea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.HL,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rR,"metabase/mbql/schema.cljc",73,$CLJS.lL,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lL,$CLJS.uL,$CLJS.U($CLJS.Nj,hU)],null),$CLJS.Jc,"Schema for a valid get-second clause.",$CLJS.n(NV)?NV.H:null])):null));return $CLJS.n(a)?a:rR}(),NV],null)])));
$CLJS.W(KO,ZN($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof hV?new $CLJS.Dc(function(){return hV},$CLJS.pd(Afa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),vS,"metabase/mbql/schema.cljc",59,$CLJS.gI,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.gI,$CLJS.OB,$CLJS.U($CLJS.Nj,YS),$CLJS.qx,$CLJS.U($CLJS.Nj,yea),$CLJS.KA,$CLJS.U($CLJS.Nj,$CLJS.U(kT,TS))],null),$CLJS.Jc,"Schema for a valid substring clause.",$CLJS.n(hV)?hV.H:null])):null));return $CLJS.n(a)?a:vS}(),hV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof jV?new $CLJS.Dc(function(){return jV},
$CLJS.pd($fa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),US,"metabase/mbql/schema.cljc",54,$CLJS.aJ,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aJ,$CLJS.OB,$CLJS.U($CLJS.Nj,YS)],
null),$CLJS.Jc,"Schema for a valid trim clause.",$CLJS.n(jV)?jV.H:null])):null));return $CLJS.n(a)?a:US}(),jV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof lV?new $CLJS.Dc(function(){return lV},$CLJS.pd(dfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,
1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BS,"metabase/mbql/schema.cljc",55,$CLJS.cJ,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cJ,$CLJS.OB,$CLJS.U($CLJS.Nj,YS)],null),$CLJS.Jc,"Schema for a valid ltrim clause.",$CLJS.n(lV)?lV.H:null])):null));return $CLJS.n(a)?a:BS}(),lV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&
"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof kV?new $CLJS.Dc(function(){return kV},$CLJS.pd(Bfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DR,"metabase/mbql/schema.cljc",
55,$CLJS.iJ,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iJ,$CLJS.OB,$CLJS.U($CLJS.Nj,YS)],null),$CLJS.Jc,"Schema for a valid rtrim clause.",$CLJS.n(kV)?kV.H:null])):null));return $CLJS.n(a)?a:DR}(),kV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof oV?new $CLJS.Dc(function(){return oV},$CLJS.pd(Tda,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,
$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GT,"metabase/mbql/schema.cljc",57,$CLJS.hJ,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.hJ,$CLJS.OB,$CLJS.U($CLJS.Nj,YS),eea,$CLJS.U($CLJS.Nj,$CLJS.Wj),$CLJS.Xu,$CLJS.U($CLJS.Nj,$CLJS.Wj)],null),$CLJS.Jc,"Schema for a valid replace clause.",
$CLJS.n(oV)?oV.H:null])):null));return $CLJS.n(a)?a:GT}(),oV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof nV?new $CLJS.Dc(function(){return nV},$CLJS.pd(Efa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,
new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kU,"metabase/mbql/schema.cljc",55,$CLJS.hv,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hv,$CLJS.OB,$CLJS.U($CLJS.Nj,YS)],null),$CLJS.Jc,"Schema for a valid lower clause.",$CLJS.n(nV)?nV.H:null])):null));return $CLJS.n(a)?a:kU}(),nV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==
typeof cO&&"undefined"!==typeof mV?new $CLJS.Dc(function(){return mV},$CLJS.pd(aga,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hT,"metabase/mbql/schema.cljc",55,$CLJS.Tu,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Tu,$CLJS.OB,$CLJS.U($CLJS.Nj,YS)],null),$CLJS.Jc,"Schema for a valid upper clause.",$CLJS.n(mV)?mV.H:null])):null));return $CLJS.n(a)?a:hT}(),mV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof pV?new $CLJS.Dc(function(){return pV},$CLJS.pd(sfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,
$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",56,$CLJS.lI,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lI,ET,$CLJS.U($CLJS.Nj,YS),DT,$CLJS.U($CLJS.Nj,YS),IP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,YS))],null),$CLJS.Jc,"Schema for a valid concat clause.",$CLJS.n(pV)?
pV.H:null])):null));return $CLJS.n(a)?a:qQ}(),pV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof qV?new $CLJS.Dc(function(){return qV},$CLJS.pd(zfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.$R,"null",$CLJS.cv,"null"],null),null),
$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",74,$CLJS.MI,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.MI,$CLJS.OB,$CLJS.U($CLJS.Nj,YS),$CLJS.Su,$CLJS.U($CLJS.Nj,$CLJS.Wj)],null),$CLJS.Jc,"Schema for a valid regex-match-first clause.",$CLJS.n(qV)?qV.H:null])):null));return $CLJS.n(a)?a:nP}(),qV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==
typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof gV?new $CLJS.Dc(function(){return gV},$CLJS.pd(ES,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.$R,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
VS,"metabase/mbql/schema.cljc",58,$CLJS.NI,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.NI,ET,$CLJS.U($CLJS.Nj,tO),DT,$CLJS.U($CLJS.Nj,tO),IP,$CLJS.U($CLJS.Nj,$CLJS.U($CLJS.Mi,tO))],null),$CLJS.Jc,"Schema for a valid coalesce clause.",$CLJS.n(gV)?gV.H:null])):null));return $CLJS.n(a)?a:VS}(),gV],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof sW?new $CLJS.Dc(function(){return sW},
$CLJS.pd(EO,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pR,"metabase/mbql/schema.cljc",61,$CLJS.uI,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.uI,$CLJS.ez,$CLJS.U($CLJS.Nj,YP),$CLJS.cj,
$CLJS.U($CLJS.Nj,$CLJS.U(kT,IR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(sW)?sW.H:null])):null));return $CLJS.n(a)?a:pR}(),sW],null)])));
$CLJS.tW=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Du,":field or :expression reference or expression",$CLJS.Oi,function(a){return $CLJS.n(XN(VU,a))?iU:$CLJS.n(XN(SU,a))?$CLJS.Wj:$CLJS.n(XN(WU,a))?$CLJS.Ft:$CLJS.n(XN(YU,a))?$CLJS.uL:$CLJS.n(XN($CLJS.uI,a))?$CLJS.uI:$CLJS.Yu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[iU,ZU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wj,TU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ft,$U],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uL,$CLJS.aV],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uI,sW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yu,$CLJS.PU],null)],null);$CLJS.W(PT,YN($CLJS.TA,$CLJS.G(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.PU],null)])));var uW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,PT],null);$CLJS.W(AR,YN($CLJS.LI,$CLJS.G(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Mt,$CLJS.PU],null)])));var vW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AR],null);$CLJS.W(IO,YN($CLJS.EI,$CLJS.G(["field-or-expression",$CLJS.tW])));
var wW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,IO],null);$CLJS.W(HT,YN($CLJS.fJ,$CLJS.G(["field-or-expression",$CLJS.tW])));var xW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,HT],null);$CLJS.W(iQ,YN($CLJS.qI,$CLJS.G(["field-or-expression",$CLJS.tW])));var yW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iQ],null);$CLJS.W(WS,YN($CLJS.XI,$CLJS.G(["field-or-expression",$CLJS.tW])));var zW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,WS],null);$CLJS.W(dQ,YN($CLJS.Rn,$CLJS.G(["field-or-expression",$CLJS.tW])));
var AW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dQ],null);$CLJS.W(QP,YN($CLJS.fk,$CLJS.G(["field-or-expression",$CLJS.tW])));var BW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,QP],null);$CLJS.W(KP,YN($CLJS.dJ,$CLJS.G(["field-or-expression",$CLJS.tW,"pred",$CLJS.TV])));var CW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,KP],null);$CLJS.W(aQ,YN($CLJS.TI,$CLJS.G(["pred",$CLJS.TV])));var DW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,aQ],null);$CLJS.W(NS,YN($CLJS.RI,$CLJS.G(["pred",$CLJS.TV])));
var EW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,NS],null);$CLJS.W(fP,YN($CLJS.$I,$CLJS.G(["field-or-expression",$CLJS.tW])));var FW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,fP],null);$CLJS.W(eO,YN($CLJS.rI,$CLJS.G(["field-or-expression",$CLJS.tW])));var GW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,eO],null);$CLJS.W(mQ,YN($CLJS.YI,$CLJS.G(["field-or-expression",$CLJS.tW])));var HW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,mQ],null);$CLJS.W(aR,YN($CLJS.wI,$CLJS.G(["field-or-expression",$CLJS.tW,"percentile",cV])));
var IW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,aR],null);$CLJS.W(QS,YN($CLJS.FI,$CLJS.G(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.gN],null),qU],null)])));var JW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,QS],null);
$CLJS.W(MS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Du,"unnamed aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(XN(VU,a))?dR:$CLJS.Yu}],null),new $CLJS.P(null,2,5,$CLJS.Q,[dR,ZU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Yu,ZN($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof wW?new $CLJS.Dc(function(){return wW},$CLJS.pd(yfa,
new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mR,"metabase/mbql/schema.cljc",60,$CLJS.EI,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EI,iT,$CLJS.U($CLJS.Nj,$S)],null),$CLJS.Jc,"Schema for a valid avg clause.",
$CLJS.n(wW)?wW.H:null])):null));return $CLJS.n(a)?a:mR}(),wW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof xW?new $CLJS.Dc(function(){return xW},$CLJS.pd(Jea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,
new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uT,"metabase/mbql/schema.cljc",64,$CLJS.fJ,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fJ,iT,$CLJS.U($CLJS.Nj,$S)],null),$CLJS.Jc,"Schema for a valid cum-sum clause.",$CLJS.n(xW)?xW.H:null])):null));return $CLJS.n(a)?a:uT}(),xW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==
typeof cO&&"undefined"!==typeof yW?new $CLJS.Dc(function(){return yW},$CLJS.pd(Aea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qR,"metabase/mbql/schema.cljc",65,$CLJS.qI,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qI,iT,$CLJS.U($CLJS.Nj,$S)],null),$CLJS.Jc,"Schema for a valid distinct clause.",$CLJS.n(yW)?yW.H:null])):null));return $CLJS.n(a)?a:qR}(),yW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof FW?new $CLJS.Dc(function(){return FW},$CLJS.pd(Ufa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,
$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.UI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BO,"metabase/mbql/schema.cljc",76,$CLJS.$I,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$I,iT,$CLJS.U($CLJS.Nj,$S)],null),$CLJS.Jc,"Schema for a valid stddev clause.",$CLJS.n(FW)?FW.H:null])):null));return $CLJS.n(a)?a:BO}(),FW],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof zW?new $CLJS.Dc(function(){return zW},$CLJS.pd(nfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),YO,"metabase/mbql/schema.cljc",60,$CLJS.XI,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XI,iT,$CLJS.U($CLJS.Nj,$S)],null),$CLJS.Jc,"Schema for a valid sum clause.",$CLJS.n(zW)?zW.H:null])):null));return $CLJS.n(a)?a:YO}(),zW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof AW?new $CLJS.Dc(function(){return AW},$CLJS.pd(bea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,
$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.au,"metabase/mbql/schema.cljc",60,$CLJS.Rn,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rn,iT,$CLJS.U($CLJS.Nj,$S)],null),$CLJS.Jc,"Schema for a valid min clause.",$CLJS.n(AW)?AW.H:
null])):null));return $CLJS.n(a)?a:$CLJS.au}(),AW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof BW?new $CLJS.Dc(function(){return BW},$CLJS.pd(Xda,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.fu,"metabase/mbql/schema.cljc",60,$CLJS.fk,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fk,iT,$CLJS.U($CLJS.Nj,$S)],null),$CLJS.Jc,"Schema for a valid max clause.",$CLJS.n(BW)?BW.H:null])):null));return $CLJS.n(a)?a:$CLJS.fu}(),BW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&
"undefined"!==typeof JW?new $CLJS.Dc(function(){return JW},$CLJS.pd(xea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pQ,"metabase/mbql/schema.cljc",18,$CLJS.FI,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FI,Kea,$CLJS.U($CLJS.Nj,new $CLJS.P(null,3,5,
$CLJS.Q,[$CLJS.ot,Oea,TO],null))],null),$CLJS.Jc,"Schema for a valid metric clause.",$CLJS.n(JW)?JW.H:null])):null));return $CLJS.n(a)?a:pQ}(),JW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof EW?new $CLJS.Dc(function(){return EW},$CLJS.pd(Wea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,
$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hQ,"metabase/mbql/schema.cljc",62,$CLJS.RI,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RI,$CLJS.jo,$CLJS.U($CLJS.Nj,ZQ)],null),$CLJS.Jc,"Schema for a valid share clause.",$CLJS.n(EW)?EW.H:null])):null));return $CLJS.n(a)?a:hQ}(),EW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof DW?new $CLJS.Dc(function(){return DW},$CLJS.pd(cea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UO,"metabase/mbql/schema.cljc",68,$CLJS.TI,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TI,$CLJS.jo,$CLJS.U($CLJS.Nj,ZQ)],null),$CLJS.Jc,"Schema for a valid count-where clause.",$CLJS.n(DW)?DW.H:null])):null));return $CLJS.n(a)?a:UO}(),DW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof CW?new $CLJS.Dc(function(){return CW},$CLJS.pd(hga,new $CLJS.h(null,1,[$CLJS.Yj,!0],
null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nR,"metabase/mbql/schema.cljc",66,$CLJS.dJ,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.dJ,iT,$CLJS.U($CLJS.Nj,$S),$CLJS.jo,$CLJS.U($CLJS.Nj,ZQ)],null),$CLJS.Jc,"Schema for a valid sum-where clause.",
$CLJS.n(CW)?CW.H:null])):null));return $CLJS.n(a)?a:nR}(),CW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof sW?new $CLJS.Dc(function(){return sW},$CLJS.pd(EO,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,
new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pR,"metabase/mbql/schema.cljc",61,$CLJS.uI,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.uI,$CLJS.ez,$CLJS.U($CLJS.Nj,YP),$CLJS.cj,$CLJS.U($CLJS.Nj,$CLJS.U(kT,IR))],null),$CLJS.Jc,"Schema for a valid case clause.",$CLJS.n(sW)?sW.H:null])):null));return $CLJS.n(a)?a:pR}(),sW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&
"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof HW?new $CLJS.Dc(function(){return HW},$CLJS.pd(Nfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.GI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),sT,"metabase/mbql/schema.cljc",
68,$CLJS.YI,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YI,iT,$CLJS.U($CLJS.Nj,$S)],null),$CLJS.Jc,"Schema for a valid median clause.",$CLJS.n(HW)?HW.H:null])):null));return $CLJS.n(a)?a:sT}(),HW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof IW?new $CLJS.Dc(function(){return IW},$CLJS.pd(hfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,
iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.GI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PS,"metabase/mbql/schema.cljc",72,$CLJS.wI,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.wI,iT,$CLJS.U($CLJS.Nj,$S),$CLJS.wI,$CLJS.U($CLJS.Nj,TS)],null),$CLJS.Jc,"Schema for a valid percentile clause.",$CLJS.n(IW)?IW.H:null])):
null));return $CLJS.n(a)?a:PS}(),IW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof GW?new $CLJS.Dc(function(){return GW},$CLJS.pd(mea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),wO,"metabase/mbql/schema.cljc",77,$CLJS.rI,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rI,iT,$CLJS.U($CLJS.Nj,$S)],null),$CLJS.Jc,"Schema for a valid var clause.",$CLJS.n(GW)?GW.H:null])):null));return $CLJS.n(a)?a:wO}(),GW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof vW?new $CLJS.Dc(function(){return vW},$CLJS.pd(Zda,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,
$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$Q,"metabase/mbql/schema.cljc",74,$CLJS.LI,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.LI,$CLJS.KI,$CLJS.U($CLJS.Nj,$CLJS.U(kT,EP))],null),$CLJS.Jc,"Schema for a valid cum-count clause.",
$CLJS.n(vW)?vW.H:null])):null));return $CLJS.n(a)?a:$Q}(),vW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof uW?new $CLJS.Dc(function(){return uW},$CLJS.pd(zea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([gR,$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,mT,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.hI,"null"],null),null),
$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.wi,"metabase/mbql/schema.cljc",70,$CLJS.TA,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.TA,$CLJS.KI,$CLJS.U($CLJS.Nj,$CLJS.U(kT,EP))],null),$CLJS.Jc,"Schema for a valid count clause.",$CLJS.n(uW)?uW.H:null])):null));return $CLJS.n(a)?a:$CLJS.wi}(),uW],null)]))],null)],null));
$CLJS.W(cS,YN($CLJS.rO,$CLJS.G(["aggregation",MS,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ek,new $CLJS.h(null,1,[$CLJS.Du,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),qU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WG,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),qU],null)],null)])));
$CLJS.W(jT,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,2,[$CLJS.Du,"aggregation clause or numeric expression",$CLJS.Oi,function(a){return $CLJS.n(XN($CLJS.rO,a))?$CLJS.rO:XP}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rO,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,cS],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[XP,MS],null)],null));$CLJS.W(AO,YN($CLJS.HI,$CLJS.G(["field",RU])));var KW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,AO],null);$CLJS.W(cR,YN($CLJS.AI,$CLJS.G(["field",RU])));
LW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,cR],null);
xga=ZN($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof KW?new $CLJS.Dc(function(){return KW},$CLJS.pd(Rfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
JQ,"metabase/mbql/schema.cljc",15,$CLJS.HI,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HI,$CLJS.KI,$CLJS.U($CLJS.Nj,CT)],null),$CLJS.Jc,"Schema for a valid asc clause.",$CLJS.n(KW)?KW.H:null])):null));return $CLJS.n(a)?a:JQ}(),KW],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof LW?new $CLJS.Dc(function(){return LW},$CLJS.pd(Iea,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,
$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),FS,"metabase/mbql/schema.cljc",16,$CLJS.AI,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AI,$CLJS.KI,$CLJS.U($CLJS.Nj,CT)],null),$CLJS.Jc,"Schema for a valid desc clause.",$CLJS.n(LW)?LW.H:null])):null));return $CLJS.n(a)?a:FS}(),LW],null)]));
MW=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kt,$CLJS.DO,$CLJS.uP,$CLJS.fO,$CLJS.vv,$CLJS.kE,$CLJS.BR],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.WG,qU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),qU],null)],null);
yga=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,MW,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.DO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.pO,qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TR,tU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UR,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),tU],null)],null)],null);
zga=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,MW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.uP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.eT,tU],null)],null)],null);
NW=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,MW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Gt],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WP,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null)],null)],null);Aga=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,tT],null);
Bga=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,NW,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Os,$CLJS.fO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fO,OU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PP,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,XO],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cj,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tt,$CLJS.Ui,$CLJS.Gt],null)],null)],null)],null);
$CLJS.OW=new $CLJS.$g(null,new $CLJS.h(null,4,[$CLJS.BR,null,$CLJS.vv,null,$CLJS.Ft,null,$CLJS.kE,null],null),null);Cga=$CLJS.dg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kt],null),$CLJS.OW);
$CLJS.W(yS,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,1,[$CLJS.Oi,$CLJS.jj],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fO,Bga],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.DO,yga],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uP,zga],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.So,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,NW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,Cga],null)],null)],null)],null)],null));
var PW=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GS,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tt,qU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,yS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.H(b,0,null);b=$CLJS.H(b,1,null);return $CLJS.F.g(c,
$CLJS.S.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kQ,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,qU],null)],null)],null),Dga=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,PW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FO,$CLJS.Gt],null)],null)],null),QW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gS],null),RW=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ut,new $CLJS.h(null,1,[$CLJS.Oi,function(a){return $CLJS.n($CLJS.KN($CLJS.xd,
$CLJS.jS)(a))?$CLJS.jS:$CLJS.VG}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jS,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Cu,PW,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jS,$CLJS.Gt],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VG,QW],null)],null),SW=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.S,qU],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TF,rU],null),new $CLJS.P(null,2,5,$CLJS.Q,[pfa,qU],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.CQ,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,sU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RP,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.ek],null)],null)],null),TW=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,pga,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pt,new $CLJS.h(null,1,[$CLJS.Du,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),Ega=$CLJS.dg.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.kt],null),
new $CLJS.$g(null,new $CLJS.h(null,4,[$CLJS.NH,null,$CLJS.wH,null,$CLJS.HH,null,$CLJS.LH,null],null),null)),UW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,rT],null);
$CLJS.W(gQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eR,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),TW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JS,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),RW],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TT,$CLJS.TV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jH,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),Ega],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xH,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.ot,new $CLJS.h(null,1,[$CLJS.Du,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,$CLJS.ZB,$CLJS.$u],null),UW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BH,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),qU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HS,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,vU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RQ,new $CLJS.h(null,
1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,SW],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.KN($CLJS.DD($CLJS.eR,$CLJS.JS),$CLJS.Ne($CLJS.KN($CLJS.eR,$CLJS.JS)))],null)],null));var Fga=$CLJS.Q,VW;var WW=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,gQ],null)],null);
if($CLJS.wd(WW)&&$CLJS.F.g($CLJS.B(WW),$CLJS.wj)){var XW=$CLJS.z(WW);$CLJS.B(XW);var YW=$CLJS.C(XW),Gga=$CLJS.xd($CLJS.B(YW))?YW:$CLJS.ce(null,YW),ZW=$CLJS.z(Gga),Hga=$CLJS.B(ZW),Iga=$CLJS.C(ZW);VW=$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,$CLJS.R.j(Hga,$CLJS.Rn,1)],null),Iga)}else VW=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,WW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"non-empty"],null),$CLJS.z],null)],null);
$CLJS.W(iP,new $CLJS.P(null,3,5,Fga,[$CLJS.vt,VW,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"All join aliases must be unique."],null),function(a){return $CLJS.IN($CLJS.ff($CLJS.Ta,$CLJS.cf.g($CLJS.BH,a)))}],null)],null));var Jga=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,iP],null);
$CLJS.W(rT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.il,new $CLJS.h(null,1,[$CLJS.Du,"Distinct, non-empty sequence of Field clauses"],null),$N(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),$CLJS.PU],null))],null));
$CLJS.W(gS,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.JS,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),RW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eR,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),TW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zI,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),$CLJS.bV],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.GQ,new $CLJS.h(null,1,
[$CLJS.Mt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),$CLJS.PU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$R,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tt,qU,$CLJS.tW],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.xH,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),UW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gP,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.TV],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nU,new $CLJS.h(null,
1,[$CLJS.Mt,!0],null),uU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RT,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$N(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.wj,new $CLJS.h(null,1,[$CLJS.Rn,1],null),xga],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[kS,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[kS,tU],null),new $CLJS.P(null,2,5,$CLJS.Q,[rea,tU],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.CP,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),Jga],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RQ,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,SW],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.F.g(1,$CLJS.E($CLJS.Yl(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.JS,$CLJS.eR],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,
new $CLJS.h(null,1,[$CLJS.Du,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.Ee(a);a=$CLJS.I.g(b,$CLJS.GQ);b=$CLJS.I.g(b,$CLJS.xH);return $CLJS.td($CLJS.Qv.g($CLJS.eh(a),$CLJS.eh(b)))}],null)],null));
$CLJS.$W=$CLJS.Hg([$CLJS.gT,$CLJS.BR,cQ,OP,SQ,eU,xQ,$CLJS.dU,$CLJS.vv,wQ,LO,XS,UT,$CLJS.aP,QT,$P,$CLJS.XQ,uQ,$CLJS.Wi,$CLJS.tQ,sO,$CLJS.yP,$CLJS.sQ,wR,$CLJS.Ft,$CLJS.kE,oQ],[new $CLJS.h(null,1,[IQ,new $CLJS.$g(null,new $CLJS.h(null,5,[$CLJS.gT,null,$CLJS.BR,null,$CLJS.vv,null,$CLJS.Ft,null,$CLJS.kE,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.jj,$CLJS.BR,IQ,new $CLJS.$g(null,new $CLJS.h(null,5,[$CLJS.gT,null,$CLJS.BR,null,$CLJS.dU,null,$CLJS.XQ,null,$CLJS.Wi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.jj,$CLJS.Wj,$CLJS.cH,fT,IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[cQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.jj,iU,$CLJS.cH,fT,IQ,new $CLJS.$g(null,new $CLJS.h(null,5,[$CLJS.gT,null,OP,null,$CLJS.vv,null,$CLJS.Wi,null,sO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.jj,$CLJS.Wj,$CLJS.cH,PO,IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[SQ,null],null),null)],null),new $CLJS.h(null,1,[IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[eU,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.jj,
iU,$CLJS.cH,Hfa,IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[xQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.jj,$CLJS.BR,IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.dU,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.jj,iU,IQ,new $CLJS.$g(null,new $CLJS.h(null,5,[$CLJS.gT,null,OP,null,$CLJS.vv,null,$CLJS.Wi,null,sO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.jj,iU,$CLJS.cH,PO,IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[wQ,null],null),null)],null),new $CLJS.h(null,1,[IQ,new $CLJS.$g(null,
new $CLJS.h(null,1,[LO,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.jj,$CLJS.Wj,$CLJS.cH,fT,IQ,new $CLJS.$g(null,new $CLJS.h(null,8,[$CLJS.gT,null,eU,null,LO,null,XS,null,uQ,null,$CLJS.Wi,null,sO,null,$CLJS.kE,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.jj,$CLJS.Wj,$CLJS.cH,PO,IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[UT,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.jj,$CLJS.BR,IQ,new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.dU,null,$CLJS.aP,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.jj,$CLJS.Wj,$CLJS.cH,PO,IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[QT,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.jj,$CLJS.Wj,$CLJS.cH,PO,IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[$P,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.jj,$CLJS.BR,IQ,new $CLJS.$g(null,new $CLJS.h(null,5,[$CLJS.gT,null,$CLJS.BR,null,$CLJS.dU,null,$CLJS.XQ,null,$CLJS.Wi,null],null),null)],null),new $CLJS.h(null,1,[IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[uQ,null],null),null)],null),new $CLJS.h(null,1,[IQ,
new $CLJS.$g(null,new $CLJS.h(null,1,[$CLJS.Wi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.jj,$CLJS.BR,IQ,new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.dU,null,$CLJS.tQ,null],null),null)],null),new $CLJS.h(null,1,[IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[sO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.jj,$CLJS.BR,IQ,new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.dU,null,$CLJS.yP,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.jj,$CLJS.BR,IQ,new $CLJS.$g(null,new $CLJS.h(null,2,[$CLJS.dU,
null,$CLJS.sQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.jj,iU,$CLJS.cH,fT,IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[wR,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.jj,$CLJS.Ft,IQ,new $CLJS.$g(null,new $CLJS.h(null,3,[$CLJS.gT,null,$CLJS.Wi,null,$CLJS.Ft,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.jj,$CLJS.Wj,IQ,new $CLJS.$g(null,new $CLJS.h(null,8,[$CLJS.gT,null,eU,null,LO,null,XS,null,uQ,null,$CLJS.Wi,null,sO,null,$CLJS.kE,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.jj,
iU,$CLJS.cH,PO,IQ,new $CLJS.$g(null,new $CLJS.h(null,1,[oQ,null],null),null)],null)]);$CLJS.W(tT,$CLJS.dg.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"valid parameter type"],null)],null),$CLJS.Wg($CLJS.$W)));$CLJS.W(XO,$CLJS.dg.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,new $CLJS.h(null,1,[$CLJS.Du,"valid template tag widget type"],null),$CLJS.$u],null),$CLJS.Wg($CLJS.$W)));
$CLJS.W(DS,YN(iea,$CLJS.G(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,qU,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wi,qU],null)],null)],null)])));var aX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,DS],null);$CLJS.W(vO,YN($CLJS.fO,$CLJS.G(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.PU,aX],null)])));var bX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,vO],null);$CLJS.W(sP,YN(rQ,$CLJS.G(["target",aX])));
var cX=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,sP],null),Kga=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.PU,ZN($CLJS.G([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof bX?new $CLJS.Dc(function(){return bX},$CLJS.pd(ffa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,
1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oS,"metabase/mbql/schema.cljc",21,$CLJS.fO,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fO,$CLJS.OL,$CLJS.U($CLJS.Nj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,EP,HQ],null))],null),$CLJS.Jc,"Schema for a valid dimension clause.",$CLJS.n(bX)?bX.H:null])):null));return $CLJS.n(a)?a:oS}(),bX],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iR.h($CLJS.qd("undefined"!==typeof $CLJS.aO&&
"undefined"!==typeof bO&&"undefined"!==typeof cO&&"undefined"!==typeof cX?new $CLJS.Dc(function(){return cX},$CLJS.pd(vfa,new $CLJS.h(null,1,[$CLJS.Yj,!0],null)),$CLJS.Hg([$CLJS.Ti,$CLJS.S,$CLJS.kk,$CLJS.ui,iR,$CLJS.Si,$CLJS.kj,$CLJS.rk,cP,$CLJS.ti,$CLJS.T,$CLJS.jk],[$CLJS.pd(RS,new $CLJS.h(null,1,[$CLJS.T,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jU,"metabase/mbql/schema.cljc",20,rQ,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[rQ,$CLJS.OL,
$CLJS.U($CLJS.Nj,HQ)],null),$CLJS.Jc,"Schema for a valid variable clause.",$CLJS.n(cX)?cX.H:null])):null));return $CLJS.n(a)?a:jU}(),cX],null)]))],null),UN=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,dP],null);
$CLJS.W(dP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.vt,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.KN($CLJS.DD($CLJS.jS,$CLJS.FO),$CLJS.Ne($CLJS.KN($CLJS.jS,$CLJS.FO)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.Ee(b);b=$CLJS.I.g(c,$CLJS.jS);var d=$CLJS.I.g(c,$CLJS.FO);c=$CLJS.I.g(c,$CLJS.jj);c=c instanceof $CLJS.M?c.T:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ot,new $CLJS.h(null,1,[$CLJS.Du,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.mN],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.lN],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kt,$CLJS.FO,$CLJS.jS],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jS,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),Dga],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FO,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),QW],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tP,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,
8,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.jj,Aga],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wi,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),qU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.OL,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),Kga],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Gt],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.S,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),qU],null),new $CLJS.P(null,3,5,$CLJS.Q,[oea,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),
qU],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ci,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Gt],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KS,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[fga,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),DU],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[efa,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[oR,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),uU],null),new $CLJS.P(null,3,5,$CLJS.Q,[WO,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),uU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=$CLJS.Ee(a);a=$CLJS.I.g(b,oR);b=$CLJS.I.g(b,WO);return $CLJS.Sa($CLJS.n(a)?b:a)?!0:a>=b}],null)],
null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Vea,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[Qfa,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null),new $CLJS.P(null,3,5,$CLJS.Q,[kea,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null),new $CLJS.P(null,3,5,$CLJS.Q,[Uda,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),$CLJS.Ft],null),new $CLJS.P(null,3,5,$CLJS.Q,[qea,new $CLJS.h(null,1,[$CLJS.Mt,
!0],null),$CLJS.Ft],null),new $CLJS.P(null,3,5,$CLJS.Q,[Ifa,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Ft],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wda,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Ft],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Wfa,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Ft],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iC,new $CLJS.h(null,
1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.ek,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oP,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,15,5,$CLJS.Q,[$CLJS.kt,ufa,rfa,$CLJS.kQ,gfa,wfa,Tea,fea,lea,uea,Hea,Ffa,nga,Lea,Dea],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lfa,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,tU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Qea,
new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,tU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eT,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,oga],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[gga,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,qU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Sea,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,tU],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[ega,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tt,$CLJS.Gt,$CLJS.Gt],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Mea,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,tU],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nQ,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.wj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tt,$CLJS.Gt,
$CLJS.Gt],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lO,new $CLJS.h(null,1,[$CLJS.Mt,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.nt,$CLJS.Gt],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lt,new $CLJS.h(null,1,[$CLJS.Du,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],null),$CLJS.Ne($CLJS.RQ)],null)],null));var dX=$CLJS.TN(UN);
(function(){var a=Pda();return function(b){if($CLJS.n(dX.h?dX.h(b):dX.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.QN(b);throw $CLJS.hi($CLJS.SG("Invalid query: {0}",$CLJS.G([$CLJS.Lh.l($CLJS.G([c]))])),new $CLJS.h(null,2,[$CLJS.eC,c,$ea,b],null));}})();